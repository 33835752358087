import { ChangeEvent, useCallback } from 'react';

export const useChangeEventPartialUpdate = <T>(
  key: keyof T,
  updater: (payload: Partial<T>) => void,
): ((e: ChangeEvent<{ name?: string; value: string }>) => void) => {
  return useCallback(
    (e: ChangeEvent<{ name?: string; value: string }>) =>
      updater({ [key]: e.target.value } as any),
    [key, updater],
  );
};

export const useChangeEventUpdate = <T>(
  updater: (payload: T) => void,
): ((e: ChangeEvent<{ name?: string; value: unknown }>) => void) => {
  return useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      updater(e.target.value as T),
    [updater],
  );
};

export const useChangeEventOverride = <T>(
  key: keyof T,
  value: T,
  updater: (payload: T) => void,
): ((e: ChangeEvent<{ name?: string; value: unknown }>) => void) => {
  return useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      updater({ ...value, [key]: e.target.value }),
    [key, value, updater],
  );
};

export const useValuePartialUpdate = <T>(
  key: keyof T,
  updater: (payload: T) => void,
) => {
  return useCallback((value: Partial<T>) => updater({ [key]: value } as any), [
    key,
    updater,
  ]);
};
