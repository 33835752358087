import React, { FormEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useActivity } from '../activity.context';
import { useAlerts } from '../../../hooks/useAlerts';
import { useAuthUsers, useDomainPath } from '../../../auth/auth.context';
import { get, post, put } from '../../../fetch';
import { DbDocumentSelect } from '../../../components/DbDocumentSelect.components';
import { PricingIdsMapper } from './PricingIdsMapper.component';
import { findUserGroupPricing, USER_GROUPS } from '@crm/utils';

export const IntegrationsForm: React.FC = () => {
  const { activity } = useActivity();
  const { notify } = useAlerts();
  const { groups } = useAuthUsers();
  const path = useDomainPath(`/activity-integration`);

  const [integrationId, setIntegrationId] = useState<string>();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [externalActivityIds, setExternalActivityIds] = useState<string[]>();
  const [userGroupId, setUserGroupId] = useState<string>();
  const [
    userGroupIdWhenPartialPayment,
    setUserGroupIdWhenPartialPayment,
  ] = useState<string>();
  const [activityDateKey, setActivityDateKey] = useState<string>();
  const [activityTimeKey, setActivityTimeKey] = useState<string>();
  const [mapping, setMapping] = useState<CRM.PricingIdsMapping>({});

  const allPricingGroups = groups
    .concat({
      _id: USER_GROUPS.NORMAL,
      domain: '',
      displayName: 'Normal',
      login: 'normal',
      role: '',
    } as any)
    .filter(group =>
      activity?.pricing?.some(p => findUserGroupPricing(p, group._id)),
    );

  const isAllowedPricingGroup =
    userGroupId &&
    activity?.pricing?.some(p => findUserGroupPricing(p, userGroupId));

  useEffect(() => {
    get<CRM.ActivityIntegrationDocument>(`${path}/${activity._id}`).then(
      integration => {
        if (!integration) {
          return;
        }
        setIntegrationId(integration._id);
        setEnabled(integration.enabled);
        setExternalActivityIds(integration.externalActivityIds);
        setUserGroupId(integration.pricingGroupId);
        setUserGroupIdWhenPartialPayment(
          integration.pricingGroupIdWhenPartialPayment,
        );
        setMapping(integration.pricingMapping);
        setActivityTimeKey(integration.activityTimeKey);
        setActivityDateKey(integration.activityDateKey);
      },
    );
  }, [path, activity._id]);

  const save = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (
        !activity._id ||
        !externalActivityIds ||
        !userGroupId ||
        !activityDateKey ||
        !activityTimeKey
      ) {
        notify('Remplir tous les champs avant de sauvegarder', 'error');
        return;
      }
      const data: CRM.CreateActivityIntegration = {
        kind: 'woocommerce',
        enabled: enabled,
        activity: activity._id,
        externalActivityIds: externalActivityIds,
        pricingGroupId: userGroupId,
        pricingGroupIdWhenPartialPayment: userGroupIdWhenPartialPayment,
        pricingMapping: mapping,
        activityDateKey: activityDateKey,
        activityTimeKey: activityTimeKey,
      };
      if (integrationId) {
        await put<CRM.CreateActivityIntegration>(
          `${path}/${integrationId}`,
          data,
        );
      } else {
        await post<CRM.CreateActivityIntegration>(path, data);
      }
      notify('Le document a été mis à jour', 'success');
    } catch (err) {
      notify('Impossible de mettre à jour le document', 'error');
    }
  };

  const changeUserGroupId = (group?: CRM.UserGroupDocument) => {
    if (!group) {
      return setUserGroupId(undefined);
    }
    setUserGroupId(group._id);
  };

  const changeUserGroupIdWhenPartialPayment = (
    group?: CRM.UserGroupDocument,
  ) => {
    if (!group) {
      return setUserGroupIdWhenPartialPayment(undefined);
    }
    setUserGroupIdWhenPartialPayment(group._id);
  };

  return (
    <form onSubmit={save}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Woocommerce</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label="Activé"
            labelPlacement="end"
            control={
              <Checkbox
                color="secondary"
                onChange={event => setEnabled(event.target.checked)}
                checked={enabled}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Identifants externes (ex: 3752,3820,3922)"
            variant="outlined"
            value={(externalActivityIds || []).join(',')}
            onChange={event =>
              setExternalActivityIds(
                event.target.value.split(',').map(id => id.trim()),
              )
            }
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DbDocumentSelect
            fullWidth
            label="Tarification du groupe"
            selectedId={userGroupId}
            docs={allPricingGroups}
            onChange={changeUserGroupId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DbDocumentSelect
            fullWidth
            label="Tarification du groupe lors d'un paiement par acompte"
            selectedId={userGroupIdWhenPartialPayment}
            docs={allPricingGroups}
            onChange={changeUserGroupIdWhenPartialPayment}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Clé contenant la date"
            placeholder={'Choisissez la date de votre descente'}
            variant="outlined"
            value={activityDateKey || ''}
            onChange={event => setActivityDateKey(event.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Clé contenant l'heure"
            placeholder={'Heure de départ'}
            variant="outlined"
            value={activityTimeKey || ''}
            onChange={event => setActivityTimeKey(event.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <PricingIdsMapper
            allPricing={activity.pricing}
            value={mapping}
            onChange={setMapping}
          />
        </Grid>
        {!isAllowedPricingGroup && (
          <Box display="block">
            <Typography color={'error'}>
              Ce point de vente n'a pas de tarif défini pour tous les types de
              places
            </Typography>
          </Box>
        )}
        <Grid item xs={12}>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disabled={!isAllowedPricingGroup}
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
