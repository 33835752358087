import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useChangeEventUpdate } from '../hooks/useChangeEventUpdate';

interface Props {
  value?: CRM.PaymentMethod;
  onChange: (value: CRM.PaymentMethod) => any;
  disabled?: boolean;
}

export function PaymentMethodSelect({ onChange, value, disabled }: Props) {
  useEffect(() => {
    if (!value) {
      onChange('cash');
    }
  }, [onChange, value]);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="PaymentMethod-select-label">Méthode</InputLabel>
      <Select
        labelId="PaymentMethod-select-label"
        value={value || ''}
        label="Méthode"
        disabled={disabled}
        onChange={useChangeEventUpdate<CRM.PaymentMethod>(onChange)}
      >
        <MenuItem value="cash">Espèce</MenuItem>
        <MenuItem value="card">CB</MenuItem>
        <MenuItem value="check">Chèque</MenuItem>
        <MenuItem value="transfer">Virement</MenuItem>
        <MenuItem value="systempay">Systempay</MenuItem>
        <MenuItem value="paypal">Paypal</MenuItem>
        <MenuItem value="ancv">Ancv</MenuItem>
        <MenuItem value="other">Autre</MenuItem>
      </Select>
    </FormControl>
  );
}
