import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { get, put } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { DomainTabs } from './domainTabs.component';
import { ProvideDomain } from './domain.context';
import { useAlerts } from '../../hooks/useAlerts';

export const UpdateDomainView: React.FC = () => {
  const path = useDomainPath('');
  const { notify } = useAlerts();
  const [value, setValue] = useState<CRM.DomainDocument>();

  const updateDomain = useCallback(
    async (domain: CRM.Domain) => {
      try {
        await put<Partial<CRM.Domain>>(path, domain);
        notify('Le domaine a été mis à jour', 'success');
      } catch (err) {
        notify('Impossible de mettre à jour le domaine', 'error');
      }
    },
    [notify, path],
  );

  useEffect(() => {
    get<CRM.DomainDocument>(path).then(setValue);
  }, [path]);

  if (!value) {
    return <CircularProgress />;
  }

  return (
    <ProvideDomain initialValue={value} onSave={updateDomain}>
      <Box display="flex" flexDirection="column">
        <Box mb={3} mt={3}>
          <Typography variant="h4">{value.name}</Typography>
        </Box>
        <DomainTabs />
      </Box>
    </ProvideDomain>
  );
};
