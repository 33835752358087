import { useDomainPath } from '../auth/auth.context';
import { get } from '../fetch';
import { useEffect, useState } from 'react';

export function useBuses(direction: CRM.BusTravelDirection) {
  const [buses, setBuses] = useState<CRM.EntityName[]>([]);
  const busTravelPath = useDomainPath('/busTravel');

  useEffect(() => {
    get<CRM.EntityName[]>(busTravelPath+'/entitynamesbusdriver/'+direction).then(setBuses);
  }, [busTravelPath, direction]);

  return buses;
}
