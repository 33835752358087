import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useUserGroup } from './userGroup.context';
import { useDomainPath } from '../../auth/auth.context';
import { get, post } from '../../fetch';
import { UserGridItem } from './userGridItem.component';

export const UsersForm: React.FC = () => {
  const userPath = useDomainPath('/user');
  const { userGroup } = useUserGroup();
  const [displayName, setDisplayName] = useState<string>('');
  const [profilePicUrl, setProfilePicUrl] = useState<string>('');
  const [login, setLogin] = useState<string>('');
  const [users, setUsers] = useState<CRM.UserDocument[]>([]);

  const refreshUsers = useCallback(() => {
    if (userGroup._id) {
      get<CRM.UserDocument[]>(`${userPath}?group=${userGroup._id}`).then(
        setUsers,
      );
    }
  }, [userPath, userGroup._id]);

  const addUser = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (userGroup._id) {
        post<CRM.User>(userPath, {
          displayName,
          login,
          userGroup: userGroup._id,
          profilePicUrl
        }).then(refreshUsers);
      }
    },
    [userPath, refreshUsers, userGroup._id, login, displayName, profilePicUrl],
  );

  useEffect(refreshUsers, [refreshUsers, userGroup._id]);

  const handleLoginChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      setLogin(e.target.value as string),
    [],
  );

  const handleDisplayNameChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      setDisplayName(e.target.value as string),
    [],
  );

  const handleProfilePicUrlChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      setProfilePicUrl(e.target.value as string),
    [],
  );

  return (
    <form onSubmit={addUser} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            label="Identifiant de l'utilisateur"
            value={login}
            onChange={handleLoginChange}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            label="Nom d'affichage"
            value={displayName}
            onChange={handleDisplayNameChange}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            label="Lien Photo de profil"
            value={profilePicUrl}
            onChange={handleProfilePicUrlChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            // disabled={isEmpty(login) || isEmpty(displayName)}
            // onClick={save}
          >
            Ajouter
          </Button>
        </Grid>
        <Grid item xs={12}>
          {users.map(user => (
            <UserGridItem onChange={refreshUsers} onDelete={refreshUsers} value={user} groupRole={userGroup.role} key={user._id} />
          ))}
        </Grid>
      </Grid>
    </form>
  );
};
