import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { SpotsView } from './spots.view';
import { UpdateSpotView } from './create/updateSpot.view';
import { CreateSpotView } from './create/createSpot.view';

export const SpotsRouter = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <SpotsView />
        </Route>
        <Route path={`${path}/create`}>
          <CreateSpotView />
        </Route>
        <Route path={`${path}/:id`}>
          <UpdateSpotView />
        </Route>
      </Switch>
    </div>
  );
};
