import React, {
  Context,
  createContext,
  Dispatch,
  Reducer,
  useCallback,
  useContext,
  useReducer,
  useState,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { merge } from 'lodash';
import { DateRange } from '../components/DateRangeSelect.component';
import { Action, EmptyAction } from '../hooks/action';
import { get, patch, post } from '../fetch';
import { useDomainPath } from '../auth/auth.context';
import { useLocation } from 'react-router-dom';

export type UpdateField = {
  id: string;
  field: keyof CRM.ReservationDocument;
  update: any;
  original?: any;
};

type UpdateAction = EmptyAction | Action<UpdateField>;

interface ReservationsContext {
  updateDispatch: Dispatch<UpdateAction>;
  updates: CRM.UpdatesById<CRM.ReservationDocument>;
  updatesClear: () => any;
  updatesRefresh: (updateField: UpdateField) => any;
  updatesSubmit: () => any;
  reservations?: CRM.ReservationDetailed[];
  refreshReservations: () => any;
  setFilterText: (value: string) => any;
  setFilterDays: (value: DateRange) => any;
  setFilterDaysToDay: (value: Dayjs) => any;
  setMatchesStartDayTwo: (value: 0 | 1) => any;
  filterText: string | undefined;
  filterDays: DateRange;
  reservationStatus: CRM.ReservationStatus[] | undefined;
  matchesStartDayTwo?: 0 | 1 | undefined;
}

const reservationsContext: Context<ReservationsContext> = createContext(
  null,
) as any;

export function useReservations(): ReservationsContext {
  const context = useContext(reservationsContext);
  return context;
}

function adaptOtherField(payload: UpdateField) {
  if (payload.field === 'status' && payload.update === 'started') {
    return { startedDate: new Date() };
  }
  return {};
}

const updateReducer: Reducer<
  CRM.UpdatesById<CRM.ReservationDocument>,
  UpdateAction
> = (state, action) => {
  console.log('ACTION', action.type, action.payload);
  if (action.type === 'clear') return {};

  const { payload } = action as Action<UpdateField>;
  const recordedUpdates = state[payload.id] || {};

  switch (action.type) {
    case 'update-field':
      return {
        ...state,
        [payload.id]: {
          ...recordedUpdates,
          ...adaptOtherField(payload),
          [payload.field]: payload.update,
        },
      };
    case 'update-deep-field':
      const fieldUpdates =
        recordedUpdates[payload.field] || payload.original || {};
      merge(fieldUpdates, payload.update);

      return {
        ...state,
        [payload.id]: {
          ...recordedUpdates,
          [payload.field]: fieldUpdates,
        },
      };
  }
  return state;
};

function statusFilter(pathname: string): CRM.ReservationStatus[] {
  if ('/reservations/gift-voucher' === pathname) {
    return ['gift-voucher'];
  }
  if ('/reservations/futur' === pathname) {
    return ['booked'];
  }
  if ('/reservations/cancelled' === pathname) {
    return ['cancelled'];
  }
  if ('/reservations/active' === pathname) {
    return ['started'];
  }
  if ('/reservations/in-activity' === pathname) {
    return ['in-activity'];
  }
  if ('/reservations/archive' === pathname) {
    return ['done'];
  }
  if ('/reservations/refunds' === pathname) {
    return ['booked','started','in-activity','gift-voucher','done'];
  }
  return [];
}

function activeFilter(pathname: string): CRM.ReservationActive {
  if ('/reservations/trash' === pathname) {
    return 0;
  }
  return 1;
}

function paymentOptionalStatusFilter(pathname: string): CRM.ReservationPaiementStatus[] {
  if ('/reservations/wait-to-paid' === pathname) {
    return ['waitToPaid'];
  }
  if ('/reservations/refunds' === pathname) {
    return ['needRefund'];
  }
  return [];
}

export const ProvideReservations: React.FC = ({ children }) => {
  const location = useLocation();
  const reservationPath = useDomainPath('/reservation');
  const [updates, updateDispatch] = useReducer(updateReducer, {});
  const [reservations, setReservations] = useState<CRM.ReservationDetailed[]>();
  const [filterText, setFilterText] = useState<string | undefined>();
  const [reservationStatus, setReservationStatus] = useState<CRM.ReservationStatus[]>();
  const [matchesStartDayTwo, setMatchesStartDayTwo] = useState<0 | 1 | undefined>();
  const [filterDays, setFilterDays] = useState<DateRange>({
    start: dayjs(),
    end: dayjs(),
  });

  const setFilterDaysToDay = useCallback(
    day =>
      setFilterDays({
        start: day,
        end: day,
      }),
    [],
  );

  const refreshReservations = useCallback(() => {
    setReservations(undefined);

    const status = statusFilter(location.pathname);
    const paymentOptionalStatus = paymentOptionalStatusFilter(location.pathname);
    setReservationStatus(status);
    const active = activeFilter(location.pathname);
    const start = filterDays.start?.format('YYYYMMDD');
    const end = filterDays.end?.format('YYYYMMDD');
    const startQuery = start !== undefined ? 'start='+start+'&' : '';
    const endQuery = end !== undefined ? 'end='+end+'&' : '';
    return get<CRM.ReservationDetailed[]>(
      `${reservationPath}?`+startQuery+endQuery+`hydrated=true&status=${status.join(
        '&status=',
      )}&active=${active}&startDayTwo=${matchesStartDayTwo}&paymentOptionalStatus=${paymentOptionalStatus.join(
        '&paymentOptionalStatus=',
      )}`,
    ).then(setReservations);
  }, [reservationPath, filterDays, location, matchesStartDayTwo]);

  const updatesClear = useCallback(() => updateDispatch({ type: 'clear' }), [
    updateDispatch,
  ]);

  const updatesRefresh = useCallback((updateField: UpdateField) => updateDispatch({ type: 'update-field', payload: updateField }), [
    updateDispatch,
  ]);

  const updatesSubmit = useCallback(() => {
    setReservations(undefined);
    return patch<CRM.UpdatesById<CRM.ReservationDocument>>(
      reservationPath,
      updates,
    ).then(() => {
      const reservationsCheckInUpdates: Partial<CRM.ReservationDocument>[] = [];
      const reservationsCheckOutUpdates: Partial<CRM.ReservationDocument>[] = [];
      Object.entries(updates).map(([id, reservation]) => {
        reservation._id = id;
        if(reservation.checkInBusId === "update") {
          reservationsCheckInUpdates.push(reservation);
        }
        if(reservation.checkOutBusId === "update") {
          reservationsCheckOutUpdates.push(reservation);
        } 
        return true;
      });
      reservationsCheckInUpdates.length > 0 && post<Partial<CRM.ReservationDocument>[]>(
        reservationPath + '/fillBusTravelCheckIn',
        reservationsCheckInUpdates,
      );
      reservationsCheckOutUpdates.length > 0 && post<Partial<CRM.ReservationDocument>[]>(
        reservationPath + '/fillBusTravelCheckOut',
        reservationsCheckOutUpdates,
      );
      updatesClear();
      refreshReservations();
    });
  }, [reservationPath, updates, updatesClear, refreshReservations]);

  return (
    <reservationsContext.Provider
      value={{
        updates,
        updateDispatch,
        updatesClear,
        updatesRefresh,
        updatesSubmit,
        reservations,
        refreshReservations,
        filterText,
        filterDays,
        setFilterText,
        setFilterDays,
        setFilterDaysToDay,
        setMatchesStartDayTwo,
        matchesStartDayTwo,
        reservationStatus
      }}
    >
      {children}
    </reservationsContext.Provider>
  );
};
