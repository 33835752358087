import { isEmpty } from 'lodash';

export function ofActivities(activitiesId: string[]) {
  if (isEmpty(activitiesId)) {
    return () => true;
  }
  return (res: CRM.ReservationDetailed) =>
    activitiesId.includes(res.activity._id);
}

export function containsText(filterText: string | undefined) {
  if (!filterText || isEmpty(filterText)) {
    return () => true;
  }
  return (res: CRM.ReservationDetailed) => {
    const filterAsNumber = Number(filterText);
    const text = filterText.toLowerCase();
    const includes = (value: string) =>
      (value || '').toLowerCase().includes(text);

    return (
      res.refNumber === filterAsNumber ||
      res.bookingId === filterAsNumber ||
      includes(res.customer?.email) ||
      includes(res.customer?.name) ||
      includes(res.customer?.firstname) ||
      includes(res.activity?.displayName)
    );
  };
}
