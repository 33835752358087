import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import { flatMap } from 'lodash';
import { GridItemProps } from '../../../components/GridItem';
import { ButtonMenu } from '../../../components/ButtonMenu.component';
import { PositiveNumberField } from '../../../components/fields/PositiveNumberField.component';
import { I18nField } from '../../../components/fields/I18nField.component';
import { Arrays, USER_GROUPS } from '@crm/utils';
import { UserGroups } from './userGroups.component';
import { CrmTableCell, CrmTableRow } from '../../../components/Table.component';
import { PRICE_LABEL_CHILD_UNDER_7 } from '../../../utils/constants';

function buildByQuantityPricing(options: {
  hasByQuantityPricing: boolean;
  userGroupIds: string[];
  setPriceZero?:boolean;
}) {
  if (!options.hasByQuantityPricing) {
    return [
      {
        userGroupIds: options.userGroupIds,
        byQuantity: {
          1: {
            low: options.setPriceZero && options.setPriceZero === true ? 0 : 22,
            high: options.setPriceZero && options.setPriceZero === true ? 0 : 28,
          },
        },
      },
    ];
  }
  return [
    {
      userGroupIds: options.userGroupIds,
      byQuantity: {
        1: {
          low: options.setPriceZero && options.setPriceZero === true ? 0 : 22,
          high: options.setPriceZero && options.setPriceZero === true ? 0 : 28,
        },
        10: {
          low: options.setPriceZero && options.setPriceZero === true ? 0 : 20,
          high: options.setPriceZero && options.setPriceZero === true ? 0 : 26,
        },
        20: {
          low: options.setPriceZero && options.setPriceZero === true ? 0 : 18,
          high: options.setPriceZero && options.setPriceZero === true ? 0 : 24,
        },
      },
    },
  ];
}

function buildByQuantityForNormalPricing(
  hasByQuantityPricing: boolean = false,
  setPriceZero: boolean = false
) {
  return [
    ...buildByQuantityPricing({
      hasByQuantityPricing,
      userGroupIds: [USER_GROUPS.NORMAL],
      setPriceZero
    }),
    ...buildByQuantityPricing({
      hasByQuantityPricing,
      userGroupIds: [USER_GROUPS.INTERNET],
      setPriceZero
    }),
  ];
}

function buildByQuantityForGroupsPricing(
  hasByQuantityPricing: boolean = false,
  setPriceZero: boolean = false
) {
  return buildByQuantityPricing({ hasByQuantityPricing, userGroupIds: [], setPriceZero });
}

export function PeriodPricingTable(props: {
  value?: CRM.ActivityPrice[];
  onChange: (value: CRM.ActivityPrice[]) => any;
}) {
  const [prices, setPrices] = useState<CRM.ActivityPrice[]>(props.value || []);

  const addPrice = () => {
    const newPrices: CRM.ActivityPrice[] = [
      ...prices,
      {
        forUserGroups: buildByQuantityForNormalPricing(false),
        customId: 'prix-adulte',
        label: { fr: 'Prix adulte', en: 'Adult' },
        isChild: false,
        isChildUnderSeven: false,
        hasByQuantityPricing: false,
      },
    ];
    setPrices(newPrices);
    props.onChange(newPrices);
  };

  const updatePrice = (value: CRM.ActivityPrice, index: number) => {
    const newPrices = Arrays.replace(prices, index, value);
    setPrices(newPrices);
    props.onChange(newPrices);
  };

  const deletePrice = (index: number) => {
    const newPrices = Arrays.remove(prices, index);
    setPrices(newPrices);
    props.onChange(newPrices);
  };

  return (
    <div>
      <Box mb={1}>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<AddIcon />}
          onClick={addPrice}
        >
          Ajouter un tarif
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <CrmTableCell />
              <CrmTableCell>Identifiant</CrmTableCell>
              <CrmTableCell>Label</CrmTableCell>
              <CrmTableCell>Place enfant 7ans où +</CrmTableCell>
              <CrmTableCell>Place enfant -7ans</CrmTableCell>
              <CrmTableCell>Prix de groupe</CrmTableCell>
              <CrmTableCell align="right">Actions</CrmTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prices.map((price, index) => (
              <PriceTableRow
                key={index}
                value={price}
                onChange={value => updatePrice(value, index)}
                onDelete={() => deletePrice(index)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

interface PriceTableRowProps extends GridItemProps<CRM.ActivityPrice> {}

const PriceTableRow: React.FC<PriceTableRowProps> = props => {
  const [customId, setCustomId] = useState(props.value.customId);
  const [originalCustomId, setOriginalCustomId] = useState(props.value.customId);
  const [open, setOpen] = useState(false);

  function triggerOnChange(override: Partial<CRM.ActivityPrice>) {
    props.onChange && props.onChange({ ...props.value, ...override });
  }

  function addPricingForGroups() {
    triggerOnChange({
      forUserGroups: [
        ...props.value.forUserGroups,
        ...buildByQuantityForGroupsPricing(props.value.hasByQuantityPricing, props.value?.isChildUnderSeven || false),
      ],
    });
  }

  return (
    <>
      <CrmTableRow>
        <CrmTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CrmTableCell>
        <CrmTableCell>
          <TextField
            label="identifiant"
            variant="outlined"
            size="small"
            disabled={customId === PRICE_LABEL_CHILD_UNDER_7}
            value={customId}
            onChange={e => {
              setCustomId(e.target.value);
              setOriginalCustomId(e.target.value);
            }}
            onBlur={e => {
              triggerOnChange({ customId: e.target.value });
            }}
          />
        </CrmTableCell>
        <CrmTableCell>
          <I18nField
            label="label"
            variant="outlined"
            size="small"
            value={props.value.label}
            onChange={value => {
              triggerOnChange({ label: value });
            }}
          />
        </CrmTableCell>
        <CrmTableCell>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="secondary"
                onChange={event => {
                  triggerOnChange({ 
                    isChild: event.target.checked, 
                    isChildUnderSeven: event.target.checked ? false : props.value.isChildUnderSeven 
                  });
                }}
                checked={props.value.isChild}
              />
            }
            label={<ChildCareIcon />}
            labelPlacement="end"
          />
        </CrmTableCell>
        <CrmTableCell>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="secondary"
                onChange={event => {
                  triggerOnChange({ 
                    isChildUnderSeven: event.target.checked, 
                    isChild: event.target.checked ? false : props.value.isChild,
                    customId: event.target.checked ? PRICE_LABEL_CHILD_UNDER_7 : originalCustomId,
                    forUserGroups: buildByQuantityForNormalPricing(
                      props.value.hasByQuantityPricing, event.target.checked ? true : false
                    )
                  });
                  if(event.target.checked) {
                    setCustomId(PRICE_LABEL_CHILD_UNDER_7);
                  } else {
                    setCustomId(originalCustomId);
                  }
                }}
                checked={props.value.isChildUnderSeven}
              />
            }
            label={<ChildCareIcon />}
            labelPlacement="end"
          />
        </CrmTableCell>
        <CrmTableCell>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="secondary"
                onChange={event => {
                  triggerOnChange({
                    hasByQuantityPricing: event.target.checked,
                    forUserGroups: buildByQuantityForNormalPricing(
                      event.target.checked,
                      props.value?.isChildUnderSeven || false
                    ),
                  });
                }}
                checked={props.value.hasByQuantityPricing}
              />
            }
            label={<TrendingDownIcon />}
            labelPlacement="end"
          />
        </CrmTableCell>
        <CrmTableCell align="right">
          <ButtonMenu>
            <MenuItem onClick={() => props.onDelete!(props.value)}>
              <DeleteIcon />
              Supprimer
            </MenuItem>
            <MenuItem onClick={addPricingForGroups}>
              <GroupAddIcon />
              Ajouter une tarification
            </MenuItem>
          </ButtonMenu>
        </CrmTableCell>
      </CrmTableRow>
      <CrmTableRow>
        <CrmTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {props.value.forUserGroups.map((pricing, index) => (
                <GroupTable
                  key={index}
                  value={pricing}
                  removeGroup={() =>
                    triggerOnChange({
                      forUserGroups: Arrays.remove(
                        props.value.forUserGroups,
                        index,
                      ),
                    })
                  }
                  onChange={value =>
                    triggerOnChange({
                      forUserGroups: Arrays.replace(
                        props.value.forUserGroups,
                        index,
                        value,
                      ),
                    })
                  }
                />
              ))}
            </Box>
          </Collapse>
        </CrmTableCell>
      </CrmTableRow>
    </>
  );
};

export function GroupTable(props: {
  value: CRM.PeriodPriceForGroups;
  onChange: (value: CRM.PeriodPriceForGroups) => any;
  removeGroup: () => any;
}) {
  const { onChange, value } = props;
  const byQuantityRows = flatMap(props.value.byQuantity, (value, key) => ({
    quantityStartingTo: Number(key),
    ...value,
  }));

  const updateByQuantity = useCallback(
    ({ quantityStartingTo, ...overrides }: PeriodPriceWithQuantity) => {
      onChange({
        ...value,
        byQuantity: {
          ...value.byQuantity,
          [quantityStartingTo]: overrides,
        },
      });
    },
    [onChange, value],
  );

  const updateGroups = useCallback(
    (groups: CRM.UserGroupDocument[]) => {
      onChange({
        ...value,
        userGroupIds: groups.map(g => g._id),
      });
    },
    [onChange, value],
  );

  return (
    <div>
      <Box mb={1} mt={2} display={'flex'} justifyContent={'space-between'}>
        <UserGroups
          selectedIds={props.value.userGroupIds}
          onChange={updateGroups}
        />
        {!props.value.userGroupIds.includes(USER_GROUPS.NORMAL) &&
          !props.value.userGroupIds.includes(USER_GROUPS.INTERNET) && (
            <IconButton onClick={props.removeGroup}>
              <DeleteIcon />
            </IconButton>
          )}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <CrmTableCell>À partir de</CrmTableCell>
              <CrmTableCell>Basse saison</CrmTableCell>
              <CrmTableCell>Haute saison</CrmTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {byQuantityRows.map(byQuantityRow => (
              <QuantityRow
                key={byQuantityRow.quantityStartingTo}
                value={byQuantityRow}
                onChange={updateByQuantity}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

type PeriodPriceWithQuantity = CRM.PeriodPrice & { quantityStartingTo: number };

const QuantityRow: React.FC<GridItemProps<PeriodPriceWithQuantity>> = props => {
  function triggerOnChange(
    override: Partial<CRM.PeriodPrice & { quantityStartingTo: number }>,
  ) {
    props.onChange && props.onChange({ ...props.value, ...override });
  }
  return (
    <CrmTableRow>
      <CrmTableCell component="th" scope="row">
        {`${props.value.quantityStartingTo} place(s)`}
      </CrmTableCell>
      <CrmTableCell align="right">
        <PositiveNumberField
          label="prix (€)"
          variant="outlined"
          fullWidth
          size="small"
          value={props.value.low}
          onChange={e => {
            triggerOnChange({
              low: Number(e.target.value),
            });
          }}
        />
      </CrmTableCell>
      <CrmTableCell align="right">
        <PositiveNumberField
          label="prix (€)"
          variant="outlined"
          fullWidth
          size="small"
          value={props.value.high}
          onChange={e => {
            triggerOnChange({
              high: Number(e.target.value),
            });
          }}
        />
      </CrmTableCell>
    </CrmTableRow>
  );
};
