import { isEmpty } from 'lodash';
import { useDomainPath } from '../auth/auth.context';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { get } from '../fetch';

interface TemplateSelectProps {
  id?: string;
  onChange: (value: CRM.TemplateDocument) => any;
  label: string;
  disabled?: boolean;
}

export function TemplateSelect({
  onChange,
  id,
  label,
  disabled,
}: TemplateSelectProps) {
  const path = useDomainPath('/template');
  const [templates, setTemplates] = useState<CRM.TemplateDocument[]>([]);

  const load = useCallback(() => {
    get<CRM.TemplateDocument[]>(path).then(values => {
      setTemplates(values);
      if (!id && !isEmpty(values)) {
        onChange(values[0]);
      }
    });
  }, [onChange, id, path]);

  useEffect(load, [load]);

  if (isEmpty(templates)) {
    return <CircularProgress size="1em" />;
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="Template-select-label">{label}</InputLabel>
      <Select
        labelId="Template-select-label"
        label={label}
        value={id || templates[0]._id}
        disabled={disabled}
        required
        onChange={e => {
          const newSelected = templates.find(
            template => template._id === e.target.value,
          );
          if (newSelected) {
            onChange(newSelected);
          }
        }}
      >
        {templates.map(template => (
          <MenuItem value={template._id} key={template._id}>
            {template.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
