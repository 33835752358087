import React from 'react';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../components/TabPanel.component';
import { SpotForm } from './spot.form';
import { ArrivalsForm } from './arrivals.form';
import { DeparturesForm } from './departures.form';

export const CreateSpotTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState('spot');

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
        <Tab value="spot" label="Spot" />
        <Tab value="departures" label="Départs" />
        <Tab value="arrivals" label="Arrivées" />
      </Tabs>
      <Divider variant="middle" />
      <TabPanel value={selectedTab} index="spot">
        <SpotForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="departures">
        <DeparturesForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="arrivals">
        <ArrivalsForm />
      </TabPanel>
    </div>
  );
};
