import React, {
  ChangeEvent,
  Context,
  createContext,
  FormEvent,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { Action } from '../../hooks/action';
import {
  useChangeEventPartialUpdate,
  useValuePartialUpdate,
} from '../../hooks/useChangeEventUpdate';

type SpotAction = Action<Partial<CRM.ActivitySpotDocument>>;

interface SpotContext {
  spot: Partial<CRM.ActivitySpotDocument>;
  update: (payload: Partial<CRM.ActivitySpotDocument>) => void;
  save: (evt?: FormEvent) => void;
}

const spotContext: Context<SpotContext> = createContext(null) as any;

export function useSpot(): SpotContext {
  const context = useContext(spotContext);
  return context;
}

export const useSpotChangeEventUpdate = (
  key: keyof CRM.ActivitySpotDocument,
): ((e: ChangeEvent<{ name?: string; value: string }>) => void) => {
  const { update } = useSpot();
  return useChangeEventPartialUpdate(key, update);
};

export function useSpotValueUpdate<T>(
  key: keyof CRM.ActivitySpotDocument,
): (value: T) => void {
  const { update } = useSpot();
  return useValuePartialUpdate(key, update);
}

const reducer: Reducer<Partial<CRM.ActivitySpotDocument>, SpotAction> = (
  state,
  action,
) => {
  console.log('ACTION', action.type, action.payload);
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};

interface Props {
  initialValue?: CRM.ActivitySpotDocument;
  onSave: (value: Partial<CRM.ActivitySpotDocument>) => void;
}
export const ProvideSpot: React.FC<Props> = ({
  onSave,
  initialValue,
  children,
}) => {
  const [spot, dispatch] = useReducer(
    reducer,
    initialValue || {
      name: 'vallon',
      displayName: `Vallon Pont-d'Arc`,
    },
  );

  const update = useCallback((payload: Partial<CRM.ActivityDocument>) => {
    dispatch({ type: 'update', payload });
  }, []);

  const save = useCallback(
    (evt?: FormEvent) => {
      evt && evt.preventDefault();
      onSave(spot);
    },
    [onSave, spot],
  );

  return (
    <spotContext.Provider value={{ spot, update, save }}>
      {children}
    </spotContext.Provider>
  );
};
