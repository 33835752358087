import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React from 'react';

export const onlyFilter = (
  onlyDepartures?: boolean,
  onlyArrivals?: boolean,
) => (spot: CRM.ActivitySpotDocument) => {
  if (!onlyDepartures && !onlyArrivals) return true;
  if (onlyDepartures) return spot.departures && !isEmpty(spot.departures.every);
  if (onlyArrivals) return spot.arrivals && !isEmpty(spot.arrivals.every);
};

interface Props {
  label: string;
  onChange?: (value: CRM.ActivitySpotDocument | undefined) => any;
  spots: CRM.ActivitySpotDocument[];
  value?: string;
  onlyDepartures?: boolean;
  onlyArrivals?: boolean;
  disabled?: boolean;
}

export const SpotSelect: React.FC<Props> = ({
  label,
  onChange,
  spots,
  value,
  onlyDepartures,
  onlyArrivals,
  disabled,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="spot-select-label">{label}</InputLabel>
      <Select
        labelId="spot-select-label"
        value={value || ''}
        label={label}
        disabled={disabled}
        onChange={e => {
          const newSelected = spots.find(spot => spot._id === e.target.value);
          if (onChange) {
            onChange(newSelected);
          }
        }}
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {spots.filter(onlyFilter(onlyDepartures, onlyArrivals)).map(spot => (
          <MenuItem value={spot._id} key={spot._id}>
            {spot.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
