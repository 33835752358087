import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useChangeEventUpdate } from '../../hooks/useChangeEventUpdate';

interface Props {
  value?: string;
  onChange: (value?: string) => any;
}

export function PeriodNameSelect({ onChange, value }: Props) {
  return (
    <FormControl variant="outlined" className="inputSize-lg">
      <InputLabel id="Period-select-label">Saison</InputLabel>
      <Select
        labelId="Period-select-label"
        value={value || ''}
        label="Saison"
        onChange={useChangeEventUpdate<string>(onChange)}
      >
        <MenuItem value="">&nbsp;</MenuItem>
        <MenuItem value="low">Basse saison</MenuItem>
        <MenuItem value="high">Haute saison</MenuItem>
      </Select>
    </FormControl>
  );
}
