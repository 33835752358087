import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { get, httpDelete, post, put } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { useAlerts } from '../../hooks/useAlerts';
import { HtmlEditor } from '../../components/HtmlEditor.component';
import { LangSelect } from '../../components/LangSelect.component';
import { PreviewHtmlButton } from '../../components/PreviewHtmlButton.component';

interface TemplateProps {
  template: CRM.TemplateMetaDocument;
  remove: (id: string) => void;
}

export const TemplateForm: React.FC<TemplateProps> = props => {
  const { notify } = useAlerts();
  const path = useDomainPath(`/template/${props.template._id}`);

  const [type, setType] = useState<CRM.TemplateType>(props.template.type);
  const [name, setName] = useState<string>(props.template.name);
  const [source, setSource] = useState<CRM.I18nString>({});
  const [lang, setLang] = useState<CRM.I18nLang>('fr');

  const load = useCallback(() => {
    get<CRM.TemplateDocument>(path).then(template => {
      setType(template.type);
      setName(template.name);
      setSource(template.source || {});
    });
  }, [path]);

  useEffect(load, [load]);

  const update = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        const value = {
          type,
          name,
          source,
        };
        await put<CRM.CreateTemplate>(path, value);
        notify('Le modèle a été mis à jour', 'success');
      } catch (err) {
        notify('Impossible de mettre à jour le modèle', 'error');
      }
    },
    [notify, path, type, source, name],
  );

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography>{name}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={update}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                label="Nom du modèle"
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <LangSelect value={lang} onChange={setLang} />{' '}
              <PreviewHtmlButton source={source[lang]} />
            </Grid>
            <Grid item xs={12}>
              {type === 'html' && (
                <HtmlEditor
                  label={'Modèle'}
                  value={(source && source[lang]) || ''}
                  onChange={value => setSource({ ...source, [lang]: value })}
                />
              )}
              {type !== 'html' && (
                <TextField
                  label="Modèle"
                  variant="outlined"
                  value={(source && source[lang]) || ''}
                  onChange={value => setSource({ ...source, [lang]: value })}
                  fullWidth
                  required
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => props.remove(props.template._id)}
              >
                Supprimer
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                type="submit"
              >
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export const TemplatesForm: React.FC = () => {
  const path = useDomainPath('/template');

  const [name, setName] = useState<string>();
  const [templates, setTemplates] = useState<CRM.TemplateMetaDocument[]>([]);

  const refresh = useCallback(() => {
    get<CRM.TemplateMetaDocument[]>(path).then(setTemplates);
  }, [setTemplates, path]);

  const add = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (name) {
        post<CRM.CreateTemplate>(path, {
          type: 'html',
          name,
          source: {},
        }).then(refresh);
      }
    },
    [name, path, refresh],
  );

  const remove = useCallback(
    (id: string) => {
      httpDelete(`${path}/${id}`).then(() => {
        const index = templates.findIndex(template => template._id === id);
        setTemplates([
          ...templates.slice(0, index),
          ...templates.slice(index + 1, templates.length),
        ]);
      });
    },
    [path, templates, setTemplates],
  );

  useEffect(refresh, [refresh]);

  const handleNameChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      setName(e.target.value as string),
    [],
  );

  return (
    <Box>
      <Box mb={2}>
        <form onSubmit={add}>
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item>
              <TextField
                label="Nom du modèle"
                variant="outlined"
                value={name}
                onChange={handleNameChange}
                required
              />
            </Grid>
            <Grid item>
              <Button color="secondary" variant="contained" type="submit">
                Ajouter
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      {(templates || []).map(template => (
        <TemplateForm key={template._id} template={template} remove={remove} />
      ))}
    </Box>
  );
};
