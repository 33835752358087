import React, { Dispatch } from 'react';
import { GridSize } from '@material-ui/core/Grid/Grid';

export interface Table<T> {
  columns: Array<Column<T>>;
  sorting?: Sorting;
  hasUpdates?: boolean;
  style?: React.CSSProperties;
}
export interface ColumnClickHandler {
  onColumnClick: (columnKey: string) => any;
}

export interface Sorting {
  key?: string;
  order: 'asc' | 'desc' | undefined;
}

export interface RowHandlers {
  dispatch?: Dispatch<any>;
  onDelete?: () => any;
  onUpdate: () => any;
}

export interface CellProps<T> {
  value: T;
  handlers: RowHandlers;
}

export interface Column<T> {
  key: string;
  order: number;
  label: React.ReactElement | string;
  sortable?: boolean;
  compare?: (a: T, b: T) => number;
  sorted?: 'asc' | 'desc';
  xs?: GridSize;
  renderCell?: (value: T, handlers: RowHandlers) => React.ReactElement;
  Cell?: React.FC<CellProps<T>>;
}

export const compareNumber = (a: number, b: number): number => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const asDate = (value: string | Date): Date =>
  typeof value === 'string' ? new Date(value) : value;

export const compareDate = (
  a: string | Date | undefined,
  b: string | Date | undefined,
  omitYMD?: boolean | undefined
): number => {
  if (a === b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  if(!omitYMD)
    return compareNumber(asDate(a).getTime(), asDate(b).getTime());
   else {
    if(asDate(a).getHours() === asDate(b).getHours())
      return compareNumber(asDate(a).getMinutes(), asDate(b).getMinutes());
    else
      return compareNumber(asDate(a).getHours(), asDate(b).getHours());
   }
};
