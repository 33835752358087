import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Box, IconButton, styled, Typography } from '@material-ui/core';
import { PaymentStatusBadge } from '../components/paymentStatusBadge.component';
import { useReservation } from './reservation.context';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import { httpDelete, put } from '../../fetch';
import { useDomainPath, useAuth, useAuthUsers } from '../../auth/auth.context';
import { useHistory } from 'react-router-dom';
import { AlertDialog } from '../../components/alerts/AlertDialog.component';
import { isAdmin } from '../../auth/roles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { useAlerts } from '../../hooks/useAlerts';

const useStyles = makeStyles((theme: Theme) => ({
  mediumIcon: {
    fontSize: '1.8rem !important',
  },
}));

export const UpdateToolbar: React.FC = () => {
  const { reservation } = useReservation();
  const reservationPath = useDomainPath('/reservation');
  const history = useHistory();
  const { loggedUser } = useAuthUsers();
  const isReservationActive = reservation.active !== undefined && reservation.active !== 1 ? 0 : 1;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const auth = useAuth();
  const ref:any = useRef(null);
  const [width, setWidth] = useState(0);
  const classes = useStyles();
  const { notify } = useAlerts();

  useEffect(() => {
    const stored = localStorage.getItem('notifyRestauredReservation');
    if(stored !== null) {
      notify(`La réservation a été restaurée`, 'success');
      localStorage.removeItem('notifyRestauredReservation');
    }
  },[notify]);

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  },[ref?.current?.offsetWidth]);

  const Offset = styled('div')(({ theme }) => ({
    ...(theme.mixins.toolbar as BaseCSSProperties),
  }));

  const deleteReservationDialog = useCallback(() => setDeleteDialogOpen(true), [
    setDeleteDialogOpen,
  ]);
  const deleteReservationDialogClose = useCallback(
    () => setDeleteDialogOpen(false),
    [setDeleteDialogOpen],
  );

  const deleteReservation = useCallback(
    () =>
      httpDelete(`${reservationPath}/${reservation._id}`).then(() =>
        history.push(`/reservations/futur`),
      ),
    [history, reservationPath, reservation._id],
  );

  const goBack = useCallback((e) => {
    e.preventDefault();
    history.goBack();
  }, [history]);

  const deleteReservationSoft = useCallback(
    async () => {
      reservation.active = 0;
      reservation.deletedDate = new Date();
      reservation.deletedByUser = loggedUser?.displayName;
      try {
        await put<Partial<CRM.CreateReservation>>(
          `${reservationPath}/${reservation._id}`,
          reservation,
        ).then(() => {history.goBack()})

      } catch (err) {
        throw err;
      }
    },
    [reservationPath, reservation, loggedUser, history],
  );

  const restoreReservation = useCallback(
    async () => {
      reservation.active = 1;
      reservation.deletedDate = undefined;
      reservation.deletedByUser = "";
      try {
        await put<Partial<CRM.CreateReservation>>(
          `${reservationPath}/${reservation._id}`,
          reservation,
        );
        localStorage.setItem('notifyRestauredReservation', 'true');
        window.location.reload();
      } catch (err) {
        throw err;
      }
    },
    [reservationPath, reservation],
  );


  return (
    <div ref={ref} >
    <Box position={'fixed'} style={{width:width, top:'0', backgroundColor:'white', zIndex: 1000}} >
      <Box display={'flex'} style={{marginBottom: '1rem', marginTop:'1rem'}} justifyContent={'space-between'}>
      <Box display={'flex'} alignItems={'baseline'} >
        <IconButton onClick={goBack} size={'small'} title={'retourner à la liste'} color={'secondary'}>
          <ArrowBackIosIcon/>
        </IconButton>
              {(reservation && reservation._id) && isAdmin(auth.userGroup) && <IconButton
                title={!isReservationActive ? 'supprimer définitivement' : 'supprimer'}
                onClick={deleteReservationDialog}
              >
                <DeleteIcon className={classes.mediumIcon} />
              </IconButton>}
              {(reservation && reservation._id) && (!isAdmin(auth.userGroup) && isReservationActive === 1) && <IconButton
                title={'supprimer'}
                onClick={deleteReservationDialog}
                className={classes.mediumIcon}
              >
                <DeleteIcon className={classes.mediumIcon} />
              </IconButton>}
              <AlertDialog
                accept={!isReservationActive ? deleteReservation : deleteReservationSoft}
                cancel={deleteReservationDialogClose}
                open={deleteDialogOpen}
                title={'Confirmer'}
                content={
                  !isReservationActive ? "La suppression d'une réservation est irréversible, voulez-vous vraiment continuer ?" :
                    "Cette réservation ira dans la corbeille, voulez-vous vraiment continuer ?"
                }
              />
              {(reservation && reservation._id) && !isReservationActive && <IconButton
                title={'restaurer'}
                size={'small'}
                onClick={restoreReservation}
              >
                <RestoreFromTrashIcon className={classes.mediumIcon} />
              </IconButton>}
              &nbsp;
              {(reservation && reservation._id) && (<Typography variant="h4">
                {reservation.customer?.name || '...'}{' '}
                {reservation.customer?.firstname || '...'}
                {!isReservationActive ? ' ( dans la corbeille )' : ''}
              </Typography>)}
              {(reservation && reservation._id) && (<Box ml={2}>
                <Typography variant="body1">
                  n° {String(reservation.refNumber).padStart(6, '0')}
                </Typography>
              </Box>)}
              {(reservation && reservation._id) && reservation.bookingId && (
                <Box ml={2}>
                  <Typography variant="body2">
                    <i>(Résa web n° {reservation.bookingId})</i>
                  </Typography>
                </Box>
              )}
      </Box>
      {(reservation && reservation._id) && reservation.payment && (
        <PaymentStatusBadge payment={reservation.payment} />
      )}
    </Box>
  </Box>
  <Offset />
  </div>
  );
};
