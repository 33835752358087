import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useChangeEventUpdate } from '../../../hooks/useChangeEventUpdate';

interface Props {
  years: string[];
  value?: string;
  onChange: (value: string) => any;
}

export function YearSelect({ onChange, value, years }: Props) {
  return (
    <FormControl variant="outlined" className="inputSize-lg">
      <InputLabel id="Year-select-label">Année</InputLabel>
      <Select
        labelId="Year-select-label"
        value={value || ''}
        label="Année"
        onChange={useChangeEventUpdate<string>(onChange)}
      >
        {years.map(period => (
          <MenuItem key={period} value={period}>
            {period}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
