import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { ArrowRightAltOutlined } from '@material-ui/icons';

interface Props {
  allPricing?: CRM.ActivityPrice[];
  value: CRM.PricingIdsMapping;
  onChange(value: CRM.PricingIdsMapping): void;
}

export const PricingIdsMapper: React.FC<Props> = ({
  allPricing = [],
  onChange,
  value,
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gridRowGap={10}>
      {allPricing.map(pricing => (
        <Box
          key={pricing.customId}
          display={'flex'}
          alignItems={'center'}
          gridGap={10}
        >
          <Box>{pricing.customId}</Box>
          <ArrowRightAltOutlined />
          <TextField
            label="Label(s) correspondant(s)"
            variant="outlined"
            value={value[pricing.customId] || ''}
            onChange={e =>
              onChange({ ...value, [pricing.customId]: e.target.value })
            }
          />
        </Box>
      ))}
    </Box>
  );
};
