import React, { useEffect, useState } from 'react';
import {
  DbDocumentSelect,
  DbDocumentSelectProps,
  DbMultiDocumentSelect,
  DbMultiDocumentSelectProps,
} from '../DbDocumentSelect.components';
import { get } from '../../fetch';

type DomainSelectProps = Omit<DbDocumentSelectProps<CRM.EntityName>, 'docs'>;

export const DomainSelect = (props: DomainSelectProps) => {
  const [domains, setDomains] = useState<CRM.EntityName[]>();

  useEffect(() => {
    get<CRM.EntityName[]>(`domains`).then(setDomains);
  }, [setDomains]);

  return <DbDocumentSelect label="Entreprise" docs={domains} {...props} />;
};

type DomainsSelectProps = Omit<
  DbMultiDocumentSelectProps<CRM.EntityName>,
  'docs'
> & {
  filterId?: string;
};

export const DomainsSelect = ({
  filterId,
  ...selectProps
}: DomainsSelectProps) => {
  const [domains, setDomains] = useState<CRM.EntityName[]>();

  useEffect(() => {
    get<CRM.EntityName[]>(`domains`)
      .then(results => results.filter(result => result._id !== filterId))
      .then(setDomains);
  }, [setDomains, filterId]);

  return (
    <DbMultiDocumentSelect
      label="Entreprises"
      docs={domains}
      {...selectProps}
    />
  );
};
