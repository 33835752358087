import React from 'react';
import { isEmpty } from 'lodash';
import { Box, Button, Typography } from '@material-ui/core';
import { useSpot } from './spot.context';
import { useAuth } from '../../auth/auth.context';

export const SaveButton: React.FC = () => {
  const auth = useAuth();
  const { spot } = useSpot();

  const cannotSaveMessages = [];
  if (isEmpty(spot.name)) {
    cannotSaveMessages.push(`Renseigner le nom privé`);
  }
  if (isEmpty(spot.displayName)) {
    cannotSaveMessages.push(`Renseigner le nom privé`);
  }
  if (spot.domain && spot.domain !== auth.userGroup?.domainId) {
    cannotSaveMessages.push(
      `Ce spot est géré par un autre domaine, vous ne pouvez pas le modifier`,
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Button
        color="secondary"
        variant="contained"
        disabled={cannotSaveMessages.length > 0}
        type="submit"
      >
        Enregistrer
      </Button>
      {cannotSaveMessages.length > 0 && (
        <Box display="block">
          <ul>
            {cannotSaveMessages.map((message, index) => (
              <li key={index}>
                <Typography color={'error'}>{message}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};
