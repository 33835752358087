let authHeaders = {};
export const configureAccessToken = (accessToken: string) => {
  authHeaders = {
    authorization: `Bearer ${accessToken}`,
  };
};
const handleFetch = async (res: Response) => {
  if (res.status >= 400) {
    if (res.status === 401) {
      localStorage.removeItem('userGroup');
      return window.location.replace('/login');
    }
    throw res;
  }
  try {
    const contentType = res.headers.get('content-type');
    if (contentType?.toLowerCase().includes('application/json')) {
      return await res.json();
    }
    if (contentType?.toLowerCase().includes('application')) {
      return await res.blob();
    }
    return await res.text();
  } catch (err) {
    return null;
  }
};

export const baseUrl = process.env.REACT_APP_API_URL || 'https://api.naturesa.net';

export const sendMethod = (method: string) => {
  return <T>(path: string, body?: T, headers = {}) => {
    return fetch(`${baseUrl}/${path}`, {
      method: method,
      headers: {
        'content-type': 'application/json',
        ...authHeaders,
        ...headers,
      },
      ...(body ? { body: JSON.stringify(body) } : {}),
    }).then(handleFetch);
  };
};

export const post = sendMethod('post');
export const put = sendMethod('put');
export const patch = sendMethod('PATCH'); // Uppercase seems important !

export const httpDelete = (path: string) => {
  return fetch(`${baseUrl}/${path}`, {
    method: 'delete',
    headers: {
      'content-type': 'application/json',
      ...authHeaders,
    },
  }).then(handleFetch);
};

export const get = <T>(path: string): Promise<T> => {
  return fetch(`${baseUrl}/${path}`, {
    headers: {
      accept: 'application/json',
      ...authHeaders,
    },
  }).then(handleFetch);
};

export const postSignIn = (login: string, password: string) => {
  return fetch(`${baseUrl}/auth/login`, {
    method: 'post',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ login: `${login}`, password }),
  }).then(res => {
    if (res.status >= 400) {
      throw res;
    }
    return res.json();
  });
};
