import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { get } from '../fetch';
import { useDomainPath } from '../auth/auth.context';
import { ActivityGridItem } from './activityGridItem.component';
import { ActivityGridHeader } from './activityGridHeader.component';
import AddIcon from '@material-ui/icons/Add';
import { useDomains } from '../hooks/useDomain';

export function ActivitiesView() {
  const path = useDomainPath('/activity?public=true');
  const domains = useDomains();

  const [activities, setActivities] = useState<CRM.PublicActivity[]>([]);

  const refreshActivities = useCallback(
    () => get<CRM.PublicActivity[]>(path).then(setActivities),
    [path],
  );

  useEffect(() => {
    refreshActivities();
  }, [refreshActivities]);

  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        mb={3}
      >
        <Link to="/activities/create">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<AddIcon fontSize={'large'} />}
          >
            Ajouter une activité
          </Button>
        </Link>
      </Box>
      <div>
        <ActivityGridHeader />
        {activities.length === 0 && <span>Aucune activité n'a été créée</span>}
        {activities.map(activity => (
          <ActivityGridItem
            key={activity.id}
            domains={domains}
            //onDelete={refreshActivities}
            value={activity}
          />
        ))}
      </div>
    </div>
  );
}
