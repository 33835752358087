import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';

export const PreviewHtmlButton: React.FC<{ source?: string }> = ({
  source,
}) => {
  const preview = useCallback(() => {
    const win = window.open(
      '',
      'Title',
      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=880,height=600,top=10',
    );
    if (win) {
      win.document.body.innerHTML = source || '';
    }
  }, [source]);

  return <Button onClick={preview}>Preview</Button>;
};
