import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ProvideReservation } from './reservation.context';
import { post } from '../../fetch';
import { useAuthUsers, useDomainPath } from '../../auth/auth.context';
import { useHistory } from 'react-router-dom';
import { useAlerts } from '../../hooks/useAlerts';
import { ReservationForm } from './reservation.form';
import { UpdateToolbar } from './UpdateToolbar.component';

export function CreateReservationView() {
  const reservationPath = useDomainPath('/reservation');
  const history = useHistory();
  const { loggedUser } = useAuthUsers();
  const { notify } = useAlerts();

  const createReservation = useCallback(
    async (reservation: Partial<CRM.ReservationDocument>) => {
      try {
        const created = await post<Partial<CRM.CreateReservation>>(
          reservationPath,
          {
            ...reservation,
            transactionDate: new Date(),
            sellerUser: loggedUser?._id,
            sellerUserGroup: loggedUser?.userGroup,
          },
        );
        history.push(`/reservations/${created._id}`);
        notify('La réservation a été créée', 'success');
      } catch (err) {
        notify('Impossible de créer la réservation', 'error');
        throw err;
      }
    },
    [notify, history, reservationPath, loggedUser],
  );

  return (
    <ProvideReservation onSave={createReservation}>
      <Box display="flex" flexDirection="column">
        <UpdateToolbar />
        <Box mb={3} mt={3}>
          <Typography variant="h4">Ajouter une réservation</Typography>
        </Box>
        <ReservationForm />
      </Box>
    </ProvideReservation>
  );
}
