import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Divider,
  Theme,
  Select,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ActivityFilter } from '../reservations/filters/ActivityFilter.component';
import { GoToTop } from '../components/fab/GoToTop.fab';
import { useDomainPath } from '../auth/auth.context';
import { get } from '../fetch';
import { PricesGridItem } from './pricesGridItem.component';
import { useAuth } from '../auth/auth.context';
import { CrmTableCell } from './../components/Table.component';
import { sortBy } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),

    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export function PricesView() {
  const auth = useAuth();
  const classes = useStyles();
  const activitiesPath = useDomainPath('/activity');
  const userGroupPath = useDomainPath('/user-group');

  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [activities, setActivities] = useState<CRM.Activity[]>([]);
  const [publicActivities, setPublicActivities] = useState<CRM.PublicActivity[]>([]);
  const [userGroups, setUserGroups] = useState<CRM.UserGroupDocument[]>([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState<string>("");
  const currentYear = new Date().getFullYear();

  const refreshPrices = useCallback(
    (userGroupId: string, activitiesIds: string[] = []) => {
      get<CRM.Activity[]>(activitiesPath + '/group/' + userGroupId+'?public=false').then((_activities) => {

        //front-mapping version of public activities (-1 api call, lighter)
        let _publicActivities:CRM.PublicActivity[]=[];
        _activities.forEach((privActivity, index) => {
          let publicActivity:Partial<CRM.PublicActivity> = {
            id:privActivity._id,
            kind:privActivity.kind,
            displayName:privActivity.displayName,
          };
          _publicActivities.push(publicActivity as CRM.PublicActivity);
          if (index === _activities.length -1) {
            _publicActivities = sortBy(_publicActivities, a => a.kind);
            setPublicActivities(_publicActivities);
          }
        });

        //below is api version of public activities
        /*get<CRM.PublicActivity[]>(activitiesPath + '/group/' + userGroupId+'?public=true').then((_publicActivities) => {
          console.log(_publicActivities);

          _publicActivities = sortBy(_publicActivities, a => a.kind);
          setPublicActivities(_publicActivities);
        });*/

        if(activitiesIds.length > 0) {
          _activities = _activities.filter(a => activitiesIds.includes(a._id));
        }
        // order by estimated duration
        _activities = sortBy(_activities, a => a.estimatedTimeMinutes);
        const groupActivities = _activities.reduce((group:any, product) => {
          const { overDays } = product;
          group[overDays] = group[overDays] ?? [];
          group[overDays].push(product);
          return group;
        }, {});
        if(Object.keys(groupActivities).length > 0) {
          console.log(groupActivities)
          console.log(Object.keys(groupActivities)[0])
          _activities = Object.keys(groupActivities).length === 2 ? [...groupActivities[1], ...groupActivities[2]] : groupActivities[Object.keys(groupActivities)[0]];
        }
        setActivities(_activities);
      }
      );
          setSelectedUserGroup(userGroupId);
      },
    [activitiesPath],
  );

  const refreshActivities = useCallback((activitiesIds: string[]) => {
    setSelectedActivities(activitiesIds);
    refreshPrices(selectedUserGroup, activitiesIds);
      },
    [refreshPrices, selectedUserGroup]
  );

  useEffect(() => {
    refreshPrices(auth.userGroup?.userGroupId || "");
    get<CRM.UserGroupDocument[]>(userGroupPath).then(setUserGroups);
  }, [userGroupPath, refreshPrices, auth.userGroup?.userGroupId]);

  return (
    <div className={classes.root}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
      >
        <Box display={'flex'} alignItems={'flex-start'}>

        </Box>
      </Box>
      <Divider />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <ActivityFilter
          value={selectedActivities}
          onChange={(evt) => {refreshActivities(evt as string[])}}
          activitiesForGroup={publicActivities}
        />
      </Box>
      <Divider />
      {
        activities && activities[0]
          ?.pricingPeriods[currentYear]
          ?.periods.find(period => period.name === "high")
          ?.startDate &&
        (<Box
          alignItems={"left"}
          justifyContent={'left'}
          display={'flex'}
          style={{ fontWeight: 'bold' , fontStyle: 'italic', textDecorationLine : 'underline' }}
        ><br/>
          Haute Saison : du {
            new Date(activities[0]
              ?.pricingPeriods[currentYear]
              ?.periods.find(period => period.name === "high")
              ?.startDate as any).toLocaleString('fr-FR', { month: 'long', day: 'numeric' })
          }
          &nbsp;au&nbsp;
          {
            new Date(activities[0]
              ?.pricingPeriods[currentYear]
              ?.periods.find(period => period.name === "high")
              ?.endDate as any).toLocaleString('fr-FR', { month: 'long', day: 'numeric' })
          }
        </Box>)
      }
      <Box
        mt={2}
        mb={2}
        alignItems={"center"}
        justifyContent={'center'}
        display={'flex'}
      >
        {(auth.userGroup?.canViewAllPrices !== undefined && auth.userGroup?.canViewAllPrices)  && <Select
          labelId="select-group-label"
          id="select-group"
          value={selectedUserGroup}
          label="Point de vente"
          onChange={(evt) => {
            setSelectedActivities([]);
            refreshPrices(evt.target.value as string, []);
          }}
        >
          {userGroups?.map(userGroup => {
              return (
                <MenuItem key={userGroup._id} value={userGroup._id}>
                  {userGroup.displayName}
                </MenuItem>
              );
          })}
        </Select>}
      </Box>
      <Box alignItems={"center"} justifyContent={'center'}>
        {activities && activities.map(activity => (
          <Box mt={5} width='66%' mx="auto" display={'flex'}>
          <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
              <CrmTableCell colSpan={3}  style={{backgroundColor: "#eee", color: "black", fontSize: "1.3em", textTransform: "capitalize"}} align="center">{activity.displayName}</CrmTableCell>
              </TableRow>
              <TableRow>
                <CrmTableCell style={{ width: "40%" }}></CrmTableCell>
                <CrmTableCell>Basse saison</CrmTableCell>
                <CrmTableCell>Haute saison</CrmTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                activity.pricing.filter(
                  pricing => pricing.forUserGroups[0] !== undefined && (pricing.forUserGroups = pricing.forUserGroups.reduce<CRM.PeriodPriceForGroups[]>(
                    (userGroups, reducedForUserGroups) => {
                      (
                        reducedForUserGroups.userGroupIds.includes(selectedUserGroup) ||
                        reducedForUserGroups.userGroupIds.includes("normal")
                      ) &&
                        userGroups.push(reducedForUserGroups);
                      /*console.log(activity);
                      console.log(pricing.forUserGroups);*/
                      return userGroups;
                    }
                    , [])
                  ))?.map((price: CRM.ActivityPrice) => (
                    <PricesGridItem
                      value={price}
                    />
                  ))
              }
              {
              }
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
        ))}
      </Box>
      <GoToTop />
    </div>
  );
}
