import React from 'react';
import { TextField } from '@material-ui/core';

export interface HtmlEditorProps {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  rows?: number;
  rowsMax?: number;
}

export const HtmlEditor: React.FC<HtmlEditorProps> = props => {
  return (
    <TextField
      label={props.label}
      variant="outlined"
      value={props.value || ''}
      onChange={e => props.onChange(e.target.value)}
      rows={props.rows || 5}
      rowsMax={props.rowsMax || 20}
      multiline
      fullWidth
      required
    />
  );
};
