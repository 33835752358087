import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import {
  useSpot,
  useSpotChangeEventUpdate,
  useSpotValueUpdate,
} from './spot.context';
import { SaveButton } from './saveButton.component';
import { DomainsSelect } from '../../components/selects/DomainSelect.components';
import { useAuth } from '../../auth/auth.context';

export const SpotForm: React.FC = () => {
  const auth = useAuth();
  const { spot, save } = useSpot();

  const nameUpdater = useSpotChangeEventUpdate('name');
  const displayNameUpdater = useSpotChangeEventUpdate('displayName');
  const sharedWithDomainIdsUpdater = useSpotValueUpdate('sharedWithDomainIds');

  const handleSharedDomainUpdate = (domains?: CRM.EntityName[]) =>
    sharedWithDomainIdsUpdater(domains?.map(d => d._id));

  return (
    <form onSubmit={save}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            label="Nom privé"
            variant="outlined"
            value={spot?.name || ''}
            onChange={nameUpdater}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Nom d'affichage"
            variant="outlined"
            value={spot?.displayName || ''}
            onChange={displayNameUpdater}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <DomainsSelect
            label="Partagé avec"
            filterId={auth.userGroup?.domainId}
            selectedIds={spot.sharedWithDomainIds || []}
            onChange={handleSharedDomainUpdate}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </form>
  );
};
