import React, {
  ChangeEvent,
  Context,
  createContext,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useAuth, useDomainPath } from '../../auth/auth.context';
import { get } from '../../fetch';
import { Action } from '../../hooks/action';
import {
  bestEquipmentsForPeople,
  priceOfGroup,
  reservationPrice,
  USER_GROUPS,
  countBookingPeople,
  countBookingChildrenUnderSeven,
} from '@crm/utils';
import { ProvideReservationHash } from './reservationHash.context';
import dayjs from 'dayjs';
import { useChangeEventPartialUpdate } from '../../hooks/useChangeEventUpdate';

type ReservationAction =
  | Action<Partial<CRM.ReservationDocument>>
  | Action<Partial<CRM.ReservationPayment>>
  | Action<Partial<CRM.ReservationOptions>>;

interface ReservationActivityContext {
  activity?: CRM.PublicActivity;
  activityTimeSlot?: CRM.ActivityTimeSlotDetailed;
  activityTimeSlotCreate?: CRM.ActivityTimeSlotDetailed;
  updateActivity: (activityId: string/*, people?:CRM.BookingPeople*/) => any;
  updateActivityTimeSlot: (activityTimeSlotId: string, monitor?: string) => any;
  updatePricingGroupId: (pricingGroupId: string, activityId?: string) => any;
}
interface ReservationContext {
  lastSaveDate?: Date;
  equipmentsError?: string;
  reservation: Partial<CRM.ReservationDocument>;
  groupsPrice?: CRM.ActivityGroupsPrice;
  groupsPriceError: boolean;
  isPendingModification: boolean;
  dispatch: (action: ReservationAction) => void;
  update: (payload: Partial<CRM.ReservationDocument>) => void;
  save: () => Promise<void>;
  reload: () => void;
}

const reservationContext: Context<ReservationContext> = createContext(
  null,
) as any;

const activityContext: Context<ReservationActivityContext> = createContext(
  null,
) as any;

export function useReservation(): ReservationContext {
  const context = useContext(reservationContext);
  return context;
}

export function useReservationActivity(): ReservationActivityContext {
  const context = useContext(activityContext);
  return context;
}

export const useReservationChangeEventUpdate = (
  key: keyof CRM.ReservationDocument,
): ((e: ChangeEvent<{ name?: string; value: string }>) => void) => {
  const { update } = useReservation();
  return useChangeEventPartialUpdate(key, update);
};

const reducer: Reducer<Partial<CRM.ReservationDocument>, ReservationAction> = (
  state,
  action,
) => {
  console.log('ACTION', action.type, action.payload);
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      };
    case 'update-payment':
      return {
        ...state,
        payment: {
          ...state.payment,
          ...(action as Action<Partial<CRM.ReservationPayment>>).payload,
        },
      } as Partial<CRM.ReservationDocument>;
    case 'update-options':
      return {
        ...state,
        options: {
          ...state.options,
          ...(action as Action<Partial<CRM.ReservationOptions>>).payload,
        },
      } as Partial<CRM.ReservationDocument>;
  }
  return state;
};

interface Props {
  initialReservation?: CRM.ReservationDocument;
  onSave: (reservation: Partial<CRM.ReservationDocument>) => Promise<void>;
}

export const ProvideReservation: React.FC<Props> = ({
  onSave,
  initialReservation,
  children,
}) => {
  const { userGroup } = useAuth();
  const activityPath = useDomainPath('/activity');
  const activityTimeSlotPath = useDomainPath('/activity-timeslots');
  const reservationPath = useDomainPath('/reservation');
  const [reservation, dispatch] = useReducer(reducer, {
    options: {},
    payment: { amountInEuro: 0, discount: 0, refunds: [], receipts: [] },
    ...initialReservation,
  });
  const [activity, setActivity] = useState<CRM.PublicActivity>();
  const [activityTimeSlot, setActivitytimeSlot] = useState<CRM.ActivityTimeSlotDetailed>();
  const [activityTimeSlotCreate, setActivityTimeSlotCreate] = useState<CRM.ActivityTimeSlotDetailed>();
  const [groupsPrice, setGroupsPrice] = useState<CRM.ActivityGroupsPrice>();
  const [groupsPriceError, setGroupsPriceError] = useState<boolean>(false);
  const [equipmentsError, setEquipmentsError] = useState<string>();
  const [lastSaveDate, setLastSaveDate] = useState<Date>();
  const [isPendingModification, setIsPendingModification] = useState<boolean>(false);

  const reservationId = reservation._id;
  const activityKind = activity?.kind;
  const activityPricing = reservation.pricing || activity?.pricing;

  const update = useCallback((payload: Partial<CRM.ReservationDocument>) => {
    dispatch({ type: 'update', payload });
    setIsPendingModification(true);
  }, []);

  const updateActivity = useCallback((activityId: string, _pricing?:CRM.PublicActivityPricing[], _pricingGroupId?:string/*, people?:CRM.BookingPeople*/) => {
    dispatch({
      type: 'update',
      payload: {
        activity: activityId,
        activityDate: undefined,
        activityArrivalDay: undefined,
        activityArrivalDate: undefined,
        pricing: _pricing || undefined,
        pricingGroupId: _pricingGroupId || undefined,
        /*people: people || {},*/
        options: {},
      },
    });
  }, []);

  const updateActivityTimeSlot = useCallback((activityTimeSlotId:string, monitor?: string) => {
    if(monitor) {
    dispatch({
      type: 'update',
      payload: {
        activityTimeSlot: activityTimeSlotId !== '' ? activityTimeSlotId : undefined,
        options: {...reservation.options, instructors: [monitor], instructorSelectedPriceInEuro: undefined}
      },
    });
  } else {
    dispatch({
      type: 'update',
      payload: {
        activityTimeSlot: activityTimeSlotId !== '' ? activityTimeSlotId : undefined
      },
    });   
  }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePricingGroupId = useCallback(
    (pricingGroupId: string, _activityId?: string) => {
      get<CRM.PublicActivity[]>(activityPath + '/group/' + pricingGroupId+'?public=true').then((_activities) => {
        const activityId = _activityId ? _activityId : reservation.activity;
        const activity = _activities.find(a => a.id === activityId);
        if (!activity) {// if not found, set to normal price
          get<CRM.PublicActivity>(
            `${activityPath}/${activityId}?public=true`,
          ).then((a) => {
            //setActivity(a);
            const periodPricesForGroup = a.pricing;
            // console.log('UPDATE pricing : ', periodPricesForGroup, a,reservation.activity, activityId);
            //update({ pricingGroupId: USER_GROUPS.NORMAL, pricing: periodPricesForGroup });
            updateActivity(a.id, periodPricesForGroup, pricingGroupId);
          });
        } else {
          const periodPricesForGroup = activity?.pricing;
          // console.log('UPDATE pricing : ', periodPricesForGroup, activity,reservation.activity,_activities, activityId);
          updateActivity(activity?.id, periodPricesForGroup, pricingGroupId);
          update({ pricingGroupId, pricing: periodPricesForGroup });
        }
      });
  },[reservation.activity, update, activityPath, updateActivity]);

  const save = useCallback(() => {

    console.log(reservation.options);
    console.log(reservation.people);

    if(((reservation.options && reservation.options.peopleAtArrivalCount === undefined)
      || (reservation.options && reservation.options.peopleAtArrivalCount === null))
      && reservation.people)
      {
        reservation.options.peopleAtArrivalCount = countBookingPeople(reservation.people);
        console.log(reservation.people);
      }
    setLastSaveDate(new Date());
    setIsPendingModification(false);
    if (reservation.pricing) {
      return onSave(reservation);
    } else {
      return onSave({ ...reservation, pricing: activityPricing });
    }
  }, [onSave, reservation, activityPricing]);

  const reload = useCallback(() => {
    if (reservationId) {
      get<CRM.ReservationDocument>(
        `${reservationPath}/${reservationId}`,
      ).then(reloaded => dispatch({ type: 'update', payload: reloaded }));
    }
  }, [reservationPath, reservationId]);

  useEffect(() => {
    if (reservation?.activity) {
      get<CRM.PublicActivity>(
        `${activityPath}/${reservation.activity}?public=true`,
      ).then(setActivity);
    }
    if (reservation?.activityTimeSlot && !reservation?._id) {
      get<CRM.ActivityTimeSlotDetailed>(
        `${activityTimeSlotPath}/detailed/${reservation.activityTimeSlot}`,
      ).then(setActivitytimeSlot);
    } else if(reservation?.activityTimeSlot) {
      get<CRM.ActivityTimeSlotDetailed>(
        `${activityTimeSlotPath}/detailed/${reservation.activityTimeSlot}?reservationIdOmitPeople=`+reservation?._id,
      ).then(setActivitytimeSlot);
    }
  }, [
    update, 
    activityPath, 
    activityTimeSlotPath, 
    reservation.activityTimeSlot,
    reservation.activity,
    reservation?._id
  ]);

  useEffect(() => {
    if(activityTimeSlot) {
      // automatically set hour of arrival from timeslot
      if(
        reservation.activityArrivalDate === undefined || 
        (
          reservation.activityArrivalDate !== undefined && 
          dayjs(reservation.activityArrivalDate).hour() === 0
          )
        ) {
        update({ activityArrivalDate: activityTimeSlot.timeSlotArrival, activityDate: activityTimeSlot.timeSlotDeparture });
      }
      const _bookedPeopleWithMonitor: number = + (
        (reservation.pricing && reservation.people) ? 
        countBookingChildrenUnderSeven(reservation.pricing, reservation.people) : 0
        );

      const _bookedpeople: number = activityTimeSlot.bookedPeople +
        (reservation.people ? countBookingPeople(reservation.people) : 0) /*+ (activityTimeSlot.monitor !== '' ? 1 : 0)*/ /*- _bookedPeopleWithMonitor*/;

        console.log(activityTimeSlot.bookedPeople, _bookedpeople)

      setActivityTimeSlotCreate({
        _id: activityTimeSlot._id,
        timeSlotDeparture: activityTimeSlot.timeSlotDeparture,
        timeSlotArrival: activityTimeSlot.timeSlotArrival,
        monitor: activityTimeSlot.monitor,
        bookedPeople:  _bookedpeople,
        bookedPeopleWithMonitor: activityTimeSlot.bookedPeopleWithMonitor + _bookedPeopleWithMonitor,
        activity: activityTimeSlot.activity,
        minAutonomousPeople: activityTimeSlot.minAutonomousPeople,
        domain: activityTimeSlot.domain,
        constrainReservations: activityTimeSlot.constrainReservations,
        maxPeople: activityTimeSlot.maxPeople,
        maxPeopleWithMonitor: activityTimeSlot.maxPeopleWithMonitor,
        closeReservations: activityTimeSlot.closeReservations
      });
    }
  },[reservation.people, reservation.pricing, activityTimeSlot, reservation.activityArrivalDate, update]);

  useEffect(() => {
    const buildAutoEquipments = () => {
      if (activityPricing && (activityKind === 'canoe' || activityKind === 'supervised')) {
        return bestEquipmentsForPeople(
          activityPricing,
          reservation.people || {},
          false,
          (
            activityTimeSlot?.constrainReservations === true && 
            activityTimeSlot?.minAutonomousPeople &&
            !userGroup?.ignoreSupervisedChildRestrictions
          ) ? (activityTimeSlot?.minAutonomousPeople) : 0,
          activityTimeSlot?.maxPeopleWithMonitor || 1000,
          activityTimeSlot?.bookedPeopleWithMonitor
        );
      }
      return undefined;
    };

    if (!reservation.options?.equipments?.customized) {
      try {
        dispatch({
          type: 'update-options',
          payload: { equipments: buildAutoEquipments() },
        });
        setEquipmentsError(undefined);
      } catch (error) {
        setEquipmentsError(error.message);
      }
    }
  }, [
    update,
    reservation.options?.equipments?.customized,
    reservation.people,
    activityKind,
    activityPricing,
    activityTimeSlot,
    userGroup?.ignoreSupervisedChildRestrictions
  ]);

  useEffect(() => {
    if (activityPricing && activity?.pricingPeriods && userGroup?.userGroupId) {
      const pricingPeriods = {
        [dayjs().year()]: {
          periods: activity.pricingPeriods,
          exceptions: activity.pricingPeriodsExceptions,
        } as CRM.YearlyPricingPeriods,
      };
      const priceForUserGroup = (userGroupId: string) =>
        priceOfGroup(
          activityPricing,
          pricingPeriods,
          reservation.people || {},
          userGroupId,
          reservation.activityDate || new Date(),
        );

      try {
        const group = reservation.pricingGroupId || userGroup?.userGroupId;
        let bestPrice = priceForUserGroup(group);
        if (!bestPrice || bestPrice.groups.length === 0) {
          bestPrice = priceForUserGroup(USER_GROUPS.NORMAL);
          dispatch({
            type: 'update',
            payload: { pricingGroupId: USER_GROUPS.NORMAL },
          });
        } else {
          dispatch({
            type: 'update',
            payload: { pricingGroupId: group },
          });
        }
        setGroupsPrice(bestPrice);
      } catch (e) {
        console.error('Error while getting the pricing', e);
        setGroupsPriceError(true);
      }
      //
      // const price = reservationPrice(
      //   bestPrice,
      //   reservation.options?.instructors!,
      //   activity.instructorPriceInEuro,
      // );
      //
      // dispatch({
      //   type: 'update-payment',
      //   payload: { amountInEuro: price.totalPrice },
      // });
    }
  }, [
    userGroup?.userGroupId,
    reservation.people,
    reservation.activityDate,
    reservation.pricingGroupId,
    activityPricing,
    activity,
  ]);

  useEffect(() => {
    if (!activity || !groupsPrice) {
      return;
    }

    const price = reservationPrice(
      groupsPrice,
      reservation.options?.instructors!,
      reservation.options?.instructorSelectedPriceInEuro || activity.instructorPriceInEuro,
      reservation.payment?.refunds || [],
      reservation.payment?.discount || 0,
    );

    dispatch({
      type: 'update-payment',
      payload: { amountInEuro: price.totalPrice },
    });
  }, [
    groupsPrice,
    reservation.options?.instructors,
    reservation.options?.instructorSelectedPriceInEuro,
    activity,
    reservation.payment?.refunds,
    reservation.payment?.discount,
  ]);

  return (
    <activityContext.Provider value={{ activity, activityTimeSlot, activityTimeSlotCreate, updateActivity, updateActivityTimeSlot, updatePricingGroupId }}>
      <reservationContext.Provider
        value={{
          lastSaveDate,
          equipmentsError,
          reservation,
          groupsPrice,
          groupsPriceError,
          isPendingModification,
          dispatch,
          update,
          save,
          reload,
        }}
      >
        <ProvideReservationHash>{children}</ProvideReservationHash>
      </reservationContext.Provider>
    </activityContext.Provider>
  );
};
