import { Dialog, DialogTitle, DialogContent, Box, Grid, makeStyles, IconButton } from "@material-ui/core";
import React from "react";
import { RichGrid } from "../../../components/table/RichGrid.component";
import { RowHandlers } from "../../../components/table/Table";
import { Theme as DefaultTheme, Theme } from '@material-ui/core/styles/createMuiTheme';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CloseIcon from '@material-ui/icons/Close';
import { vehiclesTableProvider } from "../../../vehicles/vehiclesGrid.columns";

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
  rootSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['400'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  }
}));

interface Props {
  dialogOpen: boolean;
  availableBusTravels: CRM.BusTravelDetailed[];
  isReplacement: Exclude<CRM.VehicleGridType, 'admin'>;
  spotTimingOccurence: CRM.SpotTimingsOccurence;
  spots: CRM.ActivitySpotDocument[];
  busToReplacePassengers?: number[];
  onSelect: (busId: string) => void;
  dialogClose: () => void;
}


export const ViewAvailableBusesDialog: React.FC<Props> = (
  {
    dialogOpen,
    availableBusTravels,
    isReplacement,
    spotTimingOccurence,
    spots,
    busToReplacePassengers,
    onSelect,
    dialogClose,
  }) => {
  const classesgrid: ClassNameMap<string> = useStylesGrid();

  return (
    <Dialog
    onClose={dialogClose}
    open={dialogOpen}
    maxWidth={'lg'}
    fullWidth={true}
    id='timeSlotsList'
  >
      <DialogTitle>
      <Box display="flex" alignItems="center">
          <Box flexGrow={1} >
            BUS DISPONIBLES
          </Box>
        <Box>
          <IconButton onClick={dialogClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Box style={{ minHeight: '70vh', overflow: 'hidden' }} className='App'>
      {<RichGrid
            style={{
              paddingRight: '10px', //compensate for scrollbar width
            }}
            columns={
              vehiclesTableProvider(isReplacement, onSelect)
                .columns
            }
            rows={availableBusTravels}
            renderRow={busTravel => {
              const busTravelTyped: CRM.BusTravelDetailed = busTravel;
              const otherSpotNamesOnTiming = busTravelTyped.travelSpotsTimings
                .filter(ts => ts.timings.findIndex(
                  t => t.hour === spotTimingOccurence.hour &&
                  t.minute === spotTimingOccurence.minute
                  ) !== -1
                ).map(st => {
                  const spot = spots.find(s => s._id.valueOf() === st.spotId.valueOf());
                  return spot?.displayName || '';
                });
              return (
                <div className='Grid-row'>
                  <Grid
                    container
                    className={
                      classesgrid.root
                    }
                    spacing={1}
                    onClick={() => {}}
                  >
                    {vehiclesTableProvider(isReplacement, onSelect, undefined, busToReplacePassengers, busTravel._id, otherSpotNamesOnTiming)
                      .columns.map(column => {
                        const Cell = column.Cell;
                        return (
                          <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                            {Cell && <Cell value={busTravel.bus} handlers={{} as RowHandlers} />}
                            {column.renderCell &&
                              column.renderCell(busTravel.bus, {} as RowHandlers)}
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              );
            }}
          />}
      </Box>
    </DialogContent>
  </Dialog>
  );

}
