import React, { useCallback, useEffect, useState } from 'react';
import { get } from '../fetch';
import { useDomainPath } from '../auth/auth.context';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { SpotGridHeader } from './spotsGridHeader.component';
import { SpotGridItem } from './spotsGridItem.component';

export function SpotsView() {
  const path = useDomainPath('/activity-spot');
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);

  const refreshSpots = useCallback(() => {
    get<CRM.ActivitySpotDocument[]>(`${path}`).then(setSpots);
  }, [path]);

  useEffect(refreshSpots, [refreshSpots]);

  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        mb={3}
      >
        <Link to="/spots/create">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<AddIcon fontSize={'large'} />}
          >
            Ajouter un spot
          </Button>
        </Link>
      </Box>
      <div>
        <SpotGridHeader />
        {spots.length === 0 && <span>Aucun spot n'a été créé</span>}
        {spots.map(spot => (
          <SpotGridItem key={spot._id} onDelete={refreshSpots} value={spot} />
        ))}
      </div>
    </div>
  );
}
