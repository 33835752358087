import React, {
  ChangeEvent,
  Context,
  createContext,
  FormEvent,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { Action } from '../../hooks/action';
import {
  useChangeEventPartialUpdate,
  useValuePartialUpdate,
} from '../../hooks/useChangeEventUpdate';

type DomainAction = Action<Partial<CRM.Domain>>;

interface DomainContext {
  value: Partial<CRM.Domain>;
  update: (payload: Partial<CRM.Domain>) => void;
  save: () => void;
}

const domainContext: Context<DomainContext> = createContext(null) as any;

export function useDomain(): DomainContext {
  const context = useContext(domainContext);
  return context;
}

export const useDomainChangeEventUpdate = (
  key: keyof CRM.Domain,
): ((e: ChangeEvent<{ name?: string; value: string }>) => void) => {
  const { update } = useDomain();
  return useChangeEventPartialUpdate(key, update);
};

export function useDomainValueUpdate<T>(
  key: keyof CRM.Domain,
): (value: T) => void {
  const { update } = useDomain();
  return useValuePartialUpdate(key, update);
}

const reducer: Reducer<Partial<CRM.Domain>, DomainAction> = (state, action) => {
  console.log('ACTION', action.type, action.payload);
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};

interface Props {
  initialValue?: CRM.Domain;
  onSave: (value: CRM.Domain) => void;
}

export const ProvideDomain: React.FC<Props> = ({
  onSave,
  initialValue,
  children,
}) => {
  const [value, dispatch] = useReducer(reducer, initialValue || {});

  const update = useCallback((payload: Partial<CRM.Domain>) => {
    dispatch({ type: 'update', payload });
  }, []);

  const save = useCallback(
    (evt?: FormEvent) => {
      evt && evt.preventDefault();
      onSave(value as CRM.Domain);
    },
    [onSave, value],
  );

  return (
    <domainContext.Provider value={{ value, update, save }}>
      {children}
    </domainContext.Provider>
  );
};
