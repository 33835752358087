import React, { useCallback, useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { CrmTableRow, SmallTableCell } from '../components/Table.component';
import { DateRange } from '../components/DateRangeSelect.component';
import { get } from '../fetch';
import { useDomainPath } from '../auth/auth.context';
import { roundMoney } from '@crm/utils';

interface Props {
  receiptsMethodsSum: { [method: string]: number };
  refundsMethodsSum: { [method: string]: number };
  userGroupId: string | undefined;
  range: DateRange;
}
interface sheetsSum {
  cash: number;
  check: number;
  card: number;
  rebate: number;
  cardRebate: number;
  startingCashFund: number;
  endingCashFund: number;
}

function calculateSumOfSheets(sheets:CRM.SalesSheetDocument[]) : sheetsSum {
  const sum: sheetsSum = {cash:0,check:0,card:0,rebate:0,cardRebate:0,startingCashFund:0,endingCashFund:0};
  sheets.forEach(function (sheet) {
    sum.cash += sheet?.cash || 0;
    sum.check += sheet?.check || 0;
    sum.card += sheet?.card || 0;
    sum.rebate += sheet?.rebate || 0;
    sum.cardRebate += sheet?.cardRebate || 0;
    sum.startingCashFund += sheet?.startingCashFund || 0;
    sum.endingCashFund += sheet?.endingCashFund || 0;
  });
  return sum;
}

function getExpectedEndingCashFund(
  sheets: sheetsSum | undefined,
  receiptsMethodsSum: { [p: string]: number },
) {
  return (
    ((sheets && sheets.startingCashFund) || 0) +
    (receiptsMethodsSum['cash'] || 0) -
    ((sheets && sheets.rebate) || 0) -
    ((sheets && sheets.cash) || 0)
  );
}

export function SalesResume({
  receiptsMethodsSum,
  refundsMethodsSum,
  userGroupId,
  range
}: Props) {
  const [sumOfSheets, setSumOfSheets] = useState<sheetsSum>();
  const path = useDomainPath('/user-group');

  const loadSheet = useCallback(() => {
    const startDayFormated = range.start?.format('YYYYMMDD');
    const endDayFormated = range.end?.format('YYYYMMDD');
    get<CRM.SalesSheetDocument[]>(
      `${path}/${userGroupId || 'all'}/sales-sheet/${startDayFormated}/${endDayFormated}`,
    ).then(sheets => {
      setSumOfSheets(calculateSumOfSheets(sheets));
    });
  },[userGroupId, range, path]);
  useEffect(loadSheet, [loadSheet]);

  const expectedEndingCashFund = getExpectedEndingCashFund(
    sumOfSheets,
    receiptsMethodsSum,
  );
  const endingCashFundDiff = roundMoney(
    (sumOfSheets?.endingCashFund || 0) - expectedEndingCashFund,
  );
  return (
    <TableContainer component={Paper} style={{ width: '100%' }}>
      <Table>
        {/*<TableHead id={'salesResumeTabL1'}>
          <TableRow style={{ height: '30px' }}>
            <SmallTableCell align="center">Remise ESP</SmallTableCell>
            <SmallTableCell align="center">Télécollecte CB</SmallTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CrmTableRow key='1' style={{ height: '1.9rem' }}>
            <SmallTableCell align="center">
              {sumOfSheets?.cash.toFixed(2)}
            </SmallTableCell>
            <SmallTableCell align="center">
              {sumOfSheets?.card.toFixed(2)}
            </SmallTableCell>
          </CrmTableRow>
        </TableBody>
        <TableHead id={'salesResumeTabL2'}>
          <TableRow style={{ height: '30px' }}>
            <SmallTableCell align="center">Remise CHQ</SmallTableCell>
            <SmallTableCell align="center">Remb ESP</SmallTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CrmTableRow key='2' style={{ height: '1.9rem' }}>
            <SmallTableCell align="center">
              {sumOfSheets?.check.toFixed(2)}
            </SmallTableCell>
            <SmallTableCell align="center">
              {
              sumOfSheets && sumOfSheets.rebate > 0 ?
              sumOfSheets.rebate.toFixed(2) :
              refundsMethodsSum['cash'].toFixed(2)
              }
            </SmallTableCell>
          </CrmTableRow>
            </TableBody>*/}
        <TableHead id={'salesResumeTabL3'}>
          <TableRow style={{ height: '30px' }}>
            <SmallTableCell align="center">Delta ESP</SmallTableCell>
            <SmallTableCell align="center">Delta CB</SmallTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CrmTableRow key='3' style={{ height: '1.9rem' }}>
            <SmallTableCell align="center" style={{
              color: (endingCashFundDiff).toFixed(2) === "0.00" ? "green" : "red"
            }}>
              {endingCashFundDiff.toFixed(2)}€
            </SmallTableCell>
            <SmallTableCell align="center" style={{
              color: ((sumOfSheets?.card || 0) - receiptsMethodsSum['card']).toFixed(2) === "0.00" ? "green" : "red"
              }}>
              {((sumOfSheets?.card || 0) - receiptsMethodsSum['card']).toFixed(2)}€
            </SmallTableCell>
          </CrmTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
