import React, { useCallback } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDomain, useDomainValueUpdate } from './domain.context';
import { DomainSaveButton } from './saveButton.component';
import CardIcon from '@material-ui/icons/CreditCard';
import NoteIcon from '@material-ui/icons/Note';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { PreviewHtmlButton } from '../../components/PreviewHtmlButton.component';
import { HtmlEditor } from '../../components/HtmlEditor.component';
import { TemplateSelect } from '../templateSelect.component';

export const PaymentForm: React.FC = () => {
  const { value, save } = useDomain();
  const paymentConfigUpdater = useDomainValueUpdate('paymentConfig');

  const updateSystemPay = useCallback(
    (config: Partial<CRM.SystemPayConfig>) => {
      paymentConfigUpdater({
        ...value.paymentConfig,
        systemPay: { ...value.paymentConfig?.systemPay, ...config },
      });
    },
    [paymentConfigUpdater, value.paymentConfig],
  );

  const updatePaypal = useCallback(
    (config: Partial<CRM.PaypalConfig>) => {
      paymentConfigUpdater({
        ...value.paymentConfig,
        paypal: { ...value.paymentConfig?.paypal, ...config },
      });
    },
    [paymentConfigUpdater, value.paymentConfig],
  );

  const updateIban = useCallback(
    (html: string) => {
      paymentConfigUpdater({
        ...value.paymentConfig,
        ibanHtml: html,
      });
    },
    [paymentConfigUpdater, value.paymentConfig],
  );

  const updateCheck = useCallback(
    (html: string) => {
      const newVar = {
        ...value.paymentConfig,
        checkHtml: html,
      };
      paymentConfigUpdater(newVar);
    },
    [paymentConfigUpdater, value.paymentConfig],
  );

  return (
    <form onSubmit={save}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5">Notification</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Email"
            variant="outlined"
            value={value.paymentConfig?.paymentNotificationEmail || ''}
            onChange={e =>
              paymentConfigUpdater({
                ...value.paymentConfig,
                paymentNotificationEmail: e.target.value,
              })
            }
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TemplateSelect
            label="Modèle"
            id={value.paymentConfig?.paymentNotificationTemplateId}
            onChange={template =>
              paymentConfigUpdater({
                ...value.paymentConfig,
                paymentNotificationTemplateId: template._id,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5">
              <CardIcon /> System pay
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Systempay identifiant"
            variant="outlined"
            value={value.paymentConfig?.systemPay?.marketId || ''}
            onChange={e => updateSystemPay({ marketId: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onChange={event => {
                  updateSystemPay({ useProductionMode: event.target.checked });
                }}
                checked={Boolean(
                  value.paymentConfig?.systemPay?.useProductionMode,
                )}
              />
            }
            label="Mode production "
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Systempay clé secrete de TEST"
            variant="outlined"
            value={value.paymentConfig?.systemPay?.marketKey || ''}
            onChange={e => updateSystemPay({ marketKey: e.target.value })}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Systempay clé secrete de PROD"
            variant="outlined"
            value={value.paymentConfig?.systemPay?.marketKeyProd || ''}
            onChange={e => updateSystemPay({ marketKeyProd: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5">Paypal</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Client Id de TEST"
            variant="outlined"
            value={value.paymentConfig?.paypal?.sandboxClientId || ''}
            onChange={e => updatePaypal({ sandboxClientId: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Client Secret de TEST"
            variant="outlined"
            value={value.paymentConfig?.paypal?.sandboxClientSecret || ''}
            onChange={e =>
              updatePaypal({ sandboxClientSecret: e.target.value })
            }
            required
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Client Id de PROD"
            variant="outlined"
            value={value.paymentConfig?.paypal?.clientId || ''}
            onChange={e => updatePaypal({ clientId: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Client Secret de PROD"
            variant="outlined"
            value={value.paymentConfig?.paypal?.clientSecret || ''}
            onChange={e => updatePaypal({ clientSecret: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onChange={event => {
                  updatePaypal({ useProductionMode: event.target.checked });
                }}
                checked={Boolean(
                  value.paymentConfig?.paypal?.useProductionMode,
                )}
              />
            }
            label="Mode production "
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5">
              <NoteIcon /> Chèque
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <HtmlEditor
            label={'Contenu html à afficher'}
            rowsMax={3}
            value={value.paymentConfig?.checkHtml || ''}
            onChange={updateCheck}
          />
          <PreviewHtmlButton source={value.paymentConfig?.checkHtml} />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5">
              <AccountBalanceIcon /> Virement bancaire
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PreviewHtmlButton source={value.paymentConfig?.ibanHtml} />
        </Grid>
        <Grid item xs={12}>
          <HtmlEditor
            label={'Contenu html à afficher'}
            rowsMax={3}
            value={value.paymentConfig?.ibanHtml || ''}
            onChange={updateIban}
          />
        </Grid>
        <Grid item xs={12}>
          <DomainSaveButton />
        </Grid>
      </Grid>
    </form>
  );
};
