import React from 'react';
import { Box, Grid } from '@material-ui/core';

export function UserGroupGridHeader() {
  return (
    <Box mt={1} mb={1}>
      <Grid container spacing={1} justify={'space-between'}>
        <Grid item xs={3}>
          Nom
        </Grid>
        <Grid item xs={3}>
          Identifiant
        </Grid>
        <Grid item xs={3}>
          Rôle
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
      </Grid>
    </Box>
  );
}
