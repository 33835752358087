import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  /*Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,*/
  Divider,
  Paper,
  Theme,
  //Typography,
} from '@material-ui/core';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, sortBy } from 'lodash';
// import FileSaver from 'file-saver';
import dayjs, { Dayjs } from 'dayjs';
import { get, post } from '../fetch';
import { useDomainPath } from '../auth/auth.context';
//import { DepartureGridItem } from './departureGridItem.component';
//import { NextDaysStripSelect } from '../components/nextDaysStripSelect.component';
import { SpotStrip } from '../components/SpotStrip.component';
import { DomainsFilter } from '../components/filters/domainsFilter.component';
import { SpotTimingsFilter } from '../components/filters/spotTimingsFilter.component';
//import { DepartureGridHeader } from './departureGridHeader.component';
import { SelectionDetails } from './selectionDetails.component';
import { StatusFilter } from '../components/filters/statusFilter.component';
import { DayDetailsTable } from '../components/DayDetailsTable.component';
import { RichGrid } from '../components/table/RichGrid.component';
import { Grid } from '@material-ui/core';
import {
  departureTableProvider//, supervisedTimeSlotTableProvider
} from '../reservations/reservationGrid.columns';
import { RowHandlers, compareDate } from '../components/table/Table';
import { ClassNameMap } from "@material-ui/styles";
// import { useHistory } from 'react-router-dom';
import { OneDayStripSelect } from '../components/oneDayStripSelect.component';
import { TimeSlotsDialog } from '../activities/create/timeslots/timeSlotsDialog.component';
import { KindFilter } from '../components/filters/kindFilter.component';
import { groupTimeSlotsColumns } from '@crm/utils';
import { BusPrioritySelect } from '../components/selects/BusPrioritySelect.component';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),

    '& > *': {
      margin: theme.spacing(1),
    },
  },
  card: {
    margin: theme.spacing(1),

    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  media: {
    height: 140,
  },
}));

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.3, 0),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
}));

function isDepartureSpot(spot: CRM.ActivitySpotDocument) {
  return spot.departures && !isEmpty(spot.departures.every);
}

const inDomains = (includingDomainIds: string[]) => {
  return (res: CRM.ReservationOverview) =>
  includingDomainIds.length === 0 ? res : includingDomainIds.includes(res.domain);
};

const inKinds = (kinds: CRM.ActivityKind[]) => {
  return (res: CRM.ReservationOverview) => {
    if(
      (kinds.includes('canoe') && kinds.includes('supervised')) ||
      (
        (kinds.includes('canoe') && !res.activityTimeSlot) ||
        (kinds.includes('supervised') && res.activityTimeSlot)
      )
    ) {
      return true;
    }
    return false;
  }
   
};

const inStatus = (status: string[]) => {
  return (res: CRM.ReservationOverview) => status.length === 0 ? res : status.includes(res.status);
};

const inOccurrences = (includingTiming?: CRM.SpotTimingsOccurence) => {
  return (res: CRM.ReservationOverview) => {
    if (!includingTiming) {
      return true;
    }
    const day = dayjs(res.activityDate);
    return (
      includingTiming.minute === day.minute() &&
      includingTiming.hour === day.hour()
    );
  };
};

export function DeparturesView() {
  // const history = useHistory();
  const [refreshBusPriority, setRefreshBusPriority] = useState<number>(1);
  const classes = useStyles();
  const classesgrid: ClassNameMap<string> = useStylesGrid();
  const spotPath = useDomainPath('/activity-spot');
  const departurePath = useDomainPath('/departure');
  const timeSlotPath = useDomainPath(`/activity-timeslots`);
  //const userGroupPath = useDomainPath('/user-group');

  const [filterDay, setFilterDay] = useState<Dayjs>(dayjs());
  const [selectedSpot, setSelectedSpot] = useState<CRM.ActivitySpotDocument>();
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);
  const [domains, setDomains] = useState<CRM.Dictionary<string>>({});
  const [includingDomainIds, setIncludingDomainIds] = useState<string[]>([]);
  const [includingStatus, setIncludingStatus] = useState<
    CRM.ReservationStatus[]
  >(['booked']);
  const [includingKinds, setIncludingKinds] = useState<CRM.ActivityKind[]>(['canoe','supervised']);

  const [
    includingSpotOccurrence,
    setIncludingSpotOccurrence,
  ] = useState<CRM.SpotTimingsOccurence>();
  const [selectedReservationIds, setSelectedReservationIds] = useState<
    string[]
  >([]);
  const [selectedTimeSlotsIds, setSelectedTimeSlotsIds] = useState<
  string[]
>([]);
  const [selectionName, setSelectionName] = useState<string>('');
  const [reservations, setReservations] = useState<CRM.ReservationOverview[]>(
    [],
  );
  const [timeSlotReservationsViewDialogOpen, setTimeSlotReservationsViewDialogOpen] = useState(false);
  const [selectedTimeSlotReservations, setSelectedTimeSlotReservations] = useState<CRM.ReservationDetailed[]>([]);
  //const [groups, setGroups] = useState<CRM.Dictionary<string>>({});

  const timeSlotReservationsViewDialog = useCallback((activity?: string, id?: string, doNotOpenDialog?:boolean) => {
    get<CRM.ReservationDetailed[]>(`${timeSlotPath}/reservations/${activity || ''}/${id || ''}?omitInstructors=true`).then((reservations) => {
      setSelectedTimeSlotReservations(reservations);
      if(doNotOpenDialog === undefined || doNotOpenDialog === false) {
        setTimeSlotReservationsViewDialogOpen(true);
      }
    }
    )
  
}
  , [
    setSelectedTimeSlotReservations,
    setTimeSlotReservationsViewDialogOpen,
    timeSlotPath
  ]
);

  /*useEffect(() => {
    get<CRM.UserGroupDocument[]>(userGroupPath).then(_groups => {
      setGroups(
        _groups.reduce((acc, group) => {
          acc[group._id] = group.displayName;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
    });
  }, [setGroups, userGroupPath]);*/

  const timeSlotReservationsViewDialogClose = useCallback(
    () => setTimeSlotReservationsViewDialogOpen(false),
    [setTimeSlotReservationsViewDialogOpen],
  );
  

  const updateReservation = useCallback((reservationId) => () => {
    // history.push(`/reservations/${reservationId}`);
    window.open(
      `/reservations/${reservationId}`,
      '_blank',
    );
  }, [/*history*/]);

  const validateSelection = useCallback(() => {
    if (!includingSpotOccurrence || selectedReservationIds.length === 0) {
      return;
    }
    post<CRM.ValidateDeparturesSubmit>(departurePath, {
      name: selectionName,
      spotDisplayName: selectedSpot?.displayName || 'Inconnu',
      reservations: selectedReservationIds,
      timeSlots: selectedTimeSlotsIds,
      departureTime: includingSpotOccurrence,
    }).then(result => {
      var win = window.open('', '_blank');
      if(win) {
        win.document.body.innerHTML = '<body onload=`window.print()`><div style="margin-top:20px;">'+result+'</div></body>';
        win.print();
      }
    });
  }, [
    departurePath,
    selectionName,
    selectedSpot,
    selectedReservationIds,
    selectedTimeSlotsIds,
    includingSpotOccurrence,
  ]);

  const filteredReservations = useMemo(
    () =>
      {
        const _reservations = reservations
        .filter(inDomains(includingDomainIds))
        .filter(inStatus(includingStatus))
        .filter(inKinds(includingKinds))
        .filter(inOccurrences(includingSpotOccurrence));

        //console.log(_reservations.length)
        const result = groupTimeSlotsColumns(_reservations);
        console.log(result)
        if(result && result.length > 0) {
          return _reservations
          .filter(
            r => r.activityTimeSlot === undefined || 
            r.activityTimeSlot === null
          ).concat(result)
          .sort((a,b) => compareDate(a.activityDate, b.activityDate, true));
        }

        return _reservations
        .sort((a,b) => compareDate(a.activityDate, b.activityDate, true));
      },
    [
      reservations,
      includingDomainIds,
      includingStatus,
      includingSpotOccurrence,
      includingKinds
    ],
  );

  /*function groupTimeSlotsColumns(_reservations: CRM.ReservationOverview[]) {

    const reservationsBuffer: CRM.ReservationOverview[] = JSON.parse(JSON.stringify(_reservations)); //preserve original array
    let resultReservations: CRM.ReservationOverview[] = [];

    reservationsBuffer.filter(r=> r.activityTimeSlot !== undefined).reduce((r, o) => {
      const key = o.activityTimeSlot;
      //const monitor = o.options.instructors && o.options.instructors.length > 0 ? o.options.instructors[0] : '';
      
      const item:CRM.ReservationOverview = r.get(key) || Object.assign({}, o, {
        o,
        people: o.people,
        options: o.options,
        customer: {firstname: 'Desc. Encadrée', lastname: ''},
      });
      
      if(item.people && o._id !== item._id) { //avoid incrementing the first reservation because already defined by default
      (Object.keys(o.people || {})  as (keyof typeof o.people)[]).forEach((key) => {
        item.people[key] = (item.people[key] || 0) + o.people[key];    
      });
      }
      if(item.options && o._id !== item._id) {//avoid incrementing the first reservation because already defined by default
        console.log(key, o._id, o.options.equipments);
        (Object.keys(o.options.equipments || {})  as (keyof typeof o.options.equipments)[]).forEach((key) => {
          //console.log(key, item.options.equipments)
          let equipments:CRM.Equipments = item.options.equipments || {type: 'canoe', customized: false};

          if (key !== "type" && key !== "customized") {
            const oEquipmentCount: number = (
              o.options.equipments &&
              (o.options.equipments as any )[key]&&
              o.options.equipments.type === "canoe") ? (o.options.equipments as any )[key] : 0;
            const itemEquipmentCount: number = (
              item.options.equipments &&
              (item.options.equipments as any )[key]
            ) ? (item.options.equipments as any )[key] : 0;
            //console.log(oEquipmentCount, key, o.options.equipments);

            (equipments as any)[key] = itemEquipmentCount + oEquipmentCount;
          }

        });
      }
      if(!r.get(key)) {
        resultReservations.push(item);//#TODO move the push inside entries loop,
      }
      return r.set(key, item);
    
    }, new Map()).values();
    
    //problem only the first entry exists in result (r.set seems working)
    //for (const [key, value] of Object.entries(result)) { 
      //console.log(key, value)
      //const v = value.next().value;
      //if(v !== undefined) {
        //resultReservations.push(v);
      //}
    //}

    return resultReservations;
  }*/

  const loadSpots = useCallback(() => {
    get<CRM.ActivitySpotDocument[]>(spotPath).then(result => {
      const availableSpots = sortBy(
        result.filter(isDepartureSpot),
        spot => spot.displayName,
      );
      setSpots(availableSpots);
      if (!selectedSpot && availableSpots.length > 0) {
        setSelectedSpot(availableSpots[0]);
      }
    });
  }, [spotPath, selectedSpot]);

  const refreshReservations = useCallback(() => {
    if (!selectedSpot) {
      return;
    }

    const start = filterDay.format('YYYYMMDD');
    const end = filterDay.format('YYYYMMDD');
    get<CRM.ReservationOverview[]>(
      `${departurePath}?start=${start}&end=${end}&spot=${selectedSpot._id}`,
    )
      //.then(result => sortBy(result, [r => r.startedDate, r => r.activityDate]))
      .then(result => {
        get<CRM.ReservationOverview[]>(
          `${departurePath}/daytwo?start=${start}&end=${end}&spot=${selectedSpot._id}`,
        ).then(resultD2 => {
          result = result.concat(resultD2)
            .sort((a,b) => compareDate(a.startedDate, b.startedDate))
            .sort((a,b) => compareDate(a.activityDate, b.activityDate));
          setSelectedReservationIds([]);
          setSelectedTimeSlotsIds([]);
          setReservations(result);
        })
      });
  }, [departurePath, filterDay, selectedSpot]);

  const changeIncludingSpotOccurrence = useCallback(
    (occurrence?: CRM.SpotTimingsOccurence) => {
      setSelectedReservationIds([]);
      setSelectedTimeSlotsIds([]);
      setIncludingSpotOccurrence(occurrence);
    },
    [],
  );

  const selectReservation = useCallback(
    (value: CRM.ReservationOverview) => {
      const alreadyIncluding = selectedReservationIds.includes(value._id);
      if (alreadyIncluding) {
        setSelectedReservationIds(
          selectedReservationIds.filter(including => including !== value._id),
        );
      } else {
        setSelectedReservationIds(selectedReservationIds.concat(value._id));
      }
      if (value.activityTimeSlot) {
        const alreadyIncluding = selectedTimeSlotsIds.includes(value.activityTimeSlot);
        if (alreadyIncluding) {
          setSelectedTimeSlotsIds(
            selectedTimeSlotsIds.filter(including => including !== value.activityTimeSlot),
          );
        } else {
          setSelectedTimeSlotsIds(selectedTimeSlotsIds.concat(value.activityTimeSlot));
        }     
      }
    },
    [selectedReservationIds, selectedTimeSlotsIds, setSelectedReservationIds, setSelectedTimeSlotsIds],
  );

  useEffect(() => {
    get<CRM.EntityName[]>(`domains`).then(domains => {
      setDomains(
        domains.reduce((acc, domain) => {
          acc[domain._id] = domain.displayName;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
      setIncludingDomainIds(domains.map(domain => domain._id));
    });
  }, [setDomains, setIncludingDomainIds]);

  /**
   * Select first timing on spot change
   */
  useEffect(() => {
    if (selectedSpot && selectedSpot.departures?.every) {
      // Comment for checking all departure hours by default
      //setIncludingSpotOccurrence(selectedSpot.departures!.every[0]);
    }
  }, [selectedSpot]);

  useEffect(refreshReservations, [
    refreshReservations,
    filterDay,
    selectedSpot,
  ]);
  useEffect(loadSpots, [loadSpots]);

  if (!selectedSpot) {
    return <CircularProgress size="1em" />;
  }

  return (
    <div className={classes.root}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <OneDayStripSelect
          onChange={setFilterDay}
          value={filterDay}
        />
      </Box>
      <Divider />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <SpotStrip
          selectedId={selectedSpot._id}
          onChange={setSelectedSpot}
          spots={spots}
        />
      </Box>
      <Divider />
      <Box display={'flex'} justifyContent={'space-between'} flexGrow={0.85}>
        <Box flexGrow={0.85}>
          {<RichGrid
            columns={departureTableProvider(selectedReservationIds, domains, selectReservation).columns}
            rows={filteredReservations}
            gridStyle={{
              maxHeight:'65vh',
              marginBottom:'-7vh',
              overflowY:'scroll',
              overflowX:'hidden',
            }}
            renderRow={reservation => {
              return(
                <div className='Grid-row'>
                  <Grid
                    container
                    className={classesgrid && classesgrid.root}
                    spacing={1}
                    onClick={reservation.activityTimeSlot ? undefined : updateReservation(reservation._id)}
                  >
                    {departureTableProvider(
                      selectedReservationIds,
                      domains,
                      selectReservation,
                      reservation.activityTimeSlot ? timeSlotReservationsViewDialog : undefined
                    ).columns.map(column => {
                      const Cell = column.Cell;
                      return (
                        <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                          {Cell && <Cell value={reservation} handlers={{} as RowHandlers} />}
                          {column.renderCell &&
                            column.renderCell(reservation, {} as RowHandlers)}
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
            );
            }}
          />}
        </Box>
        <Box display={'flex'} flexDirection={'column'} width={350}>
          <DayDetailsTable
            reservations={filteredReservations}
            dateKey={'activityDate'}
          />
          &nbsp;
          <Paper>
            <Box display={'flex'} flexDirection={'column'} p={2}>
              <DomainsFilter
                domains={domains}
                includingDomainIds={includingDomainIds}
                onChange={setIncludingDomainIds}
              />
              &nbsp;
              <KindFilter
                includingKinds={includingKinds}
                onChange={setIncludingKinds}
              />
              &nbsp;
              <StatusFilter
                includingStatus={includingStatus}
                onChange={setIncludingStatus}
              />
              &nbsp;
              <SpotTimingsFilter
                timings={selectedSpot!.departures!}
                includingOccurrence={includingSpotOccurrence}
                onChange={changeIncludingSpotOccurrence}
              />
              &nbsp;
              <BusPrioritySelect
                spotTimingOccurence={includingSpotOccurrence}
                spot={selectedSpot}
                day={filterDay}
                direction='departure'
                onUpdate={()=> {refreshReservations(); setRefreshBusPriority(refreshBusPriority+1);}}
                refresh={refreshBusPriority}
              />
              &nbsp;
              <BusPrioritySelect
                spotTimingOccurence={includingSpotOccurrence}
                spot={selectedSpot}
                day={filterDay}
                direction='departure'
                onUpdate={()=> {refreshReservations(); setRefreshBusPriority(refreshBusPriority+1);}}
                refresh={refreshBusPriority}
                onlyBusesAlreadyDeparted={true}
              />
            </Box>
          </Paper>
          &nbsp;
          <Paper>
            <Box display={'flex'} flexDirection={'column'} p={2}>
              <SelectionDetails
                name={selectionName}
                onNameChange={setSelectionName}
                reservations={reservations}
                selectedReservationIds={selectedReservationIds}
              />
              &nbsp;
              <Button
                fullWidth
                disabled={
                  !includingSpotOccurrence || selectedReservationIds.length < 1
                }
                color="secondary"
                variant="contained"
                type="submit"
                onClick={validateSelection}
              >
                Valider et imprimer la liste
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
      <TimeSlotsDialog
        timeSlotReservations={selectedTimeSlotReservations}
        activityName={selectedTimeSlotReservations[0]?.activity?.displayName || ''}
        activityDate={selectedTimeSlotReservations[0]?.activityDate || Date()}
        dialogOpen={timeSlotReservationsViewDialogOpen}
        dialogClose={timeSlotReservationsViewDialogClose}
        onUpdate={() => {
          timeSlotReservationsViewDialog(
            selectedTimeSlotReservations[0]?.activity._id, 
            selectedTimeSlotReservations[0]?.activityTimeSlot,
            true
            );
          refreshReservations();
        }}
      />
      {/*<Dialog
          onClose={timeSlotReservationsViewDialogClose}
          open={timeSlotReservationsViewDialogOpen}
          maxWidth={'lg'}
          fullWidth={true}
          id='timeSlotsList'
        >
          <DialogTitle style={{ textAlign: 'center' }}>
            {selectedTimeSlotReservations[0]?.activity?.displayName} Du&nbsp;
            {new Date(selectedTimeSlotReservations[0]?.activityDate).toLocaleDateString()}  à&nbsp;
            {new Date(selectedTimeSlotReservations[0]?.activityDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            &nbsp;
          </DialogTitle>
          <DialogContent>
            <Box style={{ minHeight: '70vh', overflow: 'hidden' }} className='App'>
              <Typography variant="subtitle1">
                RESERVATIONS
              </Typography>
              <RichGrid
                style={{
                  paddingRight: '10px', //compensate for scrollbar width
                }}
                columns={supervisedTimeSlotTableProvider('booked', 1, groups).columns}
                rows={selectedTimeSlotReservations}
                renderRow={selectedTimeSlotReservation => {
                  return (
                    <Grid
                      container
                      className={classesgrid.root}
                      spacing={1}
                    >
                      {supervisedTimeSlotTableProvider('booked', 1, groups).columns.map(column => {
                        const Cell = column.Cell;
                        return (
                          <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                            {Cell && <Cell value={selectedTimeSlotReservation} handlers={{} as RowHandlers} />}
                            {column.renderCell &&
                              column.renderCell(selectedTimeSlotReservation, {} as RowHandlers)}
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={timeSlotReservationsViewDialogClose}>Fermer</Button>
          </DialogActions>
        </Dialog>*/}
    </div>
  );
}
