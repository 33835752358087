import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Arrays } from '@crm/utils';
import { isString, isEmpty, sortBy } from 'lodash';
import { GridItemProps } from '../../../components/GridItem';
import { CrmTableCell, CrmTableRow } from '../../../components/Table.component';
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import { PeriodNameSelect } from '../../../components/selects/PeriodNameSelect.component';
import { Alert } from '../../../components/alerts/Alert.component';

interface Props {
  year: number;
  value: CRM.PricingPeriodException[];
  onChange: (value: CRM.PricingPeriodException[]) => any;
}

export const Exceptions: React.FC<Props> = ({ value, onChange, year }) => {
  const exceptions = useMemo(() => {
    const withDates = value.map(exception => ({
      ...exception,
      date: dayjs(exception.date).toDate(),
    }));
    return sortBy(withDates, 'date');
  }, [value]);

  const { minDate, maxDate } = useMemo(() => {
    const thisYear = dayjs().year(year);
    return {
      minDate: thisYear.startOf('year'),
      maxDate: thisYear.endOf('year'),
    };
  }, [year]);

  const [addDate, setAddDate] = useState<dayjs.Dayjs>(minDate);
  const [addStartTime, setAddStartTime] = useState<dayjs.Dayjs>(minDate.set('hour', 0));
  const [addEndTime, setAddEndTime] = useState<dayjs.Dayjs>(minDate.set('hour', 23));


  const addException = useCallback(() => {
    const alreadyExists = () =>
      exceptions.some(
        exception => addDate?.isSame(exception.date, 'day') &&
          ((exception.startTime && exception.endTime && 
            (addStartTime?.isBetween(exception.startTime, exception.endTime) || 
              addEndTime?.isBetween(exception.startTime, exception.endTime)
              )) ||
          (!exception.startTime || !exception.endTime))
      );
    if (addDate.year() !== year || alreadyExists()) {
      return;
    }
    const newExceptions: CRM.PricingPeriodException[] = [
      ...exceptions,
      {
        date: addDate.utc(true).toDate(),
        startTime: addStartTime.utc(true).toDate(),
        endTime: addEndTime.utc(true).toDate(),
      },
    ];
    onChange(sortBy(newExceptions, 'date'));
  }, [onChange, exceptions, addDate, addStartTime, addEndTime, year]);

  const updateException = useCallback(
    (exception: CRM.PricingPeriodException, index: number) => {
      onChange(Arrays.replace(exceptions, index, exception));
    },
    [onChange, exceptions],
  );

  const deleteException = useCallback(
    (index: number) => {
      onChange(Arrays.remove(exceptions, index));
    },
    [onChange, exceptions],
  );

  return (
    <Grid container spacing={3}>
      <Grid item container spacing={3} alignItems={'center'}>
        <Grid item>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            label={`Date`}
            value={addDate}
            onChange={date => {
              if (date?.isValid()) {
                setAddDate(date);
                setAddStartTime(date);
                setAddEndTime(date.set('h', 23));
              }
            }
            }
            minDate={minDate}
            maxDate={maxDate}
            autoOk
            required
          />
        </Grid>
        <Grid item>
          <TimePicker
            disableToolbar
            ampm={false}
            variant="inline"
            inputVariant="outlined"
            label="Début du créneau *"
            value={addStartTime}
            onChange={(date) => date?.isValid() && setAddStartTime(date)}
          />
        </Grid>
        <Grid item>
          <TimePicker
            disableToolbar
            ampm={false}
            variant="inline"
            inputVariant="outlined"
            label="Fin du créneau *"
            value={addEndTime}
            onChange={(date) => date?.isValid() && setAddEndTime(date)}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={addException}>
            Ajouter une exception
          </Button>
        </Grid>
      </Grid>
      {isEmpty(exceptions) && (
        <Grid item xs={12}>
          <Box mb={2}>
            <Alert severity={'info'} variant={'standard'}>
              Aucune exception définie pour cette année
            </Alert>
          </Box>
        </Grid>
      )}
      {!isEmpty(exceptions) && (
        <Grid item xs={12} sm={10} md={8}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <CrmTableCell>Date</CrmTableCell>
                  <CrmTableCell>Forcer saison</CrmTableCell>
                  <CrmTableCell>Forcer fermeture</CrmTableCell>
                  <CrmTableCell>Commentaire</CrmTableCell>
                  <CrmTableCell>Restreindre</CrmTableCell>
                  <CrmTableCell></CrmTableCell>
                  <CrmTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {exceptions.map((exception, index) => (
                  <ExceptionRow
                    key={
                      isString(exception.date)
                        ? exception.date
                        : exception.date.toISOString()
                    }
                    value={exception}
                    onChange={value => updateException(value, index)}
                    onDelete={() => deleteException(index)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};

interface ExceptionRowProps extends GridItemProps<CRM.PricingPeriodException> {}
const ExceptionRow: React.FC<ExceptionRowProps> = props => {
  function triggerOnChange(override: Partial<CRM.PricingPeriodException>) {
    props.onChange && props.onChange({ ...props.value, ...override });
  } 
  return (
    <CrmTableRow>
      <CrmTableCell>
        {dayjs(props.value.date).format('DD/MM/YYYY')}
        <br/>
        {(props.value.startTime && props.value.endTime && dayjs(props.value.startTime).hour() !== 0) ? 
        ' de ' + dayjs(props.value.startTime).utc().format('HH:mm') + ' à ' + dayjs(props.value.endTime).utc().format('HH:mm') :
        ''
        }
      </CrmTableCell>
      <CrmTableCell>
        <PeriodNameSelect
          value={props.value.forcePeriodName}
          onChange={value => {
            triggerOnChange({
              forcePeriodName: isEmpty(value) ? undefined : value,
            });
          }}
        />
      </CrmTableCell>
      <CrmTableCell>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              onChange={event => {
                triggerOnChange({
                  isClosed: event.target.checked,
                });
              }}
              checked={props.value.isClosed || false}
            />
          }
          label="Fermé"
          labelPlacement="end"
        />
      </CrmTableCell>
      <CrmTableCell>
        <TextField
          label="Commentaire"
          variant="outlined"
          value={props.value.comment || ''}
          onChange={e => triggerOnChange({ comment: e.target.value })}
        />
      </CrmTableCell>
      <CrmTableCell>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              onChange={event => {
                triggerOnChange({
                  isForInternetOnly: event.target.checked,
                });
              }}
              checked={props.value.isForInternetOnly || false}
            />
          }
          label="Internet Seulement"
          labelPlacement="end"
        />
      </CrmTableCell>
      <CrmTableCell>
        <IconButton onClick={() => props.onDelete!(props.value)}>
          <DeleteIcon />
        </IconButton>
      </CrmTableCell>
    </CrmTableRow>
  );
};
