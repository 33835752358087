import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { get, post, put } from '../../fetch';
import { Dayjs } from 'dayjs';
import { useDomainPath } from '../../auth/auth.context';
import { Box, Card, CardContent, Chip, Grid, Radio, RadioGroup, Typography, makeStyles } from '@material-ui/core';
import { ViewPassengersDialog } from '../dialogs/bus/viewPassengersDialog.component';
import { useAlerts } from '../../hooks/useAlerts';
import { AlertDialog } from '../alerts/AlertDialog.component';
import { ViewAvailableBusesDialog } from '../dialogs/bus/viewAvailableBusesDialog.component';
import { countBookingPeople } from '@crm/utils';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: "0.5em"
  },
  line: {
    margin: 0,
    fontSize: 12,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  chip: {
    margin: 2
  },
  chipContainer: {
    marginTop: "0.5em"
  }
});

type BusPrioritySelectProps = {
  spot: CRM.ActivitySpotDocument;
  day: Dayjs;
  direction: CRM.BusTravelDirection;
  refresh: number;
  spotTimingOccurence?: CRM.SpotTimingsOccurence;
  onlyBusesAlreadyDeparted?: boolean;
  onUpdate?: () => void;
};

export const BusPrioritySelect = ({
  spot,
  day,
  direction,
  refresh,
  spotTimingOccurence,
  onlyBusesAlreadyDeparted = false,
  onUpdate,
}: BusPrioritySelectProps) => {
  const classes = useStyles();
  const { notify } = useAlerts();
  const [busesFilled, setBusesFilled] = useState<CRM.FilledBusTravel[]>([]);
  const queryDay = 'day='+day.format('YYYYMMDD');
  const querySpot = '&spotId='+spot._id;
  const querySpotHour = '&spotHour='+spotTimingOccurence?.hour;
  const querySpotMinutes = '&spotMinutes='+spotTimingOccurence?.minute;
  const queryDirection = "&direction="+direction;
  const queryRealTime = onlyBusesAlreadyDeparted === true ? "&realTime=false" : "";
  const reservationPath = useDomainPath('/reservation');
  const busTravelPath = useDomainPath('/busTravel');
  const [domains, setDomains] = useState<CRM.Dictionary<string>>({});
  const [viewPassengersReservations, setViewPassengersReservations] = useState<CRM.ReservationDetailed[]>([]);
  const [viewPassengersDialogOpen, setViewPassengersDialogOpen] = useState(false);
  const [validateBusDepartureDialogOpen, setValidateBusDepartureDialogOpen] = useState(false);
  const [transportsAreFullDialogOpen, setTransportsAreFullDialogOpen] = useState(false);
  const [availableBusesDialogOpen, setAvailableBusesDialogOpen] = useState(false);
  const [selectedBusFilled, setSelectedBusFilled] = useState<CRM.FilledBusTravel | undefined>(undefined);
  const [selectedBusPassengerGroupsCount, setSelectedBusPassengerGroupsCount] = useState<number[]>([]);
  const [availableBusTravels, setAvailableBusTravels] = useState<CRM.BusTravelDetailed[]>([]);
  const [busAddOrReplaceClick, setBusAddOrReplaceClick] = 
    useState<Exclude<CRM.VehicleGridType, 'admin'>>('replace');
  const spotsPath = useDomainPath('/activity-spot');
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);

  useEffect(() => {
    get<CRM.ActivitySpotDocument[]>(spotsPath).then(setSpots);
  }, [setSpots, spotsPath]);

  const validateBusDepartureDialog = useCallback(() => {
    setValidateBusDepartureDialogOpen(true);
  },[setValidateBusDepartureDialogOpen]);
  const validateBusDepartureDialogClose = useCallback(
    () => setValidateBusDepartureDialogOpen(false),
    [setValidateBusDepartureDialogOpen],
  );
  const transportsAreFullDialog = useCallback(() => {
    setTransportsAreFullDialogOpen(true);
  },[setTransportsAreFullDialogOpen]);
  const transportsAreFullDialogClose = useCallback(
    () => setTransportsAreFullDialogOpen(false),
    [setTransportsAreFullDialogOpen],
  );
  const viewPassengersDialog = useCallback((busFilled: CRM.FilledBusTravel) => {
    get<CRM.ReservationDetailed[]>(
      `${reservationPath}/travelReservations/filledOrEmptyBusReservations?`+
      'busId='+busFilled.bus._id +"&" +
      queryDay +
      querySpot +
      querySpotHour +
      querySpotMinutes +
      queryDirection +
      queryRealTime).then((reservations) => {
        if (onlyBusesAlreadyDeparted === true) {
          setViewPassengersReservations(reservations.filter(r => r.status === "in-activity"));
        } else {
          setViewPassengersReservations(reservations);
        }
      setSelectedBusFilled(busFilled);
      setViewPassengersDialogOpen(true);
    }
    )
  }
    , [
      setViewPassengersReservations,
      setViewPassengersDialogOpen,
      onlyBusesAlreadyDeparted,
      reservationPath, 
      queryDay, 
      querySpot, 
      querySpotHour, 
      querySpotMinutes, 
      queryDirection,
      queryRealTime
    ]
  );
  const viewPassengersDialogClose = useCallback(
    () => setViewPassengersDialogOpen(false),
    [setViewPassengersDialogOpen],
  );

  const availableBusesDialog = useCallback((selectedBusFilledBusId?: string) => {
    get<CRM.BusTravelDetailed[]>(
      `${reservationPath +
      '/travelReservations/availableBusesForSpotTiming?'
      + queryDay + querySpot + querySpotHour + querySpotMinutes+queryDirection
      }`
    ).then(
      _busTravels => {
        if (!_busTravels) {
          return;
        }
        selectedBusFilledBusId && get<CRM.ReservationDetailed[]>(
          `${reservationPath}/travelReservations/filledOrEmptyBusReservations?`+
          'busId='+selectedBusFilledBusId +"&" +
          queryDay +
          querySpot +
          querySpotHour +
          querySpotMinutes +
          queryDirection).then((reservations) => {
          const groupsCount: number[] = [];
          for(const reservation of reservations) {
            groupsCount.push(countBookingPeople(reservation.people));
          }
          setSelectedBusPassengerGroupsCount(groupsCount);
          });
        setAvailableBusTravels(_busTravels);
      });
    setAvailableBusesDialogOpen(true);
  }, [
    setAvailableBusesDialogOpen,
    reservationPath, 
    queryDay, 
    querySpot, 
    querySpotHour, 
    querySpotMinutes,
    queryDirection
  ]);
  const availableBusesDialogClose = useCallback(
    () => setAvailableBusesDialogOpen(false),
    [setAvailableBusesDialogOpen],
  );

  useEffect(() => {
    get<CRM.EntityName[]>(`domains`).then(domains => {
      setDomains(
        domains.reduce((acc, domain) => {
          acc[domain._id] = domain.displayName;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
    });
  }, [setDomains]); 

  const refreshBusesFilled = useCallback(() =>{
    if (spotTimingOccurence) {
      get<CRM.FilledBusTravel[]>(reservationPath + `/travelReservations/filledBusesTravels?` +
        queryDay +
        querySpot +
        querySpotHour +
        querySpotMinutes +
        queryDirection + 
        queryRealTime)
        .then((buses) =>
          setBusesFilled(buses.sort().sort((a,b) => 
          ((b.bus.passengerCapacity - b.passengersCount) < (a.bus.passengerCapacity - a.passengersCount) ? -1 : 1)
        ))
        );
    }
  }, [
    setBusesFilled, 
    reservationPath, 
    queryDay, 
    querySpot, 
    querySpotHour, 
    querySpotMinutes, 
    queryDirection,
    queryRealTime,
    spotTimingOccurence
  ]);

  useEffect(() => {
    if(refresh) {
      refreshBusesFilled();
    }
  },[
    refreshBusesFilled, refresh
  ]);

  const validateBusDeparture = useCallback((action: CRM.BusDepartureAction, bus?: CRM.BusDocument | undefined) => {
    if(!bus) {
      return;
    }
    const uriBusDeparture = action === "validate" ? "validateBusDeparture" : "cancelBusDeparture";
    const validateOrCancelMsg = action === "validate" ? "validé" : "annulé";
    try {
      post<CRM.BusTravelFindParams>(
        reservationPath+'/travelReservations/'+uriBusDeparture, 
      {
        busId: bus._id,
        day: day.format('YYYYMMDD'),
        spotId: spot._id,
        spotHour: spotTimingOccurence?.hour.toString(),
        spotMinutes: spotTimingOccurence?.minute.toString(),
        direction: direction
      } as CRM.BusTravelFindParams
      ).then(() => {
        notify(`Départ `+validateOrCancelMsg+` pour le bus `+bus.name, 'success');
        //refreshBusesFilled();
        onUpdate && onUpdate();
        action === "validate" && validateBusDepartureDialogClose();
      });
    } catch (err) {
      notify(`Impossible de valider / d'annuler le départ du bus `+bus.name, 'error');
      action === "validate" && validateBusDepartureDialogClose();
    }
  },[
    reservationPath,
    day,
    direction,
    spotTimingOccurence?.hour,
    spotTimingOccurence?.minute,
    spot._id,
    onUpdate,
    notify,
    refreshBusesFilled,
    validateBusDepartureDialogClose
  ]);

  const updatePriority = useCallback(
    (event: ChangeEvent<HTMLInputElement> | undefined) => {
      const busFilledId = event?.target.value;
      if (busFilledId) {
        
        busesFilled.map((busfilled, index) => {
          if(busesFilled[index]._id.includes(busFilledId)) {
            busesFilled[index].fillPriority = 1;
          } else {
            busesFilled[index].fillPriority = 0;
          }
          return true;
        });
        
        put(busTravelPath+'/priority', busesFilled).then(refreshBusesFilled);
      }
    },
    [busesFilled, busTravelPath, refreshBusesFilled],
  );

  const replaceBusTravel = useCallback((
    busTravelId: string, 
    spotTimingOccurence: CRM.SpotTimingsOccurence,
    busTravelIdToReplace: string,
  ) => {
    const params: CRM.PutReplaceTravelSpotTimingsParams = {
      spotId: spot._id,
      busTravelId: busTravelId,
      spotTimingOccurence: spotTimingOccurence,
      busTravelIdToReplace: busTravelIdToReplace,
      day: day.format('YYYYMMDD')
    };
    const bustravel = availableBusTravels.find(b => b._id.valueOf() === busTravelId);
    const busTravelToReplace = busesFilled.find(b=> b._id.valueOf() === busTravelIdToReplace);
    if(bustravel?.bus.passengerCapacity && busTravelToReplace?.passengersCount
      && bustravel.bus.passengerCapacity < busTravelToReplace?.passengersCount) {
        // show warning dialog
      }
    try {
      post(reservationPath+'/travelReservations/replaceSpotTiming', params).then((_isSuccess: string) => {
        const isSuccess: boolean | null = _isSuccess === 'true' ? 
          true : _isSuccess === 'false' ? 
          false : null;
        if(isSuccess === true) {
          notify('Le bus a bien été remplacé', 'success');
        } else if(isSuccess === false) {
          //show dialog (add transport then try replacing another time)
          transportsAreFullDialog();
        } else {
          notify('Impossible de Remplacer le bus, trajet introuvable pour ce bus', 'error');
        }
        refreshBusesFilled();
        availableBusesDialogClose();
      });
    } catch {
      notify('Impossible de Remplacer le bus', 'error');
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    reservationPath,
    availableBusTravels,
    notify,
    availableBusesDialogClose,
    refreshBusesFilled,
    spot._id,
    day
  ]);
  
  const addSpotTimingToBusTravel = useCallback((
    busTravelId: string, 
    spotTimingOccurence: CRM.SpotTimingsOccurence, 
    ) => {
    const params: CRM.PutTravelSpotTimingParams = {
      spotId: spot._id,
      busTravelId: busTravelId,
      spotTimingOccurence: spotTimingOccurence,
      isRemove: false
    };
    try {
      put(busTravelPath+'/addOrRemoveSpotTiming', params).then(() => {
        refreshBusesFilled();
        availableBusesDialogClose();
        notify('Le bus a bien été ajouté', 'success');
      });
    } catch {
      notify('Impossible d\'Ajouter/Remplacer le bus', 'error');
    }
  },[
    busTravelPath, 
    refreshBusesFilled, 
    spot._id, 
    notify, 
    availableBusesDialogClose
  ]);

  return (
    <Box>
      <RadioGroup 
        aria-label="Bus Priority"
        value={busesFilled.find(b => b.fillPriority === 1)?._id || ''}
        onChange={updatePriority}
        style={{ width: '100%', display: 'inline', alignItems: 'center', marginTop: '1em' }}
      >
        <Typography>
          <b>{onlyBusesAlreadyDeparted === true ? "En rotation" : "Transport"}</b>
        </Typography>
        {busesFilled?.map((busFilled) => {
          return (
            <Card className={classes.root} variant='outlined'>
              <CardContent>
                <Typography className={classes.line}>
                  {!onlyBusesAlreadyDeparted && 
                    <Radio color="secondary" value={busFilled._id} />
                  }
                  <b>{busFilled.bus.name}</b>&nbsp;|&nbsp;
                  {busFilled.driverName}&nbsp;|&nbsp;
                  {(busFilled.bus.passengerCapacity - busFilled.passengersCount) + " places restantes"}
                </Typography>
                <Grid container xs={12} className={classes.line}>
                  {busFilled.passengersByDomain.map((passengersByDomain) => {
                    return (
                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                      {domains[passengersByDomain.domainId]}{passengersByDomain.passengersCount}
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container xs={12} className={classes.chipContainer}>
                  {!onlyBusesAlreadyDeparted && 
                    <Chip
                      onClick={() => {viewPassengersDialog(busFilled);}}
                      className={classes.chip}
                      label="Voir Liste"
                      component="a"
                      clickable
                    />
                  }
                  {!onlyBusesAlreadyDeparted && 
                    <Chip
                      onClick={() => {
                        setSelectedBusFilled(busFilled);
                        setBusAddOrReplaceClick('replace');
                        availableBusesDialog(busFilled.bus._id);
                      }}
                      className={classes.chip}
                      label="Remplacer le véhicule"
                      component="a"
                      clickable
                    />
                  }
                  {!onlyBusesAlreadyDeparted && 
                    <Chip 
                      onClick={() => { 
                        setSelectedBusFilled(busFilled);
                        validateBusDepartureDialog();/*validateBusDeparture(busFilled.bus);*/ 
                      }} 
                      className={classes.chip} 
                      label="Confirmer le départ" 
                      component="a" 
                      clickable 
                    />
                  }
                  { onlyBusesAlreadyDeparted && //#TODO revert confirm departure, all reservations come back to location
                    <Chip 
                      onClick={() => { 
                        setSelectedBusFilled(busFilled);
                        validateBusDeparture("cancel",busFilled.bus);
                      }} 
                      className={classes.chip} 
                      label="Annuler le départ" 
                      component="a" 
                      clickable 
                    />
                  }
                </Grid>
              </CardContent>
            </Card>
          );
        })}
        {!onlyBusesAlreadyDeparted &&
          <Grid container xs={12}>
            <Chip 
              onClick={() => {
                setBusAddOrReplaceClick('add');
                availableBusesDialog();
              }}
              label="Ajouter un transport" 
              component="a"  
              clickable 
            />
          </Grid>
        }
      </RadioGroup>
      {spotTimingOccurence && (
      <ViewPassengersDialog
        reservations={viewPassengersReservations}
        busName={selectedBusFilled?.bus.name || ''}
        dialogOpen={viewPassengersDialogOpen}
        domains={domains}
        spot={spot}
        includingSpotOccurrence={spotTimingOccurence}
        dialogClose={viewPassengersDialogClose}
      />)}
      {spotTimingOccurence && (
      <ViewAvailableBusesDialog
        availableBusTravels={availableBusTravels}
        dialogOpen={availableBusesDialogOpen}
        onSelect={(busTravelId) => { 
          //console.log(busTravelId+' is selected');
          if(busAddOrReplaceClick === "add") {
            addSpotTimingToBusTravel(busTravelId, spotTimingOccurence);
          } else {
            //#TODO call replace bus for travelReservations
            selectedBusFilled &&
            replaceBusTravel(busTravelId, spotTimingOccurence, selectedBusFilled._id);     
          }
        }}
        dialogClose={availableBusesDialogClose}
        isReplacement={busAddOrReplaceClick}
        spotTimingOccurence={spotTimingOccurence}
        spots={spots}
        busToReplacePassengers={selectedBusPassengerGroupsCount || []}
      />
      )}
      <AlertDialog
          accept={()=>{validateBusDeparture("validate",selectedBusFilled?.bus);}}
          cancel={validateBusDepartureDialogClose}
          open={validateBusDepartureDialogOpen}
          title={'Confirmer le départ pour '+ selectedBusFilled?.bus.name}
          content={"Etes-vous sûr de vouloir valider le départ pour ce bus ?"}
      />
      <AlertDialog
          accept={()=>{transportsAreFullDialogClose(); setBusAddOrReplaceClick("add"); availableBusesDialog();}}
          cancel={transportsAreFullDialogClose}
          open={transportsAreFullDialogOpen}
          title={'Tous les bus sont complets'}
          content={
            "Il n'y a pas assez de places disponibles dans les bus attribués sur " +
            "ce créneau,\n\n veuillez ajouter un transport puis réessayez."
          }
      />
    </Box>
  );
};
