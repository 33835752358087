import React, { useCallback, useState } from 'react';
import { Box, Chip, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { PositiveNumberField } from '../../components/fields/PositiveNumberField.component';
import { Alert } from '../../components/alerts/Alert.component';
import AddIcon from '@material-ui/icons/Add';
import { useReservation } from './reservation.context';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import { isEmpty } from 'lodash';
import { PRICE_LABEL_CHILD_UNDER_7 } from '../../utils/constants'

interface Props {
  groupsPriceError: boolean;
  groupsPrice?: CRM.ActivityGroupsPrice;
  value: CRM.BookingPeople;
  onChange: (people: CRM.BookingPeople) => void;
}

export const PeopleForm: React.FC<Props> = ({
  groupsPriceError,
  groupsPrice,
  onChange,
  value,
}: Props) => {
  const book = useCallback((priceCustomId: string, count: number) => {
    onChange({
      ...value,
      [priceCustomId]: Math.max(count, 0),
    });
  },[onChange, value]);
  
  const { update, reservation } = useReservation();
  const [child, setChild] = useState('');

  const handleChildAdd = useCallback(() => {
    if (isEmpty(child.trim())) {
      return;
    }
    if (reservation.options?.childrenWithMonitorNames?.includes(child)) {
      return;
    }
    //const _peopleAtArrival = countBookingPeople(reservation.people || {}, [...(reservation.options?.instructors || [])])+1;
    book(PRICE_LABEL_CHILD_UNDER_7, (value[PRICE_LABEL_CHILD_UNDER_7]+1) || 1);
    update({
      options: {
        ...reservation.options,
        childrenWithMonitorNames: [...(reservation.options?.childrenWithMonitorNames || []), child],
        //peopleAtArrivalCount: _peopleAtArrival
      },
    });
  }, [update, book, value, reservation.options, child]);

  const handleChildDelete = (toDelete: string) => {
    if (!Array.isArray(reservation.options?.childrenWithMonitorNames)) {
      return;
    }
    book(PRICE_LABEL_CHILD_UNDER_7, (value[PRICE_LABEL_CHILD_UNDER_7]-1) || 0);

    update({
      options: {
        ...reservation.options,
        childrenWithMonitorNames: reservation.options!.childrenWithMonitorNames.filter(
          child => child !== toDelete,
        ),
      },
    });
  };

  if (groupsPriceError) {
    return (
      <Alert severity={'error'} variant={'standard'}>
        Impossible de définir le tarif
      </Alert>
    );
  }

  if (!groupsPrice) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      {groupsPrice.groups.map((group, index) => (
        <Grid item xs={12} key={`${group.customId}`}>
          <Box display="flex" justifyContent={'space-between'}>
            <Box display="flex" flexDirection="column">
              <Typography variant={'h6'} className={'ellipsis'}>
                {group.label.fr}
              </Typography>
              <Typography variant={'body2'}>
              {group.customId === PRICE_LABEL_CHILD_UNDER_7 && <span>{value[group.customId] || 0}X&nbsp;</span>}
                {group.price}€
              </Typography>
            </Box>
            <Box maxWidth={100}>
              {
                group.customId !== PRICE_LABEL_CHILD_UNDER_7 &&
                <PositiveNumberField
                  variant="outlined"
                  value={value[group.customId] || 0}
                  onChange={e => {
                    const number = Number(e.target.value);
                    book(group.customId, number);
                  }}
                />
              }
              {
                group.customId === PRICE_LABEL_CHILD_UNDER_7 &&
                <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                  <Box ml={1}>
                    <TextField
                      variant={'outlined'}
                      inputProps={{
                        style: { width: '55px' },
                      }}
                      size={'small'}
                      label={'Prénom'}
                      onChange={e => setChild(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleChildAdd} size={'small'}>
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              }
            </Box>
          </Box>
          {
            group.customId === PRICE_LABEL_CHILD_UNDER_7 &&
            <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
              {reservation.options?.childrenWithMonitorNames?.map(child => {
                return (
                  <Chip
                    icon={<ChildCareIcon />}
                    key={child}
                    label={child}
                    onDelete={() => handleChildDelete(child)}
                    style={{ marginRight: '0.3em', marginBottom: '0.3em' }}
                  />
                );
              })}
            </Box>
          }
        </Grid>
      ))}
    </Grid>
  );
};
