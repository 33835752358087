import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { CrmLink } from '../../components/Link.component';
import { get, put } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { UpdateParams } from '../../route/UpdateParam';
import { useAlerts } from '../../hooks/useAlerts';
import { CreateSpotTabs } from './createSpotTabs.component';
import { ProvideSpot } from './spot.context';

export function UpdateSpotView() {
  const { id } = useParams<UpdateParams>();
  const path = useDomainPath('/activity-spot');
  const { notify } = useAlerts();
  const [value, setValue] = useState<CRM.ActivitySpotDocument>();

  const update = useCallback(
    async (spot: Partial<CRM.ActivitySpotDocument>) => {
      try {
        await put(`${path}/${spot._id}`, spot);
        notify(`Le spot a été mise à jour`, 'success');
      } catch (err) {
        notify(`Impossible de mettre à jour le spot'`, 'error');
      }
    },
    [notify, path],
  );

  useEffect(() => {
    get<CRM.ActivitySpotDocument>(`${path}/${id}`).then(setValue);
  }, [id, path]);

  if (!value) {
    return <CircularProgress />;
  }

  return (
    <ProvideSpot initialValue={value} onSave={update}>
      <Box display="flex" flexDirection="column">
        <CrmLink to="/spots">&lt; Liste des spots</CrmLink>
        <Box mb={3} mt={3}>
          <Typography variant="h4">{value?.displayName}</Typography>
        </Box>
        <CreateSpotTabs />
      </Box>
    </ProvideSpot>
  );
}
