import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
  to: string;
}

export const CrmLink: React.FC<Props> = props => {
  return (
    <Link to={props.to} className="link">
      <Typography color={'secondary'}>{props.children}</Typography>
    </Link>
  );
};
