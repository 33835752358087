import dayjs from 'dayjs';

export const buildYearlyPricingPeriods = (
  year: number,
): { [year: string]: CRM.YearlyPricingPeriods } => {
  return {
    [year]: {
      periods: [
        {
          name: 'low',
          startDate: dayjs('2021-04-03T00:00Z').year(year).toDate(),
          endDate: dayjs('2021-07-10T23:00Z').year(year).toDate(),
        },
        {
          name: 'high',
          startDate: dayjs('2021-07-11T00:00Z').year(year).toDate(),
          endDate: dayjs('2021-08-25T23:00Z').year(year).toDate(),
        },
        {
          name: 'low',
          startDate: dayjs('2021-08-26T00:00Z').year(year).toDate(),
          endDate: dayjs('2021-10-03T23:00Z').year(year).toDate(),
        },
      ],
      exceptions: [],
    },
  };
};
