import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDomainPath } from '../../auth/auth.context';
import { UpdateReservationTabs } from './createReservationTabs.component';
import { get, put } from '../../fetch';
import { ProvideReservation } from './reservation.context';
import { useAlerts } from '../../hooks/useAlerts';
import { UpdateToolbar } from './UpdateToolbar.component';

interface UpdateParams {
  id: string;
}

export function UpdateReservationView() {
  const { notify } = useAlerts();
  const { id } = useParams<UpdateParams>();
  const reservationPath = useDomainPath('/reservation');
  const [reservation, setReservation] = useState<CRM.ReservationDocument>();

  const updateReservation = useCallback(
    async (reservation: Partial<CRM.ReservationDocument>) => {
      try {
        await put<Partial<CRM.CreateReservation>>(
          `${reservationPath}/${reservation._id}`,
          reservation,
        );
        notify('La réservation a été mise à jour', 'success');
      } catch (err: any) {
        notify(
          'Impossible de mettre à jour la réservation' + err.message,
          'error',
        );
        throw err;
      }
    },
    [notify, reservationPath],
  );

  useEffect(() => {
    get<CRM.ReservationDocument>(`${reservationPath}/${id}`).then(
      setReservation,
    );
  }, [id, reservationPath]);

  if (!reservation) {
    return <CircularProgress />;
  }

  return (
    <ProvideReservation
      initialReservation={reservation}
      onSave={updateReservation}
    >
      <Box display="flex" flexDirection="column">
        <UpdateToolbar />
        <UpdateReservationTabs />
      </Box>
    </ProvideReservation>
  );
}
