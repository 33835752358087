import React, {
  Context,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { debounce, isEmpty } from 'lodash';
import { useDomainPath } from '../../auth/auth.context';
import { post } from '../../fetch';
import { useReservation, useReservationActivity } from './reservation.context';
import {
  countBookingPeople,
  paymentStatus,
  sumPaymentReceipts,
  sumReceipts,
} from '@crm/utils';

interface ReservationHashContext {
  isReady: boolean;
  hasImportantChanges: boolean;
  errors: string[];
  reservationChange: () => void;
}

const reservationHashContext: Context<ReservationHashContext> = createContext(
  null,
) as any;

export function useReservationHash(): ReservationHashContext {
  const context = useContext(reservationHashContext);
  return context;
}

function hasMissingArrivalHour(
  activity: CRM.PublicActivity,
  reservation: Partial<CRM.ReservationDocument>,
) {
  if (
    activity.spots.arrivalId &&
    (((reservation.status !== 'booked' &&
    reservation.status !== 'gift-voucher') &&
    !reservation.activityArrivalDate)
      || (reservation.activityArrivalDate &&
        (typeof reservation.activityArrivalDate === 'object' &&
          reservation.activityArrivalDate !== null &&
          'getHours' in reservation.activityArrivalDate) &&
        reservation.activityArrivalDate.getHours() === 0)
    )//0 means not set
  ) {
    return true;
  }
  return false;
}

export const ProvideReservationHash: React.FC = ({ children }) => {
  const { equipmentsError, reservation } = useReservation();
  const { activity, activityTimeSlotCreate, activityTimeSlot } = useReservationActivity();
  const reservationPath = useDomainPath('/reservation');

  const [isReady, setIsReady] = useState<boolean>(false);
  const [hasImportantChanges, setHasImportantChanges] = useState<boolean>(
    false,
  );
  const [errors, setErrors] = useState<string[]>([]);

  const debounceAsyncErrors = useMemo(
    () =>
      debounce((value: Partial<CRM.ReservationDocument>) => {
        post(`${reservationPath}/${reservation._id}/hash`, value).then(
          result => {
            setHasImportantChanges(result.hasChanged);
            setIsReady(true);
          },
        );
      }, 500),
    [reservation._id, reservationPath],
  );

  const reservationChange = useCallback(() => {
    const cannotSaveMessages = [];
    if (isEmpty(reservation.customer)) {
      cannotSaveMessages.push('Renseigner le client');
    }
    if (reservation.customer && isEmpty(reservation.customer.email)) {
      cannotSaveMessages.push(`Renseigner l'email du client`);
    }
    if (reservation.customer && isEmpty(reservation.customer.firstname)) {
      cannotSaveMessages.push('Renseigner le prénom du client');
    }
    if (reservation.customer && isEmpty(reservation.customer.name)) {
      cannotSaveMessages.push('Renseigner le nom du client');
    }
    if (reservation.customer && isEmpty(reservation.customer.lang)) {
      cannotSaveMessages.push('Renseigner la langue du client');
    }
    if (countBookingPeople(reservation.people || {}) < 1) {
      cannotSaveMessages.push('Ajouter des personnes');
    }
    if (!Boolean(reservation.activityDate)
    || (reservation.activityDate !== undefined
      && new Date(reservation.activityDate).getHours() === 0
      && new Date(reservation.activityDate).getMinutes() === 0)) {
      cannotSaveMessages.push('Sélectionner une date de départ');
    }
    if (!Boolean(reservation.payment)) {
      cannotSaveMessages.push('Renseigner les informations de paiement');
    }
    if (
      (
        reservation.status !== 'booked' &&
        reservation.status !== 'gift-voucher' &&
        reservation.status !== 'cancelled' &&
        reservation.status !== 'started' &&
        reservation.status !== 'done' &&
        paymentStatus(reservation.payment!) !== 'paid' 
      ) ||
      (
        reservation.payment?.optionalStatus !== 'waitToPaid' &&
        (
          reservation.status === 'started' || 
          reservation.status === 'done'
        ) &&
        paymentStatus(reservation.payment!) !== 'paid' && 
        paymentStatus(reservation.payment!) !== 'needRefund' &&
        paymentStatus(reservation.payment!) !== 'refunded'
      )
    ) {
      cannotSaveMessages.push(
        `Le paiement doit être complet pour enregistrer la réservation`,
      );
    }
    if (
      sumPaymentReceipts(reservation.payment) -
        sumReceipts(reservation.payment?.refunds) >
      (reservation.payment?.amountInEuro ? reservation.payment?.amountInEuro+0.01 : 0)
    ) {
      console.log(reservation);
      console.log(sumPaymentReceipts(reservation.payment),sumReceipts(reservation.payment?.refunds),(reservation.payment?.amountInEuro))
      cannotSaveMessages.push(`Encaissements supérieurs au tarif`);
    }
    if (Boolean(equipmentsError)) {
      cannotSaveMessages.push(`Corriger les problèmes d'équipements`);
    }
    if (
      (activity &&
      activity.requireArrivalDate &&
      hasMissingArrivalHour(activity, reservation))
      && reservation.status !== 'cancelled'
    ) {
      cannotSaveMessages.push(`Définir l'heure de retour`);
    }

    if(activityTimeSlotCreate) {
      if(activityTimeSlotCreate.bookedPeople > activityTimeSlotCreate.maxPeople) {
        cannotSaveMessages.push(`Le nombre maximal de places autonomes sur ce créneau est dépassé`);
      }
    } else if(activityTimeSlot) {
      if(activityTimeSlot.bookedPeople > activityTimeSlot.maxPeople) {
        cannotSaveMessages.push(`Le nombre maximal de places autonomes sur ce créneau est dépassé`);
      }
    }
    if(activityTimeSlot) {
      if (
        !reservation.payment ||
        (
          reservation.payment &&
          ( 
            (paymentStatus(reservation.payment) !== "paid" &&
              paymentStatus(reservation.payment!) !== 'needRefund' &&
              paymentStatus(reservation.payment!) !== 'refunded' &&
              paymentStatus(reservation.payment) !== "waitToPaid" &&
              reservation.status !== 'gift-voucher' &&
              reservation.status !== 'cancelled' &&
              reservation.status !== 'started' && 
              reservation.status !== 'done'
            ) ||
            (
              reservation.payment?.optionalStatus !== 'waitToPaid' &&
              (
                reservation.status === 'started' || 
                reservation.status === 'done'
              ) &&
              paymentStatus(reservation.payment!) !== 'paid' &&
              paymentStatus(reservation.payment!) !== 'needRefund' &&
              paymentStatus(reservation.payment!) !== 'refunded'
            )
          )
        )
      ) {
        //console.log(reservation.payment && paymentStatus(reservation.payment), reservation.status)
        cannotSaveMessages.push(`Le paiement doit être complet avant de valider la réservation`);
      }
      if(activityTimeSlot.closeReservations === true && !reservation._id) {
        cannotSaveMessages.push(`Ce créneau a été fermé, vous ne pouvez pas créer de réservation`);
      }
    }


    setErrors(cannotSaveMessages);

    if (reservation._id) {
      setIsReady(false);
      debounceAsyncErrors(reservation);
    } else {
      setIsReady(true);
    }
  }, [activity, debounceAsyncErrors, equipmentsError, reservation, activityTimeSlot, activityTimeSlotCreate]);
  useEffect(reservationChange, [reservationChange, reservation]);

  return (
    <reservationHashContext.Provider
      value={{
        isReady,
        hasImportantChanges,
        errors,
        reservationChange,
      }}
    >
      {children}
    </reservationHashContext.Provider>
  );
};
