import React from 'react';
import { Grid } from '@material-ui/core';
import { PositiveNumberField } from '../../components/fields/PositiveNumberField.component';

interface Props {
  equipments: CRM.CanoeEquipments;
  onChange: (value: CRM.CanoeEquipments) => any;
}

export const CanoeEquipmentsForm: React.FC<Props> = ({
  equipments,
  onChange,
}) => {
  const updater = (
    key: 'k1' | 'c2' | 'c3',
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) =>
    onChange({
      ...equipments,
      [key]: Number(e.target.value),
      customized: true,
    });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <PositiveNumberField
          label="K1"
          variant="outlined"
          value={equipments.k1 || 0}
          onChange={e => updater('k1', e)}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <PositiveNumberField
          label="C2"
          variant="outlined"
          value={equipments.c2 || 0}
          onChange={e => updater('c2', e)}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <PositiveNumberField
          label="C3"
          variant="outlined"
          value={equipments.c3 || 0}
          onChange={e => updater('c3', e)}
          fullWidth
          required
        />
      </Grid>
    </Grid>
  );
};
