import { makeStyles, Theme } from '@material-ui/core';

export interface GridItemProps<T> {
  value: T;
  onDelete?: (value: T) => any;
  onChange?: (value: T) => any;
}

export const useGridItemStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.3, 0),
    borderRadius: '5px',

    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const useGridItemClearStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
    borderRadius: '5px',

    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
