import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';

interface Props {
  includingKinds: CRM.ActivityKind[];
  onChange: (kinds: CRM.ActivityKind[]) => any;
}

export const KindFilter = ({
  includingKinds,
  onChange,
}: Props) => {
  const allKinds: CRM.ActivityKind[] = ["supervised", "canoe"];
  const toggleKind = (kind: CRM.ActivityKind, include: boolean) => {
    const alreadyIncluding = includingKinds.includes(kind);
    if (alreadyIncluding && !include) {
      onChange(includingKinds.filter(including => including !== kind));
    } else if (!alreadyIncluding && include) {
      onChange(includingKinds.concat(kind));
    }
  };

  return (
    <Box>
      <Typography>
        <b>Type</b>
      </Typography>
      <Box>
        {Object.entries(allKinds).map(([id, name]) => (
          <FormControlLabel
            key={id}
            label={name === 'supervised' ? 'encadrée' : name}
            labelPlacement="end"
            control={
              <Checkbox
                color="secondary"
                onChange={event => {
                  toggleKind(name, event.target.checked);
                }}
                checked={includingKinds.includes(name)}
              />
            }
          />
        ))}
      </Box>
    </Box>
  );
};
