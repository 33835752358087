import React, { useCallback } from 'react';
import { SplitButton } from './SplitButton.component';

interface Props {
  defaultLang?: CRM.I18nLang;
  onClick: (lang: CRM.I18nLang) => any;
  startIcon?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const PromptLangButton: React.FC<Props> = ({
  onClick,
  defaultLang,
  ...props
}) => {
  const onClickCallback = useCallback(
    (option: string) => {
      if (option === 'Français') onClick('fr');
      if (option === 'Anglais') onClick('en');
    },
    [onClick],
  );
  return (
    <SplitButton
      {...props}
      options={[
        { label: 'Français', highlighted: defaultLang === 'fr' },
        { label: 'Anglais', highlighted: defaultLang === 'en' },
      ]}
      onClick={onClickCallback}
    >
      {props.children}
    </SplitButton>
  );
};
