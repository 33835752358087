import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { get } from '../fetch';
import { useDomainPath } from '../auth/auth.context';
import { isEmpty } from 'lodash';
import { Alert } from '../components/alerts/Alert.component';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface RecurrenceInputProps {
  departureId?: string;
  value?: CRM.ActivityRecurrence;
  onChange: (value: Partial<CRM.ActivityRecurrence>) => any;
}

function labelOf(occurence: CRM.Time) {
  return `${String(occurence.hour).padStart(2, '0')}h${String(
    occurence.minute,
  ).padStart(2, '0')}`;
}

function reverseLabel(occurence: string) {
  const split = occurence.split('h');
  return { hour: Number(split[0]), minute: Number(split[1]) };
}

export function RecurrenceInput({
  onChange,
  value,
  departureId,
}: RecurrenceInputProps) {
  const path = useDomainPath('/activity-spot');
  const [spot, setSpot] = useState<CRM.ActivitySpotDocument>();
  const [hours, setHours] = useState<string[]>(
    value?.departureHours?.map(labelOf) || [],
  );

  const loadSpot = useCallback(() => {
    if (!departureId) {
      return;
    }
    setSpot(undefined);
    get<CRM.ActivitySpotDocument>(`${path}/${departureId}`).then(setSpot);
  }, [setSpot, path, departureId]);

  const triggerOnChange = useCallback(
    (override: Partial<CRM.ActivityRecurrence>) => {
      onChange && onChange({ ...value, ...override });
    },
    [onChange, value],
  );

  useEffect(loadSpot, [loadSpot]);

  if (isEmpty(departureId)) {
    return (
      <Alert severity={'warning'} variant={'standard'}>
        Veuillez choisir le point de départ pour afficher ce formulaire
      </Alert>
    );
  }
  if (isEmpty(spot)) {
    return <CircularProgress size="1em" />;
  }

  // const selectedLabels = value?.departureHours.map(labelOf) || [];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="Departures-select-label">Heures de départ</InputLabel>
          <Select
            multiple
            label="Heures de départ"
            labelId="Departures-select-label"
            value={hours.sort()}
            onChange={(event: React.ChangeEvent<any>) => {
              const newHours = event.target.value as string[];
              setHours(newHours);
              triggerOnChange({ departureHours: newHours.map(reverseLabel) });
            }}
            renderValue={selected => {
              return (selected as string[]).join(', ');
            }}
            MenuProps={MenuProps}
          >
            {spot?.departures?.every
              .map(every => ({ ...every, label: labelOf(every) }))
              .map(every => (
                <MenuItem key={every.label} value={every.label}>
                  <Checkbox checked={hours.indexOf(every.label) > -1} />
                  <ListItemText primary={every.label} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
