import { sumBy } from 'lodash';

export function sumReceiptsMethod(
  receipts: CRM.PaymentReceipt[],
  method: CRM.PaymentMethod,
  forGroupId?: string,
): number {
  if(!forGroupId) {
    return sumBy(
      receipts.filter(r => r.method === method),
      r => r.amountInEuro,
    );
  }
  return sumBy(
    receipts.filter(r => r.method === method && (r.userGroupId && r.userGroupId === forGroupId)),
    r => r.amountInEuro,
  );
}
