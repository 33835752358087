import DescriptionIcon from '@material-ui/icons/Description';
import React, { useCallback } from 'react';
import { useDomainPath } from '../../../auth/auth.context';
import { baseUrl, get } from '../../../fetch';
import { useReservation } from '../reservation.context';
import { Grid, Button } from '@material-ui/core';

interface Props {
  disabled?: boolean;
  fullWidth?: boolean;
}

export const ContractButton: React.FC<Props> = props => {
const { reservation, /*reload, save*/ } = useReservation();
  const reservationPath = useDomainPath('/reservation');
  const lang =
  (reservation.customer?.lang === undefined || reservation.customer?.lang === "fr") ?
  "fr" : "en";

  const printContract = useCallback(
    (lang: CRM.I18nLang) => {
      var win = window.open(
        `${baseUrl}/${reservationPath}/${reservation._id}/contract?lang=${lang}`,
        '_blank',
      );
      if(win) {
        // win.print();
      }
    },
    [ reservationPath, reservation._id],
  );

  const printDocusignContract = useCallback(
    () => {
      /*window.open(
        `${baseUrl}/${reservationPath}/${reservation._id}/contract/docusign`,
        '_blank',
      );*/
      get<string>(
        `${reservationPath}/${reservation._id}/contract/docusign`,
      ).then((base64Pdf: string) => { 
        console.log(base64Pdf);
        /*const url = window.URL.createObjectURL(new Blob([base64Pdf]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'yourcoolpdf.pdf');
        document.body.appendChild(link);
        link.click();*/
        let pdfWindow = window.open("")//base64
        pdfWindow && pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(base64Pdf.replace('undefined', '')) + "'></iframe>"
        );
      });
    },
    [ reservationPath, reservation._id],
  );

  /*const saveContract = useCallback(() => {
    if (reservation._id) {
      save().then(() => {
        setTimeout(reload, 2000);
      });
    }
  },[save, reload, reservation._id]);*/



  return (
    <Grid
    container
    spacing={3}
    item
    xs={12}
    style={{
      margin: props?.fullWidth === true ? "0" : "auto", 
      marginTop: props?.fullWidth === true ? "1em" : "auto",
    }}
    >
      {/*<Grid
      style={{padding: props?.fullWidth === true ? "0" : "auto"}}
      item xs={12}
      >
        <Button
          {...props}
          color="secondary"
          variant="contained"
          type="button"
          onClick={saveContract}
        >
          Enregistrer
        </Button>
      </Grid>*/}
      <Grid
        style={{padding: props?.fullWidth === true ? "0" : "auto"}}
        item xs={6}
       >
        <Button
            {...props}
            color="secondary"
            variant="contained"
            type="button"
            onClick={()=> {printContract(lang)}}
            startIcon={<DescriptionIcon />}
          >
           Imprimer le contrat
        </Button>
      </Grid>
      { reservation.documents?.docusignEnvelope && 
        <Grid
        style={{padding: props?.fullWidth === true ? "0" : "auto"}}
        item xs={6}
       >
        <Button
            {...props}
            color="secondary"
            variant="contained"
            type="button"
            //disabled={true}
            onClick={()=> {printDocusignContract()}}
            startIcon={<DescriptionIcon />}
          >
           Imprimer le contrat Docusign
        </Button>
      </Grid>
    }
    </Grid>
  );
};
