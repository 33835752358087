import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  // Typography,
  // Grid
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import dayjs from 'dayjs';
import { get, post, put } from '../fetch';
import { useAuthUsers, useDomainPath } from '../auth/auth.context';
// import { PositiveNumberField } from '../components/fields/PositiveNumberField.component';
import { PositiveDecimalField } from '../components/fields/PositiveDecimalField.component';
import { useAlerts } from '../hooks/useAlerts';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';
import { roundMoney } from '@crm/utils';

const DEFAULT_SHEET: CRM.SalesSheetDocument = {
  _id: undefined as any,
  day: new Date(),
  startingCashFund: 0,
  endingCashFund: 0,
  cash: 0,
  check: 0,
  ancv: 0,
  card: 0,
  paypal: 0,
  rebate: 0,
  cardRebate: 0
};

const styleProps: OutlinedTextFieldProps = {
  size: 'small',
  variant: 'outlined'
};

interface Props {
  day: dayjs.Dayjs;
  userGroupId: string;
  receiptsMethodsSum: { [method: string]: number };
  refundsMethodsSum: { [method: string]: number };
}

function getExpectedEndingCashFund(
  sheet: CRM.SalesSheet & CRM.DbDocument,
  receiptsMethodsSum: { [p: string]: number },
  refundsMethodsSum: { [p: string]: number },
) {
  return (
    (sheet?.startingCashFund || 0) +
    ((receiptsMethodsSum['cash'] || 0)
    -(refundsMethodsSum['cash'] || 0)) -
    sheet.rebate -
    sheet.cash
  );
}

export function SalesSheet({ day, userGroupId, receiptsMethodsSum, refundsMethodsSum }: Props) {
  const path = useDomainPath('/user-group');
  const { notify } = useAlerts();
  const { loggedUser } = useAuthUsers();

  const [loading, setLoading] = useState<boolean>(true);
  const [sheet, setSheet] = useState<CRM.SalesSheetDocument>(DEFAULT_SHEET);

  const loadSheet = useCallback(() => {
    setLoading(true);
    const dayFormated = day.format('YYYYMMDD');
    get<CRM.SalesSheetDocument>(
      `${path}/${userGroupId}/sales-sheet/${dayFormated}`,
    ).then(sheet => {
      if (!sheet) {
        const previousDay: dayjs.Dayjs = day.subtract(1, 'day');
        get<CRM.SalesSheetDocument>(
          `${path}/${userGroupId}/sales-sheet/${previousDay.format('YYYYMMDD')}`,
        ).then(prevSheet => {
          const now = dayjs().isSame(day, 'day') ? dayjs().tz('Europe/Paris', false).utc(true) : undefined;
          const newSheet = prevSheet ?
            {
              ...DEFAULT_SHEET, day: day.toDate(),
              lastConnexionDate: now?.toDate() || undefined,
              startingCashFund: prevSheet.endingCashFund
            } :
            { ...DEFAULT_SHEET, day: day.toDate(), lastConnexionDate: now?.toDate() || undefined }
          setSheet(newSheet);
          // updating the last connexion date
          post<CRM.SalesSheetDocument>(
            `${path}/${userGroupId}/sales-sheet`,
            newSheet,
          ).then((rSheet) => {
            setSheet(rSheet);
          });
        });
      } else if(dayjs().isSame(day, 'day')) {
        sheet.lastConnexionDate = day.toDate();
        setSheet(sheet);
        // updating the last connexion date
        put<CRM.SalesSheetDocument>(
          `${path}/${userGroupId}/sales-sheet/${sheet._id}`,
          sheet,
        );
      } else {
        setSheet(sheet);
      }
      setLoading(false);
    });
  }, [path, day, userGroupId]);

  const updateSheet = useCallback(
    async (evt?: FormEvent) => {
      evt && evt.preventDefault();
      const updateRequest = () => {
        sheet.lastValidationDate = dayjs().toDate();
        sheet.lastValidationUserName = loggedUser?.displayName || '';
        if (sheet._id) {
          return put<CRM.SalesSheetDocument>(
            `${path}/${userGroupId}/sales-sheet/${sheet._id}`,
            sheet,
          );
        }
        return post<CRM.SalesSheetDocument>(
          `${path}/${userGroupId}/sales-sheet`,
          sheet,
        );
      };
      try {
        await updateRequest();
        notify('La fiche de caisse a été mise à jour', 'success');
      } catch (err) {
        notify('Impossible de mettre à jour la fiche de caisse', 'error');
      }
    },
    [path, notify, sheet, userGroupId, loggedUser?.displayName],
  );

  useEffect(loadSheet, [loadSheet]);

  if (loading) {
    return (
      <Box>
        <CircularProgress size="1em" />
      </Box>
    );
  }

  const expectedEndingCashFund = getExpectedEndingCashFund(
    sheet,
    receiptsMethodsSum,
    refundsMethodsSum
  );
  const endingCashFundDiff = roundMoney(
    sheet?.endingCashFund - expectedEndingCashFund,
  );

  return (
    <form onSubmit={updateSheet} id={'formSheet'}>
      <Box mb={4} pb={2} display={'flex'} style={{borderBottom: '1px dashed #ddd'}}>
      <Grid
          container
          spacing={3}
          item
          xs={12}
          style={{paddingLeft: '15px'}}
        >
          <Grid xs={6}>Heure Der. Connexion : { (sheet?.lastConnexionDate && dayjs(sheet?.lastConnexionDate).format('HH:mm:ss')) || ''}</Grid>
          <Grid xs={6}>Heure Der. Validation : { (sheet?.lastValidationDate && dayjs(sheet?.lastValidationDate).format('HH:mm:ss')) || 'Non Validée'}</Grid>
          <Grid xs={6}></Grid>
          <Grid xs={6}>Validée Par : { sheet?.lastValidationUserName || '-'}</Grid>
        </Grid>
      </Box>

      <Box mb={2} display={'flex'} id={'sheetLine1'}>
        <PositiveDecimalField
          {...styleProps}
          label="Fond de caisse ouverture"
          value={sheet?.startingCashFund.toFixed(2) || '0.00'}
          onChange={event =>
            setSheet({
              ...sheet,
              startingCashFund: Number(event.target.value),
            })
          }
          required
        />
        &nbsp;
        <PositiveDecimalField
          {...styleProps}
          label="Fond de caisse fermeture"
          value={sheet.endingCashFund.toFixed(2)}
          onChange={event =>
            setSheet({
              ...sheet,
              endingCashFund: Number(event.target.value),
            })
          }
          error={endingCashFundDiff !== 0}
          required
        />
        &nbsp;
        <PositiveDecimalField
          {...styleProps}
          label="Télécollecte CB"
          value={sheet.card.toFixed(2)}
          onChange={event =>
            setSheet({
              ...sheet,
              card: Number(event.target.value),
            })
          }
          required
        />
      </Box>
      <Box mb={2} display={'flex'} id={'sheetLine2'}>
      <PositiveDecimalField
          {...styleProps}
          label="Paypal"
          value={sheet.paypal.toFixed(2)}
          onChange={event =>
            setSheet({
              ...sheet,
              paypal: Number(event.target.value),
            })
          }
          required
        />
        &nbsp;
        <PositiveDecimalField
          {...styleProps}
          label="Remise espèces"
          value={sheet.cash.toFixed(2)}
          onChange={event =>
            setSheet({
              ...sheet,
              cash: Number(event.target.value),
            })
          }
          required
        />
        &nbsp;
        <PositiveDecimalField
          {...styleProps}
          label="Remise chèques"
          value={sheet.check.toFixed(2)}
          onChange={event =>
            setSheet({
              ...sheet,
              check: Number(event.target.value),
            })
          }
          required
        />
      </Box>
      <Box mb={2} display={'flex'}  id={'sheetLine3'}>
      <PositiveDecimalField
          {...styleProps}
          label="Remise ANCV"
          value={sheet.ancv.toFixed(2)}
          onChange={event =>
            setSheet({
              ...sheet,
              ancv: Number(event.target.value),
            })
          }
          required
        />
        &nbsp;
        <PositiveDecimalField
          {...styleProps}
          label="Remboursement ESP"
          value={
            sheet.rebate > 0 ?
              sheet.rebate.toFixed(2) :
              refundsMethodsSum['cash'].toFixed(2)
          }
          onChange={event =>
            setSheet({
              ...sheet,
              rebate: Number(event.target.value),
            })
          }
          required
        />
        &nbsp;
        <PositiveDecimalField
          {...styleProps}
          label="Remboursement CB"
          value={
            sheet.cardRebate > 0 ?
              sheet.cardRebate.toFixed(2) :
              refundsMethodsSum['card'].toFixed(2)
          }
          onChange={event =>
            setSheet({
              ...sheet,
              cardRebate: Number(event.target.value),
            })
          }
          required
        />
      </Box>
      <Box mb={4} mt={3} display={'flex'}  id={'sheetLine4'}>
        {/*<PositiveDecimalField
          {...styleProps}
          label="Delta ESP"
          value={((sheet.rebate + sheet.endingCashFund) - ((sheet?.startingCashFund || 0) + sheet.cash)).toFixed(2)}
          color="secondary"
          disabled={true}
        />
        &nbsp;
        <PositiveDecimalField
          {...styleProps}
          label="Delta CB"
          value={(sheet.card - receiptsMethodsSum['card']).toFixed(2)}
          disabled={true}
        />*/}
        {<Grid
          container
          spacing={3}
          item
          xs={12}
          style={{paddingLeft: '15px'}}
        >
          <Grid
            item xs={4}
            style={{
              fontSize: "14px",
              padding: "6px",
              color: (endingCashFundDiff).toFixed(2) === "0.00" ? "green" : "red"
            }}
          >
            <label>
              Delta ESP : {endingCashFundDiff.toFixed(2)}€
            </label></Grid>
          <Grid
            item xs={4}
            style={{
              fontSize: "14px",
              padding: "6px",
              color: (sheet.card - receiptsMethodsSum['card']).toFixed(2) === "0.00" ? "green" : "red"
            }}
          >
            <label>
              Delta CB : {(sheet.card - receiptsMethodsSum['card']).toFixed(2)}€
            </label></Grid>
            <Grid
            item xs={4}
            style={{
              fontSize: "14px",
              padding: "6px",
              color: (sheet.ancv - receiptsMethodsSum['ancv']).toFixed(2) === "0.00" ? "green" : "red"
            }}
          >
            <label>
              Delta ANCV : {(sheet.ancv - receiptsMethodsSum['ancv']).toFixed(2)}€
            </label></Grid>
        </Grid>}
      </Box>
      <Box mb={1} mt={2} display={'flex'} alignItems={'flex-start'}  id={'sheetLine5'}>
        <TextField
          {...styleProps}
          label="Commentaire"
          value={sheet.comment || ''}
          onChange={event =>
            setSheet({
              ...sheet,
              comment: event.target.value,
            })
          }
          rows={1}
          multiline
          fullWidth
        />
      </Box>
      <Box mb={1} mt={2} display={'flex'} alignItems={'center'}>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          startIcon={<SaveIcon fontSize={'large'} />}
          id="validateBtn"
        >
          Valider
        </Button>
        {/*endingCashFundDiff !== 0 && (
          <Box ml={2}>
            <Typography color={'error'}>
              Différence du fond de caisse: {endingCashFundDiff}€
            </Typography>
          </Box>
        )*/}
      </Box>
    </form>
  );
}
