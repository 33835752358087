import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { translateMethod } from '@crm/utils';
import { CrmTableRow, SmallTableCell, CrmTableCell } from '../components/Table.component';

export type DisplayedMethods = Array<CRM.PaymentMethod>;
export const allMethods: DisplayedMethods = [
  'cash',
  'check',
  'ancv',
  'card',
  'transfer',
  'paypal',
  'systempay',
  'other',
];

interface Props {
  receiptsMethodsSum: { [activityKind: string]: CRM.ReceiptsMethodsSum};
  type?: 'receipt' | 'refund' | 'receipt_resume';
}

export function SalesByPaymentMethod({
  receiptsMethodsSum,
  type
}: Props) {

  return (
    <TableContainer component={Paper} style={{ width: '100%' }}>
      <Table>
        <TableHead id={'totalReceiptTab'}>
          <TableRow>
            <CrmTableCell style={{padding:'0.3rem'}} colSpan={6} align="center">Total {
            type && type === 'refund' ?
            'Remboursements' : (type !== 'receipt_resume' ? 'Encaissements' : 'C.A')
            }
            </CrmTableCell>
          </TableRow>
          <TableRow style={{height:'30px'}}>
            <SmallTableCell align="right">Règlement</SmallTableCell>
            <SmallTableCell align="center">Canoë</SmallTableCell>
            <SmallTableCell align="center">Act. Encadrées</SmallTableCell>
            <SmallTableCell align="center">Vtes Addit.</SmallTableCell>
            <SmallTableCell align="center">
              Total
            </SmallTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allMethods.map(method => (
            <CrmTableRow key={method} style={{height:'1.9rem'}}>
              <SmallTableCell align="right">
                {translateMethod(method, 'fr')}
              </SmallTableCell>
              <SmallTableCell align="right">
                {receiptsMethodsSum["canoe"] ? receiptsMethodsSum["canoe"][method].toFixed(2) : 0.00}€
              </SmallTableCell>
              <SmallTableCell align="right">
                {receiptsMethodsSum["supervised"] ? receiptsMethodsSum["supervised"][method].toFixed(2) : 0.00}€
              </SmallTableCell>
              <SmallTableCell align="right">
                0.00€
              </SmallTableCell>
              <SmallTableCell align="right">
                {(
                  (receiptsMethodsSum["canoe"] ? receiptsMethodsSum["canoe"][method] : 0)
                  +(receiptsMethodsSum["supervised"] ? receiptsMethodsSum["supervised"][method] : 0)
                  ).toFixed(2)}€
              </SmallTableCell>
            </CrmTableRow>
          ))}
          <CrmTableRow style={{height:'3rem'}}>
          <SmallTableCell align="right"><h4>Total</h4></SmallTableCell>
          <SmallTableCell align="right">
            <h4>{receiptsMethodsSum["canoe"] ? receiptsMethodsSum["canoe"]['total'].toFixed(2) : 0.00}€</h4>
          </SmallTableCell>
          <SmallTableCell align="right">
            <h4>{receiptsMethodsSum["supervised"] ? receiptsMethodsSum["supervised"]['total'].toFixed(2) : 0.00}€</h4>
          </SmallTableCell>
          <SmallTableCell align="right">
            <h4>0.00€</h4>
          </SmallTableCell>
          <SmallTableCell align="right">
          <h4>
            {(
              (receiptsMethodsSum["canoe"] ? receiptsMethodsSum["canoe"]['total'] : 0)
              + (receiptsMethodsSum["supervised"] ? receiptsMethodsSum["supervised"]['total'] : 0)
            ).toFixed(2)}€
          </h4>
          </SmallTableCell>
          </CrmTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
