import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useSpot, useSpotValueUpdate } from './spot.context';
import { SaveButton } from './saveButton.component';
import { SpotTimingsForm } from './spotTimings.form';

const DEFAULT_EVERY: CRM.SpotTimingsOccurence[] = [
  9,
  10,
  11,
  12,
  13,
  14,
  15,
].map(hour => ({
  hour: hour,
  minute: 0,
  allowOverBooking: false,
  capacity: 80,
}));

export const DeparturesForm: React.FC = () => {
  const { spot, save } = useSpot();
  const departuresUpdater = useSpotValueUpdate('departures');

  return (
    <form onSubmit={save}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onChange={event => {
                  if (event.target.checked) {
                    departuresUpdater({ every: DEFAULT_EVERY });
                  } else {
                    departuresUpdater(null);
                  }
                }}
                checked={Boolean(spot.departures)}
              />
            }
            label="Ce spot sert de point de départ"
            labelPlacement="end"
          />
        </Grid>
        {spot?.departures && (
          <Grid item xs={12}>
            <SpotTimingsForm
              value={spot.departures}
              onChange={departuresUpdater}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </form>
  );
};
