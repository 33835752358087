import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CrmLink } from '../../components/Link.component';
import { post } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { useHistory } from 'react-router-dom';
import { ProvideActivity } from './activity.context';
import { CreateActivityTabs } from './createActivityTabs.component';
import { useAlerts } from '../../hooks/useAlerts';

export function CreateActivityView() {
  const path = useDomainPath('/activity');
  const history = useHistory();
  const { notify } = useAlerts();

  const create = useCallback(
    async (activity: Partial<CRM.CreateActivity>) => {
      try {
        const created = await post<Partial<CRM.CreateActivity>>(path, activity);
        history.push(`/activities/${created._id}`);
        notify(`L'activité a été créée`, 'success');
      } catch (err) {
        notify(`Impossible de créer l'activité`, 'error');
      }
    },
    [notify, history, path],
  );

  return (
    <ProvideActivity onSave={create}>
      <Box display="flex" flexDirection="column">
        <CrmLink to="/activities">&lt; Liste des activités</CrmLink>
        <Box mb={3} mt={3}>
          <Typography variant="h4">Ajouter une activité</Typography>
        </Box>
        <CreateActivityTabs />
      </Box>
    </ProvideActivity>
  );
}
