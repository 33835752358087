import React, { useEffect } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import { Box, Button } from '@material-ui/core';

interface Props {
  content: React.ReactElement;
  className?: string;
  closeCell?: boolean;
  onClosed?: () => void;
}

export const EditableCell: React.FC<Props> = ({
  className,
  content,
  children,
  closeCell,
  onClosed
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if(closeCell && closeCell === true) {
      setAnchorEl(null);
      onClosed && onClosed();
    }
  },[closeCell,onClosed]);

  const handleClose = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box
      display={'flex'}
      className={className}
      alignItems={'center'}
      onClick={handleClick}
    >
      {content}
      <KeyboardArrowDownIcon
        aria-controls="simple-menu"
        aria-haspopup="true"
        className="opaqueOnHover iconButton"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '250px',
            width: '45ch',
          },
        }}
      >
        <Box p={1} display={'flex'} flexDirection={'column'}>
          {children}
          &nbsp;
          <Button variant="contained" color="primary" onClick={handleClose}>
            Fermer
          </Button>
          &nbsp;
          <i>
            Veuillez valider les changements pour voir les nouveaux taux
            d'occupation
          </i>
        </Box>
      </Menu>
    </Box>
  );
};
