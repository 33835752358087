import React, { ChangeEvent, useCallback, useState } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import Menu from '@material-ui/core/Menu';

type Props = Omit<TextFieldProps, 'value' | 'onChange'> & {
  onChange: (value: CRM.I18nString) => any;
  value?: CRM.I18nString;
};

export const I18nField: React.FC<Props> = ({
  value,
  label,
  onChange,
  ...inputProps
}) => {
  const [lang, setLang] = useState<CRM.I18nLang>('fr');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const setValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({ ...value, [lang]: event.target.value });
    },
    [onChange, value, lang],
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLang = (newLang: CRM.I18nLang) => {
    setLang(newLang);
    handleClose();
  };

  return (
    <FormControl variant="outlined">
      <TextField
        id="i18n-field"
        type={'text'}
        variant={'outlined'}
        value={(value && value[lang]) || ''}
        onChange={setValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleClick}>
                {lang} <FlagIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...inputProps}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeLang('fr')}>FR</MenuItem>
        <MenuItem onClick={() => changeLang('en')}>EN</MenuItem>
      </Menu>
    </FormControl>
  );
};
