import React, { ChangeEvent, useCallback, useState } from 'react';
import { /*Button,*/ Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@material-ui/core';
import { ButtonMenu } from '../../components/ButtonMenu.component';
import { httpDelete, put } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { GridItemProps, useGridItemStyles } from '../../components/GridItem';

interface role {
  groupRole?: CRM.UserGroupRole;
}

export function UserGridItem({
  value,
  onDelete,
  onChange,
  groupRole
}: GridItemProps<CRM.UserDocument> & role) {
  const classes = useGridItemStyles();
  const userPath = useDomainPath('/user');
  const [profilePicUrl, setProfilePicUrl] = useState<string | undefined>(undefined);
  const [canManageArrivals, setCanManageArrivals] = useState<boolean | undefined>(undefined);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const handleDelete = useCallback(
    () =>
      httpDelete(`${userPath}/${value._id}`).then(
        () => onDelete && onDelete(value),
      ),
    [onDelete, userPath, value],
  );

  const handleProfilePicUrlChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      {
        setProfilePicUrl(e.target.value as string);
      },
    [],
  );

  const handleApplyEditChanges = useCallback(
    (url: string, manageArrivals?: boolean) =>
      { console.log(url);
        put<CRM.User>(userPath+'/'+value._id, {
          displayName: value.displayName,
          login: value.login,
          userGroup: value.userGroup,
          profilePicUrl : url !== '' ? url : value.profilePicUrl,
          canManageArrivals : manageArrivals !== undefined ? manageArrivals : value.canManageArrivals
        }).then((res) => {
          onChange && onChange(res as CRM.UserDocument); 
          setIsEditMode(false);
          setProfilePicUrl(undefined);
          setCanManageArrivals(undefined);
        });
      },
    [onChange, userPath, value],
  );

  return (
    <Grid container className={classes.root}>
      <Grid item xs={3}>
        {value.login}
      </Grid>
      <Grid item xs={3}>
        {value.displayName}
      </Grid>
      { !isEditMode && (
      <Grid item xs={groupRole !== 'driver' ? 5 : 3} style={{whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>
        {value.profilePicUrl || ''}
      </Grid>
      )}
      { isEditMode && (
      <Grid item xs={groupRole !== 'driver' ? 5 : 3} style={{whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>
      <TextField
            value={profilePicUrl !== undefined ? profilePicUrl : value.profilePicUrl}
            onChange={handleProfilePicUrlChange}
            fullWidth
      />
      </Grid>
      )}
      { groupRole === 'driver' && (
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="secondary"
                disabled={!isEditMode}
                onChange={event => {
                  setCanManageArrivals(event.target.checked);
                }}
                checked={canManageArrivals !== undefined ? canManageArrivals : value.canManageArrivals || false}
              />
            }
            label={'Gestion des retours'}
            labelPlacement="end"
          />
        </Grid>
      )}
      <ButtonMenu>
        <MenuItem 
          onClick={() => {handleApplyEditChanges(profilePicUrl || '', canManageArrivals)}}
          disabled={(isEditMode === true && (profilePicUrl !== undefined || canManageArrivals !== undefined)) ? false : true}
        >
          Enregistrer Modifications
        </MenuItem>
        <MenuItem onClick={() => {setIsEditMode(!isEditMode)}}>{isEditMode ? 'Annuler Modifications' : 'Modifier'}</MenuItem>
        <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
      </ButtonMenu>
    </Grid>
  );
}
