import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import DescriptionIcon from '@material-ui/icons/Description';
import PlaceIcon from '@material-ui/icons/Place';
import {
  useActivity,
  useActivityChangeEventUpdate,
  useActivityValueUpdate,
} from './activity.context';
import { SaveButton } from './saveButton.component';
import { RecurrenceInput } from '../recurrence.component';
import { PositiveNumberField } from '../../components/fields/PositiveNumberField.component';
import { TemplateSelect } from '../../domain/templateSelect.component';
import { SpotsInput } from './spots.component';
import { ActivityKindSelect } from './activityKindSelect.component';
import { USER_GROUPS } from '@crm/utils';
import { AllowedUserGroups } from './allowedUserGroups.component';
import { useAlerts } from '../../hooks/useAlerts';
import { SharedTimeSlotsDomains } from './sharedTimeSlotsDomains.component';

export const ActivityForm: React.FC = () => {
  const { activity, update, save } = useActivity();
  const { notify } = useAlerts();
  const [ areValidAttachmentUrls, setAreValidAttachmentUrls ] = useState<boolean>(true);
  const nameUpdater = useActivityChangeEventUpdate('name');
  const displayNameUpdater = useActivityChangeEventUpdate('displayName');
  const descriptionUpdater = useActivityChangeEventUpdate('description');
  const meetingPointUpdater = useActivityChangeEventUpdate('meetingPoint');
  const imageUpdater = useActivityChangeEventUpdate('image');
  const minAgeUpdater = useActivityChangeEventUpdate('minAge');
  const estimatedTimeMinutesUpdater = useActivityChangeEventUpdate(
    'estimatedTimeMinutes',
  );
  const estimatedWalkTimeMinutesUpdater = useActivityChangeEventUpdate(
    'estimatedWalkTimeMinutes',
  );
  const overDaysUpdater = useActivityChangeEventUpdate('overDays');
  const kindUpdater = useActivityValueUpdate('kind');
  const recurrenceUpdater = useActivityValueUpdate('recurrence');
  const spotsUpdater = useActivityValueUpdate('spots');
  const spotsDayTwoUpdater = useActivityValueUpdate('spotsDayTwo');

  const handleRequireArrivalDateUpdate = useCallback(
    event => update({ requireArrivalDate: event.target.checked }),
    [update],
  );
  const handleContractTemplateUpdate = useCallback(
    value => update({ contractTemplate: value._id }),
    [update],
  );
  const handleContractDocusignTemplateUpdate = useCallback(
    value => update({ contractDocusignTemplate: value._id }),
    [update],
  );
  const handleInvoiceTemplateUpdate = useCallback(
    value => update({ invoiceTemplate: value._id }),
    [update],
  );
  const handleConfirmationTemplateUpdate = useCallback(
    value => update({ confirmationTemplate: value._id }),
    [update],
  );

  useEffect(() => {

    if(activity?.kind && activity.kind === 'supervised') {
      update({ requireArrivalDate: true });
    } /*else {
      update({ requireArrivalDate: false });
    }*/
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    activity?.kind
  ]);

  const onSaveForm = useCallback(
    (evt) => {
      evt.preventDefault();
      if(areValidAttachmentUrls) {
        save(evt);
        return true;
      } else {
        notify('Veuillez entrer les url des pièces jointes séparées par un point virgule, et sans point virgule à la fin.', 'error');
        return false;
      }
  },[save, areValidAttachmentUrls, notify]);

  const handleMailAttachmentPathUpdate = useCallback(
    evt => {
      update({ mailAttachmentPath: evt.target.value });

      let splitSeparator:string[] = evt.target.value.split(';');

      let areValidUrlFormats: boolean = true;
      splitSeparator.forEach((url) => {
        let splitHttp = url.match('http');

        if (
          (splitHttp === null || (splitHttp && splitHttp.length > 1))
            && evt.target.value !== ''
          ) {
          areValidUrlFormats = false;
          setAreValidAttachmentUrls(false);
          return;
        }
      });

      if(areValidUrlFormats) {
        setAreValidAttachmentUrls(true);
      }
    },
    [update],
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && !activity.name) {
      update({
        kind: 'canoe',
        allowedUserGroups: [USER_GROUPS.INTERNET],
        name: 'canoe-8km',
        displayName: 'Descente 8km',
        description: 'ça va être fou fou fou',
        meetingPoint: 'Loulou bateau',
        image: 'https://docs.nestjs.com/assets/logo-small.svg',
      });
    }
    if (!activity.recurrence) {
      const defaultRecurrence: Partial<CRM.ActivityRecurrence> = {
        departureHours: [
          { hour: 8, minute: 0 },
          { hour: 9, minute: 0 },
          { hour: 10, minute: 0 },
          { hour: 11, minute: 0 }
        ]
      }; 
      recurrenceUpdater(defaultRecurrence);
    }
      // #TODO fix eventually these dependancies for not causes infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, activity.name, recurrenceUpdater]);

  return (
    <form onSubmit={onSaveForm}>
      { activity.sharedByDomain && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
          <AllowedUserGroups />
          </Grid>
        </Grid>
      )}
      { !activity.sharedByDomain && (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <ActivityKindSelect value={activity?.kind} onChange={kindUpdater} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AllowedUserGroups />
        </Grid>
        { activity?.kind === 'supervised' && 
        <Grid item xs={12} sm={3}>
          <SharedTimeSlotsDomains />
        </Grid>
        }
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="identifant"
            variant="outlined"
            value={activity.name || ''}
            onChange={nameUpdater}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Nom public"
            variant="outlined"
            value={activity.displayName || ''}
            onChange={displayNameUpdater}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Point de rendez-vous"
            variant="outlined"
            value={activity.meetingPoint || ''}
            onChange={meetingPointUpdater}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            variant="outlined"
            value={activity.description || ''}
            onChange={descriptionUpdater}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Image"
            variant="outlined"
            value={activity.image || ''}
            onChange={imageUpdater}
            fullWidth
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <PositiveNumberField
            label="Age requis"
            variant="outlined"
            value={activity.minAge || ''}
            onChange={minAgeUpdater}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <PositiveNumberField
            label="Temps parcours en canoë (mn)"
            variant="outlined"
            value={activity.estimatedTimeMinutes || ''}
            onChange={estimatedTimeMinutesUpdater}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <PositiveNumberField
            label="Temps parcours à pied (mn)"
            variant="outlined"
            value={activity.estimatedWalkTimeMinutes || ''}
            onChange={estimatedWalkTimeMinutesUpdater}
            fullWidth
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <PositiveNumberField
            label="Sur nombre de jour(s)"
            variant="outlined"
            value={activity.overDays || ''}
            onChange={overDaysUpdater}
            minValue={1}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <Typography variant="h5">
              <PlaceIcon /> Départ & Arrivée
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {activity.overDays && Number(activity.overDays) === 2 &&
            <Box mb={1} pl={2}><Typography variant="h6">Jour 1</Typography></Box>
          }
          <SpotsInput value={activity.spots} onChange={spotsUpdater} />
          {activity.overDays && Number(activity.overDays) === 2 &&
            <Box>
              <Box mt={2} mb={1} pl={2}><Typography variant="h6">Jour 2</Typography></Box>
              <SpotsInput value={activity.spotsDayTwo} onChange={spotsDayTwoUpdater} />
            </Box>
          }
          {activity.kind !== 'supervised' &&
            <Box mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    onChange={handleRequireArrivalDateUpdate}
                    checked={activity.requireArrivalDate}
                  />
                }
                label="Obligation de définir l'heure de retour"
                labelPlacement="end"
              />
            </Box>
          }
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        {activity.kind !== 'supervised' && <Grid item xs={12}>
          <Grid item xs={12}>
            <Box mb={3}>
              <Typography variant="h5">
                <UpdateIcon /> Recurrence
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <RecurrenceInput
              departureId={activity.spots?.departureId}
              value={activity.recurrence}
              onChange={recurrenceUpdater}
            />
          </Grid>
        </Grid>}
      </Grid>
      )}
      <Grid container spacing={3}>  
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <Typography variant="h5">
              <DescriptionIcon /> Modèles
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <TemplateSelect
            label="Contrat"
            id={activity.contractTemplate}
            onChange={handleContractTemplateUpdate}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TemplateSelect
              label="Contrat Docusign"
              id={activity.contractDocusignTemplate}
              onChange={handleContractDocusignTemplateUpdate}
            />
          </Grid>
        <Grid item xs={12} md={2}>
          <TemplateSelect
            label="Facture"
            id={activity.invoiceTemplate}
            onChange={handleInvoiceTemplateUpdate}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TemplateSelect
            label="Email de confirmation"
            id={activity.confirmationTemplate}
            onChange={handleConfirmationTemplateUpdate}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Pièces Jointes (https://...;https://...)"
            variant="outlined"
            value={activity.mailAttachmentPath || ''}
            onChange={handleMailAttachmentPathUpdate}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </form>
  );
};
