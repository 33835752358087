import React from 'react';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../components/TabPanel.component';
import { ReservationForm } from './reservation.form';
import { DocumentsForm } from './documents/documents.form';

export const UpdateReservationTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState('reservation');

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="menu des réservations"
      >
        <Tab value="reservation" label="Réservation" />
        <Tab value="documents" label="Documents" />
      </Tabs>
      <Divider variant="middle" />
      <TabPanel value={selectedTab} index="reservation">
        <ReservationForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="documents">
        <DocumentsForm />
      </TabPanel>
    </div>
  );
};
