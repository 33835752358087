import React from 'react';
import { Box, Paper } from '@material-ui/core';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { useAuth } from './auth.context';
import { AuthForm } from './auth.form';

export function AuthView() {
  const auth = useAuth();

  if (auth.userGroup) {
    return (
      <Paper elevation={3}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <SupervisedUserCircleIcon style={{ fontSize: '60px' }} />
          <Box ml={5}>
            <p>
              <b>Identifiant</b>: {auth.userGroup.login}
            </p>
            <p>
              <b>Rôle</b>: {auth.userGroup.role}
            </p>
          </Box>
        </Box>
      </Paper>
    );
  }

  return <AuthForm />;
}
