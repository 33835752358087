import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid, MenuItem } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { ButtonMenu } from '../components/ButtonMenu.component';
import { GridItemProps, useGridItemStyles } from '../components/GridItem';
import { httpDelete } from '../fetch';
import { useDomainPath } from '../auth/auth.context';
import { useAlerts } from '../hooks/useAlerts';

export function SpotGridItem({
  value,
  onDelete,
}: GridItemProps<CRM.ActivitySpotDocument>) {
  const classes = useGridItemStyles();
  const path = useDomainPath('/activity-spot');
  const history = useHistory();
  const { notify } = useAlerts();

  const handleDelete = useCallback(
    () =>
      httpDelete(`${path}/${value._id}`)
        .then(() => onDelete && onDelete(value))
        .catch(err => {
          if (err.status === 404) {
            notify(`Ce spot appartient à un autre domaine`, 'error');
          }
          if (err.status === 409) {
            err.json().then((body: { message: string[] }) => {
              notify(
                `Ce spot est utilisé au moins par les activités suivantes: ` +
                  body.message.slice(0, 3).join(', '),
                'error',
              );
            });
          }
        }),
    [onDelete, notify, path, value],
  );

  const handleUpdate = useCallback(() => {
    history.push(`/spots/${value._id}`);
  }, [value._id, history]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={2}>
        {value.name}
      </Grid>
      <Grid item xs={2}>
        {value.displayName}
      </Grid>
      <Grid item xs={2}>
        <TimingsDefinedIcon timings={value.departures} />
      </Grid>
      <Grid item xs={2}>
        <TimingsDefinedIcon timings={value.arrivals} />
      </Grid>
      <Grid item xs={1}>
        <ButtonMenu>
          <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
          <MenuItem onClick={handleUpdate}>Modifier</MenuItem>
        </ButtonMenu>
      </Grid>
    </Grid>
  );
}

const TimingsDefinedIcon: React.FC<{ timings?: CRM.SpotTimings }> = ({
  timings,
}) => {
  if (!timings || isEmpty(timings.every)) {
    return <CancelIcon />;
  }
  return <CheckCircleIcon className={'iconGreen'} />;
};
