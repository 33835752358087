import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FaceIcon from '@material-ui/icons/Face';
import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useReservation, useReservationActivity } from './reservation.context';
import { countBookingPeople } from '@crm/utils';

export const Instructors: React.FC = () => {
  const { update, reservation } = useReservation();
  const { activityTimeSlot } = useReservationActivity();
  const [instructor, setInstructor] = useState('');
  
  const handleInstructorAdd = useCallback(() => {
    if (isEmpty(instructor.trim())) {
      return;
    }
    if (reservation.options?.instructors?.includes(instructor)) {
      return;
    }
    update({
      options: {
        ...reservation.options,
        instructors: [...(reservation.options?.instructors || []), instructor],
        peopleAtArrivalCount: countBookingPeople(reservation.people || {}, [...(reservation.options?.instructors || []), instructor])
      },
    });
  }, [update, reservation.options, instructor, reservation.people]);

  const handleInstructorsDelete = (toDelete: string) => {
    if (!Array.isArray(reservation.options?.instructors)) {
      return;
    }
    update({
      options: {
        ...reservation.options,
        instructors: reservation.options!.instructors.filter(
          instructor => instructor !== toDelete,
        ),
      },
    });
  };

  useEffect(() => {//reset instructor if not present in reservation options
    if(
      (!reservation.options?.instructors || 
      (reservation.options?.instructors && reservation.options?.instructors.length === 0)) && 
      activityTimeSlot?.monitor) {
      console.log(activityTimeSlot)
      //setHasTimeSlotInstructorAdded(false);
      setInstructor(activityTimeSlot?.monitor);
      if (!isEmpty(instructor.trim())) {
        handleInstructorAdd();
      }
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reservation.options?.instructors, activityTimeSlot?.monitor, instructor]);

  /*useEffect(() => {
    if(activityTimeSlot?.monitor && !hasTimeSlotInstructorAdded) {
      setInstructor(activityTimeSlot?.monitor);
      if (!isEmpty(instructor.trim())) {
        handleInstructorAdd();
        setHasTimeSlotInstructorAdded(true);
      }
    }
  },[activityTimeSlot?.monitor,handleInstructorAdd, instructor, hasTimeSlotInstructorAdded]);*/

  return (
    <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
      {!reservation.activityTimeSlot && reservation.options?.instructors?.map(instructor => {
        return (
          <Chip
            icon={<FaceIcon />}
            key={instructor}
            label={instructor}
            onDelete={() => handleInstructorsDelete(instructor)}
          />
        );
      })}
      {reservation.activityTimeSlot && reservation.options?.instructors?.map(instructor => {
        return (
          <Chip
            icon={<FaceIcon />}
            key={instructor}
            label={instructor}
          />
        );
      })}
      {!reservation.activityTimeSlot && 
      (<Box ml={1}>
        <TextField
          variant={'outlined'}
          inputProps={{
            style: { width: '55px' },
          }}
          size={'small'}
          label={'Moniteur'}
          onChange={e => setInstructor(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleInstructorAdd} size={'small'}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>)
      }
    </Box>
  );
};
