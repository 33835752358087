import { Grid, TextField, capitalize } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useReservation } from './reservation.context';
import { LangSelect } from '../../components/LangSelect.component';
import { useValuePartialUpdate } from '../../hooks/useChangeEventUpdate';

export const CustomerForm: React.FC = () => {
  const { reservation, update } = useReservation();
  const { customer } = reservation;
  const inputName : React.MutableRefObject<HTMLInputElement | undefined> = React.useRef();
  const [fieldNameCursorPosition, setFieldNameCursorPosition] = useState<any>();
  const updateFieldNameCursorPosition = () => setFieldNameCursorPosition(inputName?.current?.selectionStart);
  const [fieldNameCountChars, setFieldNameCountChars] = useState<number>(reservation.customer?.name?.length || 0);
  const inputFirstName : React.MutableRefObject<HTMLInputElement | undefined> = React.useRef();
  const [fieldFirstNameCursorPosition, setFieldFirstNameCursorPosition] = useState<any>();
  const updateFieldFirstNameCursorPosition = () => setFieldFirstNameCursorPosition(inputFirstName?.current?.selectionStart);
  const [fieldFirstNameCountChars, setFieldFirstNameCountChars] = useState<number>(reservation.customer?.firstname?.length || 0);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && !customer) {
      update({
        customer: {
          firstname: '',
          name: '',
          address1: '',
          address2: '',
          email: '',
          phone: '+33',
          city: '',
          codePostal: '',
          country: 'france',
          lang: 'fr',
        },
      });
    }
  }, [update, customer]);

  const updatePayment = useValuePartialUpdate<CRM.ReservationDocument>(
    'customer',
    update,
  );

  const updateCustomer = useCallback(
    (value: Partial<CRM.Customer>) => {
      updatePayment({ ...customer, ...value } as any);
    },
    [updatePayment, customer],
  );

  return (
    <Grid container spacing={2}>
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={6}>
          <TextField
            label="Nom"
            variant="outlined"
            value={reservation.customer?.name || ''}
            inputRef={inputName}
            onSelect={updateFieldNameCursorPosition}
            onChange={e => {
              const cursorPosition : number = fieldNameCountChars > e.target.value.length ? 
                fieldNameCursorPosition-1 : fieldNameCursorPosition + 1;
              setFieldNameCountChars(e.target.value.length);
              // console.log(currentFieldCountChars, e.target.value.length, cursorPosition)
              updateCustomer({ name: capitalize(e.target.value) }); 
              setTimeout(()=>{e.target.setSelectionRange(cursorPosition, cursorPosition)},50);

            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Prénom"
            variant="outlined"
            value={reservation.customer?.firstname || ''}
            inputRef={inputFirstName}
            onSelect={updateFieldFirstNameCursorPosition}
            onChange={e => {
              const cursorPosition : number = fieldFirstNameCountChars > e.target.value.length ? 
              fieldFirstNameCursorPosition-1 : fieldFirstNameCursorPosition + 1;
              setFieldFirstNameCountChars(e.target.value.length);
              updateCustomer({ firstname: capitalize(e.target.value) });
              setTimeout(()=>{e.target.setSelectionRange(cursorPosition, cursorPosition)},50);
            }}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={6}>
          <TextField
            label="Email"
            variant="outlined"
            value={reservation.customer?.email || ''}
            onChange={e => updateCustomer({ email: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Téléphone"
            variant="outlined"
            value={reservation.customer?.phone || ''}
            onChange={e => updateCustomer({ phone: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="adresse"
            variant="outlined"
            value={reservation.customer?.address1 || ''}
            onChange={e => updateCustomer({ address1: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Complément d'adresse"
            variant="outlined"
            value={reservation.customer?.address2 || ''}
            onChange={e => updateCustomer({ address2: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={6}>
          <TextField
            label="Code Postal"
            variant="outlined"
            value={reservation.customer?.codePostal || ''}
            onChange={e => updateCustomer({ codePostal: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Ville"
            variant="outlined"
            value={reservation.customer?.city || ''}
            onChange={e => updateCustomer({ city: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={6}>
          <TextField
            label="Pays"
            variant="outlined"
            value={reservation.customer?.country || ''}
            onChange={e => updateCustomer({ country: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <LangSelect
            value={reservation.customer?.lang}
            onChange={lang => updateCustomer({ lang: lang })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
