import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  IconButton,
  ButtonGroup,
  CircularProgress,
  Divider,
  Theme,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import 'react-tiny-fab/dist/styles.css';
import { groupBy, isEmpty } from 'lodash';
import { DateRangeSelect } from '../components/DateRangeSelect.component';
import { humanizeKind } from '../activities/utils/translations';
import { ViewActions } from './components/viewActions.button';
import { Filters } from './filters/Filters.component';
import { useReservations } from './reservations.context';
import { ActivityFilter } from './filters/ActivityFilter.component';
import { ReservationGrid } from './reservationGrid.component';
//import { GoToTop } from '../components/fab/GoToTop.fab';
import { containsText, ofActivities } from './filters/predicates';
import { AlertDialog } from '../components/alerts/AlertDialog.component';
import { post } from '../fetch';
import { useDomainPath, useAuth } from '../auth/auth.context';
import { isAdmin } from '../auth/roles';
import { CompactCheckbox } from '../components/fields/CompactCheckbox.component';
import { UpdateField } from '../reservations/reservations.context';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),

    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export function ReservationsTrashView() {
  const classes = useStyles();
  const {
    updates,
    updatesClear,
    updatesRefresh,
    updatesSubmit,
    reservations,
    refreshReservations,
    filterDays,
    filterText,
    setFilterDays,
    setFilterText,
    reservationStatus
  } = useReservations();
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);

  useEffect(() => {
    refreshReservations();
  }, [refreshReservations, filterDays]);

  useEffect(() => {
    filterDays.start = undefined;
    filterDays.end = undefined;
    refreshReservations();
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtered = (reservations || [])
    .filter(containsText(filterText))
    .filter(ofActivities(selectedActivities));
  let byKindReservations = groupBy(filtered, res => res.activity.kind);
  const kinds = Object.keys(byKindReservations) as CRM.ActivityKind[];
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteSelectedDialogOpen, setDeleteSelectedDialogOpen] = React.useState(false);
  const [selectAllToDelete, setSelectAllToDelete] = React.useState(false);
  const reservationPath = useDomainPath('/reservation');
  const location = useLocation();
  const auth = useAuth();
  const deleteReservationsDialog = useCallback(() => setDeleteDialogOpen(true), [
    setDeleteDialogOpen,
  ]);
  const deleteReservationsDialogClose = useCallback(
    () => setDeleteDialogOpen(false),
    [setDeleteDialogOpen],
  );
  const deleteSelectedReservationsDialog = useCallback(() => setDeleteSelectedDialogOpen(true), [
    setDeleteSelectedDialogOpen,
  ]);
  const deleteSelectedReservationsDialogClose = useCallback(
    () => setDeleteSelectedDialogOpen(false),
    [setDeleteSelectedDialogOpen],
  );

  const deleteReservations = useCallback(
    () =>
      post(`${reservationPath}/emptytrashes`).then(() =>
        window.location.reload(),
      ),
    [ reservationPath],
  );

  const switchSelectAllToDelete = useCallback(
    (checked :boolean) => {
      Object.entries(byKindReservations).forEach(
        ([key]) => {
          byKindReservations[key].forEach((reservationDetailed) => {
            updatesRefresh({
              id:reservationDetailed._id,
              field:"status",
              update: checked ? "wait-to-deleted" : "no-changes"
            } as UpdateField );
          });
        }
      );

      console.log(byKindReservations);
      if(!checked) {
        updatesClear();
      }
      setSelectAllToDelete(checked);
  }, [byKindReservations, setSelectAllToDelete, updatesRefresh, updatesClear]);

  return (
    <div className={classes.root}>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        flexWrap={'wrap'}
      >

        <Box display={'flex'} alignItems={'flex-start'}>
          <DateRangeSelect range={filterDays} onChange={setFilterDays} />
        </Box>
        {
        isAdmin(auth.userGroup) && !(reservationStatus && 
          (
            reservationStatus[0] === 'gift-voucher' || 
            reservationStatus[0] === 'cancelled' || 
            location.pathname.includes('wait-to-paid') || 
            location.pathname.includes('refunds'))
          ) && 
          <Box display={'flex-start'} alignItems={'center'}><IconButton
            title={'vider toute la corbeille'}
            size={'medium'}
            onClick={deleteReservationsDialog}
          >
          <DeleteIcon />
        </IconButton></Box>
      }
        <Filters filterText={filterText} onChange={setFilterText} />
      </Box>
      <Divider />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <ActivityFilter
          value={selectedActivities}
          onChange={setSelectedActivities}
        />
        <ViewActions reservations={reservations} />
      </Box>
      <Divider />
      <Box>
        <AlertDialog
          accept={deleteReservations}
          cancel={deleteReservationsDialogClose}
          open={deleteDialogOpen}
          title={'Confirmer'}
          content={"Le vidage de la corbeille est irréversible, voulez-vous vraiment continuer ?"}
        />
        <AlertDialog
          accept={()=>{updatesSubmit(); deleteSelectedReservationsDialogClose()}}
          cancel={deleteSelectedReservationsDialogClose}
          open={deleteSelectedDialogOpen}
          title={'Confirmer la (les) suppression(s) sélectionnée(s)'}
          content={"Cette action est irréversible, voulez-vous vraiment continuer ?"}
        />
        {reservations === undefined && <CircularProgress size="1.2em" />}
        {reservations && kinds.length === 0 && (
          <span>Aucune réservation trouvée</span>
        )}
        {kinds.map(kind => (
          <Box mb={7} mt={2} key={kind}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant={'h6'}>
                <b>{humanizeKind(kind)}</b>
              </Typography>
              {!isEmpty(updates) && (
                <ButtonGroup
                  size={'small'}
                  aria-label="contained primary button group"
                >
                  <Button variant="contained" onClick={() => {
                      updatesClear();
                      switchSelectAllToDelete(false);
                    }}>
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={deleteSelectedReservationsDialog}
                  >
                    Supprimer
                  </Button>
                </ButtonGroup>
              )}
              {isAdmin(auth.userGroup) && <Box display={'flex'} justifyContent={'center'}>
                <FormControlLabel
                  control={
                    <CompactCheckbox
                    color="secondary"
                    onClick={event => {
                      event.stopPropagation();
                      const checked = (event.target as any).checked;
                      switchSelectAllToDelete(checked);
                    }}
                    checked={selectAllToDelete === true}
                  />
                  }
                  label="Sel. Tout"
                />
              </Box>}
            </Box>
            <ReservationGrid
              status={
                reservationStatus && 
                  (
                    reservationStatus[0] === 'gift-voucher' || 
                    reservationStatus[0] === 'cancelled' || 
                    location.pathname.includes('wait-to-paid') || 
                    location.pathname.includes('refunds')
                  ) ? reservationStatus[0]: 'done'
                }
              kind={kind}
              rows={byKindReservations[kind]}
              active={
                reservationStatus && 
                (
                  reservationStatus[0] === 'gift-voucher' || 
                  reservationStatus[0] === 'cancelled' || 
                  location.pathname.includes('wait-to-paid') || 
                  location.pathname.includes('refunds')
                ) ? 1 : 0
              }
              isAdmin={isAdmin(auth.userGroup)}
            />
          </Box>
        ))}
      </Box>
      {/*<GoToTop />*/}
    </div>
  );
}
