import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Theme } from '@material-ui/core';

interface Props {
  options: { label: string; highlighted?: boolean }[];
  onClick: (value: string) => any;
  startIcon?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlighted: {
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  }),
);

export const SplitButton: React.FC<Props> = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (option: string) => {
    setOpen(false);
    props.onClick(option);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid item xs={12}>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        fullWidth={props.fullWidth}
      >
        <Button
          startIcon={props.startIcon}
          color="secondary"
          variant="contained"
          onClick={handleToggle}
          disabled={props.disabled}
        >
          {props.children}
        </Button>
        {/*<Button*/}
        {/*  color="default"*/}
        {/*  size="small"*/}
        {/*  variant={'outlined'}*/}
        {/*  aria-controls={open ? 'split-button-menu' : undefined}*/}
        {/*  aria-expanded={open ? 'true' : undefined}*/}
        {/*  aria-label="select merge strategy"*/}
        {/*  aria-haspopup="menu"*/}
        {/*  onClick={handleToggle}*/}
        {/*>*/}
        {/*  <ArrowDropDownIcon />*/}
        {/*</Button>*/}
      </ButtonGroup>
      <Popper
        style={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {props.options.map((option, index) => (
                    <MenuItem
                      key={index}
                      className={clsx({
                        [classes.highlighted]: option.highlighted,
                      })}
                      onClick={() => handleMenuItemClick(option.label)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};
