import React from 'react';
import { GridItemProps } from '../components/GridItem';
import { CrmTableCell, CrmTableRow } from './../components/Table.component';
import { flatMap } from 'lodash';

export function PricesGridItem({ value }: GridItemProps<CRM.ActivityPrice>) {

  let byQuantityRows;

  let isCustomPrice = false;

  if(value?.forUserGroups.length > 1) // Means Correspond to selected userGroup = Other group than normal
  {
    byQuantityRows = flatMap(value?.forUserGroups.find(u => !u.userGroupIds.includes("normal"))?.byQuantity, (value, key) => ({
      quantityStartingTo: Number(key),
      ...value,
    }));
    // console.log(value?.forUserGroups.length);
    isCustomPrice = true;
  } else {
    byQuantityRows = flatMap(value?.forUserGroups.find(u => u.userGroupIds.includes("normal"))?.byQuantity, (value, key) => ({
      quantityStartingTo: Number(key),
      ...value,
    }));
    // console.log(value?.forUserGroups.length);
  }

  /* console.log(value);*/

  if(isCustomPrice) {
  return (
      <CrmTableRow>
        <CrmTableCell>
          {value.label.fr + " ( Personnalisé )"}
        </CrmTableCell>
        <CrmTableCell style={{  color: 'green', fontWeight:'bold'}}>
          {byQuantityRows.find(q => q.quantityStartingTo = 1)?.low} €
        </CrmTableCell>
        <CrmTableCell style={{  color: 'green', fontWeight:'bold'}}>
          {byQuantityRows.find(q => q.quantityStartingTo = 1)?.high} €
        </CrmTableCell>
      </CrmTableRow>
    );
  }
  return (
    <CrmTableRow>
      <CrmTableCell>
        {value.label.fr}
      </CrmTableCell>
      <CrmTableCell>
        {byQuantityRows.find(q => q.quantityStartingTo = 1)?.low} €
      </CrmTableCell>
      <CrmTableCell>
        {byQuantityRows.find(q => q.quantityStartingTo = 1)?.high} €
      </CrmTableCell>
    </CrmTableRow>
  );

}
