import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { RichGrid } from "../components/table/RichGrid.component";
import { vehiclesTableProvider } from "./vehiclesGrid.columns";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Theme as DefaultTheme, Theme } from '@material-ui/core/styles/createMuiTheme';
import { RowHandlers } from "../components/table/Table";
import { useDomainPath } from "../auth/auth.context";
import { get } from "../fetch";

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
  rootSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['400'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
  rootDisabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderRadius: '5px',
    border: '1px solid lightgrey',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'not-allowed',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  }
}));

interface VehiclesGridProps {
  onConfigure: (bus: CRM.BusDocument)=>void;
  onAssign: (busId: string)=>void;
  refresh?: number;
}

export function VehiclesGrid({
  onConfigure,
  onAssign,
  refresh,
}: VehiclesGridProps) {

  const path = useDomainPath(`/bus`);
  const classesgrid: ClassNameMap<string> = useStylesGrid();
  const [buses, setBuses] = useState<CRM.BusDocument[]>([]);
  const refreshVehicles = useCallback(
    () => get<CRM.BusDocument[]>(`${path}`).then(
      _buses => {
        if (!_buses) {
          return;
        }
      setBuses(_buses);
    }),[path]);
      
  
    useEffect(() => {
      if(refresh) {
        refreshVehicles();
      }
    }, [ refresh, refreshVehicles]);

  return (
    <div>
      <Grid item xs={12}>
        <Box flexGrow={0.85}>

          {<RichGrid
            style={{
              paddingRight: '10px', //compensate for scrollbar width
            }}
            columns={
              vehiclesTableProvider('admin', onAssign, onConfigure)
                .columns
            }
            rows={buses}
            renderRow={bus => {
              return (
                <div className='Grid-row'>
                  <Grid
                    container
                    className={
                      classesgrid.root
                    }
                    spacing={1}
                    onClick={() => {}}
                  >
                    {vehiclesTableProvider('admin', onAssign, onConfigure)
                      .columns.map(column => {
                        const Cell = column.Cell;
                        return (
                          <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                            {Cell && <Cell value={bus} handlers={{} as RowHandlers} />}
                            {column.renderCell &&
                              column.renderCell(bus, {} as RowHandlers)}
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              );
            }}
          />}
        </Box>
      </Grid>
    </div>
  );
}