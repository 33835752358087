import React, { FormEvent, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Box,
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from './auth.context';
import { makeStyles } from '@material-ui/core/styles';
import { DomainSelect } from '../components/selects/DomainSelect.components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

export function AuthForm() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [message, setMessage] = useState<string | null>();
  const [selectedDomain, setSelectedDomain] = useState<string>();

  // @ts-ignore
  const { from } = location.state || { from: { pathname: '/' } };
  const doLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMessage(null);
    auth.signin(login, /*selectedDomain,*/ password, (err: string) => {
      if (err) {
        return setMessage(err);
      } else {
        history.replace(from);
      }
    });
  };

  return (
    <form onSubmit={doLogin} className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={3} mt={3}>
            <Typography variant="h4">Identification</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={{ width: '100%' }}
            variant="outlined"
            label="Identifiant du point de vente"
            value={login}
            onChange={e => setLogin(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={{ width: '100%' }}
            variant="outlined"
            label="Mot de passe"
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </Grid>
        <Grid item xs={12} style={{display:'none'}}>
          <DomainSelect
            label="Entreprise"
            selectedId={selectedDomain}
            onChange={domain => {
              domain && setSelectedDomain(domain._id);
            }}
            autoSelectFirst
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            fullWidth
            disabled={
              isEmpty(login) || isEmpty(password) || isEmpty(selectedDomain)
            }
          >
            S'identifier
          </Button>
        </Grid>
      </Grid>
      {message && <span>{message}</span>}
    </form>
  );
}
