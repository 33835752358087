import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import { TabPanel } from "../components/TabPanel.component";
import { AssignVehicleForm } from "./assignVehicleForm.component";

interface AssignVehicleDialogProps {
  dialogOpen: boolean;
  dialogClose: any;
  busId: string;
}

export const AssignVehicleDialog: React.FC<AssignVehicleDialogProps> = ({
  dialogOpen,
  dialogClose,
  busId
}) => {
  const [selectedTab, setSelectedTab] = React.useState('departures');
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      onClose={dialogClose}
      open={dialogOpen}
      maxWidth={'md'}
      fullWidth={true}
      fullScreen={fullScreen}
    >
      <DialogTitle style={{paddingBottom:0}}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} >
            <b>Attribuer le véhicule</b>
          </Box>
          <Box>
            <IconButton onClick={()=> {dialogClose(); setSelectedTab('departures');}}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{ minHeight: '600px' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label=""
        >
          <Tab value="departures" label="Départs" />
          <Tab value="arrivals" label="Retours" />
        </Tabs>
        <Divider variant="middle" />
        <TabPanel value={selectedTab} index="departures">
          <AssignVehicleForm
            travelDirection="departure"
            onCompletedValidationForm={()=>{dialogClose(); setSelectedTab('departures');}}
            busId={busId}
          />
            </TabPanel>
            <TabPanel value={selectedTab} index="arrivals">
              <AssignVehicleForm
                travelDirection="return"
                onCompletedValidationForm={() => { dialogClose();setSelectedTab('departures');}}
                busId={busId}
              />
            </TabPanel>
          </DialogContent>
        </Dialog>
        )
}