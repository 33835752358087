import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CrmLink } from '../../components/Link.component';
import { post } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { useHistory } from 'react-router-dom';
import { ProvideUserGroup } from './userGroup.context';
import { UserGroupForm } from './userGroup.form';
import { useAlerts } from '../../hooks/useAlerts';

export function CreateUserGroupView() {
  const userGroupPath = useDomainPath('/user-group');
  const history = useHistory();
  const { notify } = useAlerts();

  const create = useCallback(
    async (userGroup: Partial<CRM.UserGroup>) => {
      try {
        const created = await post<CRM.CreateUserGroup>(
          userGroupPath,
          userGroup as CRM.CreateUserGroup,
        );
        history.push(`/user-groups/${created._id}`);
        notify('Le groupe a été créé', 'success');
      } catch (err) {
        notify('Impossible de créer le groupe', 'error');
      }
    },
    [notify, history, userGroupPath],
  );

  return (
    <ProvideUserGroup onSave={create}>
      <Box display="flex" flexDirection="column">
        <CrmLink to="/reservations">&lt; Liste des groupes</CrmLink>
        <Box mb={3} mt={3}>
          <Typography variant="h4">Ajouter un groupe</Typography>
        </Box>
        <UserGroupForm />
      </Box>
    </ProvideUserGroup>
  );
}
