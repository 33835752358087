import React, { useCallback } from 'react';
import { Divider, Grid, TextField, Typography } from '@material-ui/core';
import {
  useDomain,
  useDomainChangeEventUpdate,
  useDomainValueUpdate,
} from './domain.context';
import { DomainSaveButton } from './saveButton.component';
import { MarketHeader } from './marketHeader.form';

export const DisplayConfigForm: React.FC = () => {
  const { value, save } = useDomain();
  const displayConfigUpdater = useDomainValueUpdate('displayConfig');
  const logoUrlUpdater = useDomainChangeEventUpdate('logoUrl');

  const updateConfig = useCallback(
    (config: Partial<CRM.DomainDisplayConfig>) => {
      displayConfigUpdater({ ...value.displayConfig, ...config });
    },
    [displayConfigUpdater, value.displayConfig],
  );

  return (
    <form onSubmit={save}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <TextField
            label="Couleur principale"
            variant="outlined"
            value={value.displayConfig?.backgroundColor || ''}
            onChange={e => updateConfig({ backgroundColor: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Url du logo"
            variant="outlined"
            value={value.logoUrl || ''}
            onChange={logoUrlUpdater}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Header de la vente en ligne</Typography>
        </Grid>
        <Grid item xs={12}>
          <MarketHeader
            rowsMax={10}
            label={'Header'}
            source={value.displayConfig?.headerHtml}
            onChange={source => updateConfig({ headerHtml: source })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Footer de la vente en ligne</Typography>
        </Grid>
        <Grid item xs={12}>
          <MarketHeader
            rowsMax={10}
            label={'Footer'}
            source={value.displayConfig?.footerHtml}
            onChange={source => updateConfig({ footerHtml: source })}
          />
        </Grid>
        <Grid item xs={12}>
          <DomainSaveButton />
        </Grid>
      </Grid>
    </form>
  );
};
