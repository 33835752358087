import { isEmpty } from 'lodash';
import { useDomainPath } from '../../../auth/auth.context';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { get } from '../../../fetch';
import { humanizeKind } from '../../../activities/utils/translations';

interface ActivitySelectProps {
  value?: CRM.PublicActivity;
  onChange: (value: CRM.PublicActivity | undefined) => any;
  disabled?: boolean;
  kind?: CRM.ActivityKind;
  displayAll?: boolean;
}

export function ActivitySelect({
  onChange,
  value,
  disabled,
  kind,
  displayAll
}: ActivitySelectProps) {
  const activityPath = useDomainPath('/activity');
  const [activities, setActivities] = useState<CRM.PublicActivity[]>([]);

  const loadActivities = useCallback(() => {
    get<CRM.PublicActivity[]>(`${activityPath}/?public=true`).then(
      domainActivities => {
        if(kind) {
          domainActivities = domainActivities.filter(a => a.kind === kind);
        }
        setActivities(domainActivities);
      },
    );
  }, [activityPath, kind]);

  useEffect(loadActivities, [loadActivities]);

  if (isEmpty(activities)) {
    return <CircularProgress size="1em" />;
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="Activity-select-label">{value?.id ? "Activité" : ""}</InputLabel>
      <Select
        labelId="Activity-select-label"
        label={value?.id ? "Activité" : ""}
        value={value?.id || ''}
        disabled={disabled}
        displayEmpty
        onChange={e => {
          const newSelected = activities.find(
            activity => activity.id === e.target.value,
          );
          onChange(newSelected);
          /*if (newSelected) {
            onChange(newSelected);
          }*/
        }}
      >
        {displayAll && (
          <MenuItem value="">
            Toutes les Activités
          </MenuItem>
        )}
        {activities.map(activity => (
          <MenuItem value={activity.id} key={activity.id}>
            {activity.displayName} ({humanizeKind(activity.kind)})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
