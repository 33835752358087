import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import { Box } from '@material-ui/core';

interface Props {
  className?: string;
}

export const ButtonMenu: React.FC<Props> = props => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const children = Array.isArray(props.children)
    ? props.children
    : [props.children];

  return (
    <Box
      display={'flex'}
      justifyContent={'flex-end'}
      className={props.className}
    >
      <MoreVertIcon
        aria-controls="simple-menu"
        aria-haspopup="true"
        className="opaqueOnHover iconButton"
        onClick={handleClick}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(children as Array<React.Component<{ onClick: () => void }>>).map(
          (child, index) => {
            return React.cloneElement(child as any, {
              key: index,
              onClick: (event: React.MouseEvent<any>) => {
                child.props.onClick();
                handleClose(event);
              },
            });
          },
        )}
      </Menu>
    </Box>
  );
};
