import { get } from '../fetch';
import { useEffect, useState } from 'react';

export function useDomains() {
  const [domains, setDomains] = useState<CRM.EntityName[]>([]);

  useEffect(() => {
    get<CRM.EntityName[]>('domains').then(setDomains);
  }, []);

  return domains;
}
