import React from 'react';
import { isEmpty } from 'lodash';
import { useDomain } from './domain.context';
import { SaveButton } from '../../components/SaveButton.component';

export const DomainSaveButton: React.FC = () => {
  const { value } = useDomain();

  const cannotSaveMessages = [];
  if (isEmpty(value.name)) {
    cannotSaveMessages.push('Renseigner le nom du domaine');
  }

  return <SaveButton cannotSaveMessages={cannotSaveMessages} />;
};
