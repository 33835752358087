import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { SelectProps } from '@material-ui/core/Select/Select';

export interface DbDocumentSelectProps<T>
  extends Omit<SelectProps, 'onChange'> {
  onChange?: (value?: T) => any;
  docs?: T[];
  selectedId?: string;
  autoSelectFirst?: boolean;
  allowNone?: boolean;
  labelForEmpty?: string;
}

export const DbDocumentSelect = <
  T extends CRM.DbDocument & { displayName: string }
>({
  onChange,
  docs,
  selectedId,
  autoSelectFirst,
  allowNone,
  className,
  labelForEmpty,
  ...selectProps
}: DbDocumentSelectProps<T>) => {
  useEffect(() => {
    if (autoSelectFirst && !selectedId && docs && docs.length > 0) {
      onChange && onChange(docs[0]);
    }
  }, [autoSelectFirst, onChange, selectedId, docs]);

  if (!docs) {
    return <CircularProgress size="1em" />;
  }

  return (
    <FormControl
      variant="outlined"
      className={className}
      fullWidth={selectProps.fullWidth}
    >
      <InputLabel id="Group-select-label">{selectProps.label}</InputLabel>
      <Select
        {...selectProps}
        displayEmpty={allowNone ? true : false}
        labelId="Doc-select-label"
        value={selectedId || ''}
        onChange={e => {
          const newSelected = docs.find(doc => doc._id === e.target.value);

          if (newSelected && onChange) {
            onChange(newSelected);
          } else if (allowNone && onChange) {
            onChange();
          }
        }}
      >
        {allowNone && <MenuItem value="">{labelForEmpty || 'Tous'}</MenuItem>}
        {docs.map(doc => (
          <MenuItem value={doc._id} key={doc._id}>
            {doc.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export interface DbMultiDocumentSelectProps<T>
  extends Omit<SelectProps, 'onChange'> {
  onChange?: (value?: T[]) => any;
  docs?: T[];
  selectedIds?: string[];
  allowNone?: boolean;
}

export const DbMultiDocumentSelect = <
  T extends CRM.DbDocument & { displayName: string }
>({
  onChange,
  docs,
  selectedIds,
  allowNone,
  className,
  ...selectProps
}: DbMultiDocumentSelectProps<T>) => {
  if (!docs) {
    return <CircularProgress size="1em" />;
  }

  const selected = selectedIds || [];

  return (
    <FormControl
      variant="outlined"
      className={className}
      fullWidth={selectProps.fullWidth}
    >
      <InputLabel id="Group-select-label">{selectProps.label}</InputLabel>
      <Select
        {...selectProps}
        multiple
        labelId="Doc-select-label"
        value={selected}
        onChange={e => {
          const newSelected = docs.filter(doc =>
            (e.target.value as string[]).includes(doc._id),
          );

          if (newSelected && onChange) {
            onChange(newSelected);
          } else if (allowNone && onChange) {
            onChange();
          }
        }}
        renderValue={selected => {
          return (selected as string[])
            .map(item => docs?.find(i => i._id === item)?.displayName)
            .join(', ');
        }}
        MenuProps={MenuProps}
      >
        {allowNone && <MenuItem value="">&nbsp;</MenuItem>}
        {docs.map(doc => (
          <MenuItem value={doc._id} key={doc._id}>
            <Checkbox checked={selected.includes(doc._id)} />
            {doc.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
