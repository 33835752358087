import React, { useCallback, useState } from 'react';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { useActivity, useActivityValueUpdate } from '../activity.context';
import { SaveButton } from '../saveButton.component';
import { YearlyPeriods } from './periods.component';
import { YearSelect } from './YearSelect.component';
import dayjs from 'dayjs';
import { buildYearlyPricingPeriods } from './buildYearlyPricingPeriods';
import { Exceptions } from './exceptions.component';

export const PeriodsForm: React.FC = () => {
  const { activity, save } = useActivity();
  const updatePricingPeriods = useActivityValueUpdate('pricingPeriods');

  const allPeriodsYears = Object.keys(activity.pricingPeriods || {}).sort();
  const latestYear = allPeriodsYears[allPeriodsYears.length - 1];
  const [selectedYear, setSelectedYear] = useState<string>(
    latestYear || String(dayjs().year()),
  );

  const onPeriodsChange = useCallback(
    newPeriods => {
      const newYearPricingPeriods: CRM.YearlyPricingPeriods = {
        ...activity.pricingPeriods![selectedYear],
        periods: newPeriods,
      };
      updatePricingPeriods({
        ...activity.pricingPeriods,
        [selectedYear]: newYearPricingPeriods,
      });
    },
    [updatePricingPeriods, selectedYear, activity.pricingPeriods],
  );

  const onPeriodsExceptionsChange = useCallback(
    newPeriods => {
      const newYearPricingPeriods: CRM.YearlyPricingPeriods = {
        ...activity.pricingPeriods![selectedYear],
        exceptions: newPeriods,
      };
      updatePricingPeriods({
        ...activity.pricingPeriods,
        [selectedYear]: newYearPricingPeriods,
      });
    },
    [updatePricingPeriods, selectedYear, activity.pricingPeriods],
  );

  const addPeriodYear = useCallback(() => {
    const newYear = latestYear || dayjs().year();
    const newYearPricingPeriods = buildYearlyPricingPeriods(
      Number(newYear) + 1,
    );
    updatePricingPeriods({
      ...activity.pricingPeriods,
      ...newYearPricingPeriods,
    });
  }, [updatePricingPeriods, latestYear, activity.pricingPeriods]);

  return (
    <form onSubmit={save}>
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={3} alignItems={'center'}>
          <Grid item>
            <YearSelect
              value={selectedYear}
              onChange={setSelectedYear}
              years={allPeriodsYears}
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={addPeriodYear}
            >
              Ajouter une saison
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            <EventNoteIcon /> Périodes d'ouverture
          </Typography>
        </Grid>
        {activity.pricingPeriods && activity.pricingPeriods[selectedYear] && (
          <Grid item xs={12}>
            <YearlyPeriods
              year={Number(selectedYear)}
              pricingPeriods={activity.pricingPeriods[selectedYear]}
              onPeriodsChange={onPeriodsChange}
            />
          </Grid>
        )}
        {activity.kind !== "supervised" && <Grid item xs={12}>
          <Typography variant="h5">
            <EventBusyIcon /> Exceptions
          </Typography>
        </Grid>}
        {activity.kind !== "supervised" && activity.pricingPeriods && activity.pricingPeriods[selectedYear] && (
          <Grid item xs={12} container spacing={3} alignItems={'center'}>
            <Exceptions
              year={Number(selectedYear)}
              value={activity.pricingPeriods[selectedYear].exceptions}
              onChange={onPeriodsExceptionsChange}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </form>
  );
};
