import React from 'react';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core';

export const TextPaper: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <Box bgcolor={theme.palette.grey['300']} borderRadius={5} p={1}>
      {children}
    </Box>
  );
};
