import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { CrmLink } from '../../components/Link.component';
import { get, put } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { UserGroupTabs } from './userGroupTabs.component';
import { ProvideUserGroup } from './userGroup.context';
import { UpdateParams } from '../../route/UpdateParam';
import { useAlerts } from '../../hooks/useAlerts';

export function UpdateUserGroupView() {
  const { id } = useParams<UpdateParams>();
  const userGroupPath = useDomainPath('/user-group');
  const { notify } = useAlerts();
  const [userGroup, setUserGroup] = useState<CRM.UserGroupDocument>();

  const update = useCallback(
    async (userGroup: Partial<CRM.UserGroupDocument>) => {
      try {
        await put<Partial<CRM.CreateUserGroup>>(
          `${userGroupPath}/${userGroup._id}`,
          userGroup,
        );
        notify('Le groupe a été mis à jour', 'success');
      } catch (err) {
        notify('Impossible de mettre à jour le groupe', 'error');
      }
    },
    [notify, userGroupPath],
  );

  useEffect(() => {
    get<CRM.UserGroupDocument>(`${userGroupPath}/${id}`).then(setUserGroup);
  }, [id, userGroupPath]);

  if (!userGroup) {
    return <CircularProgress />;
  }

  return (
    <ProvideUserGroup initialUserGroup={userGroup} onSave={update}>
      <Box display="flex" flexDirection="column">
        <CrmLink to="/user-groups">&lt; Liste des groupes</CrmLink>
        <Box mb={3} mt={3}>
          <Typography variant="h4">{userGroup.displayName}</Typography>
        </Box>
        <UserGroupTabs />
      </Box>
    </ProvideUserGroup>
  );
}
