import { Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useReservation } from '../reservation.context';
import { FormTitle } from '../../../components/FormTitle.component';
import { ContractButton } from '../components/contractButton.component';
import { PromptLangButton } from '../../../components/PromptLangButton.component';
import EmailIcon from '@material-ui/icons/Email';
import { baseUrl, get, post } from '../../../fetch';
import { useAlerts } from '../../../hooks/useAlerts';
import { useReservationHash } from '../reservationHash.context';
import { useDomainPath } from '../../../auth/auth.context';
import DescriptionIcon from '@material-ui/icons/Description';

export const DocumentsForm: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid
        container
        spacing={3}
        item
        xs={12}
        sm={6}
        alignContent={'flex-start'}
      >
        <Grid item xs={12}>
          <FormTitle>Contrat de location</FormTitle>
        </Grid>
        <Grid item xs={12}>
          <ContractForm />
        </Grid>
        <Grid item xs={12}>
          <FormTitle>Facture</FormTitle>
        </Grid>
        <Grid item xs={12}>
          <InvoiceForm />
        </Grid>
      </Grid>
    </Grid>
  );
};

const ContractForm: React.FC = () => {
  const { notify } = useAlerts();
  const { reservation, reload, save } = useReservation();
  const { errors } = useReservationHash();
  const reservationPath = useDomainPath('/reservation');
  const activityPath = useDomainPath('/activity');
  const [isDocusignValid, setIsDocusignValid] = useState<boolean>(false);

  useEffect(() => {
    if(reservation.activity) {
      get<string>(activityPath+`/checkHaveValidDocusignContract/${reservation.activity}`).then(
        (isvalid: string) => {
          setIsDocusignValid(isvalid === 'true');
        }
      )
    }
    
  }, [reservation.activity, activityPath]);

  const sendConfirmationEmail = useCallback(
    async (lang: CRM.I18nLang) => {
      try {
        if (reservation._id) {
          await save();
          await post(
            `${reservationPath}/${reservation._id}/notifications?type=email&name=confirmation&lang=${lang}`,
          );
          notify(`Email envoyé`, 'success');
          setTimeout(reload, 2000);
        }
      } catch (err) {
        notify(`Impossible d'envoyer l'email`, 'error');
      }
    },
    [save, reload, notify, reservationPath, reservation._id],
  );

  const requestSignatureByEmail = useCallback(
    async () => {
      try {
        if (reservation._id) {
          await save();
          await post(
            `${reservationPath}/${reservation._id}/contract/emailSignatureRequest`,
          );
          notify(`Demande de signature par email envoyée`, 'success');
          setTimeout(reload, 2000);
        }
      } catch (err) {
        notify(`Impossible d'envoyer l'email de demande de signature`, 'error');
      }
    },
    [save, reload, notify, reservationPath, reservation._id],
  );

  return (
    <Grid container spacing={2}>
      {reservation.documents && (
        <Grid item xs={12}>
          <Typography>Contrat n°{reservation.documents.contractId}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <ContractButton>Imprimer le contrat</ContractButton>
      </Grid>
      {isDocusignValid === true && (
        <Grid item xs={12}>
          <Button
            onClick={() => { requestSignatureByEmail() }}
            startIcon={<EmailIcon />}
            disabled={errors.length > 0}
            color="secondary"
            variant="contained"
            type="button"
          >
            Envoyer la demande de signature électronique
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <PromptLangButton
          defaultLang={reservation.customer?.lang}
          onClick={sendConfirmationEmail}
          startIcon={<EmailIcon />}
          disabled={errors.length > 0}
        >
          Envoyer l'email de confirmation
        </PromptLangButton>
      </Grid>
    </Grid>
  );
};

const InvoiceForm: React.FC = () => {
  const { reservation, reload, save } = useReservation();
  const { errors } = useReservationHash();
  const reservationPath = useDomainPath('/reservation');

  const generateInvoice = useCallback(
    (lang: CRM.I18nLang) => {
      if (reservation._id) {
        save().then(() => {
          var win = window.open(
            `${baseUrl}/${reservationPath}/${reservation._id}/invoice?lang=${lang}`,
            '_blank',
          );
          if(win) {
            //win.print();
          }
          // reload to actualize fields that could be updated on the backend, like the contract id
          setTimeout(reload, 2000);
        });
      }
    },
    [reload, save, reservationPath, reservation._id],
  );

  return (
    <Grid container spacing={2}>
      {reservation.documents && (
        <Grid item xs={12}>
          <Typography>Facture n°{reservation.documents.invoiceId}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <PromptLangButton
          defaultLang={reservation.customer?.lang}
          onClick={generateInvoice}
          startIcon={<DescriptionIcon />}
          disabled={errors.length > 0}
        >
          Imprimer la facture
        </PromptLangButton>
      </Grid>
    </Grid>
  );
};
