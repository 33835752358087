import React, { useCallback, useMemo, useState } from 'react';
import { Column, Sorting } from './Table';
import { Header } from './Header.component';
import { makeStyles } from '@material-ui/core';
import { Theme as DefaultTheme, Theme } from '@material-ui/core/styles/createMuiTheme';

interface Props<T> {
  renderRow: (value: T) => React.ReactElement;
  columns: Column<T>[];
  rows: T[];
  style?: React.CSSProperties;
  gridStyle?: React.CSSProperties;
  onBottom?: () => any;
}

const useStyles = makeStyles<DefaultTheme>(
  (theme: Theme) => ({
    rowEvenOddBg: {
      '& .Grid-row:nth-of-type(odd) .MuiGrid-container': {
        backgroundColor: '#d8d8d8'
      },
      '& .Grid-row:nth-of-type(even) .MuiGrid-container': {
        backgroundColor: '#efefef'
      },   

    }
  })

);

export const RichGrid: React.FC<Props<any>> = ({
  rows,
  columns,
  style,
  gridStyle,
  renderRow,
  onBottom
}) => {
  const [sorting, setSorting] = useState<Sorting>();
  const classes = useStyles();

  const onSortChange = useCallback(
    (columnKey: string) => {
      // first column click
      if (sorting?.key !== columnKey || !sorting.order) {
        return setSorting({ key: columnKey, order: 'asc' });
      }
      // update sorting to desc or remove sorting
      return setSorting({
        key: columnKey,
        order: sorting.order === 'asc' ? 'desc' : undefined,
      });
    },
    [sorting],
  );

  const handleScroll = (e:any) => {
    const bottom = e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight;
    if (bottom) {
      onBottom && onBottom();
      // e.target.scrollTop = e.target.scrollHeight*0.95;
      e.stopPropagation();
     }
  }

  const sortedRows = useMemo(() => {
    if (!sorting || !sorting.key || !sorting.order) {
      return rows;
    }

    const sortedColumn = columns.find(c => c.key === sorting.key);
    if (!sortedColumn?.compare) {
      return rows;
    }

    const sortingRows = [...rows];
    sortingRows.sort((a, b) => {
      const compared = sortedColumn!.compare!(a, b);
      if (sorting.order === 'desc') {
        if (compared > 0) {
          return -1;
        }
        if (compared < 0) {
          return 1;
        }
      }
      return compared;
    });
    return sortingRows;
  }, [sorting, rows, columns]);

  return (
    <>
      <Header
        columns={columns}
        sorting={sorting}
        onColumnClick={onSortChange}
        style={style}
      />
      {<div className={classes.rowEvenOddBg} id={'richGrid'} style={gridStyle || {maxHeight:'57vh', marginBottom:'-7vh',overflowY:'scroll', overflowX:'hidden'}} onScroll={handleScroll}>
        {sortedRows.map(renderRow)}
      </div>}
    </>
  );
};
