import React, { ChangeEvent, useCallback } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useUserGroup } from './userGroup.context';
import { SaveButton } from './saveButton.component';
import { ROLES } from '@crm/utils';

export const UserGroupForm: React.FC = () => {
  const { userGroup, update, save } = useUserGroup();

  const handleDisplayNameChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: string }>) =>
      update({ displayName: e.target.value }),
    [update],
  );

  const handleRoleChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>{
      update({ role: e.target.value as CRM.UserGroupRole });
      if(e.target.value as CRM.UserGroupRole === 'admin' ) {
        //set by default all privileges to true if changed to admin
        update({ 
          canViewAllPrices: true,
          haveSupervisedActivitiesAccess: true,
          ignoreSupervisedChildRestrictions: true,
          canManageTransports: true
        });
      }
  },
    [update],
  );

  const handleLoginChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      update({ login: e.target.value as string }),
    [update],
  );

  const handlePasswordChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      update({ password: e.target.value as string }),
    [update],
  );

  const handleCanViewAllPricesChange = useCallback(
    (e: ChangeEvent<{ checked?: boolean; value: unknown }>) =>
      update({ canViewAllPrices: e.target.checked as boolean }),
    [update],
  );

  const handleHaveSupervisedActivitiesAccessChange = useCallback(
    (e: ChangeEvent<{ checked?: boolean; value: unknown }>) =>
      update({ haveSupervisedActivitiesAccess: e.target.checked as boolean }),
    [update],
  );

  const handleIgnoreSupervisedChildRestrictionsChange = useCallback(
    (e: ChangeEvent<{ checked?: boolean; value: unknown }>) =>
      update({ ignoreSupervisedChildRestrictions: e.target.checked as boolean }),
    [update],
  );

  const handleCanManageTransportsChange = useCallback(
    (e: ChangeEvent<{ checked?: boolean; value: unknown }>) =>
      update({ canManageTransports: e.target.checked as boolean }),
    [update],
  );

  return (
    <form onSubmit={save}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl variant="outlined">
            <InputLabel id="Role-select-label">Rôle</InputLabel>
            <Select
              className="inputSize-sm"
              labelId="Role-select-label"
              label="Rôle"
              value={userGroup.role}
              onChange={handleRoleChange}
            >
              <MenuItem value={ROLES.ADMIN}>Admin</MenuItem>
              <MenuItem value={ROLES.SELLER}>Vendeur</MenuItem>
              <MenuItem value={ROLES.PARTNER}>Partenaire</MenuItem>
              <MenuItem value={ROLES.DRIVER}>Chauffeur</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            variant="outlined"
            label="Nom du point de vente"
            value={userGroup.displayName}
            onChange={handleDisplayNameChange}
            required
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            variant="outlined"
            label="Identifiant du point de vente"
            value={userGroup.login}
            onChange={handleLoginChange}
            required
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            variant="outlined"
            label="Mot de passe"
            value={userGroup.password}
            onChange={handlePasswordChange}
            type="password"
            required
          />
        </Grid>
        { userGroup.role && userGroup.role !== 'driver' &&
          <Grid item md={6}>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userGroup.canViewAllPrices !== undefined ? userGroup.canViewAllPrices : false}
                  inputProps={{ 'aria-label': 'Peut voir tous les Tarifs' }}
                  onChange={handleCanViewAllPricesChange}
                />} label="Peut voir tous les tarifs"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userGroup.haveSupervisedActivitiesAccess !== undefined ? userGroup.haveSupervisedActivitiesAccess : false}
                  inputProps={{ 'aria-label': 'A accès aux activités encadrées' }}
                  onChange={handleHaveSupervisedActivitiesAccessChange}
                />} label="A accès aux activités encadrées"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userGroup.ignoreSupervisedChildRestrictions !== undefined ? userGroup.ignoreSupervisedChildRestrictions : false}
                  inputProps={{ 'aria-label': 'Peut ignorer les restrictions des activités encadrées' }}
                  onChange={handleIgnoreSupervisedChildRestrictionsChange}
                />} label="Peut ignorer les restrictions des activités encadrées"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userGroup.canManageTransports !== undefined ? userGroup.canManageTransports : false}
                  inputProps={{ 'aria-label': 'Autorisé à gérer la flotte de véhicules' }}
                  onChange={handleCanManageTransportsChange}
                />} label="Autorisé à gérer la flotte de véhicules"
            />
        </Grid>
        </Grid>}
        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </form>
  );
};
