import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { get, put } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { useAlerts } from '../../hooks/useAlerts';

export const DocumentsConfigForm: React.FC = () => {
  const { notify } = useAlerts();
  const path = useDomainPath(`/documents`);

  const [
    documentsConfig,
    setDocumentsConfig,
  ] = useState<CRM.DocumentsConfigDocument>();

  const load = useCallback(() => {
    get<CRM.DocumentsConfigDocument>(path).then(setDocumentsConfig);
  }, [path]);

  useEffect(load, [load]);

  const updatePrefix = useCallback(async () => {
    try {
      await put<Partial<CRM.DocumentsConfig>>(path, {
        prefix: documentsConfig?.prefix,
      });
      notify('Le préfix a été mis à jour', 'success');
    } catch (err) {
      notify('Impossible de mettre à jour le préfix', 'error');
    }
  }, [notify, path, documentsConfig?.prefix]);

  if (!documentsConfig) {
    return null;
  }

  return (
    <>
      <Grid item xs={12} md={3}>
        <TextField
          label="Dernier numéro de facture"
          variant="outlined"
          value={documentsConfig.lastInvoiceNumber}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Dernier numéro de contrat"
          variant="outlined"
          value={documentsConfig.lastContractNumber}
          disabled
        />
      </Grid>
      <Grid container item xs={12} md={4} alignContent={'center'}>
        <TextField
          label="Prefix"
          variant="outlined"
          value={documentsConfig.prefix}
          onChange={event =>
            setDocumentsConfig({
              ...documentsConfig,
              prefix: event.target.value,
            })
          }
        />{' '}
        <Button color="primary" variant="contained" onClick={updatePrefix}>
          OK
        </Button>
      </Grid>
    </>
  );
};
