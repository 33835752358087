import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';

interface Props {
  onClick?(): any;
  cannotSaveMessages: string[];
}

export const SaveButton: React.FC<Props> = ({
  cannotSaveMessages,
  onClick
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Button
        color="secondary"
        variant="contained"
        disabled={cannotSaveMessages.length > 0}
        type={onClick ? 'button' : 'submit'}
        onClick={onClick}
      >
        Enregistrer
      </Button>
      {cannotSaveMessages.length > 0 && (
        <Box display="block">
          <ul>
            {cannotSaveMessages.map((message, index) => (
              <li key={index}>
                <Typography color={'error'}>{message}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};
