import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  title: string;
  content: string;
  open: boolean;
  accept: () => any;
  cancel?: () => any;
}

export const AlertDialog: React.FC<Props> = ({ accept, cancel, ...props }) => {
  return (
    <Dialog
      open={props.open}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"><span style={{whiteSpace:"pre-line"}}>{props.title}</span></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <span style={{whiteSpace:"pre-line"}}>{props.content}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={cancel}
          color="secondary"
          variant={'contained'}
          autoFocus
        >
          Annuler
        </Button>
        <Button onClick={accept} color="primary" variant={'contained'}>
          Accepter
        </Button>
      </DialogActions>
    </Dialog>
  );
};
