import React, {
  Context,
  createContext,
  Props,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { configureAccessToken, get, postSignIn } from '../fetch';
import { USER_GROUPS } from '@crm/utils';

export interface AuthUserGroup {
  access_token: string;
  userGroupId: string;
  login: string;
  domainId: string;
  role: CRM.UserGroupRole;
  canViewAllPrices?: boolean;
  haveSupervisedActivitiesAccess?: boolean;
  ignoreSupervisedChildRestrictions?: boolean;
  canManageTransports?: boolean;
}

interface AuthUsersContext {
  groups: CRM.UserGroupDocument[];
  groupUsers: CRM.UserDocument[];
  loggedUser?: CRM.UserDocument;
  domain?: CRM.DomainConfig;
  setLoggedUser: (user: CRM.UserDocument) => void;
  getUser: (userId?: string) => CRM.UserDocument | undefined;
  getUsersOfGroup: (userGroupId?: string) => CRM.UserDocument[];
}
export interface AuthContext {
  userGroup: AuthUserGroup | undefined;
  loaded: boolean;
  signin: Function;
  signout: Function;
}

const authUsersContext: Context<AuthUsersContext> = createContext(null) as any;
const authContext: Context<AuthContext> = createContext(null) as any;

export function useAuthUsers() {
  return useContext(authUsersContext);
}
export function useAuth() {
  return useContext(authContext);
}

export function useDomainPath(suffix: string) {
  const { userGroup } = useAuth();
  return `domains/${userGroup?.domainId}${suffix}`;
}

function useProvideAuthUsers(userGroup?: AuthUserGroup): AuthUsersContext {
  const [loggedUser, setLoggedUser] = useState<CRM.UserDocument>();
  const [domain, setDomain] = useState<CRM.DomainConfig>();
  const [groupUsers, setGroupUsers] = useState<CRM.UserDocument[]>([]);
  const [domainUsers, setDomainUsers] = useState<CRM.UserDocument[]>([]);
  const [groups, setGroups] = useState<CRM.UserGroupDocument[]>([]);

  useEffect(() => {
    if (userGroup?.userGroupId) {
      get<CRM.DomainConfig>(`market/config-by-id/${userGroup?.domainId}`).then(
        setDomain,
      );
      get<CRM.UserDocument[]>(`domains/${userGroup?.domainId}/user`).then(
        value => {
          setDomainUsers(value);

          const groupUser = value.filter(
            user => user.userGroup === userGroup.userGroupId,
          );
          setGroupUsers(groupUser);
          if (!loggedUser && groupUser.length > 0) {
            setLoggedUser(groupUser[0]);
          }
        },
      );
      get<CRM.UserGroupDocument[]>(
        `domains/${userGroup?.domainId}/user-group`,
      ).then(value => {
        setGroups(
          value.concat({
            _id: USER_GROUPS.INTERNET,
            domain: '',
            displayName: 'Internet',
            login: 'internet',
            role: '',
          } as any),
        );
      });
    }
  }, [userGroup, loggedUser]);

  const getUser = useCallback(
    (userId?: string) => {
      if (!userId) return;
      return domainUsers.find(user => user._id === userId);
    },
    [domainUsers],
  );

  const getUsersOfGroup = useCallback(
    (userGroupId?: string): CRM.UserDocument[] => {
      if (!userGroupId) return [];
      return domainUsers.filter(user => user.userGroup === userGroupId);
    },
    [domainUsers],
  );

  return {
    groups,
    domain,
    groupUsers,
    loggedUser,
    setLoggedUser,
    getUser,
    getUsersOfGroup,
  };
}

function useProvideAuth(): AuthContext {
  const [userGroup, setUserGroup] = useState<AuthUserGroup | undefined>();
  const [loaded, setLoaded] = useState<boolean>(false);

  function setAuth(userGroup: AuthUserGroup) {
    localStorage.setItem('userGroup', JSON.stringify(userGroup));
    configureAccessToken(userGroup.access_token);
    setUserGroup(userGroup);
  }

  function removeAccessToken() {
    localStorage.removeItem('userGroup');
  }

  useEffect(() => {
    try {
      const stored = localStorage.getItem('userGroup');
      if (stored) {
        setAuth(JSON.parse(stored));
      }
    } catch (error) {
      setUserGroup(undefined);
    }
    setLoaded(true);
  }, []);

  const signin = (
    login: string,
    /*domain: string,*/
    password: string,
    cb: Function,
  ) => {
    postSignIn(login,/* domain,*/ password)
      .then(body => {
        setAuth(body);
        cb();
      })
      .catch(() => cb(`Impossible de s'identifier..`));
  };

  const signout = (cb: Function) => {
    removeAccessToken();
    setUserGroup(undefined);
    cb();
    window.location.reload();
  };

  return {
    userGroup: userGroup,
    loaded,
    signin,
    signout,
  };
}

export function ProvideAuth(props: Props<any>) {
  const auth = useProvideAuth();
  const authUsers = useProvideAuthUsers(auth.userGroup);
  return (
    <authContext.Provider value={auth}>
      <authUsersContext.Provider value={authUsers}>
        {props.children}
      </authUsersContext.Provider>
    </authContext.Provider>
  );
}
