import { TextField } from '@material-ui/core';
import React, { useCallback } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

export const PositiveDecimalField: React.FC<
  TextFieldProps & { minValue?: number }
> = ({ onClick, onChange, minValue, ...props }) => {

  const onChangePositive = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let start = e.target.selectionStart;
      // console.log(start);
      let val = e.target.value;
      val = val.replace(/([^0-9.]+)/, "");
      val = val.replace(/^(0|\.)/, "");
      const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
      // console.log(match);
      const m1:string = (match && match[1]) || '';
      const m2:string = (match && match[2]) || '';
      const value = m1+m2;
      // console.log(value);
      e.target.value = value?.toString() || '';

      if (onChange && Number(value) >= (minValue || 0)) {
        e.target.value = Number(value).toFixed(2);
        e.target.setSelectionRange(start || 0, start || 0);
        return onChange && onChange(e);
      }
    },
    [onChange, minValue],
  );

  const onClickAutoSelect = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      const target = event.target as any;
      target.select && target.select();
      onClick && onClick(event);
    },
    [onClick],
  );

  return (
    <TextField
      type="text"
      onChange={onChangePositive}
      onClick={onClickAutoSelect}
      style={{ fontSize: "22px", padding: "4px"}}
      InputLabelProps={{style: {fontWeight: 'bold'}}}
      {...props}
    />
  );
};
