import { Box, TextField, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { countBookingPeople } from '@crm/utils';

interface Props {
  reservations: CRM.ReservationOverview[];
  selectedReservationIds: string[];
  onNameChange: (value: string) => void;
  name: string;
}

export const SelectionDetails: React.FC<Props> = ({
  selectedReservationIds,
  reservations,
  ...props
}) => {
  const peopleCount = useMemo(() => {
    return reservations.reduce((count, reservation) => {
      if (selectedReservationIds.includes(reservation._id)) {
        return count + countBookingPeople(reservation.people);
      }
      return count;
    }, 0);
  }, [selectedReservationIds, reservations]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box display={'flex'}>
        <Typography>
          Nombre de places pour la sélection: {peopleCount}
        </Typography>
      </Box>
      &nbsp;
      <Box display={'flex'}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Nommer la sélection en cours"
          value={props.name}
          onChange={e => props.onNameChange(e.target.value)}
        />
      </Box>
    </Box>
  );
};
