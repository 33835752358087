import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { keyBy, sortBy } from 'lodash';
import { useAuthUsers } from '../../auth/auth.context';
import { useActivity } from './activity.context';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function AllowedUserGroups() {
  const { groups } = useAuthUsers();
  const { activity, update } = useActivity();

  const allowedUserGroupsUpdater = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      update({
        ...activity,
        allowedUserGroups: event.target.value as string[],
      });
    },
    [activity, update],
  );

  const sortedGroups = sortBy(groups, group => group.displayName);
  const groupById = keyBy(groups, group => group._id);

  return (
    <FormControl variant={'outlined'} fullWidth>
      <InputLabel id="allowed-groups-label">En vente pour</InputLabel>
      <Select
        labelId="allowed-groups-label"
        label={'En vente pour'}
        multiple
        value={activity.allowedUserGroups}
        onChange={allowedUserGroupsUpdater}
        renderValue={selected => {
          return (selected as string[])
            .filter(selectedGroup => groupById[selectedGroup])
            .map(selectedGroup => groupById[selectedGroup].displayName)
            .join(', ');
        }}
        MenuProps={MenuProps}
      >
        {sortedGroups.map(group => (
          <MenuItem key={group._id} value={group._id}>
            <Checkbox
              checked={activity.allowedUserGroups?.includes(group._id)}
            />
            <ListItemText primary={group.displayName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
