import React, { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { EditableCell } from './EditableCell.component';
import { DeparturesChoiceComponent } from '../create/components/departuresChoice.component';
import { useDomainPath } from '../../auth/auth.context';
import { get } from '../../fetch';

interface Props {
  activityId: string;
  activityOverDays: number;
  departureDate: Dayjs;
  arrivalDate?: Dayjs;
  onChange: (date: Dayjs) => void;
  className?: string;
}

export const EditableArrivalMenu: React.FC<Props> = ({
  activityId,
  activityOverDays,
  arrivalDate,
  departureDate,
  onChange,
}) => {
  const dayOfArrival =
    arrivalDate || departureDate.add(activityOverDays - 1, 'day');
  const source: CRM.requestSource = 'backoffice';
  const arrivalsPath = useDomainPath('/arrivals');
  const [dayArrivals, setDayArrivals] = useState<
    CRM.ActivityOccurrence[] | undefined
  >();

  const fetchDayDepartures = useCallback(
    (date: Dayjs) => {
      const dateFormated = date.format('YYYYMMDD');
      get<CRM.ActivityOccurrence[]>(
        `${arrivalsPath}/activities/${activityId}?day=${dateFormated}&source=${source}`,
      ).then(res => {
        setDayArrivals(res);
      });
    },
    [activityId, arrivalsPath],
  );

  useEffect(() => fetchDayDepartures(dayOfArrival), [
    fetchDayDepartures,
    dayOfArrival,
  ]);

  return (
    <DeparturesChoiceComponent
      dayDepartures={dayArrivals}
      value={arrivalDate}
      selectedDay={dayOfArrival}
      onChange={onChange}
    />
  );
};

export const EditableArrivalCell: React.FC<Props> = props => {
  const [isHourSelected, setIshourSelected] = useState<boolean>(false);
  return (
    <EditableCell
      content={
        <span>
          {props.arrivalDate
            ? dayjs(props.arrivalDate).format('HH:mm')
            : 'définir'}
        </span>
      }
      className={props.className}
      closeCell={isHourSelected}
      onClosed={()=>{setIshourSelected(false)}}
    >
      <EditableArrivalMenu {...props}
        onChange={(date)=>{props.onChange(date); setIshourSelected(true);}}
        />
    </EditableCell>
  );
};
