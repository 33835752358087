import React, {
  Context,
  createContext,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { Action } from '../../hooks/action';

type UserGroupAction = Action<Partial<CRM.UserGroup>>;

interface UserGroupContext {
  userGroup: Partial<CRM.UserGroupDocument>;
  update: (payload: Partial<CRM.UserGroup>) => void;
  save: () => void;
}

const userGroupContext: Context<UserGroupContext> = createContext(null) as any;

export function useUserGroup(): UserGroupContext {
  const context = useContext(userGroupContext);
  return context;
}

const reducer: Reducer<Partial<CRM.UserGroup>, UserGroupAction> = (
  state,
  action,
) => {
  console.log('ACTION', action.type, action.payload);
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};

interface Props {
  initialUserGroup?: CRM.UserGroup;
  onSave: (userGroup: CRM.UserGroup) => void;
}

export const ProvideUserGroup: React.FC<Props> = ({
  onSave,
  initialUserGroup,
  children,
}) => {
  const [userGroup, dispatch] = useReducer(reducer, initialUserGroup || {});

  const update = useCallback((payload: Partial<CRM.UserGroup>) => {
    dispatch({ type: 'update', payload });
  }, []);

  const save = useCallback(() => {
    onSave(userGroup as CRM.UserGroup);
  }, [onSave, userGroup]);

  return (
    <userGroupContext.Provider value={{ userGroup, update, save }}>
      {children}
    </userGroupContext.Provider>
  );
};
