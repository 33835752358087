import React from 'react';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../components/TabPanel.component';
import { DomainForm } from './domain.form';
import { DisplayConfigForm } from './displayConfig.form';
import { PaymentForm } from './payment.form';
import {TemplatesForm} from "./templates.form";

export const DomainTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState('domain');

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="menu du domaine"
      >
        <Tab value="domain" label="Domaine" />
        <Tab value="displayConfig" label="Affichage" />
        <Tab value="payment" label="Paiement" />
        <Tab value="templates" label="Modèles" />
      </Tabs>
      <Divider variant="middle" />
      <TabPanel value={selectedTab} index="domain">
        <DomainForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="displayConfig">
        <DisplayConfigForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="payment">
        <PaymentForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="templates">
        <TemplatesForm />
      </TabPanel>
    </div>
  );
};
