import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CrmLink } from '../../components/Link.component';
import { post } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { useHistory } from 'react-router-dom';
import { useAlerts } from '../../hooks/useAlerts';
import { CreateSpotTabs } from './createSpotTabs.component';
import { ProvideSpot } from './spot.context';

export function CreateSpotView() {
  const path = useDomainPath('/activity-spot');
  const history = useHistory();
  const { notify } = useAlerts();

  const create = useCallback(
    async (spot: Partial<CRM.CreateActivitySpot>) => {
      try {
        const created = await post<Partial<CRM.CreateActivitySpot>>(path, spot);
        history.push(`/spots/${created._id}`);
        notify(`Le spot a été créée`, 'success');
      } catch (err) {
        notify(`Impossible de créer le spot`, 'error');
      }
    },
    [notify, history, path],
  );

  return (
    <ProvideSpot onSave={create}>
      <Box display="flex" flexDirection="column">
        <CrmLink to="/spots">&lt; Liste des spots</CrmLink>
        <Box mb={3} mt={3}>
          <Typography variant="h4">Ajouter un spot</Typography>
        </Box>
        <CreateSpotTabs />
      </Box>
    </ProvideSpot>
  );
}
