import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

interface Props {
  onChange?: (value: string) => any;
  users: CRM.UserDocument[];
  value?: string;
  disabled?: boolean;
  label?: string;
  allowUnselect?: boolean;
}
export const UserSelect: React.FC<Props> = ({
  onChange,
  users,
  value,
  disabled,
  label,
  allowUnselect
}) => {
  return (
    <FormControl variant="outlined" className="inputSize-lg">
      <InputLabel id="Utilisateur-select-label">{label ? label : 'Utilisateur'}</InputLabel>
      <Select
        labelId="Utilisateur-select-label"
        value={value || ''}
        label={label ? label : 'Utilisateur'}
        disabled={disabled}
        onChange={e => {
          const newSelected = users.find(user => user._id === e.target.value);

          if (/*(newSelected) && */onChange) {
            onChange(newSelected?._id || '');
          }
        }}
      >
        {allowUnselect && allowUnselect === true &&
          <MenuItem value={''} key={''}>
            Aucun
          </MenuItem>
        }
        {users.map(user => (
          <MenuItem value={user._id} key={user._id}>
            {user.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
