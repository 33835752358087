import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback } from 'react';
import { Box, Button, Divider, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateRange } from './DateRangeSelect.component';

interface NextDaysStripSelectProps {
  value: DateRange;
  onChange: (value: Dayjs, valueEnd?: Dayjs) => any;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2, 1, 0, 1),
    },
  },
}));

const OneDay = ({
  label,
  selected,
  onClick,
}: {
  label: string;
  selected: boolean;
  onClick?: any;
}) => {
  return (
    <Button onClick={onClick}>
      <Typography
        variant="h5"
        gutterBottom
        color={selected ? 'textPrimary' : 'textSecondary'}
        style={{ opacity: selected ? 1 : 0.7 }}
      >
        {label}
      </Typography>
    </Button>
  );
};
export const NextDaysStripSelect = ({
  value,
  onChange,
}: NextDaysStripSelectProps) => {
  const classes = useStyles();

  const onlyOneDayRange = value.end && value.start?.isSame(value.end, 'day');
  const today = dayjs();
  const tomorrow = today.add(1, 'day');
  const afterTomorrow = today.add(2, 'day');

  const selectToday = useCallback(() => onChange(today), [onChange, today]);
  const selectTomorrow = useCallback(() => onChange(tomorrow), [
    onChange,
    tomorrow,
  ]);
  const selectAfterTommorow = useCallback(() => onChange(afterTomorrow), [
    onChange,
    afterTomorrow,
  ]);

  const selectAllFutur = useCallback(() => onChange(today, dayjs().endOf('year')), [
    onChange,
    today
  ]);

  return (
    <Box display="flex" className={classes.root}>
      <OneDay
        label={"Tous"}
        selected={!Boolean(onlyOneDayRange) && today.isSame(value.start!, 'day') && dayjs().endOf('year').isSame(value.end!, 'day')}
        onClick={selectAllFutur}
      />
      <Divider orientation="vertical" flexItem />
      <OneDay
        label={"Aujourd'hui"}
        selected={Boolean(onlyOneDayRange) && today.isSame(value.start!, 'day')}
        onClick={selectToday}
      />
      <Divider orientation="vertical" flexItem />
      <OneDay
        label={'Demain'}
        selected={
          Boolean(onlyOneDayRange) && tomorrow.isSame(value.start!, 'day')
        }
        onClick={selectTomorrow}
      />
      <Divider orientation="vertical" flexItem />
      <OneDay
        label={'Apr. demain'}
        selected={
          Boolean(onlyOneDayRange) && afterTomorrow.isSame(value.start!, 'day')
        }
        onClick={selectAfterTommorow}
      />
      <Divider orientation="vertical" flexItem />
    </Box>
  );
};
