import { Fab } from 'react-tiny-fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React from 'react';

const goToTop = () => window.scrollTo(0, 0);

export const GoToTop: React.FC = () => (
  <Fab
    mainButtonStyles={{ backgroundColor: 'black' }}
    icon={<ArrowUpwardIcon />}
    alwaysShowTitle={true}
    onClick={goToTop}
  ></Fab>
);
