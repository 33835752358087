import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { ArrivalsView } from './arrivals.view';
import { DriverArrivalsView } from './driverArrivals.view';

export const ArrivalsRouter = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          {path === "/arrivals" && <ArrivalsView />}
          {path === "/driver-arrivals" && <DriverArrivalsView />}
        </Route>
      </Switch>
    </div>
  );
};
