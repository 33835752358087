import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useAuth } from '../../../auth/auth.context';

interface ActivityTimeSlotProps {
    timeSlotId: string;
    value: CRM.ActivityTimeSlotDetailed;
    dates: Date[];
    onChange: (timeSlotId: string) => any;
    onDeleteButtonClick: () => void;
    onAbortButtonClick: () => void;
    displayCreate?: boolean;
}

export function SaveTimeSlot({ onChange, timeSlotId, value, dates, onDeleteButtonClick, onAbortButtonClick, displayCreate }: ActivityTimeSlotProps) {

 
  const [cannotSaveMessages, setCannotSaveMessages] = useState<string[]>([]);
  const auth = useAuth();
  //let cannotSaveMessages:string[] = [];

  useEffect(() => {
    const _cannotSaveMessages = [];
    if (!dates || (dates && dates.length === 0)) {
      _cannotSaveMessages.push('Sélectionner au moins une date');
    }
    if (value.maxPeople<1) {
      _cannotSaveMessages.push('Renseigner le nombre de personnes max');
    }
    if (value.maxPeopleWithMonitor<1) {
      _cannotSaveMessages.push('Renseigner le nombre de personnes max avec moniteur');
    }
    if (isEmpty(value.monitor)) {
      _cannotSaveMessages.push('Renseigner le nom du moniteur');
    }
    setCannotSaveMessages(_cannotSaveMessages);
    //cannotSaveMessages = _cannotSaveMessages;

  }, [value, dates, setCannotSaveMessages]);

  const updateOnChange = useCallback((id: string) => {
    onChange(id);
  },[onChange]);

  return (
    <Box display="flex" flexDirection="column" mt={3} ml={3} mb={2} style={{width:'100%'}}>
      <Grid item container spacing={3} xs={12} wrap='nowrap'>

        <Grid item>
          {displayCreate && displayCreate === true && <Button
            color="secondary"
            variant="contained"
            disabled={cannotSaveMessages.length > 0}
            type="submit"
            onMouseDown={e => { updateOnChange('') }}
          >
            Créer de nouveaux créneaux avec ces paramètres
          </Button>}
        </Grid>
        {
          value._id.valueOf() !== '' && cannotSaveMessages.length === 0 &&
          (<Grid item>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            onMouseDown={e => { updateOnChange(timeSlotId) }}
          >
            Enregistrer 
          </Button>
        </Grid>)
        }
        {
          value._id.valueOf() !== '' &&
          (<Grid item>
            <Button
              color="secondary"
              variant="contained"
              type="button"
              onClick={onAbortButtonClick}
            >
              Annuler
            </Button>
          </Grid>)
        }
        {
          timeSlotId !== '' &&
          ((value.bookedPeople + value.bookedPeopleWithMonitor === 0 && (value.domain.valueOf() === auth.userGroup?.domainId.valueOf()))) &&
          (<Grid item>
            <Button
              color="secondary"
              variant="contained"
              type="button"
              onClick={onDeleteButtonClick}
            >
              Supprimer
            </Button>
          </Grid>)
        }

      </Grid>
      {cannotSaveMessages.length > 0 && (
        <Box display="block">
          <ul>
            {cannotSaveMessages.map((message, index) => (
              <li key={index}>
                <Typography color={'error'}>{message}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};
