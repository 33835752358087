import { Grid } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import {
  HtmlEditor,
  HtmlEditorProps,
} from '../../components/HtmlEditor.component';
import { LangSelect } from '../../components/LangSelect.component';
import { PreviewHtmlButton } from '../../components/PreviewHtmlButton.component';

type Props = Omit<HtmlEditorProps, 'value' | 'onChange'> & {
  onChange: (source: CRM.I18nString) => any;
  source?: CRM.I18nString;
};

export const MarketHeader: React.FC<Props> = ({
  source,
  onChange,
  ...editorProps
}) => {
  const [lang, setLang] = useState<CRM.I18nLang>('fr');

  const setSource = useCallback(
    (value: string) => {
      onChange({ ...source, [lang]: value });
    },
    [onChange, source, lang],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LangSelect value={lang} onChange={setLang} />{' '}
        <PreviewHtmlButton source={source && source[lang]} />
      </Grid>
      <Grid item xs={12}>
        <HtmlEditor
          {...editorProps}
          value={(source && source[lang]) || ''}
          onChange={setSource}
        />
      </Grid>
    </Grid>
  );
};
