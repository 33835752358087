import { Box, Grid, TextField, Select, MenuItem, Typography } from '@material-ui/core';
import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import {
  useReservation,
  useReservationActivity,
  useReservationChangeEventUpdate,
} from './reservation.context';
import { CustomerForm } from './customer.form';
import { EquipementsForm } from './equipments.form';
import { PeopleForm } from './people.form';
import { ReservationActivityForm } from './reservationActivity.form';
import { Instructors } from './instructors.components';
import { FormTitle } from '../../components/FormTitle.component';
import { PaymentForm } from './payment.form';
import { TextPaper } from '../../components/TextPaper.component';
import { PositiveNumberField } from '../../components/fields/PositiveNumberField.component';
import { get } from '../../fetch';
import { useDomainPath, useAuth } from '../../auth/auth.context';
import { countBookingPeople } from '@crm/utils';
import { useLocation } from "react-router-dom";

export const ReservationForm: React.FC = () => {
  const queryParameters = new URLSearchParams(useLocation().search);
  const { activity, activityTimeSlot, activityTimeSlotCreate, updatePricingGroupId, updateActivityTimeSlot } = useReservationActivity();
  const {
    reservation,
    groupsPrice,
    groupsPriceError,
    dispatch,
    update,
  } = useReservation();
  const auth = useAuth();

  const [userGroups, setUserGroups] = useState<CRM.UserGroupDocument[]>([]);
  const [bookedPeopleOnTimeSlot, setBookedPeopleOnTimeSlot] = useState<number>(0);
  const [bookedUnderSevenOnTimeSlot, setBookedUnderSevenOnTimeSlot] = useState<number>(0);
  // const activitiesPath = useDomainPath('/activity');

  const handlePeopleUpdate = (people: CRM.BookingPeople) => {
    update(
      {
        people,
        options: { ...reservation.options, peopleAtArrivalCount: countBookingPeople(
          people, 
          (activity?.kind !== 'supervised' ? reservation.options?.instructors : undefined) || undefined
          )
        }
      }
    );
  };
  const handlePricingGroupIdUpdate = useCallback(
    (pricingGroupId: string, activityId?: string) => {
      updatePricingGroupId(pricingGroupId, activityId);
      /*get<CRM.PublicActivity[]>(activitiesPath + '/group/' + pricingGroupId+'?public=true').then((_activities) => {
        const activity = _activities.find(a => a.id === reservation.activity);
        const periodPricesForGroup = activity?.pricing;
        console.log('UPDATE pricing : ', periodPricesForGroup);
        update({ pricingGroupId, pricing: periodPricesForGroup });
      });*/
  },[updatePricingGroupId/*reservation.activity, update, activitiesPath*/]);

  const handlePeopleAtArrivalCountUpdate = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    console.log('e.target.value', e.target.value);
    dispatch({
      type: 'update-options',
      payload: {
        peopleAtArrivalCount: e.target.value ? Number(e.target.value) : null,
      },
    });
  };
  const userGroupPath = useDomainPath('/user-group');
  useEffect(() => {
    get<CRM.UserGroupDocument[]>(userGroupPath).then(setUserGroups);
      //#TODO* if timeslotid in querystring, set activity and params
      if(
        queryParameters.has('timeslotid') && 
        queryParameters.has('activityid') && 
        auth.userGroup?.userGroupId) {
        updateActivityTimeSlot(queryParameters.get('timeslotid') || '');
        handlePricingGroupIdUpdate(auth.userGroup?.userGroupId, queryParameters.get('activityid') || "")
        //set monitor from timeslot => totest
        //set hours from timeslot => totest
        //set limits of people from timeslot
        //displaying specifics availability texts and force to pay 100%
      }
      // #TODO fix eventually these dependancies for not causes infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userGroupPath,
  ]);

  useEffect(() => {
    if(activityTimeSlotCreate) {
      setBookedPeopleOnTimeSlot(
        activityTimeSlotCreate.maxPeople - 
        activityTimeSlotCreate.bookedPeople
      );
      setBookedUnderSevenOnTimeSlot(
        activityTimeSlotCreate.maxPeopleWithMonitor - 
        activityTimeSlotCreate.bookedPeopleWithMonitor
      );
    } else if(activityTimeSlot) {
      setBookedPeopleOnTimeSlot(
        activityTimeSlot.maxPeople - 
        activityTimeSlot.bookedPeople
      );
      setBookedUnderSevenOnTimeSlot(
        activityTimeSlot.maxPeopleWithMonitor - 
        activityTimeSlot.bookedPeopleWithMonitor
      );
    }
  },[activityTimeSlotCreate, activityTimeSlot]);

  return (
    <Grid container spacing={3}>
      <Grid
        container
        spacing={3}
        item
        xs={12}
        sm={4}
        alignContent={'flex-start'}
      >
        <Grid item xs={12}>
          <FormTitle>Fiche identité</FormTitle>
        </Grid>
        <Grid item xs={12}>
          <CustomerForm />
        </Grid>
        <Grid item xs={12}>
          <EquipementsForm />
        </Grid>
        <Grid item xs={12}>
          <FormTitle>Nombre de personnes au retour</FormTitle>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={6} md={3}>
            <PositiveNumberField
              label="Nombre"
              variant="outlined"
              value={
                (reservation.options?.peopleAtArrivalCount !== undefined
                && reservation.options?.peopleAtArrivalCount !== null
                && reservation.options?.peopleAtArrivalCount >-1)
                ? reservation.options?.peopleAtArrivalCount
                : ''/*countBookingPeople(reservation.people || {})*/
              }
              onChange={handlePeopleAtArrivalCountUpdate}
              fullWidth
              required
            />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormTitle>Commentaires</FormTitle>
        </Grid>
        <Grid item xs={12}>
          <TextPaper>
            <Box p={2}>
              <TextField
                fullWidth
                multiline
                rows={3}
                value={reservation.comments}
                onChange={useReservationChangeEventUpdate('comments')}
              />
            </Box>
          </TextPaper>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        item
        xs={12}
        sm={4}
        alignContent={'flex-start'}
      >
        <Grid item xs={12}>
          <FormTitle>Activité</FormTitle>
        </Grid>
        <Grid item xs={12}>
          <ReservationActivityForm
            // resetting the selected pricing if activity has been changed
            onActivityChange={(activity)=> {auth.userGroup?.userGroupId && handlePricingGroupIdUpdate(auth.userGroup?.userGroupId, activity.id)}}
          />
        </Grid>
        {activityTimeSlot && (
        <Grid item>
          <Grid item xs={12}>
            <FormTitle>Disponibilités</FormTitle>
          </Grid>
          <Grid item xs={12} spacing={2}>
            <br/>
            <Typography variant={'h6'} className={'ellipsis'} style={{color: (bookedPeopleOnTimeSlot < 0 ) ? 'red' : 'inherit'}}>
                Places disponibles sur ce créneau : {
                  bookedPeopleOnTimeSlot
                }
            </Typography>
            <Typography variant={'h6'} className={'ellipsis'} style={{color: (bookedUnderSevenOnTimeSlot < 0 ) ? 'red' : 'inherit'}}>
              Places disponibles avec le moniteur : {
                bookedUnderSevenOnTimeSlot
              }
            </Typography>
            <Typography variant={'h6'} className={'ellipsis'} style={{textDecoration: 'underline'}}>
              Conditions d'accès :
            </Typography>
            <Typography variant={'h6'} className={'ellipsis'}>
              Places autonomes par place avec le moniteur : {activityTimeSlot.constrainReservations ? activityTimeSlot.minAutonomousPeople : 'sans restriction'}
            </Typography>
          </Grid>
        </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={3}
        item
        xs={12}
        sm={4}
        alignContent={'flex-start'}
      >
        <Grid item xs={12}>
          <FormTitle>Tarification</FormTitle>
          {(auth.userGroup?.canViewAllPrices !== undefined
          && auth.userGroup?.canViewAllPrices
          /*&& reservation.pricingGroupId !== 'internet'*/) && <Select
            labelId="select-group-label"
            id="select-group"
            value={reservation.pricingGroupId || auth.userGroup?.userGroupId}
            label="Point de vente"
            onChange={(evt) => {
              handlePricingGroupIdUpdate(evt.target.value as string);
            }}
          >
            {userGroups?.map(userGroup => {
                return (
                  <MenuItem key={userGroup._id} value={userGroup._id}>
                    {userGroup.displayName}
                  </MenuItem>
                );
            })}
            <MenuItem key='internet' value='internet'>
              Internet
            </MenuItem>
          </Select>}
          {/*reservation.pricingGroupId === 'internet' &&
          <Typography variant={'h6'}>
            Internet
          </Typography>
          */}
        </Grid>
        {activity && (
          <Grid item xs={12}>
            <PeopleForm
              groupsPriceError={groupsPriceError}
              groupsPrice={groupsPrice}
              value={reservation.people || {}}
              onChange={handlePeopleUpdate}
            />
          </Grid>
        )}
        {activity && (activity.instructorPriceInEuro > 0 || activityTimeSlot) && (
          <>
            <Grid item xs={12}>
              <FormTitle>Moniteur</FormTitle>
            </Grid>
            <Grid item xs={12}>
              <Instructors />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <PaymentForm />
        </Grid>
      </Grid>
    </Grid>
  );
};

