import React, { useCallback, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Grid, Typography } from '@material-ui/core';
import { useReservation, useReservationActivity } from './reservation.context';
import { ActivitySelect } from './components/activitySelect.component';
import { DepartureSelect } from './components/departureSelect.component';
import { ReservationStatusSelect } from '../components/reservationStatusSelect.component';
import { ArrivalHourSelect } from './components/arrivalHourSelect.component';
import { arrivalDay } from '@crm/utils';

interface Props {
  onActivityChange: (activity: CRM.PublicActivity | Partial<CRM.PublicActivity>) => void;
}

export const ReservationActivityForm: React.FC<Props> = ({onActivityChange}:Props) => {
  const { reservation, update, lastSaveDate, isPendingModification } = useReservation();
  const { activity, activityTimeSlot, updateActivity, updateActivityTimeSlot } = useReservationActivity();
  const activityOverDays = activity?.overDays;
  const [arrivalColor, setArrivalColor] = useState<string>('');

  const handleStatusUpdate = useCallback(
    (value: CRM.ReservationStatus) => {

      let updatePayment: CRM.ReservationPayment | undefined = reservation.payment ? {
        amountInEuro: reservation.payment.amountInEuro /*|| 0*/, 
        discount:reservation.payment.discount /*|| 0*/,
        refunds: reservation.payment.refunds /*|| []*/,
        receipts: reservation.payment.receipts /*|| []*/,
        optionalStatus: reservation.payment.optionalStatus
      } : undefined;

      // reset status to if uncancelled
      /*if(
        (reservation.payment?.optionalStatus === "needRefund" ||
        reservation.payment?.optionalStatus === "refunded") &&
          value !== 'cancelled' &&
          updatePayment
        ) {
          updatePayment.optionalStatus = undefined;
      }*/

      if (value === 'started') {
        update({ status: value, startedDate: new Date(), payment: updatePayment });
      } 
      else if(value === 'cancelled' && updatePayment) {
        updatePayment.optionalStatus = "needRefund"
        update({ status: value, payment: updatePayment });
      }
      else {
        update({ status: value, payment: updatePayment });
      }
      if((value === 'cancelled' || value === 'gift-voucher') && reservation.activityTimeSlot) {
        updateActivityTimeSlot(''); // remove link to timeslot
      }
    },
    [update, updateActivityTimeSlot, reservation.payment, reservation.activityTimeSlot],
  );

  const handleActivityUpdate = useCallback(
    (value: CRM.PublicActivity | undefined) => {
      if (value) {
        updateActivity(value.id/*, reservation.people*/);
        onActivityChange(value)
      }
    },
    [updateActivity, onActivityChange],
  );

  const handleDepartureUpdate = useCallback(
    (date: Dayjs) => {
      // if day has been changed and return date already exists,
      // set return hour to 0 for "is not set"
      if(
        reservation.activityArrivalDate
        //&& !dayjs(reservation.activityArrivalDate).isSame(date, 'day')
        && dayjs(reservation.activityArrivalDate).hour() !== 0
        ) {
          update({
            activityDate: date.toDate(),
            activityArrivalDay: arrivalDay(date, activityOverDays).toDate(),
            activityArrivalDate: date.hour(0).minute(0).toDate(),
          });
      } else {
        update({
          activityDate: date.toDate(),
          activityArrivalDay: arrivalDay(date, activityOverDays).toDate(),
          activityArrivalDate: undefined,
        });
      }
    },
    [update, activityOverDays,reservation.activityArrivalDate],
  );

  const handleArrivalHourUpdate = useCallback(
    date => {
      update({ activityArrivalDate: date.toDate() });
    },
    [update],
  );

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12} sm={6}>
        <ReservationStatusSelect
          reservation={reservation}
          activity={activity}
          onChange={handleStatusUpdate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ActivitySelect kind={activity?.kind === 'supervised' ? undefined : 'canoe'} value={activity} disabled={activity?.kind === 'supervised' ? true: false} onChange={handleActivityUpdate} />
      </Grid>
      {activity && (
          activity.kind !== 'supervised' || 
          (activity.kind === 'supervised' && activityTimeSlot)
        ) && (
        <DepartureSelect
          reservation={reservation}
          activity={activity}
          activityTimeSlot={activityTimeSlot}
          value={reservation.activityDate && dayjs(reservation.activityDate)}
          lastSaveDate={lastSaveDate}
          isPendingModification={isPendingModification}
          onChange={handleDepartureUpdate}
          onChangeTimeSlot={(id, monitor, activity) => { updateActivityTimeSlot(id, monitor); onActivityChange(activity);}}
        />
      )}
      {activity && 
        activity.requireArrivalDate && 
        activity.spots.arrivalId && (
          activity.kind !== 'supervised' || 
          (activity.kind === 'supervised' && activityTimeSlot)
        ) && (
        <>
          <Grid item xs={12}>
            <Typography variant={'h6'}>
              Retour le{' '}
              <b style={{color: arrivalColor}} >
                {(reservation.activityArrivalDate &&
                  dayjs(reservation.activityArrivalDate)?.hour() !== 0 &&
                  dayjs(reservation.activityArrivalDate)?.format(
                    'DD/MM/YY[ à ]HH:mm',
                  )) || '?'}
              </b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ArrivalHourSelect
              activity={activity}
              activityDate={/*activityTimeSlot ? dayjs(activityTimeSlot.timeSlotArrival) :*/ dayjs(reservation.activityDate)}
              onChange={(day, color) => {
                handleArrivalHourUpdate(day);
                setArrivalColor(color);
              }}
              value={reservation.activityArrivalDate}
              lastSaveDate={lastSaveDate}
            />
          </Grid>
        </>
      )}

    </Grid>
  );
};
