import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import { get } from '../fetch';
import { useDomainPath } from '../auth/auth.context';
import { UserGroupGridItem } from './userGroupGridItem.component';
import { UserGroupGridHeader } from './userGroupGridHeader.component';
import AddIcon from '@material-ui/icons/Add';

export const UserGroupsView: React.FC = () => {
  const userGroupPath = useDomainPath('/user-group');

  const [userGroups, setUserGroups] = useState<CRM.UserGroupDocument[]>([]);

  const refreshUserGroups = useCallback(
    () => get<CRM.UserGroupDocument[]>(userGroupPath).then(setUserGroups),
    [userGroupPath],
  );

  useEffect(() => {
    refreshUserGroups();
  }, [refreshUserGroups]);

  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        mb={3}
      >
        <Link to="/user-groups/create">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<AddIcon fontSize={'large'} />}
          >
            Ajouter un groupe
          </Button>
        </Link>
      </Box>
      <div>
        <UserGroupGridHeader />
        {userGroups.length === 0 && <span>Aucun groupe n'a été créé</span>}
        {userGroups.map(userGroup => (
          <UserGroupGridItem
            key={userGroup._id}
            onDelete={refreshUserGroups}
            value={userGroup}
          />
        ))}
      </div>
    </div>
  );
};
