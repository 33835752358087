import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Arrays } from '@crm/utils';

const RowRadioGroup = withStyles({
  root: {
    flexDirection: 'row',
  },
  checked: {},
})(RadioGroup);

interface Props {
  timings: CRM.SpotTimings;
  includingOccurrence?: CRM.SpotTimingsOccurence;
  onChange: (occurrence?: CRM.SpotTimingsOccurence) => any;
}

function labelOf(occurence: CRM.Time) {
  return `${String(occurence.hour).padStart(2, '0')}h${String(
    occurence.minute,
  ).padStart(2, '0')}`;
}

export const SpotTimingsFilter = ({
  timings,
  includingOccurrence,
  onChange,
}: Props) => {
  const every = useMemo(
    () => timings.every.map(every => ({ ...every, label: labelOf(every) })),
    [timings],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedLabel = (event.target as HTMLInputElement).value;

      if (!selectedLabel) {
        return onChange(undefined);
      }

      onChange(every.find(timing => timing.label === selectedLabel));
    },
    [onChange, every],
  );

  return (
    <Box>
      <Typography>
        <b>Créneaux</b>
      </Typography>
      <Box>
        <RowRadioGroup
          value={includingOccurrence ? labelOf(includingOccurrence) : ''}
          onChange={handleChange}
        >
          {every.map(everyOccurrence => (
            <FormControlLabel
              key={everyOccurrence.label}
              label={everyOccurrence.label}
              value={everyOccurrence.label}
              labelPlacement="end"
              control={<Radio />}
            />
          ))}
          <FormControlLabel
            label={'Voir tout'}
            value={''}
            labelPlacement="end"
            control={<Radio />}
          />
        </RowRadioGroup>
      </Box>
    </Box>
  );
};

interface MultiProps {
  timings: CRM.SpotTimings;
  includingOccurrences: CRM.SpotTimingsOccurence[];
  onChange: (occurrences: CRM.SpotTimingsOccurence[]) => any;
}

export const SpotMultiTimingsFilter = ({
  timings,
  includingOccurrences,
  onChange,
}: MultiProps) => {
  const toggleOccurrence = (
    every: CRM.SpotTimingsOccurence,
    include: boolean,
  ) => {
    const alreadyIncludingIndex = includingOccurrences.findIndex(
      occurrence =>
        occurrence.hour === every.hour && occurrence.minute === every.minute,
    );
    if (alreadyIncludingIndex !== -1 && !include) {
      onChange(Arrays.remove(includingOccurrences, alreadyIncludingIndex));
    } else if (alreadyIncludingIndex === -1 && include) {
      onChange(includingOccurrences.concat(every));
    }
  };

  return (
    <Box>
      <Typography>
        <b>Créneaux</b>
      </Typography>
      <Box>
        {timings.every
          .map(every => ({ ...every, label: labelOf(every) }))
          .map(every => (
            <FormControlLabel
              key={every.label}
              label={every.label}
              labelPlacement="end"
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    toggleOccurrence(every, event.target.checked);
                  }}
                  checked={includingOccurrences.some(
                    occurrence =>
                      occurrence.hour === every.hour &&
                      occurrence.minute === every.minute,
                  )}
                />
              }
            />
          ))}
      </Box>
    </Box>
  );
};
