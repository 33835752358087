import React, { useMemo, useState } from 'react';
import { Box, Grid} from '@material-ui/core';
import { TimeSlotsGrid } from './timeSlotsGrid.component';
import { ActivityFilter } from '../../../reservations/filters/ActivityFilter.component';
import { EditTimeSlot } from './editTimeSlot.component';
import dayjs from 'dayjs';

export function TimeSlotsView() {

  const defaultActivity: CRM.ActivityDocument = {
    _id: ''
  } as any;
  const defaultActivityTimeSlot: CRM.ActivityTimeSlotDetailed = {
    _id: '',
    domain: '',
    activity: defaultActivity,
    timeSlotDeparture: new Date(),//#TODO replace by other form fields
    timeSlotArrival: new Date(),
    bookedPeople: 0,
    bookedPeopleWithMonitor: 0,
    maxPeople: 16,
    maxPeopleWithMonitor: 4,
    minAutonomousPeople: 3,
    constrainReservations: true,
    closeReservations: false,
    monitor: 'bruno'
  }
  //const [timeSlotId, setTimeSlotId] = useState<string>();
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [selectedActivityTimeSlot, setSelectedActivityTimeSlot] = useState<CRM.ActivityTimeSlotDetailed>(defaultActivityTimeSlot);
  const [refresh, setRefresh] = useState<number>(0);
  const [createtimeSlotsDates, setCreateTimeSlotsDates] = useState<Date[]>([dayjs().add(1,'day').toDate()]);

  const { minDate, maxDate } = useMemo(() => {
    const thisYear = dayjs().year(new Date().getFullYear());
    return {
      minDate: thisYear.startOf('year'),
      maxDate: thisYear.endOf('year'),
    };
  }, []);
  
  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        mb={3}
      >
        <Grid container spacing={2} item xs={12}>
        { selectedActivityTimeSlot && selectedActivityTimeSlot.activity._id !== '' && 
        <Grid item xs={12} sm={12}>
            <EditTimeSlot
              setCreateTimeSlotsDates={setCreateTimeSlotsDates}
              createtimeSlotsDates={createtimeSlotsDates}
              setSelectedActivityTimeSlot={setSelectedActivityTimeSlot}
              selectedActivityTimeSlot={selectedActivityTimeSlot}
              //setTimeSlotId={setTimeSlotId}
              //timeSlotId={timeSlotId}
              minDate={minDate}
              maxDate={maxDate}
              onSave={() => { setRefresh(refresh + 1); }}
              callRefresh={() => { 
                setSelectedActivityTimeSlot(defaultActivityTimeSlot);
                setRefresh(refresh + 1); 
              }}
            />
          </Grid>
          }
          <Box mt={3} width='100%'>
            <Grid item xs={12} sm={12}>
              <ActivityFilter
                value={selectedActivities}
                onChange={setSelectedActivities}
                nowrapLabels={true}
                kindFilter={['supervised']}
              />
            </Grid>
            <Grid item xs={12}>
              <TimeSlotsGrid
                //onChange={(t) => { setSelectedActivityTimeSlot(t); setTimeSlotId(t._id); }}
                setSelectedActivityTimeSlot={setSelectedActivityTimeSlot}
                selectedActivityTimeSlot={selectedActivityTimeSlot}
                activitiesIdFilter={selectedActivities}
                allowDelete={false}
                allowCreate={true}
                refresh={refresh}
              />
            </Grid>
          </Box>
        </Grid>
      </Box>
    </div>
  );
}
