import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './auth.context';
import React from 'react';

interface PrivateRouteProps extends RouteProps {
  allowedRoles: CRM.UserGroupRole[];
}

export function PrivateRoute(props: PrivateRouteProps) {
  const { children, ...rest } = props;
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!auth.loaded) {
          return <div></div>;
        }
        const displayRoute =
          auth.userGroup?.login &&
          props.allowedRoles.includes(auth.userGroup.role);

        return displayRoute ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
