import {
  createStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';

export const CrmTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

export const CrmTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

export const SmallTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 2),
    },
  }),
)(CrmTableCell as any);
