import React from 'react';
import { Typography } from '@material-ui/core';

export const FormTitle: React.FC = ({ children }) => {
  return (
    <Typography variant="h5">
      <b>{children}</b>
    </Typography>
  );
};
