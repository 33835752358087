/// <reference types="@crm/types" />
import React from 'react';
import ReactDOM from 'react-dom';
import DayjsUtils from '@date-io/dayjs';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import fr from 'dayjs/locale/fr';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as timezone from 'dayjs/plugin/timezone';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import reportWebVitals from './reportWebVitals';
import { ProvideAuth } from './auth/auth.context';
import { ProvideAlerts } from './hooks/useAlerts';
import App from './App';
import './index.css';

dayjs.extend(isBetween as any);
dayjs.extend(utc as any);
dayjs.extend(timezone as any);
dayjs.locale(fr);

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff',
    },
    text: {
      primary: '#152738',
    },
    primary: {
      main: '#C0C0C0',
    },
    secondary: {
      main: '#6200EE',
    },
    error: {
      main: '#b44',
    },
    grey: {
      '300': '#E8EDF3',
      '400': '#d0d0d0',
      '500': '#a5a5a5',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <ProvideAuth>
          <ProvideAlerts>
            <App />
          </ProvideAlerts>
        </ProvideAuth>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
