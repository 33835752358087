import React, { useCallback } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useReservation, useReservationActivity } from './reservation.context';
import { CanoeEquipmentsForm } from './canoeEquipments.form';
import { FormTitle } from '../../components/FormTitle.component';

export const EquipementsForm: React.FC = () => {
  const { equipmentsError, reservation, update } = useReservation();
  const { activity } = useReservationActivity();

  const handleEquipmentsUpdate = useCallback(
    (equipments?: CRM.Equipments) =>
      update({ options: { ...reservation.options, equipments } }),
    [update, reservation.options],
  );

  if ((activity?.kind === 'canoe' || activity?.kind === 'supervised') && reservation.options?.equipments) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormTitle>Embarcations</FormTitle>
        </Grid>
        <Grid item xs={12}>
          <CanoeEquipmentsForm
            equipments={reservation.options?.equipments as CRM.CanoeEquipments}
            onChange={handleEquipmentsUpdate}
          />
        </Grid>
        {equipmentsError && (
          <Box mt={1}>
            <Typography color={'error'}>{equipmentsError}</Typography>
          </Box>
        )}
      </Grid>
    );
  }

  return null;
};
