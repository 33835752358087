import React from 'react';
import { Box, Grid } from '@material-ui/core';

export function ActivityGridHeader() {
  return (
    <Box mt={1} mb={1}>
      <Grid container spacing={1} justify={'space-between'}>
        <Grid item xs={2}>
          Id
        </Grid>
        <Grid item xs={2}>
          Domaine propriétaire
        </Grid>
        <Grid item xs={2}>
          Nom
        </Grid>
        <Grid item xs={2}>
          Type
        </Grid>
        <Grid item xs={1}>
          Temps estimé
        </Grid>
        <Grid item xs={1}>
          Nombre de jours
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
      </Grid>
    </Box>
  );
}
