import { Box, Button, Grid } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { VehiclesGrid } from "./vehiclesGrid.component";
import { EditVehicleDialog } from "./editVehicleDialog.component";
import { AssignVehicleDialog } from "./assignVehicleDialog.component";

export function VehiclesView() {
  const [refresh, setRefresh] = useState<number>(1);
  const [vehicleDialogOpen, setVehicleDialogOpen] = useState(false);
  const [assignVehicleDialogOpen, setAssignVehicleDialogOpen] = useState(false);
  const defaultBus: Partial<CRM.BusDocument> = {
    name:'',
    registration:'',
    passengerCapacity:30,
    functionnal: true,
    status: 'ready'
  };
  const [busUpdate, setBusUpdate] = useState<Partial<CRM.BusDocument>>(defaultBus);
  const [assignBusId, setAssignBusId] = useState<string>('');

  const vehicleDialog = useCallback(() => setVehicleDialogOpen(true), [
    setVehicleDialogOpen,
  ]);
  const vehicleDialogClose = useCallback(
    () => { setVehicleDialogOpen(false); },
    [setVehicleDialogOpen],
  );

  const assignVehicleDialog = useCallback(() => setAssignVehicleDialogOpen(true), [
    setAssignVehicleDialogOpen,
  ]);
  const assignVehicleDialogClose = useCallback(
    () => { setAssignVehicleDialogOpen(false); },
    [setAssignVehicleDialogOpen],
  );

  return (
    <Box mt={4}>
      <EditVehicleDialog
        dialogOpen={vehicleDialogOpen}
        dialogClose={()=> { vehicleDialogClose(); setRefresh(refresh+1);}}
        bus={busUpdate}
        busUpdate={(bus) => {setBusUpdate(bus); setRefresh(refresh+1);}}
      />
      <AssignVehicleDialog
        dialogOpen={assignVehicleDialogOpen}
        dialogClose={()=> { assignVehicleDialogClose(); setRefresh(refresh+1);}}
        busId={assignBusId}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            onMouseDown={e => {setBusUpdate(defaultBus); vehicleDialog(); }}
          >
            <b>+</b> &nbsp;Ajouter un véhicule à la flotte
          </Button>
        </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <VehiclesGrid
            refresh={refresh}
            onConfigure={(bus) => {setBusUpdate(bus); vehicleDialog();}}
            onAssign={(busId) => {setAssignBusId(busId); assignVehicleDialog();}}
          />
        </Grid>
      </Grid>
    </Box>
  )
}