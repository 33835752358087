import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useActivity } from './activity.context';
import { activityPricingHasUniqueId } from '@crm/utils';

export const SaveButton: React.FC = () => {
  const { activity } = useActivity();

  const cannotSaveMessages = [];
  if (isEmpty(activity.name)) {
    cannotSaveMessages.push('Renseigner le nom privé');
  }
  if (isEmpty(activity.displayName)) {
    cannotSaveMessages.push('Renseigner le nom publique');
  }
  if (isEmpty(activity.description)) {
    cannotSaveMessages.push('Renseigner la description');
  }
  if (isEmpty(activity.pricing)) {
    cannotSaveMessages.push('Renseigner les tarifs');
  }
  if (!activityPricingHasUniqueId(activity.pricing || [])) {
    cannotSaveMessages.push('Les identifiants des tarifs doivent être uniques');
  }
  if (
    (
      !activity.spots || 
      (activity.spots && (!activity.spots.departureId || !activity.spots.arrivalId))
    )) {
    cannotSaveMessages.push('Renseigner les spots de départ et arrivée');
  }
  if ((!activity.recurrence || isEmpty(activity.recurrence.departureHours)) && activity.kind !== 'supervised') {
    cannotSaveMessages.push('Renseigner les heures de départ');
  }
  if (!activity.minAge || activity.minAge < 0) {
    cannotSaveMessages.push(`Renseigner l'âge minimal`);
  }
  if (!activity.estimatedTimeMinutes || activity.estimatedTimeMinutes < 0) {
    cannotSaveMessages.push(`Renseigner le temps estimé`);
  }

  return (
    <Box display="flex" flexDirection="column">
      <Button
        color="secondary"
        variant="contained"
        disabled={cannotSaveMessages.length > 0}
        type="submit"
      >
        Enregistrer
      </Button>
      {cannotSaveMessages.length > 0 && (
        <Box display="block">
          <ul>
            {cannotSaveMessages.map((message, index) => (
              <li key={index}>
                <Typography color={'error'}>{message}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};
