import { Dayjs } from 'dayjs';
import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';

export type DateRange = { start?: Dayjs; end?: Dayjs };

interface DateRangeProps {
  range?: DateRange;
  onChange: (value: DateRange) => any;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const DateRangeSelect = ({ range, onChange }: DateRangeProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.root}>
      <DatePicker
        invalidDateMessage="Format invalide"
        variant="inline"
        inputVariant="outlined"
        format="DD/MM/YYYY"
        label="Début"
        style={{ width: '10em' }}
        value={range?.start || null}
        onChange={date => { date && onChange({ start: date, end: date })}}
        autoOk
        required
      />
      <DatePicker
        invalidDateMessage="Format invalide"
        variant="inline"
        inputVariant="outlined"
        format="DD/MM/YYYY"
        label="Fin"
        style={{ width: '10em' }}
        value={range?.end || null}
        onChange={date => date && onChange({ start: range?.start, end: date })}
        autoOk
        required
      />
    </Box>
  );
};
