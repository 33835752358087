import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { keyBy, sortBy } from 'lodash';
import { useActivity } from './activity.context';
import { useDomains } from '../../hooks/useDomain';
import { useAuth } from '../../auth/auth.context';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function SharedTimeSlotsDomains() {
  const { activity, update } = useActivity();
  const { userGroup } = useAuth();
  const domains = useDomains()/*.filter(d => d._id !== userGroup?.domainId)*/;

  useEffect(() => {
    if(userGroup?.domainId && !activity.sharedTimeSlotsDomains?.includes(userGroup?.domainId)) {
      update({
        ...activity,
        sharedTimeSlotsDomains: activity.sharedTimeSlotsDomains ? 
          activity.sharedTimeSlotsDomains?.concat([userGroup?.domainId]) : 
          activity.sharedTimeSlotsDomains,
      });
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activity.sharedTimeSlotsDomains]);

  const sharedTimeSlotsDomainsUpdater = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      update({
        ...activity,
        sharedTimeSlotsDomains: event.target.value as string[],
      });
    },
    [activity, update],
  );
    console.log(domains)
  const sortedDomains = sortBy(domains, domain => domain.displayName);
  const domainById = keyBy(domains, domain => domain._id);

  return (
    <FormControl variant={'outlined'} fullWidth>
      <InputLabel id="shared-domains-label">Créneaux partagés avec</InputLabel>
      <Select
        labelId="shared-domains-label"
        label={'Créneaux partagés avec'}
        multiple
        value={activity.sharedTimeSlotsDomains || []}
        onChange={sharedTimeSlotsDomainsUpdater}
        renderValue={selected => {
          return (selected as string[])
            .filter(selectedDomain => domainById[selectedDomain])
            .map(selectedDomain => domainById[selectedDomain].displayName)
            .join(', ');
        }}
        MenuProps={MenuProps}
      >
        {sortedDomains.map(domain => (
          <MenuItem key={domain._id} value={domain._id}>
            <Checkbox
              disabled={userGroup?.domainId.valueOf() === domain._id.valueOf() ? true : false }
              checked={
                userGroup?.domainId.valueOf() === domain._id.valueOf() ?
                true : 
                activity.sharedTimeSlotsDomains?.includes(domain._id)
              }
            />
            <ListItemText primary={domain.displayName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
