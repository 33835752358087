import React, { useCallback, useEffect, useState } from "react";
import { useAuth, useAuthUsers, useDomainPath } from "../auth/auth.context";
import { isEmpty, sortBy } from "lodash";
import { get } from "../fetch";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { SpotStrip } from "../components/SpotStrip.component";
import { BusPrioritySelect } from "../components/selects/BusPrioritySelect.component";
import dayjs, { Dayjs } from 'dayjs';

export function DriverArrivalsView() {
  const auth = useAuth();
  const { loggedUser } = useAuthUsers();
  const spotPath = useDomainPath('/activity-spot');
  const busTravelPath = useDomainPath('/busTravel');
  const [selectedSpot, setSelectedSpot] = useState<CRM.ActivitySpotDocument>();
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);
  const [busTravelsArrival, setBusTravelsArrival] = useState<CRM.BusTravelDocument[]>();
  const [busTravelsSpotIds, setBusTravelsSpotIds] = useState<string[]>([]);
  const [driverUserId, setDriverUserId] = useState<string>();
  const [refresh, setRefresh] = useState<number>(1);

  function isArrivalSpot(spot: CRM.ActivitySpotDocument) {
    return spot.arrivals && !isEmpty(spot.arrivals.every);
  }

  const loadSpots = useCallback(() => {
    get<CRM.ActivitySpotDocument[]>(spotPath).then(result => {
      const availableSpots = sortBy(
        result.filter(isArrivalSpot)
          .filter(s => busTravelsSpotIds
          .includes(s._id)),
        spot => spot.displayName,
      );
      setSpots(availableSpots);
      if (!selectedSpot && availableSpots.length > 0) {
        setSelectedSpot(availableSpots[0]);
      }
    });
  }, [spotPath, selectedSpot, busTravelsSpotIds]);

  const loadTravelsArrival = useCallback(() => {
    get<CRM.BusTravelDocument[]>(
      busTravelPath+'/arrivalsForDriverId/'+(auth.userGroup?.role === 'driver' ? loggedUser?._id : driverUserId)
      ).then(busTravels => {
        setBusTravelsArrival(busTravels);
        const busTravelIds = busTravels.map(
          (busTravel) => {
            return busTravel.travelSpotsTimings.map(({spotId}) => spotId);
          }
          ).flat();
        setBusTravelsSpotIds(busTravelIds);
    });
  },[
    busTravelPath,
    auth.userGroup?.role, 
    loggedUser?._id, 
    driverUserId
  ]);
  useEffect(loadTravelsArrival, [loadTravelsArrival]);
  useEffect(loadSpots, [loadSpots]);

  //#TODO set drivers list for admin selection
  //#TODO set available timingOccurences
  //#TODO set buses to fill with buttons (lists view, actions)
  //#TODO add unfilled reservations (call /filledOrEmptyBusReservations endpoint)
  //#TODO manage responsive ergonomy (tablets++)

  if (!selectedSpot) {
    return <div><CircularProgress size="1em" /> Bientôt disponible pour les administrateurs</div>;
  }
  
  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <SpotStrip
          selectedId={selectedSpot._id}
          onChange={setSelectedSpot}
          spots={spots}
        />
      </Box>
      <br/><br/> Retours (Vue Chauffeur)
      <Box>
        <Grid container xs={12}>
          <Grid item xs={3}>
            {/*<BusPrioritySelect
              spot={spots.find(s => s.displayName === "Vallon Pont-d'Arc") || selectedSpot}
              direction="departure"
              day={dayjs("2024/10/03")}
              spotTimingOccurence={{hour: 9, minute: 0, capacity:0, allowOverBooking: true}}
              refresh={refresh}
            />*/}
          </Grid>
          <Grid item xs={9}>

          </Grid>
        </Grid>
      </Box>
    </div>
  );
}