import React from 'react';
import clsx from 'clsx';
import { Box, createStyles, Grid, Theme } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { makeStyles } from '@material-ui/core/styles';
import { ColumnClickHandler, Table } from './Table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortable: {
      cursor: 'pointer',
    },
    sorted: {
      textDecoration: 'underline',
    },
    sortIcon: {
      opacity: 0.6,
      marginLeft: '2px',
    },
    wrapText: {
      textOverflow: 'ellipsis',
    },
  }),
);

export const Header: React.FC<Table<any> & ColumnClickHandler> = props => {
  const classes = useStyles();

  return (
    <Box mt={1} mb={1}>
      <Grid container spacing={1} id={'gridHeader'} justify={'space-between'} style={props.style}>
        {props.columns.map(column => {
          const sortIcon = () => {
            if (!props.sorting?.order) return null;
            if (props.sorting?.key !== column.key) return null;
            if (props.sorting?.order === 'desc')
              return (
                <ArrowUpwardIcon
                  fontSize={'small'}
                  className={classes.sortIcon}
                />
              );
            return (
              <ArrowDownwardIcon
                fontSize={'small'}
                className={classes.sortIcon}
              />
            );
          };
          const sort = sortIcon();
          return (
            <Grid
              item
              className={clsx({
                [classes.sortable]: column.sortable,
                [classes.sorted]: Boolean(sort),
              })}
              key={column.key}
              xs={column.xs}
              onClick={() => props.onColumnClick(column.key)}
            >
              <Box className={classes.wrapText} display={'flex'}>
                {column.label}
                {sort}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
