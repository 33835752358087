import { Box, createStyles, Grid, Theme, Typography } from '@material-ui/core';
import { Alert } from '../../../components/alerts/Alert.component';
import React, { useEffect, useState } from 'react';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { makeStyles } from '@material-ui/core/styles';
import dayjs, { Dayjs } from 'dayjs';
import { departureCapacityColor } from '@crm/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    departure: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      border: '1px solid',
      borderColor: theme.palette.text.disabled,
      borderRadius: 5,
      cursor: 'pointer',
      padding: '5px',
      '&:hover': {
        boxShadow: theme.shadows['3'],
      },
    },
    departureSelected: {
      backgroundColor: 'white',
      boxShadow: theme.shadows['3'],
    },
  }),
);

interface Departure extends Omit<CRM.ActivityOccurrence, 'date'> {
  color: string;
  date: dayjs.Dayjs;
  placesRemaining: number;
}

export function DeparturesChoiceComponent(props: {
  value?: Dayjs;
  dayDepartures?: CRM.ActivityOccurrence[] | undefined;
  selectedDay: Dayjs;
  onChange: (date: Dayjs) => void;
  onChangeColor?: (color: string) => void;
}) {
//  const today = dayjs().tz('Europe/Paris');
  const classes = useStyles();
  const [isClickAction, setIsClickAction] = useState<boolean>(false);

  const onDepartureSelect = (departure: Departure) => {
    if (departure.placesRemaining > 0 || departure.allowOverBooking) {
      props.onChange(departure.date);
    }
  };

  function calibrateGmt(d: dayjs.Dayjs) {
    /*if(today.isAfter(dayjs(today.year()+'-03-30')) && today.isBefore(dayjs(today.year()+'-12-15')))//summer hour
    {
      d = d.subtract(1, 'hour');
    }*/
    return d;
  }

  //console.log(props.dayDepartures,props.selectedDay)
  const selectedDayDepartures =
    props.dayDepartures &&
    props.dayDepartures
      .map(departure => ({
        ...departure,
        color: departureCapacityColor(departure),
        date: calibrateGmt(dayjs.utc(departure.date).local()),
        placesRemaining: departure.maxPeople - departure.currentPeople,
      }))
      .filter(departure => props.selectedDay.isSame(departure.date, 'day'));

    useEffect(() => {
      const initDeparture = selectedDayDepartures?.find(
        d => d.date.isSame(props.value!, 'day')
        && (d.allowOverBooking || d.placesRemaining > 0)
      );
      if(initDeparture !== undefined && props.onChangeColor) {
        props.onChangeColor(departureCapacityColor(initDeparture));
        if(!initDeparture.date.isSame(props.value!)
        && !isClickAction
        && props.value && props.value.hour() === 0) {
          props.onChange(initDeparture.date);
          setIsClickAction(false);
        }
      }
    }, [selectedDayDepartures, isClickAction, props]);

  return (
    <Grid container spacing={1}>
      {selectedDayDepartures &&
        selectedDayDepartures.map(departure =>
          {
            return (
            <Grid item xs={6} key={departure.date.toISOString()}>
              <Box
                className={
                  departure.date.isSame(props.value!)
                    ? `${classes.departure} ${classes.departureSelected}`
                    : classes.departure
                }
                onClick={() => { onDepartureSelect(departure); setIsClickAction(true);}}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box display={'flex'}>
                      <Box
                        fontWeight={'bold'}
                        fontSize={'1rem'}
                        color={
                          departure.color
                        }
                        mr={1}
                      >
                        {departure.date.format('HH[h]mm')}{' '}
                      </Box>
                      {departure.allowOverBooking && (
                        <GroupAddIcon
                          fontSize={'small'}
                          titleAccess={'Surbooking autorisé'}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                    fontSize={'0.65rem'}
                    color={departure.color}
                    style={{textAlign: 'right'}}
                    >
                    {departure.maxPeople - departure.placesRemaining} places occupées
                    </Box>
                  </Grid>
                </Grid>
                <Typography variant={'caption'}>
                  {departure.placesRemaining} places
                  restantes{' '}
                </Typography>
              </Box>
            </Grid>
            )
      })}
      {selectedDayDepartures?.length === 0 && (
        <Grid item>
          <Alert severity={'info'} variant={'standard'}>
            Aucun horaire n'est disponible pour le jour sélectionné
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}
