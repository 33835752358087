import React from 'react';
import { Box, Grid } from '@material-ui/core';

export function SpotGridHeader() {
  return (
    <Box mt={1} mb={1}>
      <Grid container spacing={1} justify={'space-between'}>
        <Grid item xs={2}>
          Nom privé
        </Grid>
        <Grid item xs={2}>
          Nom d'affichage
        </Grid>
        <Grid item xs={2}>
          Point de départ
        </Grid>
        <Grid item xs={2}>
          Point d'arrivée
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
      </Grid>
    </Box>
  );
}
