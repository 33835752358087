import React, { useCallback } from 'react';
import { useReservation } from './reservation.context';
import { useValuePartialUpdate } from '../../hooks/useChangeEventUpdate';
import { useAuthUsers } from '../../auth/auth.context';
import { sumPaymentReceipts, sumReceipts } from '@crm/utils';
import { PaymentReceipts } from './paymentReceipts.component';
import { Box, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export const PaymentReceiptsForm: React.FC = () => {
  const { reservation, update } = useReservation();
  const { loggedUser } = useAuthUsers();

  const updatePayment = useValuePartialUpdate<CRM.ReservationDocument>(
    'payment',
    update,
  );

  const onChange = useCallback(
    newReceipts =>
      updatePayment({ ...reservation.payment, receipts: newReceipts } as any),
    [updatePayment, reservation.payment],
  );

  const addReceipt = useCallback(() => {
    const newReceipts: CRM.PaymentReceipt[] = [
      ...(reservation.payment?.receipts || []),
      {
        amountInEuro: 0,
        method: 'cash',
        userGroupId: loggedUser?.userGroup,
        userId: loggedUser?._id,
        userName: loggedUser?.displayName,
        date: new Date(),
      },
    ];
    onChange(newReceipts);
  }, [onChange, loggedUser, reservation.payment?.receipts]);

  const isOverPaid =
    sumPaymentReceipts(reservation.payment) -
      sumReceipts(reservation.payment?.refunds) >
    (reservation.payment?.amountInEuro || 0);

  return (
    <PaymentReceipts
      value={reservation.payment?.receipts || []}
      isOverPaid={isOverPaid}
      onChange={onChange}
    >
      <Box mt={1}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={addReceipt}
        >
          Ajouter un encaissement
        </Button>
      </Box>
    </PaymentReceipts>
  );
};
