import { Dialog, DialogTitle, Button, DialogContent, Box, Typography, Grid, DialogActions, makeStyles } from "@material-ui/core";
import React from "react";
import { RichGrid } from "../../../components/table/RichGrid.component";
import { RowHandlers } from "../../../components/table/Table";
import { availableActivityTimeSlotsTableProvider } from "../../../reservations/reservationGrid.columns";
import { Theme as DefaultTheme, Theme } from '@material-ui/core/styles/createMuiTheme';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  }
}));

interface Props {
  timeSlots: CRM.ActivityTimeSlotDetailed[];
  dialogOpen: boolean;
  dialogClose: () => void;
  onSelect:(id: string, monitor: string, activity: Partial<CRM.PublicActivity>) => void;
}


export const AvailableTimeSlotsDialog: React.FC<Props> = ({ timeSlots, dialogOpen, dialogClose, onSelect }) => {

  const classesgrid: ClassNameMap<string> = useStylesGrid();

  return (
    <Dialog
    onClose={dialogClose}
    open={dialogOpen}
    maxWidth={'lg'}
    fullWidth={true}
    id='availableTimeSlots'
  >
    <DialogTitle style={{ textAlign: 'center' }}>
      Choix du créneau
    </DialogTitle>
    <DialogContent>
      <Box style={{ minHeight: '70vh', overflow: 'hidden' }} className='App'>
        <Typography variant="subtitle1">
          CRENEAUX
        </Typography>
        {<RichGrid
            style={{
              paddingRight: '10px', //compensate for scrollbar width
            }}
            columns={availableActivityTimeSlotsTableProvider().columns}
            rows={timeSlots}
            renderRow={activityTimeSlot => {
              const available: boolean =  activityTimeSlot.closeReservations !== true && 
                (activityTimeSlot.bookedPeople < (activityTimeSlot.maxPeople-1)) ? true: false;
              return (
                <div className='Grid-row'>
                  <Grid
                    container
                    className={classesgrid && (classesgrid.root)}
                    spacing={1}
                    style={{ opacity: available ? '1' : '0.4' }}
                    onClick={() => {
                      let publicActivity: Partial<CRM.PublicActivity> = {
                        id: activityTimeSlot.activity._id
                      }
                      if (available) {
                        onSelect(activityTimeSlot._id, activityTimeSlot.monitor, publicActivity); /*window.open(`/reservations/${reservation._id}`) */
                      }
                    }}
                  >
                    {availableActivityTimeSlotsTableProvider().columns.map(column => {
                      const Cell = column.Cell;
                      return (
                        <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                          {Cell && <Cell value={activityTimeSlot} handlers={{} as RowHandlers} />}
                          {column.renderCell &&
                            column.renderCell(activityTimeSlot, {} as RowHandlers)}
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              );
            }}
          />}
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={dialogClose}>Fermer</Button>
    </DialogActions>
  </Dialog>
  );

}
