import React from 'react';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../components/TabPanel.component';
import { UserGroupForm } from './userGroup.form';
import { UsersForm } from './users.form';

export const UserGroupTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState('general');

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="menu des groupes utilisateurs"
      >
        <Tab value="general" label="Groupe" />
        <Tab value="users" label="Utilisateurs" />
      </Tabs>
      <Divider variant="middle" />
      <TabPanel value={selectedTab} index="general">
        <UserGroupForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="users">
        <UsersForm />
      </TabPanel>
    </div>
  );
};
