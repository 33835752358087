import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { allReservationStatus, translateStatus } from '@crm/utils';

interface Props {
  includingStatus: CRM.ReservationStatus[];
  onChange: (status: CRM.ReservationStatus[]) => any;
}

export const StatusFilter = ({ includingStatus, onChange }: Props) => {
  const toggleStatus = (status: CRM.ReservationStatus, include: boolean) => {
    const alreadyIncluding = includingStatus.includes(status);
    if (alreadyIncluding && !include) {
      onChange(includingStatus.filter(including => including !== status));
    } else if (!alreadyIncluding && include) {
      onChange(includingStatus.concat(status));
    }
  };

  return (
    <Box>
      <Typography>
        <b>Status</b>
      </Typography>
      <Box>
        {allReservationStatus.filter(
          s => !s.includes(
            "gift-voucher" as CRM.ReservationStatus && 
            "cancelled" as CRM.ReservationStatus
            )
          ).map(status => (
          <FormControlLabel
            key={status}
            label={translateStatus(status)}
            labelPlacement="end"
            control={
              <Checkbox
                color="secondary"
                onChange={event => {
                  toggleStatus(status, event.target.checked);
                }}
                checked={includingStatus.includes(status)}
              />
            }
          />
        ))}
      </Box>
    </Box>
  );
};
