import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { CrmLink } from '../../components/Link.component';
import { get, put } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { useParams } from 'react-router-dom';
import { ProvideActivity } from './activity.context';
import { CreateActivityTabs } from './createActivityTabs.component';
import { UpdateParams } from '../../route/UpdateParam';
import { useAlerts } from '../../hooks/useAlerts';

export function UpdateActivityView() {
  const { id } = useParams<UpdateParams>();
  const path = useDomainPath('/activity');
  const { notify } = useAlerts();
  const [value, setValue] = useState<CRM.ActivityDocument>();

  const update = useCallback(
    async (activity: Partial<CRM.ActivityDocument>) => {
      try {
        await put(`${path}/${activity._id}`, activity);
        notify(`L'activité a été mise à jour`, 'success');
      } catch (err) {
        notify(`Impossible de mettre à jour l'activité'`, 'error');
      }
    },
    [notify, path],
  );

  useEffect(() => {
    get<CRM.ActivityDocument>(`${path}/${id}`).then(setValue);
  }, [id, path]);

  if (!value) {
    return <CircularProgress />;
  }

  return (
    <ProvideActivity initialValue={value} onSave={update}>
      <Box display="flex" flexDirection="column">
        <CrmLink to="/activities">&lt; Liste des activités</CrmLink>
        <Box mb={3} mt={3}>
          <Typography variant="h4">{value?.displayName}</Typography>
        </Box>
        <CreateActivityTabs />
      </Box>
    </ProvideActivity>
  );
}
