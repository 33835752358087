import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  /*Paper,
  Table,
  TableBody,
  TableContainer,*/
  Theme,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import dayjs, { Dayjs } from 'dayjs';
import { get, post } from '../fetch';
import { useAuth, useAuthUsers, useDomainPath } from '../auth/auth.context';
// import { ReservationGridHeader } from './reservationGridHeader.component';
// import { ReservationGridItem } from './reservationGridItem.component';
import { DbDocumentSelect } from '../components/DbDocumentSelect.components';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  allMethods,
  DisplayedMethods,
  SalesByPaymentMethod,
} from './salesByPaymentMethod.form';
import { isAdmin } from '../auth/roles';
import { SalesSheet } from './salesSheet.form';
// import html2canvas from 'html2canvas';
import { sumReceiptsMethod } from './sumReceiptsMethod';
import {
  // ReservationsFilter,
  ReservationsFilters,
} from './reservationsFilters.form';
import { roundMoney, sumPaymentReceipts } from '@crm/utils';
import { useAlerts } from '../hooks/useAlerts';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../components/TabPanel.component';
import { RichGrid } from '../components/table/RichGrid.component';
import { salesTableProvider } from '../reservations/reservationGrid.columns';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';
import { RowHandlers } from '../components/table/Table';

const VALID_DATE = {
  START: dayjs('2021-04-01T00:00:00.000Z'),
  END: dayjs('2099-04-01T00:00:00.000Z'),
};

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.3, 0),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0),

    '& > *': {
      margin: theme.spacing(2, 0),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

type sumType = 'receipt' | 'refund';

const inDisplayedMethods = (displayedMethods: DisplayedMethods) => {
  if (displayedMethods.length === 0) {
    return (res: CRM.ReservationDetailed) => res.payment.receipts.length === 0;
  }
  return (res: CRM.ReservationDetailed) =>
    res.payment.receipts.some(receipt =>
      displayedMethods.includes(receipt.method),
    );
};

const inFilters = (filters: ReservationsFilters) => {
  return (res: CRM.ReservationDetailed) => {
    if (
      !filters.includeStarted &&
      ['in-activity'].includes(res.status)
    ) {
      return false;
    }
    if (!filters.includeDone && res.status === 'done') {
      return false;
    }
    if (!filters.includeLocation && res.status === 'started') {
      return false;
    }
    if (!filters.includeReservation && res.status === 'booked') {
      return false;
    }
    if (!filters.includeCanoe && res.activity.kind === 'canoe') {
      return false;
    }
    if (!filters.includeFree && res.activity.kind === 'supervised') {
      return false;
    }
    return true;
  };
};

const ofUser = (userId: string | undefined) => {
  if (!userId) {
    return () => true;
  }
  return (res: CRM.ReservationDetailed) => {
    return res.sellerUser === userId;
  };
};


function sumReceiptsOrRefundsMethods(reservations: CRM.ReservationDetailed[], type: sumType, day: Dayjs, userGroupId?: string) {
  //const receiptsMethodsSum: { [method: string]: number} = {};
  const receiptsMethodsSum: { [activityKind: string]: CRM.ReceiptsMethodsSum} = {};
  receiptsMethodsSum['all'] = {};

  let activityKinds: Array<string> = ['all'];
  //[key in activityKind]: CRM.ActivityKind]
  reservations.forEach(reservation => {
    allMethods.forEach(method => {
      if (day) {
        if (type === 'receipt' && reservation.payment.receipts) {
          reservation.payment.receipts = reservation.payment.receipts.filter(
            r => r.date
              && (dayjs(r.date).isSame((day || dayjs()), 'day'))
              && (dayjs(r.date).isSame((day || dayjs()), 'day'))
          );
        }
        if (type === 'refund' && reservation.payment.refunds) {
          reservation.payment.refunds = reservation.payment.refunds.filter(
            r => r.date
            && ( dayjs(r.date).isSame((day || dayjs()), 'day'))
            && ( dayjs(r.date).isSame((day || dayjs()), 'day'))
          );
        }
      }
      const activityKind = reservation.activity.kind as string;
      if(activityKinds.find(k =>  k.includes(activityKind)) === undefined) {
        activityKinds.push(activityKind);
        // prevent undefined array index with this kind
        if(!receiptsMethodsSum[activityKind]) {
          receiptsMethodsSum[activityKind] = {};
        }
      }

      const cash = sumReceiptsMethod(
        type === 'receipt' ?
        reservation.payment.receipts : reservation.payment.refunds,
        method,
        userGroupId
      );

      receiptsMethodsSum[activityKind][method] = (receiptsMethodsSum[activityKind][method] || 0) + cash;
      receiptsMethodsSum[activityKind]['total'] = (receiptsMethodsSum[activityKind]['total'] || 0) + cash;
    });
  });
  console.log(reservations, type, receiptsMethodsSum)

  activityKinds.forEach(kind => {
    allMethods.forEach(method => {
      receiptsMethodsSum[kind][method] = roundMoney(receiptsMethodsSum[kind][method] || 0);
      receiptsMethodsSum['all'][method] = (receiptsMethodsSum['all'][method] || 0) + receiptsMethodsSum[kind][method];
    });
    receiptsMethodsSum[kind]['total'] = roundMoney(receiptsMethodsSum[kind]['total'] || 0);
    receiptsMethodsSum['all']['total'] = (receiptsMethodsSum['all']['total'] || 0) + receiptsMethodsSum[kind]['total'];
  });
  return receiptsMethodsSum;
}

export function SalesView() {
  const { notify } = useAlerts();
  const userGroupPath = useDomainPath('/user-group');
  const [salesPinCode, setSalesPinCode] = useState<string | undefined>();
  const classes = useStyles();
  const salesPath = useDomainPath('/user-group');
  const auth = useAuth();
  const { groups, getUsersOfGroup } = useAuthUsers();
  const isAdminUser = isAdmin(auth.userGroup);
  const classesgrid: ClassNameMap<string> = useStylesGrid();

  const [displayedMethods/*, setDisplayedMethods*/] = useState<DisplayedMethods>(
    allMethods,
  );
  const [
    reservationsFilters/*,
    setReservationsFilters,*/
  ] = useState<ReservationsFilters>({
    includeStarted: true,
    includeDone: true,
    includeCanoe: true,
    includeFree: true,
    includeLocation: true,
    includeReservation: true,
    includeAdditionalSale: true
  });
  const [filterDay, setFilterDay] = useState<Dayjs>(dayjs());
  const [userGroupId, setUserGroupId] = useState<string>(
    auth.userGroup!.userGroupId,
  );
  const [userId, setUserId] = useState<string | undefined>();
  const [reservations, setReservations] = useState<CRM.ReservationDetailed[]>(
    [],
  );
  const [selectedTab, setSelectedTab] = React.useState('synthesis');
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const print = useCallback(() => {
    // window.scrollTo(0, 0);
    const element = document.querySelector('#salesView');
    var win = window.open('', '_blank');
    if(win) {
      win.document.head.innerHTML = document.head.innerHTML;
      win.document.body.innerHTML = '<body><div style="max-width:1500px;margin-top:20px;overflow-x:visible">'+
      '<style>body, html, #wrapper, #gridHeader td,' +
      '.ellipsis td {font-size:0.8rem;} #gridHeader {padding-right:0!important;}'+
      '#richGrid div {margin:0px;padding-bottom:0px;padding-top:0px;}</style>' +
      element?.innerHTML +
      +'</div></body>';
      // hide or calibrate elements before printing
      win.document.querySelector('[aria-label="menu caisse"]')
      ?.setAttribute('style', 'display:none');
      win.document.querySelector('[title="imprimer"]')
      ?.setAttribute('style', 'display:none');
      win.document.querySelector('[title="actualiser la liste"]')
      ?.setAttribute('style', 'display:none');
      win.document.getElementById('salesCodeInput')
      ?.setAttribute('style', 'display:none');
      win.document.getElementById('salesCodeButton')
      ?.setAttribute('style', 'display:none');
      win.document.getElementById('richGrid')
      ?.setAttribute('style', 'max-height: none');
      win.document.getElementById('validateBtn')
      ?.setAttribute('style', 'display:none');
      let ellipsisTexts = win.document.querySelectorAll(".ellipsis");
      let divSheets = win.document.querySelectorAll('#formSheet div');
      divSheets.forEach((txt) => {
        txt?.setAttribute('style', 'padding: 4px;margin-bottom:4px');
      });
      let th = win.document.querySelectorAll('#totalReceiptTab th');
      th.forEach((txt) => {
        txt?.setAttribute('style', 'color: #111');
      });

      ellipsisTexts.forEach((txt) => {
        txt?.setAttribute('style', 'width: 100%;display:inline-block;overflow:hidden');
      });
      win.document.body.innerHTML = win.document.body.innerHTML.replace('NaN', '');

      const script = win.document.createElement('script');
    script.innerHTML = "setTimeout(() => {window.print();/*window.close();*/}, 100);";
      win.document.body.appendChild(script);

      let head = win.document.head || win.document.getElementsByTagName('head')[0],
      style = document.createElement('style');
      style.innerHTML = "@media print{@page {size: landscape; overflow-x: visible}}";

      head.appendChild(style);
      //win.print();
      //win.close();
    }
    /*html2canvas(element as any, {
      allowTaint: true,
      useCORS: true,
    }).then(canvas => {
      canvas.toBlob(function (blob) {
        saveAs(blob as any, 'fiche de caisse.png');
      });
    });*/
  }, []);

  const refreshReservations = useCallback(() => {
    const start = filterDay.format('YYYYMMDD');
    const end = filterDay.format('YYYYMMDD');
    return get<CRM.ReservationDetailed[]>(
      `${salesPath}/${userGroupId}/reservations?start=${start}&end=${end}`,
    ).then(setReservations);
  }, [salesPath, filterDay, userGroupId]);

  useEffect(() => {
    refreshReservations();
  }, [refreshReservations, filterDay]);

  const users = getUsersOfGroup(userGroupId);
  const filteredReservations = useMemo(
    () =>
      {
        return reservations
          .filter(inDisplayedMethods(displayedMethods))
          .filter(inFilters(reservationsFilters))
          .filter(ofUser(userId));
      },
    [reservations, displayedMethods, reservationsFilters, userId],
  );

  const filteredReservationsForSum = useMemo(
    () => reservations.filter(ofUser(userId)),
    [reservations, userId],
  );

  const receiptsMethodsSum = useMemo(() => sumReceiptsOrRefundsMethods(reservations, 'receipt', filterDay, userGroupId), [
    reservations,
    userGroupId,
    filterDay
  ]);
  const refundsMethodsSum = useMemo(() => sumReceiptsOrRefundsMethods(reservations, 'refund', filterDay, userGroupId), [
    reservations,
    userGroupId,
    filterDay
  ]);

  const [isPinCodeAuthenticated, setIsPinCodeAuthenticated] = useState(isAdminUser ? true : false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const loadSalesPinCode = useCallback(() => {
    if(isAdminUser) {
      get<CRM.UserGroupDocument>(`${userGroupPath}/`+ auth.userGroup?.userGroupId).then((userGroup :CRM.UserGroupDocument) => {
        setSalesPinCode(userGroup.salesPinCode)
      });
    } else {
      setSalesPinCode("");
    }
  }, [setSalesPinCode, userGroupPath, auth, isAdminUser]);

  useEffect(loadSalesPinCode, [loadSalesPinCode]);

  const checkPinCode = (event: any) => {
    event.preventDefault();
    get<string>(`${userGroupPath}/checkadminsalespincode/`+event.target.elements.code.value).then((isValid) => {
      if(isValid === 'true') {
        setIsPinCodeAuthenticated(true);
        setErrorMessage(
          ""
        );
      } else {
        setErrorMessage(
          "Mauvais Code..."
        );
      }
    });
  };

  const changePinCode = (event: any) => {
    event.preventDefault();
    updateUserGroup(event.target.elements.code.value);
  };

  const updateUserGroup = useCallback(
    async (salesPinCode: string) => {
      try {
        await post<string>(
          `${userGroupPath}/updateadminsalespincode/`+ salesPinCode + `/` + auth.userGroup?.userGroupId
        );
        notify('Le code pin a été mis à jour', 'success');
      } catch (err) {
        notify('Impossible de mettre à jour le code pin', 'error');
      }
    },
    [notify, userGroupPath, auth.userGroup],
  );

  return (
    <div className={classes.root} id={'salesView'}>
      {!isPinCodeAuthenticated && <Box
      id={'salesCodeInput'}
      component={'form'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      onSubmit={checkPinCode}
      mt={2}
      >
      <div>
        <TextField
          required
          helperText={errorMessage}
          id="outlined-required"
          name="code"
          label="Code Pin"
          placeholder="0000"
        />
        <Button type="submit" variant="text">Accéder à la fiche de caisse</Button>
      </div>
      </Box>}
      {isAdminUser && <Box
      id={'salesCodeButton'}
      component={'form'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      onSubmit={changePinCode}
      mt={2}
      >
      <div>
        <TextField
          required
          id="outlined-required"
          name="code"
          label="Changer le Code Pin"
          value={salesPinCode !== undefined ? salesPinCode : ""}
          onChange={event => setSalesPinCode(event.target.value)}
        />
        <Button type="submit" variant="text">Modifier l'Accès</Button>
      </div>
      </Box>}


      {isPinCodeAuthenticated && <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mt={2}
      >
        <Box display={'flex'} alignItems={'center'}>
          <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              label="Date"
              InputLabelProps={{style: {fontWeight: 'bold'}}}
              value={filterDay.startOf('day')}
              disabled={isAdminUser ? false : true}
              onChange={date => {
                date &&
                  date.isValid() &&
                  date.isBetween(VALID_DATE.START, VALID_DATE.END) &&
                  setFilterDay(date.tz('Europe/Paris', false).utc(true));
              }}
              autoOk
              required
          />
          &nbsp;
          <DbDocumentSelect
            label="Groupe"
            selectedId={userGroupId}
            docs={groups}
            disabled={isAdminUser ? false : true}
            onChange={group => {
              group && setUserGroupId(group._id);
              setUserId(undefined);
            }}
          />
          &nbsp;
          <DbDocumentSelect
            className="inputSize-lg"
            label={userId ? "Utilisateur" : 'Tous'}
            selectedId={userId}
            docs={users}
            onChange={user => {
              user ? setUserId(user._id) : setUserId(undefined);
            }}
            allowNone
          />
          <IconButton onClick={print} title={'imprimer'}>
            <PrintIcon />
          </IconButton>
          <IconButton
            onClick={refreshReservations}
            title={'actualiser la liste'}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
        <Box>
          <u>Nombre de reservations</u>: {filteredReservationsForSum.length}
        </Box>
      </Box>}

      {isPinCodeAuthenticated && <div>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="menu caisse"
        >
          <Tab value="synthesis" label="Synthèse Caisse Opérateur" />
          <Tab value="canoes" label="Canoës" />
          <Tab value="supervised" label="Encadrées" />
          <Tab value="additionalsales" label="Ventes Add" />
          <Tab value="refunds" label="Remboursements" />
        </Tabs>
        <Divider variant="middle" />
        <TabPanel value={selectedTab} index="synthesis">
          <Box
            display={'inline-table'}
            alignItems={'normal'}
            justifyContent={'space-between'}
            style={{ width: '50%', float:'left' }}
            mt={2}
          >
            <SalesByPaymentMethod
              receiptsMethodsSum={receiptsMethodsSum}
            />
          </Box>
          <Box
            display={'flex'}
            alignItems={'normal'}
            justifyContent={'space-between'}
            style={{ width: '45%', float: 'right' }}
            mt={2}
          >
            <SalesSheet
              day={filterDay}
              userGroupId={userGroupId}
              receiptsMethodsSum={receiptsMethodsSum['all']}
              refundsMethodsSum={refundsMethodsSum['all']}
            />
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index="canoes">
          {/*<Box
            display={'inline-table'}
            alignItems={'normal'}
            justifyContent={'space-between'}
            style={{ width: '50%' }}
            mt={2}
          >
            <ReservationsFilter
              filters={reservationsFilters}
              onChange={setReservationsFilters}
              displayedMethods={displayedMethods}
              onDisplayedMethodsChange={setDisplayedMethods}
          />
          </Box>*/}
          <Box flexGrow={0.85}>
            {<RichGrid
              style={{
                paddingRight:'10px', //compensate for scrollbar width
                /*
                position:'fixed',
                background:'white',
                marginTop:'-70px',
                height:'70px',
                width:'inherit',
            paddingRight:'35px'*/}}
              columns={salesTableProvider(userGroupId).columns}
              rows={filteredReservations.filter(r => r.activity.kind === "canoe")}
              renderRow={reservation => {
                if(sumPaymentReceipts(
                  reservation.payment,
                  userGroupId
                ) === 0) {
                  return (<Grid style={{display:'none'}}></Grid>);
                }
                return(
                  <div className='Grid-row'>
                    <Grid
                      container
                      className={classesgrid && classesgrid.root}
                      spacing={1}
                      onClick={() => { window.open(`/reservations/${reservation._id}`) }}
                    >
                      {salesTableProvider(userGroupId).columns.map(column => {
                        const Cell = column.Cell;
                        return (
                          <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                            {Cell && <Cell value={reservation} handlers={{} as RowHandlers} />}
                            {column.renderCell &&
                              column.renderCell(reservation, {} as RowHandlers)}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
              );
              }}
            />}
          </Box>
          {/*<Box mt={2}>
            <TableContainer component={Paper}>
              <Table>
                <ReservationGridHeader />
                <TableBody>
                  {filteredReservations.map(reservation => (
                    <ReservationGridItem key={reservation._id} value={reservation} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>*/}
        </TabPanel>
        <TabPanel value={selectedTab} index="supervised">
        <Box flexGrow={0.85}>
            {<RichGrid
              style={{
                paddingRight: '10px', //compensate for scrollbar width
              }}
              columns={salesTableProvider(userGroupId).columns}
              rows={filteredReservations.filter(r => r.activity.kind === "supervised")}
              renderRow={reservation => {
                if (sumPaymentReceipts(
                  reservation.payment,
                  userGroupId
                ) === 0) {
                  return (<Grid style={{ display: 'none' }}></Grid>);
                }
                return (
                  <div className='Grid-row'>
                    <Grid
                      container
                      className={classesgrid && classesgrid.root}
                      spacing={1}
                      onClick={() => { window.open(`/reservations/${reservation._id}`) }}
                    >
                      {salesTableProvider(userGroupId).columns.map(column => {
                        const Cell = column.Cell;
                        return (
                          <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                            {Cell && <Cell value={reservation} handlers={{} as RowHandlers} />}
                            {column.renderCell &&
                              column.renderCell(reservation, {} as RowHandlers)}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                );
              }}
            />}
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index="additionalsales">
        <Box mt={2} display='flex' textAlign='center'>
          <h3 style={{width:'100%'}}>A Venir...</h3>
        </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index="refunds">
        <Box
            display={'inline-table'}
            alignItems={'normal'}
            justifyContent={'space-between'}
            style={{ width: '50%', float:'left' }}
            mt={2}
          >
            <SalesByPaymentMethod
              receiptsMethodsSum={refundsMethodsSum}
              type={'refund'}
            />
          </Box>
        </TabPanel>
      </div>}
    </div>
  );
}
