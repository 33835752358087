import React, { useCallback, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Arrays } from '@crm/utils';
import { sortBy } from 'lodash';
import { GridItemProps } from '../../components/GridItem';
import { PositiveNumberField } from '../../components/fields/PositiveNumberField.component';
import { CrmTableCell, CrmTableRow } from '../../components/Table.component';
import DeleteIcon from '@material-ui/icons/Delete';

interface Props {
  value: CRM.SpotTimings;
  onChange: (timings: CRM.SpotTimings) => any;
}

export const SpotTimingsForm: React.FC<Props> = ({ value, onChange }) => {
  const [hour, setHour] = useState<number>(9);
  const [minute, setMinute] = useState<number>(0);

  const addEvery = useCallback(() => {
    const alreadyExists = value.every.some(
      every => every.hour === hour && every.minute === minute,
    );
    if (alreadyExists) {
      return;
    }
    const newEvery: CRM.SpotTimingsOccurence[] = [
      ...value.every,
      {
        hour,
        minute,
        capacity: 80,
        allowOverBooking: false,
      },
    ];
    onChange({ ...value, every: sortBy(newEvery, ['hour', 'minute']) });
  }, [onChange, value, minute, hour]);

  const updateEvery = useCallback(
    (every: CRM.SpotTimingsOccurence, index: number) => {
      const newEvery = Arrays.replace(value.every, index, every);
      onChange({ ...value, every: newEvery });
    },
    [onChange, value],
  );

  const deleteEvery = useCallback(
    (index: number) => {
      const newEvery = Arrays.remove(value.every, index);
      onChange({ ...value, every: newEvery });
    },
    [onChange, value],
  );

  return (
    <Grid container spacing={3}>
      <Grid item container spacing={3}>
        <Grid item xs={4} sm={2} md={1}>
          <PositiveNumberField
            size={'small'}
            label="Heure"
            variant="outlined"
            value={hour}
            onChange={e => setHour(Number(e.target.value))}
            required
          />
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <PositiveNumberField
            size={'small'}
            label="Minute"
            variant="outlined"
            value={minute}
            onChange={e => setMinute(Number(e.target.value))}
            required
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<AddIcon />}
            onClick={addEvery}
          >
            Ajouter un horaire
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={8} md={6}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <CrmTableCell>Heure</CrmTableCell>
                <CrmTableCell>Capacité</CrmTableCell>
                <CrmTableCell>Surbooking</CrmTableCell>
                <CrmTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {value.every.map((every, index) => (
                <EveryRow
                  key={every.hour}
                  value={every}
                  onChange={value => updateEvery(value, index)}
                  onDelete={() => deleteEvery(index)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

interface EveryRowProps extends GridItemProps<CRM.SpotTimingsOccurence> {}
const EveryRow: React.FC<EveryRowProps> = props => {
  function triggerOnChange(override: Partial<CRM.SpotTimingsOccurence>) {
    props.onChange && props.onChange({ ...props.value, ...override });
  }
  return (
    <CrmTableRow>
      <CrmTableCell>
        {String(props.value.hour).padStart(2, '0')}h
        {String(props.value.minute).padStart(2, '0')}
      </CrmTableCell>
      <CrmTableCell>
        <PositiveNumberField
          label="Capacité"
          variant="outlined"
          size="small"
          value={props.value.capacity}
          onChange={e => {
            triggerOnChange({
              capacity: Number(e.target.value),
            });
          }}
          fullWidth
          required
        />
      </CrmTableCell>
      <CrmTableCell>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              onChange={event => {
                triggerOnChange({
                  allowOverBooking: event.target.checked,
                });
              }}
              checked={props.value.allowOverBooking}
            />
          }
          label="Surbooking"
          labelPlacement="end"
        />
      </CrmTableCell>
      <CrmTableCell>
        <IconButton onClick={() => props.onDelete!(props.value)}>
          <DeleteIcon />
        </IconButton>
      </CrmTableCell>
    </CrmTableRow>
  );
};
