import { ButtonMenu } from '../../components/ButtonMenu.component';
import { MenuItem } from '@material-ui/core';
import React from 'react';
import FileSaver from 'file-saver';
import { humanizeKind } from '../../activities/utils/translations';
import { countBookingPeople } from '@crm/utils';
import dayjs from 'dayjs';

interface Props {
  reservations?: CRM.ReservationDetailed[];
}

export const ViewActions: React.FC<Props> = ({ reservations = [] }) => {
  return (
    <ButtonMenu>
      <MenuItem onClick={() => exportCSV(reservations)}>Export .csv</MenuItem>
    </ButtonMenu>
  );
};

const exportCSV = (reservations: CRM.ReservationDetailed[]) => {
  const parts = [
    'type; date; email; nom; prénom; téléphone; activité; nbr participants\n',
    ...reservations.map(asLine),
  ];
  const blob = new Blob(parts, {
    type: 'text/csv;charset=utf-8',
  });
  FileSaver.saveAs(blob, 'reservations.csv');
};

const asLine = (r: CRM.ReservationDetailed) =>
  [
    humanizeKind(r.activity.kind),
    dayjs(r.activityDate).local().format('YYYY-MM-DD HH:mm'),
    r.customer.email,
    r.customer.name,
    r.customer.firstname,
    (r.customer.phone || '').startsWith('+')
      ? `\\${r.customer.phone}`
      : r.customer.phone,
    r.activity.name,
    countBookingPeople(r.people),
    '\n',
  ].join(';');
