import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'react-tiny-fab/dist/styles.css';
import { groupBy, isEmpty } from 'lodash';
import { DateRangeSelect } from '../components/DateRangeSelect.component';
import { humanizeKind } from '../activities/utils/translations';
import { ViewActions } from './components/viewActions.button';
import { Filters } from './filters/Filters.component';
import { useReservations } from './reservations.context';
import { ActivityFilter } from './filters/ActivityFilter.component';
import { ReservationGrid } from './reservationGrid.component';
import { NextDaysStripSelect } from '../components/nextDaysStripSelect.component';
//import { GoToTop } from '../components/fab/GoToTop.fab';
import { containsText, ofActivities } from './filters/predicates';
import dayjs from 'dayjs';
//import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),

    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export function ReservationsStartedView() {
  const classes = useStyles();
  const {
    updates,
    updatesClear,
    updatesSubmit,
    reservations,
    refreshReservations,
    filterDays,
    filterText,
    setFilterDays,
    setFilterText,
    setFilterDaysToDay,
    setMatchesStartDayTwo
  } = useReservations();
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  //const location = useLocation();

  useEffect(() => {
    if(filterDays.start === undefined) {
      filterDays.start = dayjs();
    }
    if(filterDays.end === undefined) {
      filterDays.end = dayjs();
    }
    setMatchesStartDayTwo(1);// find departures at day two
    refreshReservations();
  }, [refreshReservations, filterDays, setMatchesStartDayTwo]);

  const filtered = (reservations || [])
    .filter(containsText(filterText))
    .filter(ofActivities(selectedActivities));
  const byKindReservations = groupBy(filtered, res => res.activity.kind);
  const kinds = Object.keys(byKindReservations) as CRM.ActivityKind[];

  return (
    <div className={classes.root}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
      >
        <Box display={'flex'} alignItems={'flex-start'}>
          <NextDaysStripSelect
            onChange={setFilterDaysToDay}
            value={filterDays}
          />
          <DateRangeSelect range={filterDays} onChange={setFilterDays} />
        </Box>
        <Filters filterText={filterText} onChange={setFilterText} />
      </Box>
      <Divider />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <ActivityFilter
          value={selectedActivities}
          onChange={setSelectedActivities}
        />
        <ViewActions reservations={reservations} />
      </Box>
      <Divider />
      <Box>
        {reservations === undefined && <CircularProgress size="1.2em" />}
        {reservations && kinds.length === 0 && (
          <span>Aucune réservation trouvée</span>
        )}
        {kinds.map(kind => (
          <Box mb={7} mt={2} key={kind}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant={'h6'}>
                <b>{humanizeKind(kind)}</b>
              </Typography>
              {!isEmpty(updates) && (
                <ButtonGroup
                  size={'small'}
                  aria-label="contained primary button group"
                >
                  <Button variant="contained" onClick={updatesClear}>
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={updatesSubmit}
                  >
                    Valider
                  </Button>
                </ButtonGroup>
              )}
            </Box>
            <ReservationGrid
              status={
                /*( location.pathname.includes('wait-to-paid') || location.pathname.includes('refunds') ) ? 
                undefined :*/'started'}
              kind={kind}
              rows={byKindReservations[kind]}
              active={1}
            />
          </Box>
        ))}
      </Box>
      {/*<GoToTop />*/}
    </div>
  );
}
