import React, { useCallback, useEffect, useState } from 'react';
import { Button, Chip, Divider, FormHelperText, Grid, Typography } from '@material-ui/core';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EuroIcon from '@material-ui/icons/Euro';
import { useActivity, useActivityValueUpdate } from './activity.context';
import { SaveButton } from './saveButton.component';
import { PeriodPricingTable } from './pricing/prices.component';
import { PositiveNumberField } from '../../components/fields/PositiveNumberField.component';

export const PricingForm: React.FC = () => {
  const { activity, update, save } = useActivity();
  const instructorUpdater = useActivityValueUpdate('instructorPriceInEuro');
  const pricingUpdater = useActivityValueUpdate('pricing');
  //const instructorAvailablePricesUpdater = useActivityValueUpdate('instructorAvailablePricesInEuro');
  const [priceToAdd, setPriceToAdd] = useState<string>('');

  const handleDeleteInstructorPrice = useCallback((index: number) => {
    if(
      activity.instructorAvailablePricesInEuro === undefined || 
      (activity.instructorAvailablePricesInEuro && 
      activity.instructorAvailablePricesInEuro.length) <= index) {
        return;
    }
    const prices: number[] = activity.instructorAvailablePricesInEuro?.filter((price, _index) => _index !== index) || undefined;
    console.log(activity.instructorAvailablePricesInEuro,index)
    update({instructorAvailablePricesInEuro : prices});
  },[update, activity.instructorAvailablePricesInEuro]);

  const handleAddInstructorPrice = useCallback((price: number) => {
    //console.log(price)
    if(price < 0) {
      return;
    }
    const prices: number[] = activity.instructorAvailablePricesInEuro?.concat([price]) || [price];
    update({
      instructorAvailablePricesInEuro : prices
    });
    //console.log(prices, activity.instructorAvailablePricesInEuro);
  },[update, activity.instructorAvailablePricesInEuro]);

  useEffect(() => {
    if(!activity.instructorPriceInEuro) {
      instructorUpdater(0);
    }
  },[instructorUpdater, activity.instructorPriceInEuro]);

  return (
    <form onSubmit={save}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">
            <EuroIcon /> Tarification
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PeriodPricingTable
            value={activity.pricing}
            onChange={pricingUpdater}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            <EmojiPeopleIcon /> Moniteur
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PositiveNumberField
            label="opt. moniteur par défaut (€)"
            variant="outlined"
            value={activity.instructorPriceInEuro}
            onChange={e => {
              instructorUpdater(Number(e.target.value));
            }}
          />
          <FormHelperText>Mettre 0 pour désactiver l'option moniteur</FormHelperText>
        </Grid>
        {activity.kind === 'canoe' && (
          <Grid item xs={12}>
          { (
            activity.instructorAvailablePricesInEuro === undefined ||
            (activity.instructorAvailablePricesInEuro && activity.instructorAvailablePricesInEuro.length === 0)
          ) && (
            <FormHelperText>Aucun prix = prix de l'option par defaut activée pour les réservations</FormHelperText>
          )}
          { 
          activity.instructorAvailablePricesInEuro !== undefined && 
          activity.instructorAvailablePricesInEuro.map((price, index) => 
            <Chip label={price+'€'} key={index} variant="outlined" onDelete={() => {handleDeleteInstructorPrice(index);}} />
          )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignContent='flex-start'
          >
            {activity.kind === 'canoe' && (
              <Grid item>
                <PositiveNumberField
                  label="ajout option moniteur (€)"
                  variant="outlined"
                  onChange={e => {
                    setPriceToAdd(e.target.value);
                  }}
                />
              </Grid>
            )}
            {activity.kind === 'canoe' && (
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {handleAddInstructorPrice(Number(priceToAdd))}}
                >
                  + Ajouter l'option
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SaveButton />
        </Grid>
      </Grid>
    </form>
  );
};
