import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { ActivitiesView } from './activities.view';
import { CreateActivityView } from './create/createActivity.view';
import { UpdateActivityView } from './create/updateActivity.view';

export const ActivityRouter = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <ActivitiesView />
        </Route>
        <Route path={`${path}/create`}>
          <CreateActivityView />
        </Route>
        <Route path={`${path}/:id`}>
          <UpdateActivityView />
        </Route>
      </Switch>
    </div>
  );
};
