import React from 'react';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../components/TabPanel.component';
import { ActivityForm } from './activity.form';
import { PricingForm } from './pricing.form';
import { PeriodsForm } from './periods/periods.form';
import { IntegrationsForm } from './integrations/integrations.form';
import { useActivity } from './activity.context';
import { TimeSlotsForm } from './timeslots/timeslots.form';


export const CreateActivityTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState('activity');
  const { activity}  = useActivity();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="menu des réservations"
      >
        <Tab value="activity" label="Activité" />
        {
          //activity?.kind !== "supervised" &&
        <Tab value="periods" label="Périodes" />
        }
        <Tab value="pricing" label="Tarifs" />
        <Tab value="integrations" label="Intégrations" />
        {
          activity?.kind === "supervised" &&
          <Tab value="timeslots" label="Créneaux" />
        }
      </Tabs>
      <Divider variant="middle" />
      <TabPanel value={selectedTab} index="activity">
        <ActivityForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="periods">
        <PeriodsForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="pricing">
        <PricingForm />
      </TabPanel>
      <TabPanel value={selectedTab} index="integrations">
        <IntegrationsForm />
      </TabPanel>
      {
        activity?.kind === "supervised" &&
        <TabPanel value={selectedTab} index="timeslots">
          <TimeSlotsForm />
        </TabPanel>
      }
    </div>
  );
};
