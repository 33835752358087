import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { Box, Button, Divider, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TodayIcon from '@material-ui/icons/Today';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface OneDayStripSelectProps {
  value: Dayjs;
  onChange: (value: Dayjs) => any;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),

    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const NextDay = ({
  date,
  selected,
  onClick,
}: {
  date: string;
  selected: boolean;
  onClick?: any;
}) => {
  return (
    <Button onClick={onClick}>
      <Typography
        variant="h5"
        gutterBottom
        color={selected ? 'textPrimary' : 'textSecondary'}
        style={{ opacity: selected ? 1 : 0.7 }}
      >
        {date}
      </Typography>
    </Button>
  );
};
export const OneDayStripSelect = ({
  value,
  onChange,
}: OneDayStripSelectProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const today = dayjs();
  const yesterday = today.subtract(1, 'day');
  const tomorrow = today.add(1,'day');
  const intwodays = today.add(2,'day');

  const minusOne = value.subtract(1, 'day');
  const plusOne = value.add(1, 'day');
  const plusTwo = value.add(2, 'day');

  function getDayLabelFromDayjs(d: dayjs.Dayjs) {
    if(d.isSame(yesterday, 'day')) {
      return 'Hier';
    } else
    if(d.isSame(today, 'day')) {
      return 'Aujourd\'hui';
    } else if(d.isSame(tomorrow, 'day')) {
      return 'Demain';
    } else if(d.isSame(intwodays, 'day')) {
      return 'Après-demain';
    }
    return d.format('DD MM YYYY');

  }

  return (
    <Box display="flex" className={classes.root}>
      <Button size={'small'} onClick={() => setIsOpen(true)}>
        <TodayIcon />
      </Button>
      <KeyboardDatePicker
        autoOk
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        variant="inline"
        inputVariant="outlined"
        label="With keyboard"
        format="MM/dd/yyyy"
        value={today}
        style={{ width: '0px', visibility: 'hidden' }}
        InputAdornmentProps={{ position: 'start', disableTypography: true }}
        onChange={onChange as (date: MaterialUiPickersDate) => void}
      />
      <NextDay
        date={getDayLabelFromDayjs(minusOne)}
        selected={false}
        onClick={() => onChange(minusOne)}
      />
      <Divider orientation="vertical" flexItem />
      <NextDay
        date={getDayLabelFromDayjs(value)}
        selected={true} />
      <Divider orientation="vertical" flexItem />
      <NextDay
        date={getDayLabelFromDayjs(plusOne)}
        selected={false}
        onClick={() => onChange(plusOne)}
      />
      <Divider orientation="vertical" flexItem />
      <NextDay
        date={getDayLabelFromDayjs(plusTwo)}
        selected={false}
        onClick={() => onChange(plusTwo)}
      />
    </Box>
  );
};
