import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useUserGroup } from './userGroup.context';

export const SaveButton: React.FC = () => {
  const { userGroup, save } = useUserGroup();

  const cannotSaveMessages = [];
  if (isEmpty(userGroup.login)) {
    cannotSaveMessages.push(`Renseigner l'identifiant`);
  }
  if (isEmpty(userGroup.role)) {
    cannotSaveMessages.push(`Renseigner le rôle`);
  }

  return (
    <Box display="flex" flexDirection="column">
      <Button
        color="secondary"
        variant="contained"
        disabled={cannotSaveMessages.length > 0}
        onClick={save}
      >
        Enregistrer
      </Button>
      {cannotSaveMessages.length > 0 && (
        <ul>
          {cannotSaveMessages.map((message, index) => (
            <li key={index}>
              <Typography color={'error'}>{message}</Typography>
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
};
