import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { ReservationsView } from './reservations.view';
import { CreateReservationView } from './create/createReservation.view';
import { UpdateReservationView } from './create/updateReservation.view';
import { ProvideReservations } from './reservations.context';
import { ReservationsStartedView } from './reservationsStarted.view';
import { ReservationsArchivedView } from './reservationsArchived.view';
import { ReservationsTrashView } from './reservationsTrash.view';

export const ReservationRouter = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <ProvideReservations>
        <Switch>
          <Route path={`${path}/gift-voucher`}>
            <ReservationsTrashView />
          </Route>
          <Route path={`${path}/futur`}>
            <ReservationsView />
          </Route>
          <Route path={`${path}/cancelled`}>
            <ReservationsTrashView />
          </Route>
          <Route path={`${path}/active`}>
            <ReservationsStartedView />
          </Route>
          <Route path={`${path}/in-activity`}>
            <ReservationsStartedView />
          </Route>
          <Route path={`${path}/wait-to-paid`}>
            <ReservationsTrashView />
          </Route>
          <Route path={`${path}/refunds`}>
            <ReservationsTrashView />
          </Route>
          <Route path={`${path}/archive`}>
            <ReservationsArchivedView />
          </Route>
          <Route path={`${path}/trash`}>
            <ReservationsTrashView />
          </Route>
          <Route path={`${path}/create`}>
            <CreateReservationView />
          </Route>
          <Route path={`${path}/:id`}>
            <UpdateReservationView />
          </Route>
        </Switch>
      </ProvideReservations>
    </div>
  );
};
