import React, { useCallback, useEffect, useState } from 'react';
import { 
  Box, 
  Divider, 
  /*Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, */
  Grid, 
  Typography, 
  //Typography, 
  makeStyles} from "@material-ui/core";
import { RichGrid } from '../../../components/table/RichGrid.component';
import { RowHandlers } from '../../../components/table/Table';
import { activityTimeSlotsTableProvider/*, supervisedTimeSlotTableProvider*/ } from '../../../reservations/reservationGrid.columns';
//import PrintIcon from '@material-ui/icons/Print';
import dayjs from 'dayjs';
import { DateRange, DateRangeSelect } from '../../../components/DateRangeSelect.component';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useDomainPath, useAuth } from '../../../auth/auth.context';
import { Theme as DefaultTheme, Theme } from '@material-ui/core/styles/createMuiTheme';
import { get, httpDelete } from '../../../fetch';
import { AlertDialog } from '../../../components/alerts/AlertDialog.component';
import { TimeSlotsDialog } from './timeSlotsDialog.component';
import { NextDaysStripSelect } from '../../../components/nextDaysStripSelect.component';
import { Filters } from '../../../reservations/filters/Filters.component';
import { useDomains } from '../../../hooks/useDomain';


interface TimeSlotsGridProps {
    //onChange: (selectedTimeSlot: CRM.ActivityTimeSlotDetailed) => any;
    setSelectedActivityTimeSlot: React.Dispatch<React.SetStateAction<CRM.ActivityTimeSlotDetailed>>;
    selectedActivityTimeSlot: CRM.ActivityTimeSlotDetailed;
    allowDelete?: boolean;
    allowCreate?: boolean;
    refresh?: number;
    activitiesIdFilter?: string[];
    defaultActivityId?: string;
}
const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
  rootSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['400'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
  rootDisabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderRadius: '5px',
    border: '1px solid lightgrey',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'not-allowed',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  }
}));

/*function PrintElem(elem: string)
{
    var win = window.open('', '_blank');
    if(win) {
      var css= [];

      for (var sheeti= 0; sheeti<document.styleSheets.length; sheeti++) {
          var sheet= document.styleSheets[sheeti];
          var rules= sheet.cssRules ;
          for (var rulei= 0; rulei<rules.length; rulei++) {
              var rule= rules[rulei];
              if ('cssText' in rule)
                  css.push(rule.cssText);
          }
      }
      win.document.head.style.cssText = css.join('\n');
      win.document.body.innerHTML = '<body onload=`window.print()`><style>'+css.join('\n')+'\n button { display: none ! important; } </style><div style="margin-top:20px;width:1000px">'+document?.getElementById(elem)?.innerHTML+'</div></body>';
      let grid = win.document.getElementById('richGrid');
      if(grid !== null)  { 
        grid.style.overflowY = 'auto' ;
      }
      win.print();
    }

    return true;
}*/

// #TODO should be called in timeslots.form and timeslots.view
export function TimeSlotsGrid({ /*onChange,*/
  setSelectedActivityTimeSlot,
  selectedActivityTimeSlot,
  allowDelete,
  allowCreate,
  refresh,
  activitiesIdFilter,
  defaultActivityId
}: TimeSlotsGridProps) {
  const path = useDomainPath(`/activity-timeslots`);
  const auth = useAuth();
  const domains = useDomains();
  const classesgrid: ClassNameMap<string> = useStylesGrid();
  //const [groups, setGroups] = useState<CRM.Dictionary<string>>({});
  //const userGroupPath = useDomainPath('/user-group');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  
  const [activityTimeSlots, setActivityTimeSlots] = useState<CRM.ActivityTimeSlotDetailed[]>([]);
  const [filteredActivityTimeSlots, setFilteredActivityTimeSlots] = useState<CRM.ActivityTimeSlotDetailed[]>([]);
  const [filterTimeSlotsPeriod, setFilterTimeSlotsPeriod] = useState<DateRange>({ start: dayjs().startOf('day'), end: dayjs().endOf('year') });
  const defaultDateDeparture = dayjs().add(1, 'day').hour(9).minute(0).toDate();
  const defaultDateArrival = dayjs().add(1, 'day').hour(15).minute(0).toDate();
  const defaultActivity: CRM.ActivityDocument = {
    _id: defaultActivityId || ''
  } as any;
  const defaultActivityTimeSlot: CRM.ActivityTimeSlotDetailed = {
    _id: '',
    domain: auth.userGroup?.domainId || '',
    activity: defaultActivity,
    timeSlotDeparture: defaultDateDeparture,//#TODO replace by other form fields
    timeSlotArrival: defaultDateArrival,
    bookedPeople: 0,
    bookedPeopleWithMonitor: 0,
    maxPeople: 16,
    maxPeopleWithMonitor: 4,
    minAutonomousPeople: 3,
    constrainReservations: true,
    monitor: 'bruno',
    closeReservations: false
  }
  const [reservationsViewDialogOpen, setReservationsViewDialogOpen] = useState(false);
  const [selectedTimeSlotReservations, setSelectedTimeSlotReservations] = useState<CRM.ReservationDetailed[]>([]);
  //const [selectedActivityTimeSlot, setSelectedActivityTimeSlot] = useState<CRM.ActivityTimeSlotDetailed>(defaultActivityTimeSlot);
  //const [selectedActivityTimeSlotName, setSelectedActivityTimeSlotName] = useState<string>("");
  const [currentViewingActivityTimeSlot, setCurrentViewingActivityTimeSlot] = useState<CRM.ActivityTimeSlotDetailed | undefined>(undefined);
  const [filterText, setFilterText] = useState<string>("");

  const filterTimeSlotsFromText = useCallback(
    (text: string) => {
      setFilterText(text);
      setFilteredActivityTimeSlots(activityTimeSlots.filter(ts => ts.monitor.includes(text)));
    },[activityTimeSlots]);

  const selectActivityTimeSlot = useCallback(
    (detailed: CRM.ActivityTimeSlotDetailed) => {
      //setSelectedActivityTimeSlotName(detailed.activity.displayName);
      setSelectedActivityTimeSlot(detailed/*{
        _id: detailed._id,
        domain: detailed.domain,
        activity: detailed.activity._id,
        timeSlotDeparture: detailed.timeSlotDeparture,
        timeSlotArrival: detailed.timeSlotArrival,
        maxPeople: detailed.maxPeople,
        maxPeopleWithMonitor: detailed.maxPeopleWithMonitor,
        minAutonomousPeople: detailed.minAutonomousPeople,
        constrainReservations: detailed.constrainReservations,
        monitor: detailed.monitor,
        closeReservations: detailed.closeReservations
      }*/);
      //onChange(detailed);
    }, [/*onChange*/setSelectedActivityTimeSlot]);

    const toggleSelectActivityTimeSlot = useCallback((activityTimeSlot: CRM.ActivityTimeSlotDetailed) => {
      if (selectedActivityTimeSlot._id === activityTimeSlot._id) {
        //setSelectedActivityTimeSlotName('');
        selectActivityTimeSlot(defaultActivityTimeSlot);
        //onChange(defaultActivityTimeSlot);
      } else {
        if (activityTimeSlot.domain.valueOf() === auth.userGroup?.domainId.valueOf()) {
          selectActivityTimeSlot(activityTimeSlot);
        }
      }
      // #TODO fix eventually these dependancies for not causes infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      selectedActivityTimeSlot,
      //setSelectedActivityTimeSlotName,
      selectActivityTimeSlot,
      auth.userGroup?.domainId,
      //onChange
    ]); 

  const refreshTimeSlots = useCallback(
    () => get<CRM.ActivityTimeSlotDetailed[]>(`${path}/${'all'}`).then(
      timeslots => {
        if (!timeslots) {
          return;
        }
        if (filterTimeSlotsPeriod.start && filterTimeSlotsPeriod.end) {
          timeslots = timeslots.filter(t =>
            filterTimeSlotsPeriod.start && dayjs(t.timeSlotDeparture).isAfter(filterTimeSlotsPeriod.start.startOf('day')) &&
            filterTimeSlotsPeriod.end && dayjs(t.timeSlotDeparture).isBefore(filterTimeSlotsPeriod.end.endOf('day')))
        }
        if(activitiesIdFilter && activitiesIdFilter.length > 0) {
          timeslots = timeslots.filter(t => activitiesIdFilter.includes(t.activity._id));
        }
        //set other params
        setActivityTimeSlots(timeslots);
        setFilteredActivityTimeSlots(timeslots);
      }),
    [path, filterTimeSlotsPeriod, activitiesIdFilter],
  );

  useEffect(() => {
    if(refresh) {
      refreshTimeSlots();
    }
  }, [ refresh, refreshTimeSlots]);

  const reservationsViewDialog = useCallback((activity?: string, id?: string, doNotOpenDialog?:boolean) => {
    get<CRM.ReservationDetailed[]>(`${path}/reservations/${activity}/${id || ''}?omitInstructors=true`).then((reservations) => {
      setSelectedTimeSlotReservations(reservations);
      if(doNotOpenDialog === undefined || doNotOpenDialog === false) {
        setReservationsViewDialogOpen(true);
      }
    }
    )
  }
    , [
      setSelectedTimeSlotReservations,
      setReservationsViewDialogOpen,
      path
    ]
  );

  const reservationsViewDialogClose = useCallback(
    () => setReservationsViewDialogOpen(false),
    [setReservationsViewDialogOpen],
  );

  const deleteTimeSlotDialog = useCallback(() => setDeleteDialogOpen(true), [
    setDeleteDialogOpen,
  ]);
  const deleteTimeSlotDialogClose = useCallback(
    () => { setDeleteDialogOpen(false); selectActivityTimeSlot(defaultActivityTimeSlot);},
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDeleteDialogOpen],
  );
  const deleteTimeSlot = useCallback(
    () =>
      httpDelete(`${path}/${selectedActivityTimeSlot._id}`).then(() =>
        {
          refreshTimeSlots(); 
          deleteTimeSlotDialogClose();
          setSelectedActivityTimeSlot(defaultActivityTimeSlot);
          //onChange(defaultActivityTimeSlot);
        }
      ),
      // #TODO fix eventually these dependancies for not causes infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      refreshTimeSlots, 
      selectedActivityTimeSlot._id, 
      path, 
      deleteTimeSlotDialogClose, 
      setSelectedActivityTimeSlot
    ],
  );

  useEffect(() => {
    refreshTimeSlots();
  }, [refreshTimeSlots]);



  return (
    <div>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'wrap'}
        >
          <Box display={'flex'} alignItems={'flex-start'}>
            <Typography
              variant="h5"
              gutterBottom
              style={{ paddingTop: '0.95em' }}
            >
            </Typography>
            <NextDaysStripSelect
              onChange={(d: dayjs.Dayjs, de?: dayjs.Dayjs) => {
                setFilterTimeSlotsPeriod({start: d, end:de ? de : d});
              }}
              value={filterTimeSlotsPeriod}
            />
            <DateRangeSelect range={filterTimeSlotsPeriod} onChange={setFilterTimeSlotsPeriod} />
          </Box>
          <Filters filterText={filterText} onChange={filterTimeSlotsFromText} />
        </Box>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box flexGrow={0.85}>

          {<RichGrid
            style={{
              paddingRight: '10px', //compensate for scrollbar width
            }}
            columns={
              activityTimeSlotsTableProvider(
                auth.userGroup?.domainId || '', 
                //reservationsViewDialog, 
                toggleSelectActivityTimeSlot, 
                auth, 
                domains,
                allowCreate? allowCreate : false,
                allowDelete? deleteTimeSlotDialog: undefined)
                .columns
              }
            rows={filteredActivityTimeSlots}
            renderRow={activityTimeSlot => {
              return (
                <div className='Grid-row'>
                  <Grid
                    container
                    className={
                      classesgrid &&
                      /*activityTimeSlot.domain.valueOf() !== auth.userGroup?.domainId.valueOf() ? classesgrid.rootDisabled :*/
                      (activityTimeSlot._id === selectedActivityTimeSlot._id ? classesgrid.rootSelected : classesgrid.root)
                    }
                    spacing={1}
                    onClick={() => {
                      /*if(selectedActivityTimeSlot._id === activityTimeSlot._id) {
                        setSelectedActivityTimeSlotName('');
                        setSelectedActivityTimeSlot(defaultActivityTimeSlot);
                        onChange(defaultActivityTimeSlot);
                      } else {
                        if(activityTimeSlot.domain.valueOf() === auth.userGroup?.domainId.valueOf()) {
                          selectActivityTimeSlot(activityTimeSlot);
                        }
                      }*/
                      setCurrentViewingActivityTimeSlot(activityTimeSlot as CRM.ActivityTimeSlotDetailed);
                      reservationsViewDialog(activityTimeSlot.activity._id, activityTimeSlot._id)
                    }}
                  >
                    {activityTimeSlotsTableProvider(
                      auth.userGroup?.domainId || '',
                      //reservationsViewDialog, 
                      toggleSelectActivityTimeSlot,
                      auth,
                      domains,
                      allowCreate ? allowCreate : false,
                      allowDelete ? deleteTimeSlotDialog : undefined)
                      .columns.map(column => {
                        const Cell = column.Cell;
                        return (
                          <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                            {Cell && <Cell value={activityTimeSlot} handlers={{} as RowHandlers} />}
                            {column.renderCell &&
                              column.renderCell(activityTimeSlot, {} as RowHandlers)}
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              );
            }}
          />}
        </Box>
      </Grid>
      {<TimeSlotsDialog
        timeSlotReservations={selectedTimeSlotReservations}
        activityName={currentViewingActivityTimeSlot?.activity.displayName || ''}
        activityDate={currentViewingActivityTimeSlot?.timeSlotDeparture || new Date()}
        activityTimeSlot={currentViewingActivityTimeSlot}
        dialogOpen={reservationsViewDialogOpen}
        dialogClose={reservationsViewDialogClose}
        onUpdate={() => {
          reservationsViewDialog(
            selectedTimeSlotReservations[0]?.activity._id, 
            selectedTimeSlotReservations[0]?.activityTimeSlot, 
            true
            );
        }}
      />}
        <AlertDialog
          accept={deleteTimeSlot}
          cancel={deleteTimeSlotDialogClose}
          open={deleteDialogOpen}
          title={'Confirmer'}
          content={
             "La suppression d'un créneau est irréversible, toutes les réservations liées en corbeille seront également supprimées, voulez-vous vraiment continuer ?"
          }
        />
      </div>
  );
};