import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { UpdateUserGroupView } from './create/updateUserGroup.view';
import { CreateUserGroupView } from './create/createUserGroup.view';
import { UserGroupsView } from './userGroups.view';

export const UserGroupRouter = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <UserGroupsView />
        </Route>
        <Route path={`${path}/create`}>
          <CreateUserGroupView />
        </Route>
        <Route path={`${path}/:id`}>
          <UpdateUserGroupView />
        </Route>
      </Switch>
    </div>
  );
};
