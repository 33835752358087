import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { SalesView } from './sales.view';

export const SalesRouter = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <SalesView />
        </Route>
      </Switch>
    </div>
  );
};
