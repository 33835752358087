import React, { useCallback } from 'react';
import { Box, Grid, MenuItem } from '@material-ui/core';
import { LabelImportant as LabelImportantIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { ButtonMenu } from '../components/ButtonMenu.component';
import { GridItemProps, useGridItemStyles } from '../components/GridItem';

export function UserGroupGridItem({
  value,
}: GridItemProps<CRM.UserGroupDocument>) {
  const classes = useGridItemStyles();
  const history = useHistory();

  const handleUpdate = useCallback(() => {
    history.push(`/user-groups/${value._id}`);
  }, [value._id, history]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={3}>
        {value.displayName}
      </Grid>
      <Grid item xs={3}>
        {value.login}
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" alignItems="end">
          <LabelImportantIcon className="iconGold" />
          {value.role}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <ButtonMenu>
          <MenuItem onClick={handleUpdate}>Modifier</MenuItem>
        </ButtonMenu>
      </Grid>
    </Grid>
  );
}
