import { Dialog, DialogTitle, Button, DialogContent, Box, Typography, Grid, makeStyles, IconButton, ButtonGroup } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { RichGrid } from "../../../components/table/RichGrid.component";
import { RowHandlers } from "../../../components/table/Table";
import { supervisedTimeSlotTableProvider } from "../../../reservations/reservationGrid.columns";
import { Theme as DefaultTheme, Theme } from '@material-ui/core/styles/createMuiTheme';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import { useAuthUsers, useDomainPath } from "../../../auth/auth.context";
import { get, post } from "../../../fetch";
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { useDomains } from "../../../hooks/useDomain";

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
  rootSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['400'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  }
}));

/*function PrintElem(elem: string)
{
    var win = window.open('', '_blank');
    if(win) {
      var css= [];

      for (var sheeti= 0; sheeti<document.styleSheets.length; sheeti++) {
          var sheet= document.styleSheets[sheeti];
          var rules= sheet.cssRules ;
          for (var rulei= 0; rulei<rules.length; rulei++) {
              var rule= rules[rulei];
              if ('cssText' in rule)
                  css.push(rule.cssText);
          }
      }
      win.document.head.style.cssText = css.join('\n');
      win.document.body.innerHTML = '<body onload=`window.print()`><style>'+css.join('\n')+'\n button { display: none ! important; } </style><div style="margin-top:20px;width:1000px">'+document?.getElementById(elem)?.innerHTML+'</div></body>';
      let grid = win.document.getElementById('richGrid');
      if(grid !== null)  { 
        grid.style.overflowY = 'auto' ;
      }
      win.print();
    }

    return true;
}*/

interface Props {
  timeSlotReservations: CRM.ReservationDetailed[];
  activityName: string;
  activityDate: Date;
  dialogOpen: boolean;
  dialogClose: () => void;
  onUpdate: () => void;
  activityTimeSlot?: CRM.ActivityTimeSlotDetailed;
}


export const TimeSlotsDialog: React.FC<Props> = ({ timeSlotReservations, dialogOpen, activityName,activityDate, dialogClose, onUpdate, activityTimeSlot }) => {

  const userGroupPath = useDomainPath('/user-group');
  const timeSlotPath = useDomainPath(`/activity-timeslots`);
  const reservationPath = useDomainPath('/reservation');
  const classesgrid: ClassNameMap<string> = useStylesGrid();
  const [groups, setGroups] = useState<CRM.Dictionary<string>>({});
  const domains = useDomains();
  const { domain } = useAuthUsers();
  const [checkInReservations, setCheckInReservations] = useState<Partial<CRM.ReservationDetailed>[]>([]);
  //const [resetValidateCheckin, setResetValidateCheckIn] = useState<boolean>(false);
  
  useEffect(() => {
    get<CRM.UserGroupDocument[]>(userGroupPath+'/allDomains').then(_groups => {
      setGroups(
        _groups.reduce((acc, group) => {
          acc[group._id] = group.displayName;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
    });
  }, [setGroups, userGroupPath]);

  const AddRemoveCheckInReservationId = useCallback((Id: string, checked: boolean) => {
    //setResetValidateCheckIn(false);
    setCheckInReservations(
      checkInReservations.filter(r => !r?._id?.includes(Id))
      .concat([{_id: Id, status: checked === true ? 'started' : 'booked'}])
    );
  },[setCheckInReservations, checkInReservations/*, setResetValidateCheckIn*/]);

  const updateCheckInReservations = useCallback(async () => {
    await post<Partial<CRM.ReservationDetailed>[]>(
      `${reservationPath}/checkIn`,
      checkInReservations
    );
    setCheckInReservations([]);
    //setResetValidateCheckIn(true);
    onUpdate();
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[checkInReservations, reservationPath/*setResetValidateCheckIn, setCheckInReservationsIds*/]);

  const printList = useCallback(() => {
    if (timeSlotReservations.length === 0 || (timeSlotReservations.length > 0 && !timeSlotReservations[0].activityTimeSlot)) {
      return;
    }
    get<string>(timeSlotPath+'/peopleListHtml/'+ timeSlotReservations[0].activityTimeSlot).then(result => {
      var win = window.open('', '_blank');
      if(win) {
        win.document.body.innerHTML = '<body onload=`window.print()`><div style="margin-top:20px;">'+result+'</div></body>';
        win.print();
      }
    });
  }, [
    timeSlotReservations, timeSlotPath,
  ]);

  return (
    <Dialog
    onClose={dialogClose}
    open={dialogOpen}
    maxWidth={'lg'}
    fullWidth={true}
    id='timeSlotsList'
  >
      <DialogTitle>
      <Box display="flex" alignItems="center">
          <Box flexGrow={1} >
            {activityName} Du&nbsp;
            {new Date(activityDate).toLocaleDateString()}  à&nbsp;
            {new Date(activityDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            &nbsp;
            <Button onClick={e => printList()/*PrintElem('timeSlotsList')*/}>
              <PrintIcon />
            </Button>
          </Box>
        <Box>
          <IconButton onClick={dialogClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Box style={{ minHeight: '70vh', overflow: 'hidden' }} className='App'>
        <Grid container spacing={2} xs={12} >
          <Grid item>
            <Typography variant="subtitle1">
              RESERVATIONS
            </Typography>
          </Grid>
          {activityTimeSlot && (
            <Grid item>
              <Link target="_blank" to={"/reservations/create?activityid=" + activityTimeSlot.activity._id + "&timeslotid=" + activityTimeSlot._id}>
                <AddIcon />
              </Link>
            </Grid>
          )}
        </Grid>
        {checkInReservations.length > 0 /*&& resetValidateCheckin == false*/ &&  (
                <ButtonGroup
                  size={'small'}
                  aria-label="contained primary button group"
                >
                  <Button variant="contained" onClick={() => {
                      setCheckInReservations([]);
                    }}>
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={updateCheckInReservations}
                  >
                    Valider check-in
                  </Button>
                </ButtonGroup>
          )}
        <RichGrid
          style={{
            paddingRight: '10px', //compensate for scrollbar width
          }}
          columns={supervisedTimeSlotTableProvider( groups, domains, domain, AddRemoveCheckInReservationId, checkInReservations).columns}
          rows={timeSlotReservations}
          renderRow={selectedTimeSlotReservation => {
            return (
              <div className='Grid-row'>
                <Grid
                  container
                  className={classesgrid.root}
                  spacing={1}
                >
                  {supervisedTimeSlotTableProvider(groups, domains, domain, AddRemoveCheckInReservationId, checkInReservations).columns.map(column => {
                    const Cell = column.Cell;
                    return (
                      <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                        {Cell && <Cell value={selectedTimeSlotReservation} handlers={{} as RowHandlers} />}
                        {column.renderCell &&
                          column.renderCell(selectedTimeSlotReservation, {} as RowHandlers)}
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            );
          }}
        />
      </Box>
    </DialogContent>
    {/*<DialogActions>
      <Button onClick={dialogClose}>Fermer</Button>
    </DialogActions>*/}
  </Dialog>
  );

}
