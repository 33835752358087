import React from 'react';
import dayjs from 'dayjs';
import { Box, Grid, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { GridItemProps } from '../../components/GridItem';
import { PaymentMethodSelect } from '../../components/PaymentMethodSelect.component';
//import { PositiveNumberField } from '../../components/fields/PositiveNumberField.component';
import { useAuthUsers, useAuth } from '../../auth/auth.context';
import { isAdmin } from '../../auth/roles';
import { PositiveDecimalField } from '../../components/fields/PositiveDecimalField.component';

const ReceiptGridItem: React.FC<
  GridItemProps<CRM.PaymentReceipt> & { isOverPaid?: boolean }
> = props => {

  const auth = useAuth();
  const isAdminUser = isAdmin(auth.userGroup);
  const { groups, loggedUser } = useAuthUsers();
  const today = dayjs().tz('Europe/Paris', false);

  function getGroupNameFromId(id:string | undefined) {
    return groups.find(g => g._id === id)?.displayName || 'point de vente inconnu';
  }

  function isToday(date:dayjs.Dayjs) {
    return today.isSame(date, 'day');
  }

  function triggerOnChange(override: Partial<CRM.PaymentReceipt>) {
    props.onChange && props.onChange({ ...props.value, ...override });
  }

  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item xs={3}>
        <PositiveDecimalField
          disabled={
            (loggedUser?.userGroup === props.value.userGroupId
              && isToday(dayjs(props.value.date)))
            || (isAdminUser)
            ? false : true
          }
          label="Montant"
          variant="outlined"
          value={props.value.amountInEuro.toFixed(2)}
          onChange={e => {
            const value = Number(e.target.value);
            triggerOnChange({ amountInEuro: value });
          }}
          error={props.isOverPaid}
        />
      </Grid>
      <Grid item xs={4}>
        <PaymentMethodSelect
          disabled={
            (loggedUser?.userGroup === props.value.userGroupId
              && isToday(dayjs(props.value.date)))
            || (isAdminUser)
            ? false : true
          }
          value={props.value.method}
          onChange={e => {
            triggerOnChange({ method: e });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          title={getGroupNameFromId(props.value.userGroupId)}
          label="Date"
          variant="outlined"
          disabled={true}
          value={dayjs(props.value.date).format('DD/MM/YYYY')}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          title={'supprimer'}
          size={'small'}
          onClick={() => props.onDelete!(props.value)}
          disabled={
            (loggedUser?.userGroup === props.value.userGroupId
              && isToday(dayjs(props.value.date)))
            || (isAdminUser)
            ? false : true
          }
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

interface Props {
  isOverPaid?: boolean;
  value: CRM.PaymentReceipt[];
  onChange: (value: CRM.PaymentReceipt[]) => any;
}

export const PaymentReceipts: React.FC<Props> = ({
  children,
  isOverPaid,
  value,
  onChange,
}) => {
  const updateReceipt = (updating: CRM.PaymentReceipt, index: number) => {
    const newReceipts = [
      ...value.slice(0, index),
      updating,
      ...value.slice(index + 1, value.length),
    ];
    onChange(newReceipts);
  };

  const deleteReceipt = (index: number) => {
    const newReceipts = [
      ...value.slice(0, index),
      ...value.slice(index + 1, value.length),
    ];
    onChange(newReceipts);
  };

  return (
    <Box>
      {value.map((receipt, index) => (
        <ReceiptGridItem
          key={index}
          value={receipt}
          isOverPaid={isOverPaid}
          onChange={value => updateReceipt(value, index)}
          onDelete={() => deleteReceipt(index)}
        />
      ))}
      {children}
    </Box>
  );
};
