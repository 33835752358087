import React, { Context, createContext, useCallback, useContext } from 'react';
import { AlertPopup, Color } from '../components/alerts/AlertPopup.component';

interface AlertsContext {
  notify: (message: string, color: Color) => void;
}
interface AlertParams {
  message: string;
  color: Color;
}

const alertsContext: Context<AlertsContext> = createContext(null) as any;

export function useAlerts() {
  return useContext(alertsContext);
}

export const ProvideAlerts: React.FC = props => {
  const [params, setParams] = React.useState<AlertParams>({
    message: '',
    color: 'success',
  });

  const [opened, setOpened] = React.useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpened(false);
  };

  const notify = useCallback((message, color) => {
    setOpened(true);
    setParams({ message, color });
  }, []);

  return (
    <alertsContext.Provider value={{ notify }}>
      {props.children}
      <AlertPopup
        message={params?.message}
        color={params?.color}
        open={opened}
        handleClose={handleClose}
      />
    </alertsContext.Provider>
  );
};
