import { TextField } from '@material-ui/core';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  onChange: (value: CRM.UserGroupDocument[]) => any;
  availableGroups: CRM.UserGroupDocument[];
  selectedIds: string[];
}

export const DomainGroupAutocomplete: React.FC<Props> = ({
  onChange,
  availableGroups,
  selectedIds,
}) => {
  return (
    <Autocomplete
      fullWidth
      multiple
      filterSelectedOptions
      options={availableGroups}
      getOptionLabel={option => option.displayName}
      groupBy={option => option.role}
      value={availableGroups.filter(g => selectedIds.includes(g._id))}
      onChange={(event: any, newValue: CRM.UserGroupDocument[] | null) => {
        onChange(newValue!);
      }}
      renderInput={params => (
        <TextField {...params} variant="outlined" label="Points de vente" />
      )}
    />
  );
};
