import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { useReservation, useReservationActivity } from './reservation.context';
import { SaveButton } from '../../components/SaveButton.component';
import { useReservationHash } from './reservationHash.context';
import { ContractButton } from './components/contractButton.component';
import { canoeCheckWarningBreakedRules } from '@crm/utils';
import { AlertDialog } from '../../components/alerts/AlertDialog.component';

export const SaveReservationButton: React.FC = () => {
  const { save, reservation, reload } = useReservation();
  const { activity, activityTimeSlot } = useReservationActivity();
  const { isReady, hasImportantChanges, errors } = useReservationHash();
  const cannotSaveMessages = [...errors];
  const [warnings, setWarnings] = useState<string[]>([]);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  const warningDialog = useCallback(() => setWarningDialogOpen(true), [
    setWarningDialogOpen,
  ]);
  const warningDialogClose = useCallback(
    () => setWarningDialogOpen(false),
    [setWarningDialogOpen],
  );

  if (!isReady) {
    cannotSaveMessages.push(
      `Vérification en cours sur la mise à jour du contrat...`,
    );
  }

  const saveReservation = useCallback(() => {
    if (
      reservation._id &&
      isEmpty(cannotSaveMessages) &&
      isReady &&
      hasImportantChanges
    ) {
      save().then(() => {
        setTimeout(reload, 2000);
      });
    } else {
      save();
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    save,
    reload,
    reservation._id,
    hasImportantChanges,
    isReady
  ]);


  const checkWarningsAndSave = useCallback(() => {
    const activityPricing = reservation.pricing || activity?.pricing;
    let _warnings: string[] = [];
    if (activityPricing && activity?.kind === "supervised") {
      _warnings = canoeCheckWarningBreakedRules(
        activityPricing,
        reservation.people || {},
        (
          activityTimeSlot?.constrainReservations === true &&
          activityTimeSlot?.minAutonomousPeople
        ) ? (activityTimeSlot?.minAutonomousPeople) : 0,
      );
      setWarnings(_warnings);
    }
    if(_warnings.length === 0) {
      setWarnings([]);
      saveReservation();
    } else {
      warningDialog();
    }

  },[
    reservation.pricing,
    activity?.pricing,
    activityTimeSlot?.constrainReservations,
    activityTimeSlot?.minAutonomousPeople,
    activity?.kind,
    reservation?.people,
    saveReservation,
    warningDialog
  ]);
  const warningDialogElement = (<AlertDialog
    accept={()=> {saveReservation();warningDialogClose(); }}
    cancel={warningDialogClose}
    open={warningDialogOpen}
    title={'Cette inscription ne respecte pas les règles suivantes: \n Souhaitez vous quand même valider ?'}
    content={
      warnings.map(
        (message) => ` - ${message}`
      ).join('\n')/*+'\n\n Souhaitez vous quand même valider ?'*/
    }
  />);

  if (isEmpty(cannotSaveMessages) && isReady && hasImportantChanges) {
    return (
      <div>
          {warningDialogElement}
          <SaveButton cannotSaveMessages={[]} onClick={checkWarningsAndSave} />
          <ContractButton fullWidth>
          </ContractButton>
      </div>
    );
  }

  return <div>
    {warningDialogElement}
    <SaveButton cannotSaveMessages={cannotSaveMessages} onClick={checkWarningsAndSave} />
  </div>;
};
