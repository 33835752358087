import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  canoeEquipementOf,
  countBookingPeople,
  labelOfArrival,
  numberOrZero,
} from '@crm/utils';
import { CrmTableRow, SmallTableCell } from './Table.component';

interface Props {
  reservations: CRM.ReservationOverview[];
  dateKey: 'activityArrivalDate' | 'activityDate';
  isArrivalDetails?: boolean;
}

interface Details {
  people: number;
  c2: number;
  c3: number;
  k1: number;
}

type DetailsByArrivalLabel = CRM.Dictionary<Details>;

function mergeDetails(
  detail: Details,
  reservation: CRM.ReservationOverview,
  isArrivalDetails?: boolean
): Details {
  const people =
    (isArrivalDetails && reservation.options?.peopleAtArrivalCount !== undefined
      && reservation.options?.peopleAtArrivalCount !== null
      && reservation.options?.peopleAtArrivalCount >= 0) ?
      reservation.options?.peopleAtArrivalCount
    : countBookingPeople(reservation.people, reservation.options.instructors);
  const c2 = canoeEquipementOf('c2', reservation.options);
  const c3 = canoeEquipementOf('c3', reservation.options);
  const k1 = canoeEquipementOf('k1', reservation.options);

  return {
    people: (detail?.people || 0) + people,
    c2: (detail?.c2 || 0) + numberOrZero(c2),
    c3: (detail?.c3 || 0) + numberOrZero(c3),
    k1: (detail?.k1 || 0) + numberOrZero(k1),
  };
}

export const DayDetailsTable: React.FC<Props> = ({ dateKey, reservations, isArrivalDetails }) => {
  const dayDetails = useMemo(() => {

    return reservations.reduce((details, reservation) => {
      const label = labelOfArrival(reservation[dateKey]);
      details[label] = mergeDetails(details[label], reservation, isArrivalDetails);
      return details;
    }, {} as DetailsByArrivalLabel);
  }, [reservations, dateKey, isArrivalDetails]);


  const dayTotal = useMemo(() => {
    return Object.values(dayDetails).reduce(
      (total, arrivalDetails) => {
        total.people += arrivalDetails.people;
        total.c2 += arrivalDetails.c2;
        total.c3 += arrivalDetails.c3;
        total.k1 += arrivalDetails.k1;
        return total;
      },
      { people: 0, c2: 0, c3: 0, k1: 0 } as Details,
    );
  }, [dayDetails]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <SmallTableCell align="center">Horaire</SmallTableCell>
            <SmallTableCell align="center">Personnes</SmallTableCell>
            <SmallTableCell align="center">C2</SmallTableCell>
            <SmallTableCell align="center">C3</SmallTableCell>
            <SmallTableCell align="center">K1</SmallTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(dayDetails).map(([label, details]) => (
            <CrmTableRow key={label}>
              <SmallTableCell align="center">{label}</SmallTableCell>
              <SmallTableCell align="center">{details.people}</SmallTableCell>
              <SmallTableCell align="center">{details.c2}</SmallTableCell>
              <SmallTableCell align="center">{details.c3}</SmallTableCell>
              <SmallTableCell align="center">{details.k1}</SmallTableCell>
            </CrmTableRow>
          ))}
          <CrmTableRow>
            <SmallTableCell align="center">
              <b>Total</b>
            </SmallTableCell>
            <SmallTableCell align="center">
              <b>{dayTotal.people}</b>
            </SmallTableCell>
            <SmallTableCell align="center">
              <b>{dayTotal.c2}</b>
            </SmallTableCell>
            <SmallTableCell align="center">
              <b>{dayTotal.c3}</b>
            </SmallTableCell>
            <SmallTableCell align="center">
              <b>{dayTotal.k1}</b>
            </SmallTableCell>
          </CrmTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
