import React, { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { EditableCell } from './EditableCell.component';
import { DeparturesChoiceComponent } from '../create/components/departuresChoice.component';
import { useDomainPath } from '../../auth/auth.context';
import { get } from '../../fetch';

interface Props {
  activityId: string;
  value?: Dayjs;
  onChange: (date: Dayjs) => void;
  className?: string;
}

export const EditableDepartureMenu: React.FC<Props> = ({
  activityId,
  value,
  onChange,
}) => {
  const source: CRM.requestSource = 'backoffice';
  const schedulesPath = useDomainPath('/schedules');
  const [dayDepartures, setDayDepartures] = useState<
    CRM.ActivityOccurrence[] | undefined
  >();

  const fetchDayDepartures = useCallback(
    (date: Dayjs) => {
      const dateFormated = date.format('YYYYMMDD');
      get<CRM.ActivityOccurrence[]>(
        `${schedulesPath}/activities/${activityId}?day=${dateFormated}&source=${source}`,
      ).then(res => {
        setDayDepartures(res);
      });
    },
    [activityId, schedulesPath],
  );

  useEffect(() => fetchDayDepartures(value || dayjs()), [
    fetchDayDepartures,
    value,
  ]);

  return (
    <DeparturesChoiceComponent
      dayDepartures={dayDepartures}
      value={value}
      selectedDay={value || dayjs()}
      onChange={onChange}
    />
  );
};

interface Props {
  activityId: string;
  activityDate?: Dayjs;
  onChange: (date: Dayjs) => void;
  className?: string;
}

export const EditableDepartureCell: React.FC<Props> = ({
  className,
  activityDate,
  activityId,
  onChange,
}) => {
  const [isHourSelected, setIshourSelected] = useState<boolean>(false);

  return (
    <EditableCell
      content={
        <span>{activityDate && dayjs(activityDate).format('HH:mm')}</span>
      }
      className={className}
      closeCell={isHourSelected}
      onClosed={()=>{setIshourSelected(false)}}
    >
      <EditableDepartureMenu
        value={activityDate}
        activityId={activityId}
        onChange={(date)=>{onChange(date); setIshourSelected(true);}}
      />
    </EditableCell>
  );
};
