import { TextField } from '@material-ui/core';
import React, { useCallback } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

export const PositiveNumberField: React.FC<
  TextFieldProps & { minValue?: number }
> = ({ onClick, onChange, minValue, ...props }) => {
  const onChangePositive = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // event.target.value = Number(event.target.value).toFixed(2);
      //console.log(event.target.value, Number(event.target.value))
      const value = Number(event.target.value);
      if (onChange && value >= (minValue || 0))
        return onChange && onChange(event);
    },
    [onChange, minValue],
  );

  const onClickAutoSelect = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      const target = event.target as any;
      target.select && target.select();
      onClick && onClick(event);
    },
    [onClick],
  );

  const acceptingDots = useCallback(
    (event: any) => {
      if (event.key === '.') {
        setTimeout( () => {
            event.target.value+= '.00';
        }, 4);
        event.preventDefault();
      };

    },
    [],
  );

  return (
    <TextField
      type="number"
      {...props}
      onChange={onChangePositive}
      onClick={onClickAutoSelect}
      onKeyDown={acceptingDots}
    />
  );
};
