import React, { useCallback } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { ButtonMenu } from '../components/ButtonMenu.component';
import { useGridItemStyles } from '../components/GridItem';
import { useHistory } from 'react-router-dom';
import { humanizeKind } from './utils/translations';

interface Props {
  value: CRM.PublicActivity;
  domains: CRM.EntityName[];
}
//export const TimeSlotsDialog: React.FC<Props> = ({  }) => {

export const ActivityGridItem : React.FC<Props> = ({value, domains}) => {
  const classes = useGridItemStyles();
  const history = useHistory();
  //const domains = useDomains();

  const handleUpdate = useCallback(() => {
    history.push(`/activities/${value.id}`);
  }, [value.id, history]);

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={2}>
        {value.name}
      </Grid>
      <Grid item xs={2}>
        {
        value.sharedByDomain ? 
        ('Partagée par '+(domains.find(d => d._id.valueOf() === value.sharedByDomain?.valueOf())?.displayName || 'Non Identifiable')) : 
        (domains.find(d => d._id.valueOf() === value.domain?.valueOf())?.displayName || 'Non Identifiable')
        }
      </Grid>
      <Grid item xs={2}>
        {value.displayName}
      </Grid>
      <Grid item xs={2}>
        {humanizeKind(value.kind)}
      </Grid>
      <Grid item xs={1}>
        {Number(value.estimatedTimeMinutes / 60).toFixed(1)}h
      </Grid>
      <Grid item xs={1}>
        {value.overDays}
      </Grid>
      <Grid item xs={1}>
        <ButtonMenu>
          <MenuItem onClick={handleUpdate}>Modifier</MenuItem>
        </ButtonMenu>
      </Grid>
    </Grid>
  );
}
