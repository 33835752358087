import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';

interface Props {
  domains: CRM.Dictionary<string>;
  includingDomainIds: string[];
  onChange: (domainIds: string[]) => any;
}

export const DomainsFilter = ({
  domains,
  includingDomainIds,
  onChange,
}: Props) => {
  const toggleDomain = (domainId: string, include: boolean) => {
    const alreadyIncluding = includingDomainIds.includes(domainId);
    if (alreadyIncluding && !include) {
      onChange(includingDomainIds.filter(including => including !== domainId));
    } else if (!alreadyIncluding && include) {
      onChange(includingDomainIds.concat(domainId));
    }
  };

  return (
    <Box>
      <Typography>
        <b>Entités</b>
      </Typography>
      <Box>
        {Object.entries(domains).map(([id, name]) => (
          <FormControlLabel
            key={id}
            label={name}
            labelPlacement="end"
            control={
              <Checkbox
                color="secondary"
                onChange={event => {
                  toggleDomain(id, event.target.checked);
                }}
                checked={includingDomainIds.includes(id)}
              />
            }
          />
        ))}
      </Box>
    </Box>
  );
};
