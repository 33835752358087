import React, { useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
  filterText?: string;
  onChange: (query: string) => any;
}

export const Filters = ({ filterText, onChange }: Props) => {
  const [filter, setFilter] = useState(filterText || '');

  return (
    <Box display="flex">
      <TextField
        variant="outlined"
        label="rechercher..."
        value={filter}
        onChange={event => setFilter(event.target.value)}
        onKeyDown={e => {
          e.keyCode === 13 && onChange(filter);
        }}
      />
      &nbsp;
      <Button
        variant={'contained'}
        color={'secondary'}
        size="small"
        onClick={() => onChange(filter)}
      >
        <SearchIcon />
      </Button>
    </Box>
  );
};
