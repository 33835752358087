import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from './Alert.component';

interface Props {
  message: string;
  color: Color;
  open: boolean;
  handleClose: () => void;
}

export type Color = 'success' | 'info' | 'warning' | 'error';

export const AlertPopup: React.FC<Props> = ({
  open,
  handleClose,
  color,
  message,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Alert onClose={handleClose} severity={color}>
        {message}
      </Alert>
    </Snackbar>
  );
};
