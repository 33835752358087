import React, { useCallback, useMemo } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { Arrays } from '@crm/utils';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';

export function YearlyPeriods(props: {
  year: number;
  pricingPeriods: CRM.YearlyPricingPeriods;
  onPeriodsChange: (value: CRM.PricingPeriod[]) => any;
}) {
  const year = props.year;
  const { minDate, maxDate } = useMemo(() => {
    const thisYear = dayjs().year(year);
    return {
      minDate: thisYear.startOf('year'),
      maxDate: thisYear.endOf('year'),
    };
  }, [year]);
  const { pricingPeriods, onPeriodsChange } = props;
  const { periods } = pricingPeriods;

  const updateStartDate = useCallback(
    (index, date) => {
      if (!date?.isValid() || date.isAfter(periods[index].endDate)) {
        return;
      }
      const newPeriods = Arrays.replace(periods, index, {
        ...periods[index],
        startDate: date.utc(true),
      });
      onPeriodsChange(newPeriods);
    },
    [periods, onPeriodsChange],
  );

  const updateEndDate = useCallback(
    (index, date) => {
      if (!date?.isValid() || date.isBefore(periods[index].startDate)) {
        return;
      }
      const newPeriods = Arrays.replace(periods, index, {
        ...periods[index],
        endDate: date.utc(true),
      });
      onPeriodsChange(newPeriods);
    },
    [periods, onPeriodsChange],
  );

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography>
              <u>Pré saison</u>
            </Typography>
            &nbsp;
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              label={`Début`}
              value={props.pricingPeriods.periods[0].startDate}
              onChange={date => updateStartDate(0, date)}
              minDate={minDate}
              maxDate={maxDate}
              autoOk
              required
            />
            &nbsp;
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              label={`Fin`}
              value={props.pricingPeriods.periods[0].endDate}
              onChange={date => updateEndDate(0, date)}
              minDate={minDate}
              maxDate={maxDate}
              autoOk
              required
            />
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={3}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography>
              <u>Haute saison</u>
            </Typography>
            &nbsp;
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              label={`Début`}
              value={props.pricingPeriods.periods[1].startDate}
              onChange={date => updateStartDate(1, date)}
              minDate={minDate}
              maxDate={maxDate}
              autoOk
              required
            />
            &nbsp;
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              label={`Fin`}
              value={props.pricingPeriods.periods[1].endDate}
              onChange={date => updateEndDate(1, date)}
              minDate={minDate}
              maxDate={maxDate}
              autoOk
              required
            />
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={3}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography>
              <u>Après saison</u>
            </Typography>
            &nbsp;
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              label={`Début`}
              value={props.pricingPeriods.periods[2].startDate}
              onChange={date => updateStartDate(2, date)}
              minDate={minDate}
              maxDate={maxDate}
              autoOk
              required
            />
            &nbsp;
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              label={`Fin`}
              value={props.pricingPeriods.periods[2].endDate}
              onChange={date => updateEndDate(2, date)}
              minDate={minDate}
              maxDate={maxDate}
              autoOk
              required
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
