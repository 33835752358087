import React, { useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { allReservationStatus, translateStatus } from '@crm/utils';

interface ReservationStatusSelectProps {
  reservation: Partial<CRM.ReservationDocument>;
  activity: CRM.PublicActivity | undefined;
  onChange: (value: CRM.ReservationStatus) => any;
}

export function ReservationStatusSelect({
  onChange,
  reservation,
  activity
}: ReservationStatusSelectProps) {
  useEffect(() => {
    if (!reservation.status) {
      onChange('booked');
    }
  }, [onChange, reservation]);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="Status-select-label">Statut</InputLabel>
      <Select
        labelId="Status-select-label"
        value={reservation.status || ''}
        label="Statut"
        disabled={
          activity?.kind === 'supervised' && (
          reservation.status === 'cancelled' || reservation.status === 'gift-voucher'
          ) 
        }
        onChange={evt => onChange(evt.target.value as CRM.ReservationStatus)}
      >
        {allReservationStatus.filter(r => reservation.payment?.amountInEuro === 0/*Means no refund needed*/ ? r !== "cancelled" : r).map(status => (
          <MenuItem key={status} value={status}>
            {translateStatus(status)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
