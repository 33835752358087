import { Box, CircularProgress, Divider, Grid, IconButton, makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import { TabPanel } from '../components/TabPanel.component';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth, useAuthUsers, useDomainPath } from "../auth/auth.context";
import { DateRange, DateRangeSelect } from "../components/DateRangeSelect.component";
import { DbDocumentSelect } from "../components/DbDocumentSelect.components";
import { allMethods, SalesByPaymentMethod } from "../sales/salesByPaymentMethod.form";
import { get } from "../fetch";
import { sumReceiptsMethod } from "../sales/sumReceiptsMethod";
import { countBookingPeople, roundMoney, sumPaymentReceipts } from "@crm/utils";
import { SalesResume } from "../sales/salesResume.component";
// import { SalesTurnover } from "../sales/sales.turnover.component";
import { RichGrid } from "../components/table/RichGrid.component";
import { salesTableProvider } from "../reservations/reservationGrid.columns";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';
import { RowHandlers } from "../components/table/Table";
import PrintIcon from '@material-ui/icons/Print';

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.3, 0),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0),

    '& > *': {
      margin: theme.spacing(2, 0),
    },
  },
}));
type sumType = 'receipt' | 'refund' | 'turnover';

function sumReceiptsOrRefundsMethods(reservations: CRM.ReservationStats[], type: sumType, activities:CRM.ActivityDocument[], userGroupId?: string, filterDays?:DateRange) {
  //const receiptsMethodsSum: { [method: string]: number } = {};
  const receiptsMethodsSum: { [activityKind: string]: CRM.ReceiptsMethodsSum} = {};
  receiptsMethodsSum['all'] = {};
  let activityKinds: Array<string> = ['all'];

  reservations.forEach(reservation => {
    allMethods.forEach(method => {
      if (
        ((type === 'receipt' || type === 'turnover') && reservation.payment.receipts)
        || (type === 'refund' && reservation.payment.refunds)) {

          // console.log(reservation.payment.receipts, filterDays)
        if (filterDays && filterDays.start && filterDays.end) {
          if (type === 'receipt' || type === 'turnover') {
            reservation.payment.receipts = reservation.payment.receipts.filter(
              r => r.date
                && (dayjs(r.date).isBefore(filterDays?.end || dayjs()) || dayjs(r.date).isSame((filterDays?.end || dayjs()), 'day'))
                && (dayjs(r.date).isAfter(filterDays?.start || dayjs()) || dayjs(r.date).isSame((filterDays?.start || dayjs()), 'day'))
            );
          }
          if (type === 'refund' || type === 'turnover') {
            if(method === 'cash') console.log( reservation.payment.refunds)
            reservation.payment.refunds = reservation.payment.refunds.filter(
              r => r.date
              && (dayjs(r.date).isBefore(filterDays?.end || dayjs()) || dayjs(r.date).isSame((filterDays?.end || dayjs()), 'day'))
              && (dayjs(r.date).isAfter(filterDays?.start || dayjs()) || dayjs(r.date).isSame((filterDays?.start || dayjs()), 'day'))
            );
            if(method === 'cash') console.log('after', reservation.payment.refunds)
          }
        }
        const activityKind = activities.find(a => a._id === reservation.activity)?.kind as string || 'canoe';

        if(activityKinds.find(k =>  k.includes(activityKind)) === undefined) {
          activityKinds.push(activityKind);
          // prevent undefined array index with this kind
          if(!receiptsMethodsSum[activityKind]) {
            receiptsMethodsSum[activityKind] = {};
          }
        }

        const cash = type === 'turnover' ? 
          (
            sumReceiptsMethod(reservation.payment.receipts, method, userGroupId) 
            - sumReceiptsMethod(reservation.payment.refunds, method, userGroupId))
         : sumReceiptsMethod(
          type === 'receipt' ?
            reservation.payment.receipts : reservation.payment.refunds,
          method,
          userGroupId
        );
        /*receiptsMethodsSum[method] = (receiptsMethodsSum[method] || 0) + cash;
        receiptsMethodsSum['total'] = (receiptsMethodsSum['total'] || 0) + cash;*/

        receiptsMethodsSum[activityKind][method] = (receiptsMethodsSum[activityKind][method] || 0) + cash;
        receiptsMethodsSum[activityKind]['total'] = (receiptsMethodsSum[activityKind]['total'] || 0) + cash;
      }
    });
  });
  /*allMethods.forEach(method => {
    receiptsMethodsSum[method] = roundMoney(receiptsMethodsSum[method] || 0);
  });
  receiptsMethodsSum['total'] = roundMoney(receiptsMethodsSum['total'] || 0);*/
  activityKinds.forEach(kind => {
    allMethods.forEach(method => {
      receiptsMethodsSum[kind][method] = roundMoney(receiptsMethodsSum[kind][method] || 0);
      receiptsMethodsSum['all'][method] = (receiptsMethodsSum['all'][method] || 0) + receiptsMethodsSum[kind][method];
    });
    receiptsMethodsSum[kind]['total'] = roundMoney(receiptsMethodsSum[kind]['total'] || 0);
    receiptsMethodsSum['all']['total'] = (receiptsMethodsSum['all']['total'] || 0) + receiptsMethodsSum[kind]['total'];
  });
  return receiptsMethodsSum;
}

/*function sumTurnoverActivities(reservations: CRM.ReservationStats[], filterDays?: DateRange) {
  const turnoverActivitiesSum: { [activity: string]: number } = {};
  turnoverActivitiesSum['canoe'] = 0.00;
  turnoverActivitiesSum['supervised'] = 0.00;
  turnoverActivitiesSum['additional'] = 0.00;
  turnoverActivitiesSum['total'] = 0.00;
  turnoverActivitiesSum['canoe'] = sumReceiptsOrRefundsMethods(reservations, 'receipt', undefined, filterDays)['total'];
  turnoverActivitiesSum['total'] = turnoverActivitiesSum['canoe'];
  // #TODO using condition reservation.activity.kind for additions
  //reservations.forEach(reservation => {

    //turnoverActivitiesSum['canoe'] += reservation.payment.amountInEuro;
    //turnoverActivitiesSum['total'] += reservation.payment.amountInEuro;
  //});

  return turnoverActivitiesSum;
}*/

function sumPeople(reservations:CRM.ReservationStats[]) {
  let peopleSum: number = 0;
  reservations.forEach(reservation => {
    peopleSum += countBookingPeople(reservation.people);
  });

  return peopleSum;
}

export function TurnoverView() {
  const classes = useStyles();
  const auth = useAuth();
  const [ activities, setActivities ] = useState<CRM.ActivityDocument[]>([]);
  const [activityId, setActivityId] = useState<string | undefined>();
  const { groups, getUsersOfGroup } = useAuthUsers();
  const [userId, setUserId] = useState<string | undefined>();
  const [filterDays, setFilterDays] = useState<DateRange>({
    start: dayjs(),
    end: dayjs(),
  });
  const [userGroupId, setUserGroupId] = useState<string | undefined>(
    auth.userGroup!.userGroupId,
  );
  const [currentCanoePage, setCurrentCanoePage] = useState<number>(1);
  const users = getUsersOfGroup(userGroupId);
  const salesPath = useDomainPath('/user-group');
  const reservationPath = useDomainPath('/reservation');
  const activityPath = useDomainPath('/activity');
  const [isCanoeLoading, setIsCanoeLoading] = useState<boolean>(false);
  const classesgrid: ClassNameMap<string> = useStylesGrid();
  //const [hasFilterChanged, setHasFilterChanged] = useState<boolean>(true);
  const [reservations, setReservations] = useState<CRM.ReservationDetailed[]>(
    [],
  );
  const [reservationsStats, setReservationsStats] = useState<CRM.ReservationStats[]>(
    [],
  );
  const [reservationsStatsForAll, setReservationsStatsForAll] = useState<CRM.ReservationStats[]>(
    [],
  );
  const refreshReservationsStats = useCallback(() => {
    const start = filterDays?.start?.format('YYYYMMDD') || dayjs();
    const end = filterDays?.end?.format('YYYYMMDD') || dayjs();
    //setHasFilterChanged(true);
    if(!userGroupId) {
      return get<CRM.ReservationStats[]>(
        `${reservationPath}/all/forstats?start=${start}&end=${end}&activityId=${activityId || ''}`,
      ).then(
        (stats) => {
          setReservationsStats(stats);
          setReservationsStatsForAll(stats);
        }
      );
    } else {
      return get<CRM.ReservationStats[]>(
        // For turnover table we need total turnover despite if Reservation Seller has 0 receipt
        `${salesPath}/${userGroupId}/reservations/forstats?start=${start}&end=${end}&activityId=${activityId || ''}`,
        /*`${reservationPath}/all/forstats?sellerUserGroup=${userGroupId}&start=${start}&end=${end}&active=1&activityId=${activityId || ''}`,*/
      ).then(
        (statsForGroup)=> {
          setReservationsStats(statsForGroup);
          return get<CRM.ReservationStats[]>(
            `${reservationPath}/all/forstats?start=${start}&end=${end}&activityId=${activityId || ''}`,
          ).then((statsForAll) => {
            setReservationsStatsForAll(statsForAll);
          }
          );
        }
      );
    }
  }, [salesPath, filterDays, userGroupId, reservationPath, activityId]);

  const refreshReservations = useCallback((/*reset?:boolean*/) => {
    if(!isCanoeLoading) {
      setIsCanoeLoading(true);
    }
    const reset: boolean = currentCanoePage === 1 ? true : false;
    const skip = (currentCanoePage-1)*100;
    //setHasFilterChanged(false);

    const start = filterDays?.start?.format('YYYYMMDD') || dayjs();
    const end = filterDays?.end?.format('YYYYMMDD') || dayjs();
    if(!userGroupId) {
      return get<CRM.ReservationDetailed[]>(
        /*`${reservationPath}?start=${start}&end=${end}&hydrated=true&active=1&activityId=${activityId || ''}&skip=${skip}&limit=100`,*/
        `${reservationPath}/forTurnover?start=${start}&end=${end}&hydrated=true&activityId=${activityId || ''}&skip=${skip}&limit=100`,
      ).then((r) => {setReservations(reset && reset === true ? r : reservations.concat(r)); setIsCanoeLoading(false);});
    } else {
      return get<CRM.ReservationDetailed[]>(
        /*`${salesPath}/${userGroupId}/reservations?start=${start}&end=${end}&activityId=${activityId || ''}&skip=${skip}&limit=100`,*/
        /*`${reservationPath}?sellerUserGroup=${userGroupId}&start=${start}&end=${end}&hydrated=true&active=1&activityId=${activityId || ''}&skip=${skip}&limit=100`,*/
        `${reservationPath}/forTurnover?sellerUserGroup=${userGroupId}&start=${start}&end=${end}&hydrated=true&activityId=${activityId || ''}&skip=${skip}&limit=100`,
        ).then((r) => {setReservations(reset && reset === true ? r : reservations.concat(r)); setIsCanoeLoading(false);});
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[salesPath, filterDays, userGroupId, reservationPath, activityId, /*reservations,*/ currentCanoePage]);


  useEffect(() => {
    setCurrentCanoePage(1);
    get<CRM.ActivityDocument[]>(`${activityPath}/?public=false`).then(
      domainActivities => {
        setActivities(domainActivities);
        refreshReservationsStats();
        // refreshReservations(true);
      });
  }, [refreshReservationsStats,/* refreshReservations,*/ filterDays, activityPath]);

  useEffect(() => {
    /*if(hasFilterChanged === true) {
      // setCurrentCanoePage(1);
      refreshReservations(true);
      console.log('refresh with CLEARING')
    } else {
      refreshReservations();
      console.log('refresh with ADDING')
    }*/
    console.log('refresh');
    refreshReservations();
  }, [refreshReservations]);

  const [selectedTab, setSelectedTab] = React.useState('synthesis');
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const ofUser = (userId: string | undefined) => {
    if (!userId) {
      return () => true;
    }
    return (res: CRM.ReservationDetailed) => {
      return res.sellerUser === userId;
    };
  };

  const ofStatsUser = (userId: string | undefined) => {
    if (!userId) {
      return () => true;
    }
    return (res: CRM.ReservationStats) => {
      return res.sellerUser === userId;
    };
  };
  const ofStatsUserGroup = (userGroupId: string | undefined) => {
    if (!userGroupId) {
      return () => true;
    }
    return (res: CRM.ReservationStats) => {
      return (
        res.sellerUserGroup === userGroupId || 
        // if at least one receipt is from tunnel, attributes turnover of all receipts to him ?
        /*((res.payment.receipts.find(r => r.userGroupId === userGroupId) !== undefined) && res.sellerUserGroup === "internet")*/
        ((res.pricingGroupId === userGroupId ) && res.sellerUserGroup === "internet")
        );
    };
  };

  const ofActivity = (activityId: string | undefined) => {
    if (!activityId) {
      return () => true;
    }
    return (res: CRM.ReservationDetailed) => {
      return res.activity._id === activityId;
    };
  };

  const filteredReservations = useMemo(
    () =>
      { console.log(reservations)
        return reservations
          .filter(ofActivity(activityId))
          .filter(ofUser(userId))
          .filter(res => res.status && res.status !== "booked" && res.status !== "gift-voucher");
          // #TODO add activity filter
      },
    [reservations, userId, activityId],
  );

  const filteredReservationsStats = useMemo(
    () =>
      {
        return reservationsStatsForAll
          .filter(ofStatsUserGroup(userGroupId))
          .filter(ofStatsUser(userId))
          .filter(res => res.status && res.status !== "booked" && res.status !== "gift-voucher");
      },
    [reservationsStatsForAll, userId, userGroupId],
  );

  const receiptsMethodsSum = useMemo(() => sumReceiptsOrRefundsMethods(reservationsStats, 'receipt', activities, userGroupId, filterDays), [
    reservationsStats,
    userGroupId,
    filterDays,
    activities
  ]);
  const refundsMethodsSum = useMemo(() => sumReceiptsOrRefundsMethods(reservationsStats, 'refund', activities, userGroupId, filterDays), [
    reservationsStats,
    userGroupId,
    filterDays,
    activities
  ]);

  const turnoverActivitiesSum = useMemo(() => sumReceiptsOrRefundsMethods(filteredReservationsStats, 'turnover', activities, undefined, filterDays)/*sumTurnoverActivities(filteredReservationsStats, filterDays)*/, [
    filteredReservationsStats,
    filterDays,
    activities
  ]);

  const peopleSum = useMemo(() => sumPeople(reservationsStats), [
    reservationsStats,
  ]);

  const handleBottom = () => {
    if(reservationsStats.length >= (currentCanoePage*100)) {
      setCurrentCanoePage(currentCanoePage+1);
    }
  };

  const print = useCallback(() => {
    // window.scrollTo(0, 0);
    const element = document.querySelector('#turnoverView');
    var win = window.open('', '_blank');
    if(win) {
      win.document.head.innerHTML = document.head.innerHTML;
      win.document.body.innerHTML = '<body><div style="max-width:1500px;margin-top:20px;overflow-x:visible">'+
      '<style>body, html, #wrapper, #gridHeader td,' +
      '.ellipsis td {font-size:0.8rem;} #gridHeader {padding-right:0!important;}'+
      '#richGrid div {margin:0px;padding-bottom:0px;padding-top:0px;}</style>' +
      element?.innerHTML +
      +'</div></body>';
      win.document.body.innerHTML = win.document.body.innerHTML.replace('NaN', '');
      win.document.getElementById('richGrid')
      ?.setAttribute('style', 'max-height: none');
      let ellipsisTexts = win.document.querySelectorAll(".ellipsis");
      let divSheets = win.document.querySelectorAll('#formSheet div');
      divSheets.forEach((txt) => {
        txt?.setAttribute('style', 'padding: 4px;margin-bottom:4px');
      });
      let th = win.document.querySelectorAll('#totalReceiptTab th');
      th.forEach((txt) => {
        txt?.setAttribute('style', 'color: #111');
      });

      ellipsisTexts.forEach((txt) => {
        txt?.setAttribute('style', 'width: 100%;display:inline-block;overflow:hidden');
      });

      const script = win.document.createElement('script');
    script.innerHTML = "setTimeout(() => {window.print();/*window.close();*/}, 100);";
      win.document.body.appendChild(script);

      let head = win.document.head || win.document.getElementsByTagName('head')[0],
      style = document.createElement('style');
      style.innerHTML = "@media print{@page {size: landscape; overflow-x: visible}   .hidden-print {display: none !important;}}";

      head.appendChild(style);
    }
  }, []);

  return (
    <div className={classes.root} id={'turnoverView'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mt={2}
      >
        <Box display={'flex'} alignItems={'center'}>
          Période :
          <DateRangeSelect range={filterDays} onChange={setFilterDays} />
          &nbsp;
          <DbDocumentSelect
            label={userGroupId ? "Groupe" : "Tous les Groupes"}
            selectedId={userGroupId}
            docs={groups}
            labelForEmpty="Tous les Groupes"
            onChange={group => {
              group ? setUserGroupId(group._id) : setUserGroupId(undefined);
              setUserId(undefined);
            }}
            allowNone
          />
          &nbsp;
          <DbDocumentSelect
            className="inputSize-lg"
            label={userId ? "Utilisateur" : 'Tous les Utilisateurs'}
            selectedId={userId}
            docs={users}
            labelForEmpty="Tous les Utilisateurs"
            onChange={user => {
              user ? setUserId(user._id) : setUserId(undefined);
            }}
            allowNone
          />
          &nbsp;
          <DbDocumentSelect
            className="inputSize-lg"
            label={activityId ? "Produit Canoë" : 'Tous les Produits Canoë'}
            selectedId={activityId}
            docs={activities}
            labelForEmpty="Tous les Produits Canoë"
            onChange={activity => {
              activity ? setActivityId(activity._id) : setActivityId(undefined);
            }}
            allowNone
          />
          <IconButton className="hidden-print" onClick={print} title={'imprimer'}>
            <PrintIcon />
          </IconButton>
        </Box>
      </Box>
      <div>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="menu caisse"
        >
          <Tab value="synthesis" label="Synthèse Chiffre d'Affaires" />
          <Tab value="canoes" label="Canoës" />
          <Tab value="supervised" label="Encadrées" />
          <Tab value="people" label="Participants" />
        </Tabs>
        <Divider variant="middle" />
        <TabPanel value={selectedTab} index="synthesis">
          <Box
            display={'inline'}
            alignItems={'normal'}
            justifyContent={'space-between'}
            mt={2}
          >
            <Box
              display={'inline-flex'}
              alignItems={'normal'}
              justifyContent={'space-between'}
              width={'48%'}
              style={{marginRight:'2%'}}
            >
              {/*<SalesTurnover
                turnoverActivitiesSum={turnoverActivitiesSum}
          />*/}
              <SalesByPaymentMethod
                receiptsMethodsSum={turnoverActivitiesSum}
                type={'receipt_resume'}
              />
            </Box>
            <Box
              display={'inline-flex'}
              alignItems={'normal'}
              justifyContent={'space-between'}
              width={'48%'}
              style={{marginRight:'2%'}}
              mt={2}
            >
              <SalesByPaymentMethod
                receiptsMethodsSum={receiptsMethodsSum}
              />
            </Box>
            <Box
              display={'inline-flex'}
              alignItems={'normal'}
              justifyContent={'space-between'}
              width={'48%'}
              style={{marginRight:'2%'}}
              mt={2}
            >
              <SalesByPaymentMethod
                receiptsMethodsSum={refundsMethodsSum}
                type={'refund'}
              />
            </Box>
            <Box
              display={'inline-flex'}
              alignItems={'normal'}
              justifyContent={'space-between'}
              width={'18%'}
              mt={2}
            >
              {<SalesResume
              range={filterDays}
              userGroupId={userGroupId}
              receiptsMethodsSum={receiptsMethodsSum['all']}
              refundsMethodsSum={refundsMethodsSum['all']}
              />}
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index="canoes">
          <Box flexGrow={0.85}>
            {<RichGrid
              style={{
                paddingRight:'10px', //compensate for scrollbar width
              }}
              columns={salesTableProvider(/*userGroupId*/ undefined).columns}
              rows={filteredReservations.filter(r => r.activity.kind === "canoe")}
              onBottom = {() => {handleBottom()}}
              renderRow={reservation => {
                if(sumPaymentReceipts(
                  reservation.payment,
                  //userGroupId
                ) === 0) {
                  return (<Grid style={{display:'none'}}></Grid>);
                }
                return(
                  <div className='Grid-row'>
                    <Grid
                      container
                      className={classesgrid && classesgrid.root}
                      spacing={1}
                      onClick={() => { window.open(`/reservations/${reservation._id}`) }}
                    >
                      {salesTableProvider(/*userGroupId*/ undefined).columns.map(column => {
                        const Cell = column.Cell;
                        return (
                          <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                            {Cell && <Cell value={reservation} handlers={{} as RowHandlers} />}
                            {column.renderCell &&
                              column.renderCell(reservation, {} as RowHandlers)}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
              );
              }}
            />}
            {isCanoeLoading && <CircularProgress
              style={{
                position:'absolute',
                marginTop:'-2rem',
                right:'0%',
                marginRight:'69px',
                zIndex:10
              }}
              disableShrink
            />}
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index="supervised">
          <Box flexGrow={0.85}>
            {<RichGrid
              style={{
                paddingRight:'10px', //compensate for scrollbar width
              }}
              columns={salesTableProvider(/*userGroupId*/ undefined).columns}
              rows={filteredReservations.filter(r => r.activity.kind === "supervised")}
              onBottom = {() => {handleBottom()}}
              renderRow={reservation => {
                if(sumPaymentReceipts(
                  reservation.payment,
                  //userGroupId
                ) === 0) {
                  return (<Grid style={{display:'none'}}></Grid>);
                }
                return(
                  <div className='Grid-row'>
                    <Grid
                      container
                      className={classesgrid && classesgrid.root}
                      spacing={1}
                      onClick={() => { window.open(`/reservations/${reservation._id}`) }}
                    >
                      {salesTableProvider(/*userGroupId*/ undefined).columns.map(column => {
                        const Cell = column.Cell;
                        return (
                          <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                            {Cell && <Cell value={reservation} handlers={{} as RowHandlers} />}
                            {column.renderCell &&
                              column.renderCell(reservation, {} as RowHandlers)}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
              );
              }}
            />}
            {isCanoeLoading && <CircularProgress
              style={{
                position:'absolute',
                marginTop:'-2rem',
                right:'0%',
                marginRight:'69px',
                zIndex:10
              }}
              disableShrink
            />}
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index="people">
          <Box
            display={'inline'}
            alignItems={'normal'}
            justifyContent={'space-between'}
            mt={2}
          >
              <Box
              display={'inline-flex'}
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
              mt={4}
            >
              <p style={{padding: '2rem', background: '#E8EDF3', borderRadius:'1rem', fontSize:'1.0rem'}}>
                <i>Nombre de Participants sur la Période :</i> <b>{peopleSum}</b>
              </p>
            </Box>

          </Box>
        </TabPanel>
        </div>
    </div>
  );
}
