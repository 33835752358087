import React, { useState, useMemo } from 'react';
import {
  Grid,
} from '@material-ui/core';
import { useActivity } from '../activity.context';
import { useAuth } from '../../../auth/auth.context';
import dayjs from 'dayjs';
import { TimeSlotsGrid } from './timeSlotsGrid.component';
import { EditTimeSlot } from './editTimeSlot.component';

export const TimeSlotsForm: React.FC = () => {
  const { activity } = useActivity();
  const defaultDateDeparture = dayjs().add(1, 'day').hour(9).minute(0).toDate();
  const defaultDateArrival = activity.overDays && activity.overDays === 2 ?
    dayjs().add(2, 'day').hour(15).minute(0).toDate() :
    dayjs().add(1, 'day').hour(15).minute(0).toDate();
  const auth = useAuth();
  // const [timeSlotId, setTimeSlotId] = useState<string>();

  const defaultActivity: CRM.ActivityDocument = {
    _id: ''
  } as any;
    const defaultActivityTimeSlot: CRM.ActivityTimeSlotDetailed = {
        _id: '',
        domain: auth.userGroup?.domainId || '',
        activity: activity as CRM.ActivityDocument || defaultActivity,
        timeSlotDeparture: defaultDateDeparture,//#TODO replace by other form fields
        timeSlotArrival: defaultDateArrival,
        maxPeople: 16,
        bookedPeople: 0,
        bookedPeopleWithMonitor: 0,
        maxPeopleWithMonitor: 4,
        minAutonomousPeople: 3,
        constrainReservations: true,
        closeReservations: false,
        monitor: 'bruno'
    }
  const [selectedActivityTimeSlot, setSelectedActivityTimeSlot] = useState<CRM.ActivityTimeSlotDetailed>(defaultActivityTimeSlot);
  const [refresh, setRefresh] = useState<number>(0);
  const [createtimeSlotsDates, setCreateTimeSlotsDates] = useState<Date[]>([/*dayjs().add(1,'day').toDate()*/]);

  const { minDate, maxDate } = useMemo(() => {
    const thisYear = dayjs().year(new Date().getFullYear());
    return {
      minDate: thisYear.startOf('year'),
      maxDate: thisYear.endOf('year'),
    };
  }, []);

  return (
    <Grid container spacing={3}>
      <EditTimeSlot
        setCreateTimeSlotsDates={setCreateTimeSlotsDates}
        createtimeSlotsDates={createtimeSlotsDates}
        setSelectedActivityTimeSlot={setSelectedActivityTimeSlot}
        selectedActivityTimeSlot={selectedActivityTimeSlot}
        //setTimeSlotId={setTimeSlotId}
        //timeSlotId={timeSlotId}
        minDate={minDate}
        maxDate={maxDate}
        onSave={() => { setRefresh(refresh + 1); }}
        callRefresh={() => { 
          setSelectedActivityTimeSlot(defaultActivityTimeSlot);
          setRefresh(refresh + 1); 
        }}
        allowCreate={true}
      />
      {activity && activity._id &&
        (<Grid item xs={12}>
          <TimeSlotsGrid
            //onChange={(t) => { setSelectedActivityTimeSlot(t); setTimeSlotId(t._id); }}
            setSelectedActivityTimeSlot={setSelectedActivityTimeSlot}
            selectedActivityTimeSlot={selectedActivityTimeSlot}
            activitiesIdFilter={[activity._id]}
            defaultActivityId={activity._id}
            refresh={refresh}
            allowDelete={true}
            allowCreate={false}
          />
        </Grid>)
      }
    </Grid>
  );
};

