import React, { FormEvent, useCallback, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import DatePicker from 'react-multi-date-picker';
import { PositiveNumberField } from '../../../components/fields/PositiveNumberField.component';
import { SaveTimeSlot } from './saveTimeSlot.component';
import { put, post, httpDelete } from '../../../fetch';
import { useDomainPath } from '../../../auth/auth.context';
import { useAlerts } from '../../../hooks/useAlerts';
import { AlertDialog } from '../../../components/alerts/AlertDialog.component';
import Icon from "react-multi-date-picker/components/icon"

interface EditTimeSlotProps {
  setCreateTimeSlotsDates: React.Dispatch<React.SetStateAction<Date[]>>;
  createtimeSlotsDates: Date[];
  setSelectedActivityTimeSlot: React.Dispatch<React.SetStateAction<CRM.ActivityTimeSlotDetailed>>;
  selectedActivityTimeSlot: CRM.ActivityTimeSlotDetailed;
  //setTimeSlotId: React.Dispatch<React.SetStateAction<string | undefined>>;
  //timeSlotId: string | undefined;
  minDate: dayjs.Dayjs;
  maxDate: dayjs.Dayjs;
  onSave: () => void;
  callRefresh: () => void;
  allowCreate?: boolean;
}

export function EditTimeSlot({
  setCreateTimeSlotsDates,
  createtimeSlotsDates,
  setSelectedActivityTimeSlot,
  selectedActivityTimeSlot,
  //setTimeSlotId,
  //timeSlotId,
  minDate,
  maxDate,
  onSave,
  callRefresh,
  allowCreate,
  }: EditTimeSlotProps) {
    const path = useDomainPath(`/activity-timeslots`);
    const { notify } = useAlerts();
    const defaultArrivalDay: number = selectedActivityTimeSlot.activity.overDays === 2 ? 2 : 1;
    const defaultDateDeparture = dayjs().add(1, 'day').hour(9).minute(0).toDate();
    const defaultDateArrival = dayjs().add(defaultArrivalDay, 'day').hour(15).minute(0).toDate();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const deleteTimeSlotDialog = useCallback(() => setDeleteDialogOpen(true), [
      setDeleteDialogOpen,
    ]);
    const deleteTimeSlotDialogClose = useCallback(
      () => setDeleteDialogOpen(false),
      [setDeleteDialogOpen],
    );
    const deleteTimeSlot = useCallback(
      () =>
        httpDelete(`${path}/${selectedActivityTimeSlot._id}`).then(() =>
          {callRefresh(); deleteTimeSlotDialogClose();}
        ),
      // #TODO fix eventually these dependancies for not causes infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onSave, selectedActivityTimeSlot._id, path, deleteTimeSlotDialogClose],
    );

    const save = async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        /*if (
          !activity._id //||
          //!externalActivityIds ||
        ) {
          notify('Remplir tous les champs avant de sauvegarder', 'error');
          return;
        }*/
        //if selected, create timeslot from it
          const createTimeSlot: CRM.CreateActivityTimeSlot = {
              activity: selectedActivityTimeSlot.activity._id,
              timeSlotDeparture: selectedActivityTimeSlot.timeSlotDeparture,//#TODO replace by other form fields
              timeSlotArrival: selectedActivityTimeSlot.timeSlotArrival,
              maxPeople: selectedActivityTimeSlot.maxPeople,
              maxPeopleWithMonitor: selectedActivityTimeSlot.maxPeopleWithMonitor,
              minAutonomousPeople: selectedActivityTimeSlot.minAutonomousPeople,
              constrainReservations: selectedActivityTimeSlot.constrainReservations,
              monitor: selectedActivityTimeSlot.monitor,
              closeReservations: selectedActivityTimeSlot.closeReservations
          };
  
        if (selectedActivityTimeSlot._id.valueOf() !== ''/*timeSlotId*/) {
          await put<CRM.CreateActivityTimeSlot>(
            `${path}/${selectedActivityTimeSlot._id}`,
            createTimeSlot,
          );
        } else {
          for (const timeSlotDate of createtimeSlotsDates) {
            console.log(createTimeSlot);
            const arrivalDate: Date = defaultArrivalDay === 2 ? dayjs(timeSlotDate).add(1, 'day').toDate() : dayjs(timeSlotDate).toDate();
            const departure:dayjs.Dayjs = dayjs(createTimeSlot.timeSlotDeparture);
            const arrival:dayjs.Dayjs = dayjs(createTimeSlot.timeSlotArrival);
            createTimeSlot.timeSlotDeparture = dayjs(timeSlotDate).hour(departure.hour()).minute(departure.minute()).toDate();
            createTimeSlot.timeSlotArrival = dayjs(arrivalDate).hour(arrival.hour()).minute(arrival.minute()).toDate();
            await post<CRM.CreateActivityTimeSlot>(path, createTimeSlot);
          }
          // await post<CRM.CreateActivityTimeSlot>(path, createTimeSlot);
        }
        notify('Le document a été mis à jour', 'success');
        onSave();//setRefresh(refresh+1);
      } catch (err) {
        notify('Impossible de mettre à jour le document', 'error');
      }
    };

  return (
    <Grid item xs={8}>
    <form onSubmit={save}>
      <Grid item xs={12}>
          <Box mb={2}>
            <Typography variant="h5">
              Paramètres du créneau :
            </Typography>
          </Box>
    </Grid>
    <Grid item container spacing={3} xs={12}>
        <Grid item xs={5} md={4}>
            <PositiveNumberField
                label="Nombre max de participants par créneau"
                variant="outlined"
                value={selectedActivityTimeSlot.maxPeople}
                onChange={e => setSelectedActivityTimeSlot({...selectedActivityTimeSlot, maxPeople: Number(e.target.value)})}
                fullWidth
                required
            />
        </Grid>
        <Grid item xs={5} md={4}>
            <PositiveNumberField
                label="Nombre max de places avec moniteur"
                variant="outlined"
                value={selectedActivityTimeSlot.maxPeopleWithMonitor}
                onChange={e => setSelectedActivityTimeSlot({...selectedActivityTimeSlot, maxPeopleWithMonitor: Number(e.target.value)})}
                fullWidth
                required
            />
        </Grid>
    </Grid>
    <Grid item container spacing={3} xs={12}>
    <Box ml={2} mt={1} mb={1}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={e => setSelectedActivityTimeSlot({...selectedActivityTimeSlot, constrainReservations: e.target.checked})}
                  checked={selectedActivityTimeSlot.constrainReservations}
                />
              }
              label="Contraintes lors de la réservation"
              labelPlacement="end"
            />
    </Box>
    <Box ml={2} mt={1} mb={1}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={e => setSelectedActivityTimeSlot({...selectedActivityTimeSlot, closeReservations: e.target.checked})}
                  checked={selectedActivityTimeSlot.closeReservations}
                />
              }
              label="Fermer les inscriptions sur ce créneau"
              labelPlacement="end"
            />
    </Box>
    </Grid>
    <Grid item container spacing={3} xs={12}>
        <Grid item xs={8} md={6}>
            <PositiveNumberField
                label="Nombre minimum de places autonomes pour une place - de 7 ans"
                variant="outlined"
                value={selectedActivityTimeSlot.minAutonomousPeople}
                onChange={e => setSelectedActivityTimeSlot({...selectedActivityTimeSlot, minAutonomousPeople: Number(e.target.value)})}
                fullWidth
                required
            />
        </Grid>
    </Grid>
    <Grid item container spacing={3} xs={12}>
      <Box mt={3} ml={2}>
        <Grid item container spacing={3} alignItems={'center'}>
              {selectedActivityTimeSlot._id &&
                <Grid item>
                  <KeyboardDatePicker
                    disableToolbar
                    disablePast={true}
                    variant="inline"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label={`Date`}
                    value={selectedActivityTimeSlot.timeSlotDeparture}
                    onChange={date => setSelectedActivityTimeSlot({
                      ...selectedActivityTimeSlot,
                      timeSlotDeparture: date?.toDate() || defaultDateDeparture,
                      timeSlotArrival: (
                        defaultArrivalDay === 2 ? date?.add(1, 'day').hour(15).minute(0).toDate() : 
                        date?.hour(15).minute(0).toDate()) || 
                        defaultDateArrival,
                    })}
                    minDate={minDate}
                    maxDate={maxDate}
                    autoOk
                    required
                  />
                  &nbsp;
                </Grid>
              }
              { !selectedActivityTimeSlot._id &&
                <Grid item>
                  <Box style={{textAlign:'center', fontSize: '16px', height:'2rem', paddingTop: '0.0rem'}}>Sélectionner une où plusieurs dates :<br/>
                  <DatePicker
                    minDate={minDate.toDate()}
                    maxDate={maxDate.toDate()}
                    multiple
                    render={<Icon />}
                    format="DD/MM/YYYY"
                    style={{ height: '2rem' }}
                    value={createtimeSlotsDates}
                    onClose={() => {
                      if(createtimeSlotsDates.length === 0) {
                        //setCreateTimeSlotsDates([dayjs().add(1,'day').toDate()]);
                      }
                    }}
                    onChange={(dates:any) => {
                      let _dates: Date[] = [];
                      if (dates !== null && Array.isArray(dates) && dates.length > 0) {
                        dates.forEach((d:any) => {
                          _dates.push(d.toDate())
                        });
                      } else if (dates !== null && !Array.isArray(dates)) {
                        _dates.push(dates.toDate())
                      }
                      setCreateTimeSlotsDates(_dates);
                    }}
                  />
                  </Box> 
                  &nbsp;
                </Grid>
              }
            <Grid item>
                <TimePicker
                    disableToolbar
                    ampm={false}
                    variant="inline"
                    inputVariant="outlined"
                    label="Horaire de départ *"
                    value={selectedActivityTimeSlot.timeSlotDeparture}
                    onChange={date => setSelectedActivityTimeSlot({...selectedActivityTimeSlot, timeSlotDeparture: date?.toDate() || defaultDateDeparture})}
                />
            </Grid>
            <Grid item>
            <TimePicker
                    disableToolbar
                    ampm={false}
                    variant="inline"
                    inputVariant="outlined"
                    label="Horaire d'arrivée *"
                    value={selectedActivityTimeSlot.timeSlotArrival}
                    onChange={
                      date => setSelectedActivityTimeSlot({
                        ...selectedActivityTimeSlot, 
                        timeSlotArrival: date?.toDate() || defaultDateArrival
                      })
                    }
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Moniteur"
                    variant="outlined"
                    value={selectedActivityTimeSlot.monitor}
                    onChange={e => setSelectedActivityTimeSlot({...selectedActivityTimeSlot, monitor: e.target.value})}
                    fullWidth
                    required
                />
            </Grid>
        </Grid>
        <Grid item container spacing={3} alignItems={'center'}>
            <SaveTimeSlot
                value={selectedActivityTimeSlot}
                timeSlotId={selectedActivityTimeSlot._id}
                dates= {createtimeSlotsDates}
                onChange={id => {
                  if(selectedActivityTimeSlot._id.valueOf() !== '' && id.valueOf() === '') { 
                    // reset multi selector dates if a timeslot has selected and create new ts clicked
                    setCreateTimeSlotsDates([selectedActivityTimeSlot.timeSlotDeparture]);
                  }
                  setSelectedActivityTimeSlot({...selectedActivityTimeSlot, _id: id});
                  //setTimeSlotId(id);
                }}
                displayCreate={allowCreate}
                onDeleteButtonClick={deleteTimeSlotDialog}
                onAbortButtonClick={callRefresh}
            />
        </Grid>
    </Box>
    </Grid>
    <AlertDialog
          accept={deleteTimeSlot}
          cancel={deleteTimeSlotDialogClose}
          open={deleteDialogOpen}
          title={'Confirmer'}
          content={
             "La suppression d'un créneau est irréversible, toutes les réservations liées en corbeille seront également supprimées, voulez-vous vraiment continuer ?"
          }
    />
</form>
</Grid>
  );
};
