import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useChangeEventUpdate } from '../../hooks/useChangeEventUpdate';

interface Props {
  value?: CRM.ActivityKind;
  onChange: (value: CRM.ActivityKind) => any;
}

export function ActivityKindSelect({ onChange, value }: Props) {
  useEffect(() => {
    if (!value) {
      onChange('supervised');
    }
  }, [onChange, value]);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="Kind-select-label">Type d'activité</InputLabel>
      <Select
        labelId="Kind-select-label"
        value={value || ''}
        label="Type d'activité"
        onChange={useChangeEventUpdate<CRM.ActivityKind>(onChange)}
      >
        <MenuItem value="supervised">Encadrée</MenuItem>
        <MenuItem value="canoe">Canoë</MenuItem>
      </Select>
    </FormControl>
  );
}
