import React, { useEffect, useMemo, useState } from 'react';
import { Box, Chip, CircularProgress, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RowingIcon from '@material-ui/icons/Rowing';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { isEmpty, sortBy } from 'lodash';
import { useDomainPath } from '../../auth/auth.context';
import { get } from '../../fetch';
import clsx from 'clsx';
// import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  value: string[];
  onChange: (activities: string[]) => any;
  activitiesForGroup?: CRM.PublicActivity[];
  nowrapLabels?:boolean;
  kindFilter?:CRM.ActivityKind[];
}
const useStyles = makeStyles((theme: Theme) => ({
  activity: {
    fontSize: '1rem',
    marginRight: theme.spacing(1),
    marginBottom: '2px',
  },
  selected: {
    textDecoration: 'underline',
    backgroundColor: '#D5DADF',
  },
}));

export const ActivityFilter = ({ value, onChange, activitiesForGroup, nowrapLabels, kindFilter }: Props) => {
  const classes = useStyles();
  const activityPath = useDomainPath('/activity');
  const [activities, setActivities] = useState<CRM.PublicActivity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const sortedActivities = useMemo(() => sortBy(activities, a => a.kind), [
    activities,
  ]);

  useEffect(() => {
    setLoading(true);
    get<CRM.PublicActivity[]>(`${activityPath}/?public=true`).then(
      domainActivities => {
        const favoriteActivityNames= [
          'Mini_mini',
          // '5 Km',
          'canoe_1j_8km',
          'canoe_1j_12km',
          'canoe_1j_24km',
          'canoe_1j_32km',
          'canoe_2j_32km-bivouac',
          '36km 2 jours',
        ];
        if(kindFilter && kindFilter.length > 0) {
          domainActivities = domainActivities.filter(a => kindFilter.find(k => k === a.kind));
        }
        const favActivities = domainActivities.filter(a => favoriteActivityNames.includes(a.name));
        const otherActivities = domainActivities.filter(a => !favoriteActivityNames.includes(a.name));
        //console.log(favActivities, otherActivities)
        setActivities(favActivities.concat(otherActivities) || otherActivities);
        setLoading(false);
      },
    );
  }, [activityPath, kindFilter]);

  const onClick = (activity: CRM.PublicActivity, isSelected: boolean) => {
    if (isSelected) {
      onChange(value.filter(selectedId => selectedId !== activity.id));
    } else {
      onChange([...value, activity.id]);
    }
  };

  if (isEmpty(activities) && loading) {
    return <CircularProgress size="1em" />;
  }
  if (isEmpty(activities) && !loading) {
    return <label>Aucun type d'activité à afficher</label>;
  }

  return (
    <Box
    display={'flex'}
    alignItems={'center'}
    flexWrap={ nowrapLabels && nowrapLabels === true ? 'nowrap' : 'wrap'}
    style={{ overflowX: nowrapLabels && nowrapLabels === true ? 'auto' : 'hidden'  }}
    >
      {activitiesForGroup !== undefined && activitiesForGroup.map(activity => {
        const isSelected = value.includes(activity.id);
        return (
          <Chip
            className={clsx(classes.activity, {
              [classes.selected]: isSelected,
            })}
            icon={
              activity.kind === 'canoe' ? <RowingIcon /> : <DirectionsRunIcon />
            }
            key={activity.id}
            label={activity.displayName}
            onClick={() => onClick(activity, isSelected)}
          />
        );
      })}
      {activitiesForGroup === undefined && sortedActivities.map(activity => {
        const isSelected = value.includes(activity.id);
        return (
          <Chip
            className={clsx(classes.activity, {
              [classes.selected]: isSelected,
            })}
            icon={
              activity.kind === 'canoe' ? <RowingIcon /> : <DirectionsRunIcon />
            }
            key={activity.id}
            label={activity.displayName}
            onClick={() => onClick(activity, isSelected)}
          />
        );
      })}
    </Box>
  );
};
