import React from 'react';
import { Chip } from '@material-ui/core';
import { paymentStatus } from '@crm/utils';

interface PaymentStatusBadgeProps {
  payment: CRM.ReservationPayment;
}

const badges: Record<CRM.ReservationPaiementStatus, React.ReactElement> = {
  paid: (
    <Chip
      size="medium"
      label="PAYÉE"
      //title="PAYÉE"
      color="secondary"
      className={'bold bgGreen'}
      style={{fontSize: '0.9rem'}}
    />
  ),
  unpaid: (
    <Chip
      size="medium"
      label="NON PAYÉE"
      //title="NON PAYÉE"
      color="secondary"
      className={'bold bgRed'}
      style={{fontSize: '0.9rem'}}
    />
  ),
  failed: (
    <Chip
      size="medium"
      label="ECHEC"
      //title="ECHEC"
      color="secondary"
      className={'bold bgYellow'}
      style={{fontSize: '0.9rem'}}
    />
  ),
  needRefund: (
    <Chip
      size="medium"
      label="A REMBOURSER"
      //title="A REMBOURSER"
      color="secondary"
      className={'bold bgOrange'}
      style={{fontSize: '0.9rem'}}
    />
  ),
  partialRefund: (
    <Chip
      size="medium"
      label="REMB. PARTIEL"
      //title="REMB. PARTIEL"
      color="secondary"
      className={'bold bgYellow'}
      style={{fontSize: '0.9rem'}}
    />
  ),
  refunded: (
    <Chip
      size="medium"
      label="REMBOURSÉE"
      //title="REMBOURSÉE"
      color="secondary"
      className={'bold bgGreen'}
      style={{fontSize: '0.9rem'}}
    />
  ),
  partial: (
    <Chip
      size="medium"
      label="ACOMPTE"
      //title="ACOMPTE"
      color="secondary"
      className={'bold bgOrange'}
      style={{fontSize: '0.9rem'}}
    />
  ),
  waitToPaid: (
    <Chip
      size="medium"
      label="PAIEMENT EN ATTENTE"
      //title="PAIEMENT EN ATTENTE"
      color="secondary"
      className={'bold bgOrange'}
      style={{fontSize: '0.9rem'}}
    />
  ),
};

export function PaymentStatusBadge({ payment }: PaymentStatusBadgeProps) {
  const status = paymentStatus(payment, true);
  return badges[status];
}
