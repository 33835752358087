import React, {
  ChangeEvent,
  Context,
  createContext,
  FormEvent,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { Action } from '../../hooks/action';
import {
  useChangeEventPartialUpdate,
  useValuePartialUpdate,
} from '../../hooks/useChangeEventUpdate';
import dayjs from 'dayjs';
import { buildYearlyPricingPeriods } from './periods/buildYearlyPricingPeriods';

type ActivityAction = Action<Partial<CRM.ActivityDocument>>;

interface ActivityContext {
  activity: Partial<CRM.ActivityDocument>;
  dispatch: (action: ActivityAction) => void;
  update: (payload: Partial<CRM.ActivityDocument>) => void;
  save: (evt?: FormEvent) => void;
}

const activityContext: Context<ActivityContext> = createContext(null) as any;

export function useActivity(): ActivityContext {
  const context = useContext(activityContext);
  return context;
}

export const useActivityChangeEventUpdate = (
  key: keyof CRM.ActivityDocument,
): ((e: ChangeEvent<{ name?: string; value: string }>) => void) => {
  const { update } = useActivity();
  return useChangeEventPartialUpdate(key, update);
};

export function useActivityValueUpdate<T>(
  key: keyof CRM.ActivityDocument,
): (value: T) => void {
  const { update } = useActivity();
  return useValuePartialUpdate(key, update);
}

const reducer: Reducer<Partial<CRM.ActivityDocument>, ActivityAction> = (
  state,
  action,
) => {
  console.log('ACTION', action.type, action.payload);
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};

interface Props {
  initialValue?: CRM.ActivityDocument;
  onSave: (value: Partial<CRM.ActivityDocument>) => void;
}
export const ProvideActivity: React.FC<Props> = ({
  onSave,
  initialValue,
  children,
}) => {
  const [activity, dispatch] = useReducer(reducer, {
    kind: 'supervised',
    minAge: 7,
    estimatedTimeMinutes: 120,
    overDays: 1,
    allowedUserGroups: [],
    pricingPeriods: buildYearlyPricingPeriods(dayjs().year()),
    ...initialValue,
  });

  const update = useCallback((payload: Partial<CRM.ActivityDocument>) => {
    dispatch({ type: 'update', payload });
  }, []);

  const save = useCallback(
    (evt?: FormEvent) => {
      evt && evt.preventDefault();
      onSave(activity);
    },
    [onSave, activity],
  );

  return (
    <activityContext.Provider value={{ activity, dispatch, update, save }}>
      {children}
    </activityContext.Provider>
  );
};
