import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useChangeEventUpdate } from '../hooks/useChangeEventUpdate';

interface Props {
  value?: CRM.I18nLang;
  onChange: (value: CRM.I18nLang) => any;
}

export function LangSelect({ onChange, value }: Props) {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="Lang-select-label">Langue</InputLabel>
      <Select
        labelId="Lang-select-label"
        value={value || ''}
        label="Langue"
        onChange={useChangeEventUpdate<CRM.I18nLang>(onChange)}
      >
        <MenuItem value="fr">Français</MenuItem>
        <MenuItem value="en">Anglais</MenuItem>
      </Select>
    </FormControl>
  );
}
