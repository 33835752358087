import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { merge } from 'lodash';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';
import { GridItemProps } from '../components/GridItem';
import { RowHandlers, Table } from '../components/table/Table';
import { useReservations } from './reservations.context';

const useStyles = makeStyles<
  DefaultTheme,
  { reservation: CRM.ReservationDetailed }
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.3, 0),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
}));

export function ReservationGridItem({
  value,
  columns,
}: GridItemProps<CRM.ReservationDetailed> & Table<CRM.ReservationDetailed>) {
  const classes = useStyles({ reservation: value });
  const history = useHistory();
  const { updateDispatch, updates } = useReservations();
  const recordedUpdates = updates[value._id];
  const reservationPath = `/reservations/${value._id}`;

  const updateReservation = useCallback(() => {
    history.push(reservationPath);
  }, [reservationPath, history]);

  const handlers: RowHandlers = useMemo(
    () => ({
      dispatch: updateDispatch,
      onUpdate: updateReservation,
    }),
    [updateDispatch, updateReservation],
  );

  const withOverridesValue = useMemo(() => {
    if (!recordedUpdates) {
      return value;
    }
    const clone = { ...value };
    merge(clone, recordedUpdates);
    return clone;
  }, [recordedUpdates, value]);

  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      onClick={updateReservation}
    >
      {columns.map(column => {
        const Cell = column.Cell;
        return (
          <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
            {Cell && <Cell value={withOverridesValue} handlers={handlers} />}
            {column.renderCell &&
              column.renderCell(withOverridesValue, handlers)}
          </Grid>
        );
      })}
    </Grid>
  );
}
