import { DomainGroupAutocomplete } from '../../../components/DomainGroupAutocomplete.components';
import React from 'react';
import { useAuthUsers } from '../../../auth/auth.context';
import { USER_GROUPS } from '@crm/utils';

interface Props {
  selectedIds: string[];
  onChange: (value: CRM.UserGroupDocument[]) => any;
}

export const UserGroups: React.FC<Props> = props => {
  if (props.selectedIds.includes(USER_GROUPS.NORMAL)) {
    return <span>Prix de base</span>;
  }
  if (props.selectedIds.includes(USER_GROUPS.INTERNET)) {
    return <span>Prix internet</span>;
  }
  return <UserGroupsAutocomplete {...props} />;
};

export const UserGroupsAutocomplete: React.FC<Props> = props => {
  const { groups } = useAuthUsers();
  return <DomainGroupAutocomplete {...props} availableGroups={groups} />;
};
