import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { isEmpty } from 'lodash';
import { useAuth, useAuthUsers } from './auth.context';
import {
  Avatar,
  Box,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(() => ({
  iconColor: {
    color: "white",
    '& .MuiSelect-icon': {
      color:'white'
    }
  },
  accountIcon: {
    marginBottom: -4,
  }
}));


export function LogoutButton() {
  const history = useHistory();
  const auth = useAuth();

  return (
    <IconButton
      title={'Déconnexion'}
      size={'small'}
      onClick={() => auth.signout(() => history.replace('/'))}
    >
      <ExitToAppIcon />
    </IconButton>
  );
}

export function UserSelect() {
  const { groupUsers, loggedUser, setLoggedUser } = useAuthUsers();
  const classes = useStyles();

  if (isEmpty(groupUsers) || !loggedUser?._id) {
    return null;
  }

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel style={{ color: '#152738', marginTop: '-3px'}} id="Utilisateur-select-label">Utilisateur </InputLabel>
      <Select
        style={{
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: 'rgba(0,0,0,0.54)',
          boxShadow: 'inset 0px 0px 0px 5px #E8EDF3',
          borderRadius: '10px'
        }}
        className={classes.iconColor}
        labelId="Utilisateur-select-label"
        value={loggedUser?._id || ''}
        label="Utilisateur"
        onChange={e => {
          const newSelected = groupUsers.find(
            user => user._id === e.target.value,
          );
          if (newSelected) {
            setLoggedUser(newSelected);
          }
        }}
      >
        {groupUsers.map(user => 
        { 
          return (
            <MenuItem value={user._id} key={user._id}>
              <CardHeader
                style={{paddingTop:'0.2em', paddingBottom:'0.2em', paddingLeft:'0.2em'}}
                title={user.displayName}
                avatar={
                  user.profilePicUrl && user.profilePicUrl !== '' ? 
                    <Avatar alt='' src={user.profilePicUrl}/> : 
                    <Avatar>{user.displayName.charAt(0)}</Avatar>
                  }
              >
              </CardHeader>
            </MenuItem>
          );
        /*  return (
          <MenuItem value={user._id} key={user._id}>
            <AccountCircleIcon className={classes.accountIcon}/>&nbsp;{user.displayName}
          </MenuItem>
        );*/
      }
        ) }
      </Select>
    </FormControl>
  );
}

export function AuthButton() {
  const auth = useAuth();

  return auth.userGroup?.login ? (
    <Box display="flex" alignItems="center" justifyContent={'space-between'}>
      <UserSelect />
      <LogoutButton />
    </Box>
  ) : (
    <Link to="/login">Se connecter</Link>
  );
}
