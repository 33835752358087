import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { AuthView } from './auth/auth.view';
import { PrivateRoute } from './auth/private.route';
import { ActivityRouter } from './activities/activities.router';
import { TimeSlotsRouter } from './activities/create/timeslots/timeslots.router';
import { ReservationRouter } from './reservations/reservations.router';
import { UserGroupRouter } from './user/userGroupRouter';
import { DomainRouter } from './domain/domain.router';
import { SpotsRouter } from './spots/spots.router';
import { ArrivalsRouter } from './arrivals/arrivals.router';
import { DeparturesRouter } from './departures/departures.router';
import { Sidebar } from './Sidebar';
import './App.css';
import { SalesRouter } from './sales/sales.router';
import { PricesRouter } from './prices/prices.router';
import { ROLES } from '@crm/utils';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { TurnoverRouter } from './turnover/turnover.router';
import { VehiclesRouter } from './vehicles/vehicles.router';

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    marginLeft: 200,
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
}));

function App() {
  const classes = useStyles();
  return (
    <Router>
      <div className="App">
        <Sidebar />
        <div className={classes.body}>
          <Switch>
            <PrivateRoute path="/domain" allowedRoles={[ROLES.ADMIN]}>
              <DomainRouter />
            </PrivateRoute>
            <PrivateRoute path="/spots" allowedRoles={[ROLES.ADMIN]}>
              <SpotsRouter />
            </PrivateRoute>
            <PrivateRoute path="/user-groups" allowedRoles={[ROLES.ADMIN]}>
              <UserGroupRouter />
            </PrivateRoute>
            <PrivateRoute
              path="/sales"
              allowedRoles={[ROLES.ADMIN, ROLES.SELLER, ROLES.PARTNER]}
            >
              <SalesRouter />
            </PrivateRoute>
            <PrivateRoute
              path="/turnover"
              allowedRoles={[ROLES.ADMIN]}
            >
              <TurnoverRouter />
            </PrivateRoute>
            <PrivateRoute
              path="/prices"
              allowedRoles={[ROLES.ADMIN, ROLES.SELLER, ROLES.PARTNER]}
            >
              <PricesRouter />
            </PrivateRoute>
            <PrivateRoute path="/activities" allowedRoles={[ROLES.ADMIN]}>
              <ActivityRouter />
            </PrivateRoute>
            <PrivateRoute
              path="/timeslots"
              allowedRoles={[ROLES.ADMIN, ROLES.SELLER, ROLES.PARTNER]}
            >
              <TimeSlotsRouter />
            </PrivateRoute>
            <PrivateRoute
              path="/departures"
              allowedRoles={[ROLES.ADMIN, ROLES.SELLER, ROLES.DRIVER]}
            >
              <DeparturesRouter />
            </PrivateRoute>
            <PrivateRoute
              path="/arrivals"
              allowedRoles={[ROLES.ADMIN, ROLES.SELLER]}
            >
              <ArrivalsRouter />
            </PrivateRoute>
            <PrivateRoute
              path="/driver-travellist"
              allowedRoles={[ROLES.DRIVER]}
            >
            </PrivateRoute>
            <PrivateRoute
              path="/driver-arrivals"
              allowedRoles={[ROLES.ADMIN, ROLES.DRIVER]}
            >
              <ArrivalsRouter />
            </PrivateRoute>
            <PrivateRoute
              path="/driver-vehicle"
              allowedRoles={[ROLES.DRIVER]}
            >
            </PrivateRoute>
            <PrivateRoute
              path="/vehicles"
              allowedRoles={[ROLES.ADMIN, ROLES.SELLER, ROLES.PARTNER]}   
            >
              <VehiclesRouter />
            </PrivateRoute>
            <PrivateRoute
              path="/reservations"
              allowedRoles={[ROLES.ADMIN, ROLES.SELLER, ROLES.PARTNER]}
            >
              <ReservationRouter />
            </PrivateRoute>
            <Route path="/login">
              <AuthView />
            </Route>
            <Route>
              <Redirect to={{ pathname: '/login' }} />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
